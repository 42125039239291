// istanbul ignore file
import type { KeyboardEvent, MouseEvent } from 'react';
import { useCallback, useMemo, useState } from 'react';
import type { ParsedListDefinition, ParsedListItem, ViewModel } from '@cyferd/client-engine';
import { GeneralModel, capitalize, usePrevious } from '@cyferd/client-engine';
import { Input } from '@components/elements/Input';
import { TableCell } from '@components/elements/Table/TableCell';
import { styles } from './styles';
import { Icon } from '@components/elements/Icon';
import { COLOR, ENV, FONT_SIZE, GAP, TRANS } from '@constants';
import { PopoverTrigger } from '@components/elements/Popover';
import { ReadonlyFormat } from '@components/elements/ReadonlyFormat';
import { Switch } from '@components/elements/Switch';
import { Checkbox } from '@components/elements/Checkbox';
import { DropDownOptionEditTable } from '@components/elements/DropDownOptionEditTable';
import { OptionMenu } from '@components/elements/OptionMenu';
import { onCopy } from './onCopy';
import { useDebouncedOnChange } from './useDebouncedOnChange';
import { handleClipboardPaste, isControlKey } from '@utils';
import { DropdownMenuType } from '@components/elements/DropdownMenu';
import { rowIndex$ } from './constants';
import { AssociationInputEditTable } from '@components/elements/AssociationInput';
import { CollectionLookupEditTable } from '@components/elements/CollectionLookup';

const renderCellInput = ({ format, recordId, ...props }) => {
  switch (format) {
    case GeneralModel.JSONSchemaFormat.CHECKBOX:
      return (
        <div style={{ width: GAP.L }}>
          <Checkbox label="" autoFocus={false} {...props} />
        </div>
      );
    case GeneralModel.JSONSchemaFormat.SWITCH:
      return <Switch label="" autoFocus={false} {...props} />;
    case GeneralModel.JSONSchemaFormat.STRING_OPTION_LIST:
    case GeneralModel.JSONSchemaFormat.NUMBER_OPTION_LIST:
      return <DropDownOptionEditTable {...props} autoFocus={true} value={props.value} onChange={props.onChange} options={props.options} />;
    case GeneralModel.JSONSchemaFormat.ASSOCIATION:
      return (
        <AssociationInputEditTable
          {...props}
          autoFocus={true}
          recordId={recordId}
          apiQuery={{
            ...props.apiQuery,
            cursor: {
              ...props.apiQuery.cursor,
              id: recordId,
              uniqueId: `${props.apiQuery?.cursor?.collectionId}_${recordId}`
            }
          }}
          path={props.definition.path}
          subtype={GeneralModel.JSONSchemaSubtype.ASSOCIATION_DROPDOWN}
          schema={props.definition.property}
          value={props.value}
          disableCreateNew={true}
          shouldSetRecord={true}
          doClear={true}
          doSet={true}
        />
      );
    case GeneralModel.JSONSchemaFormat.COLLECTION_LOOKUP:
      return (
        <CollectionLookupEditTable
          {...props}
          autoFocus={true}
          collectionId={props.definition?.property?.metadata?.collectionId}
          recordId={recordId}
          onChange={props.onChange}
        />
      );
    default:
      return <Input type={format} autoFocus={true} noWrapperPadding={true} onChange={props.onChange} {...props} />;
  }
};

const CHECKBOX_FORMATS = [GeneralModel.JSONSchemaFormat.CHECKBOX, GeneralModel.JSONSchemaFormat.SWITCH];
export interface EditTableCellProps {
  rowIndex: number;
  colIndex: number;
  setEditing?: (rowIndex: number, colIndex: number, value: boolean) => void;
  rowId?: string;
  setSelected?: (rowIndex: number, colIndex: number) => void;
  setCursor?: (params: { row: number; col: number }) => void;
  inSelection?: boolean;
  selected?: boolean;
  editable?: boolean;
  edited?: boolean;
  editing?: boolean;
  error?: string;
  onChange: (rows: Record<string, any>) => void;
  onPaste: (e: ClipboardEvent) => void;
  onReset: (e) => void;
  value?: any;
  maskedValue?: any;
  definitionId?: string;
  color?: ParsedListItem['color'];
  icon?: GeneralModel.IconName;
  query?: GeneralModel.FetchCriteria;
  definition?: ParsedListDefinition;
  collectionId?: string;
  recordId?: string;
  // TODO: fix these horrible types. find the export and centralize all
  format?: ViewModel.CyTableProps['value']['query']['schema']['properties'][string]['format'];
  options?: ViewModel.CyTableProps['value']['query']['schema']['properties'][string]['metadata']['optionList'];
}

export const EditTableCell = ({
  rowIndex,
  colIndex,
  setEditing,
  rowId,
  setSelected,
  selected,
  editable,
  setCursor,
  inSelection,
  edited,
  editing,
  error,
  onChange: propsOnChange,
  onPaste,
  value: propsValue,
  maskedValue,
  definitionId,
  options,
  color,
  icon,
  definition,
  collectionId,
  recordId,
  format,
  query,
  onReset
}: EditTableCellProps) => {
  const testid = useMemo(() => `cell-input-${rowIndex}-${colIndex}`, [rowIndex, colIndex]);
  const isBoolFormat = useMemo(() => CHECKBOX_FORMATS.includes(format), [format]);
  const [prevEditingValue, setPrevEditingValue] = useState(null);

  const innerOnChange = useCallback(
    (value: any) => propsOnChange({ [rowId]: { [definitionId]: value, [rowIndex$]: rowIndex } }),
    [propsOnChange, rowId, definitionId, rowIndex]
  );
  const innerSetSelected = useCallback(() => setSelected(rowIndex, colIndex), [setSelected, rowIndex, colIndex]);
  const innerSetEditing = useCallback(
    value => {
      setPrevEditingValue(propsValue);
      return setEditing(rowIndex, colIndex, value);
    },
    [setEditing, rowIndex, colIndex, propsValue]
  );
  const innerSetCursor = useCallback(() => setCursor({ row: rowIndex, col: colIndex }), [setCursor, rowIndex, colIndex]);
  const { value, onChange } = useDebouncedOnChange({ delayTime: ENV.INPUT_DEBOUNCE_TIME, value: propsValue, onChange: innerOnChange });

  const isEditing = useMemo(() => editing || isBoolFormat, [editing, isBoolFormat]);
  const disabled = useMemo(() => !editable, [editable]);
  const activable = useMemo(() => !disabled && !isEditing, [disabled, isEditing]);
  const previousSelected = usePrevious(selected);

  const onKeyDown = useCallback(
    (e: KeyboardEvent<HTMLTableCellElement>) => {
      if (!editable) return;

      if (isBoolFormat) {
        // using e.code because e.key when Space is pressed comes as ' '
        if (['Enter', 'Space'].includes(e.code)) {
          e.preventDefault();
          innerOnChange(!value);
          return;
        }
      }

      if (e.key === 'Enter') {
        e.preventDefault();
        if (isBoolFormat) return innerOnChange(!value);
        innerSetEditing(!editing);
        return;
      }

      if (isBoolFormat && e.key === 'Space') return innerOnChange(!value);

      if (!editing && !isControlKey(e.key) && !hasKeyModifiers(e)) {
        e.preventDefault();
        innerSetEditing(true);
        innerOnChange(e.key);

        return;
      }

      if (editing) {
        if (e.key === 'Escape') innerOnChange(prevEditingValue);
        return;
      }

      if (e.key === 'Backspace' || e.key === 'Delete') {
        e.preventDefault();
        innerOnChange(null);
      }
    },
    [innerOnChange, innerSetEditing, editable, editing, isBoolFormat, prevEditingValue, value]
  );

  const [initialValue] = useState(value);

  const cellOptionList = useMemo(
    () => [
      {
        label: TRANS.client.buttons.copy,
        image: 'copy_all' as GeneralModel.IconName,
        testid: `copy-${rowIndex}-${colIndex}`,
        onClick: onCopy
      },
      editable && {
        label: TRANS.client.buttons.paste,
        image: 'content_paste' as GeneralModel.IconName,
        testid: `paste-${rowIndex}-${colIndex}`,
        onClick: () => handleClipboardPaste(onPaste)
      },
      editable && {
        label: TRANS.client.buttons.reset,
        image: 'replay' as GeneralModel.IconName,
        testid: `reset-${rowIndex}-${colIndex}`,
        onClick: onReset
      }
    ],
    [colIndex, onPaste, onReset, rowIndex, editable]
  );

  const innerMaskedValue = useMemo(() => definition.applyMask(value), [definition, value]);
  const visibleValue = useMemo(() => (options ? options.find(option => option.value === value)?.label : innerMaskedValue), [options, innerMaskedValue, value]);
  const safeVisibleValue = useMemo(() => visibleValue || maskedValue, [visibleValue, maskedValue]);

  const renderCell = ({ ref, onContextMenu }) => (
    <div onContextMenu={onContextMenu} data-testid={`option-menu-${rowIndex}-${colIndex}`} css={styles.menuTrigger} ref={ref}>
      <div css={styles.innerCell}>
        {error && !editing && (
          <div css={styles.erroredCell} data-testid={`error-${rowIndex}-${colIndex}`}>
            <PopoverTrigger value={capitalize(error)} color="RD_1">
              <Icon name="warning" fill={COLOR.RD_1} size={FONT_SIZE.M} title="Error" />
            </PopoverTrigger>
          </div>
        )}
        {isEditing && (
          <div css={styles.inputContainer} data-testid={`editing-${rowIndex}-${colIndex}`}>
            {renderCellInput({ onChange, value, format, options, testid, innerSetEditing, disabled, definition, apiQuery: query, recordId })}
          </div>
        )}
        <div css={isEditing && styles.hidden} data-testid={`readonly-${rowIndex}-${colIndex}`}>
          <TableCell
            id={rowId}
            color={color}
            format={format}
            testid={`${rowIndex}-${colIndex}`}
            rowIndex={rowIndex}
            item={
              <ReadonlyFormat
                item={{
                  value: safeVisibleValue,
                  calculatedValue: value,
                  rawValue: value,
                  color,
                  definitionId,
                  icon
                }}
                fullRecord={
                  {
                    [definition.id]: value
                  } as any
                }
                definition={definition}
                collectionId={collectionId}
                recordId={recordId}
              />
            }
          />
        </div>
      </div>
    </div>
  );

  const selection = useMemo(() => inSelection || selected, [inSelection, selected]);

  const onClick = (e: MouseEvent) => {
    e.preventDefault();
    if (isBoolFormat) return;
    if (previousSelected) return innerSetEditing(true);
    innerSetSelected();
  };

  const onMouseDown = (e: MouseEvent) => {
    e.preventDefault();
    if (!inSelection || [1, 3].includes(e.buttons)) innerSetSelected();
  };

  const onMouseOver = (e: MouseEvent) => {
    e.preventDefault();
    if ([1, 3].includes(e.buttons)) innerSetCursor();
  };

  return (
    <td
      aria-selected={selected}
      aria-colindex={colIndex}
      css={[styles.cellTd, edited && !editing && styles.editedTd, !editable && styles.nonEditableTd, inSelection && styles.inSelection]}
      data-rowid={rowId}
      data-definitionid={definitionId}
      data-colindex={colIndex}
      data-rowindex={rowIndex}
      data-inselection={selection}
      data-value={maskedValue}
      data-testid={`edit-table-cell-${rowIndex}-${colIndex}`}
      data-initialvalue={initialValue}
      onMouseDown={!editing ? onMouseDown : null}
      onClick={activable ? onClick : null}
      onDoubleClick={activable ? innerSetEditing : null}
      onKeyDown={selected && editable ? onKeyDown : null}
      onMouseOver={onMouseOver}
      tabIndex={0}
    >
      <OptionMenu optionList={cellOptionList} renderButton={renderCell} dropdownType={DropdownMenuType.CONTEXT_MENU} />
    </td>
  );
};

const hasKeyModifiers = (e: KeyboardEvent): boolean => {
  return e.ctrlKey || e.altKey || e.metaKey || e.shiftKey;
};
