import { ApiModel, normalize } from '@cyferd/client-engine';
import type { GeneralModel } from '@models';
import type { ViewState } from '@models/general';

export const getInitialState = (): ViewState => ({ view: null, timestamp: null });

export const areAppsEqual = (apps1: any, apps2: { id: string }[]): boolean => {
  if (!Array.isArray(apps1)) return false;
  if (apps1?.length !== apps2?.length) return false;
  for (let i = 0; i < apps1.length; i++) {
    if (apps1[i].id !== apps2[i].id) return false;
  }
  return true;
};

export const reducer = (state: GeneralModel.ViewState = getInitialState(), action: GeneralModel.Action) => {
  const { type, payload } = action;

  if (type !== ApiModel.TriggerActionType.DISPATCH_SET_VIEW) return state;

  if (state.view?.id !== payload?.id) {
    return { ...state, view: normalize.view(payload, true), timestamp: Date.now() };
  }

  if (!Array.isArray(payload?.apps)) {
    return { ...state, view: { ...state.view, apps: [] } };
  }

  if (!areAppsEqual(state?.view?.apps, payload.apps)) {
    return { ...state, view: { ...state.view, apps: payload.apps } };
  }

  return state;
};
