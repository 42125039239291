import { useDebounce, usePrevious } from '@cyferd/client-engine';
import { useEffect, useState } from 'react';

export const useDebouncedOnChange = ({ delayTime, value, onChange }) => {
  const [innerValue, setInnerValue] = useState(value);
  const deferredValue = useDebounce(innerValue, delayTime);
  const prev = usePrevious(deferredValue);

  useEffect(() => {
    if (prev !== deferredValue && deferredValue !== value) onChange(deferredValue);
  }, [onChange, deferredValue, prev, value]);

  useEffect(() => {
    setInnerValue(value);
  }, [value]);

  return {
    value: innerValue,
    onChange: setInnerValue
  };
};
