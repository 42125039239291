import { Children, useContext, useMemo } from 'react';
import type { ApiModel } from '@cyferd/client-engine';
import { ViewModel, useHasFiltersApplied, useTranslate } from '@cyferd/client-engine';
import { ProgressBar } from '@components/elements/ProgressBar';
import { Spinner } from '@components/elements/Spinner';
import { CyText } from '@components/smart/CyText';
import { TRANS } from '@constants';
import { ReadOnlyTable } from '../ReadOnlyTable';
import type { EditTableProps } from '../EditTable';
import { EditTable } from '../EditTable';
import type { useDataSource } from '../../hooks';
import { styles } from './styles';
import { CyWrapperContext } from '@components/smart/CyWrapper';

const testId = 'CyTableView';
const getTestId = (thisTestId: string) => `${testId}-${thisTestId}`;

export type CyTableViewProps = {
  refreshId: string;
  children: React.ReactNode;
  value: ApiModel.ApiValue;
  isLoading: boolean;
  editing: boolean;
  isFirstLoad: boolean;
  rowActions: ViewModel.CyTableProps['rowActions'];
  appearance: ViewModel.CyTableProps['appearance'];
  options: ViewModel.CyTableProps['options'];
  updateCursor: ReturnType<typeof useDataSource>['updateCursor'];
} & EditTableProps;

export const CyTableView = ({
  refreshId,
  isLoading,
  value,
  isFirstLoad,
  children,
  editing,
  options: { hideSorting } = {},
  appearance,
  rowActions,
  updateCursor,
  ...editTableProps
}: CyTableViewProps) => {
  const { useParsers } = useContext(CyWrapperContext);

  const { translate } = useTranslate();
  const hasFiltersApplied = useHasFiltersApplied(value?.query?.cursor);

  const { parseList } = useParsers({ query: value?.query, rowColor: appearance?.rowColor });

  const { head, items, definitionMap } = useMemo(
    () => parseList({ entity: value?.query, list: value?.list, rowColor: appearance?.rowColor }),
    [parseList, appearance?.rowColor, value?.list, value?.query]
  );

  return (
    <div data-testid={testId} css={styles.container}>
      {!!isLoading && !!value && (
        <div css={styles.progressBarContainer} data-testid={getTestId(`progress-bar`)}>
          <ProgressBar color="BRAND_2" size={5} alt={true} />
        </div>
      )}
      <div css={styles.content}>
        {isFirstLoad && (
          <div css={styles.spinnerContainer}>
            <Spinner testid={getTestId(`spinner`)} />
          </div>
        )}
        {!isLoading && !value?.list?.length && (
          <span data-testid={getTestId(`empty-list`)}>
            {Children.count(children) ? (
              children
            ) : (
              <div css={styles.spinnerContainer} data-testid={!hasFiltersApplied ? 'empty' : 'empty-with-filters'}>
                <CyText
                  content={translate(!hasFiltersApplied ? TRANS.client.emptyStates.cyList : TRANS.client.emptyStates.cyListWithFilters)}
                  titleAlignment={ViewModel.Alignment.CENTER}
                />
              </div>
            )}
          </span>
        )}
        {!isFirstLoad &&
          !!value?.list?.length &&
          (editing ? (
            <div data-testid="editing">
              <EditTable {...editTableProps} cursor={value?.query?.cursor} />
            </div>
          ) : (
            <ReadOnlyTable
              refreshId={refreshId}
              definitionMap={definitionMap}
              head={head}
              hideSorting={hideSorting}
              isLoading={isLoading}
              items={items}
              value={value}
              rowActions={rowActions}
              updateCursor={updateCursor}
            />
          ))}
      </div>
    </div>
  );
};
