import { useCallback, useState } from 'react';
import { noop } from 'rxjs';

import type { GeneralModel } from '@cyferd/client-engine';
import { Evaluator, ViewModel } from '@cyferd/client-engine';

import { ENV, TRANS } from '@constants';
import { logger, useEvaluationPayload } from '@utils';
import { styles } from '../../styles';
import { Layout } from '@components/elements/Layout';
import { JSONSyntaxEditor } from '@components/elements/JSONSyntaxEditor';
import { EvaluatorUI } from '@components/elements/Evaluator/EvaluatorUI';
import { CTA, CTAType } from '@components/elements/CTA';
import { OptionMenu } from '@components/elements/OptionMenu';

/* istanbul ignore next line */
const parseData = (formula: any, body?: any) => {
  try {
    return Evaluator.parse(formula).evaluate(body);
  } catch (error) {
    logger.warn(ENV.APP_LOGGER_HEADER, 'Error evaluating', { formula, body, error });
    return { errorMessage: (error as any)?.message, errorStack: (error as any)?.stack, error };
  }
};

export interface EvaluatorPlaygroundProps {
  value: any;
  onChange: (value: any) => void;
  onClose: () => void;
  onCopyToClipboard: (item: any) => void;
}

export const EvaluatorPlayground = ({ value, onChange, onClose, onCopyToClipboard }: EvaluatorPlaygroundProps) => {
  const [output, setOutput] = useState<any>();
  const originalPayload = useEvaluationPayload();
  const [evaluationPayload, setEvaluationPayload] = useState<GeneralModel.EvaluatorFormula>({ event: {}, ...originalPayload });
  const [showAdvanced, setShowAdvanced] = useState<boolean>(false);

  /* istanbul ignore next line */
  const onRun = () => {
    setOutput(parseData(value, evaluationPayload));
  };

  const onToggleAdv = useCallback(() => setShowAdvanced(p => !p), []);

  return (
    <Layout type={ViewModel.LayoutType.FULL}>
      <Layout type={ViewModel.LayoutType.TWO_ALT_2}>
        <Layout type={ViewModel.LayoutType.FULL}>
          <div style={{ width: '100%' }}>
            <JSONSyntaxEditor expanded={true} height="200px" label="Input" onChange={setEvaluationPayload} value={evaluationPayload} />
          </div>
          <div style={{ width: '100%' }}>
            <JSONSyntaxEditor expanded={true} height="200px" label="Output" onChange={noop} value={output} disabled={true} />
          </div>
        </Layout>
        <div className={styles.evaluatorContainer} data-testid="evaluator-ui">
          <EvaluatorUI height="500px" hideTabs={true} value={value} showAdvanced={showAdvanced} disabled={false} inputList={[]} onChange={onChange} />
        </div>
      </Layout>
      <footer className={styles.footer}>
        <CTA testid="advanced-switch" type={CTAType.LINK} label={showAdvanced ? 'Show formula' : 'Show as JSON'} onClick={onToggleAdv} />
        <OptionMenu
          optionList={[
            { important: true, testid: 'output-panel-cancel', type: CTAType.SECONDARY, label: 'Cancel', onClick: onClose },
            {
              important: true,
              testid: 'output-panel-copy',
              type: CTAType.SECONDARY,
              label: TRANS.client.buttons.copy,
              image: 'content_copy',
              onClick: () => onCopyToClipboard(value)
            },
            { important: true, testid: 'output-panel-run', type: CTAType.PRIMARY, label: 'Try', onClick: onRun }
          ]}
        />
      </footer>
    </Layout>
  );
};
