import { COLOR, GAP } from '@constants';
import { css } from '@emotion/react';

export const styles = {
  container: css`
    display: flex;
    flex-direction: row;
    justify-content: center;
    gap: ${GAP.M};
    flex-wrap: wrap;
  `,
  item: css`
    display: flex;
    align-items: center;
    color: ${COLOR.NEUTRAL_1};
  `,
  mark: css`
    width: ${GAP.M};
    height: ${GAP.M};
    aspect-ratio: 1 / 1;
    border-radius: 50%;
    display: inline-block;
    margin-right: ${GAP.S};
  `,
  line: css`
    width: ${GAP.L};
    height: ${GAP.XS};
    display: inline-block;
    margin-right: ${GAP.XS};
  `
};
