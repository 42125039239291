import { useContext, useMemo } from 'react';

import type { FlowModel } from '@cyferd/client-engine';
import { ApiModel } from '@cyferd/client-engine';

import { BondEditor } from '../../../shared/BondEditor';
import type { EditorContextValue } from '../../../shared/EditorHome';
import { EditorContext } from '../../../shared/EditorHome';
import { styles } from './styles';
import { EmptyLinkingPage } from '../../../../../@components/elements/EmptyLinkingPage';

export const EntityBondEditor = () => {
  const { item } = useContext<EditorContextValue<FlowModel.Flow>>(EditorContext);

  const watchingCursor = useMemo(() => ({ associationKey: 'watching', relatedTo: { collectionId: ApiModel.ApiEntity.FLOW, id: item?.id } }), [item]);
  const listActionCursor = useMemo(
    () => ({ associationKey: 'listActionCollections', relatedTo: { collectionId: ApiModel.ApiEntity.FLOW, id: item?.id } }),
    [item]
  );
  const recordActionCursor = useMemo(
    () => ({ associationKey: 'recordActionCollections', relatedTo: { collectionId: ApiModel.ApiEntity.FLOW, id: item?.id } }),
    [item]
  );

  const emptyLinkingPageTitle = useMemo(() => <div className={styles.emptyStateMessage}>No data</div>, []);

  return (
    <div className={styles.container}>
      <div>
        <div className={styles.title}>Triggers</div>
        <div className={styles.bondEditorContainer}>
          <BondEditor
            item={item}
            collectionId={ApiModel.ApiEntity.ENTITY}
            cursor={watchingCursor}
            associationKey="watching"
            modalTitle="Unlinked watching collections"
            searchStringHidden={true}
            advancedFiltersHidden={true}
            hideLinkingModalBtn={true}
            hideActionListChildren={true}
            customEmptyState={<EmptyLinkingPage title={emptyLinkingPageTitle} />}
          />
        </div>
      </div>
      <div>
        <div className={styles.title}>List actions</div>
        <div className={styles.bondEditorContainer}>
          <BondEditor
            item={item}
            collectionId={ApiModel.ApiEntity.ENTITY}
            cursor={listActionCursor}
            associationKey="listActionCollections"
            modalTitle="Unlinked list action collections"
            searchStringHidden={true}
            advancedFiltersHidden={true}
            hideLinkingModalBtn={true}
            hideActionListChildren={true}
            customEmptyState={<EmptyLinkingPage title={emptyLinkingPageTitle} />}
          />
        </div>
      </div>
      <div>
        <div className={styles.title}>Record actions</div>
        <div className={styles.bondEditorContainer}>
          <BondEditor
            item={item}
            collectionId={ApiModel.ApiEntity.ENTITY}
            cursor={recordActionCursor}
            associationKey="recordActionCollections"
            modalTitle="Unlinked record action collections"
            searchStringHidden={true}
            advancedFiltersHidden={true}
            hideLinkingModalBtn={true}
            hideActionListChildren={true}
            customEmptyState={<EmptyLinkingPage title={emptyLinkingPageTitle} />}
          />
        </div>
      </div>
    </div>
  );
};
