import type { ComponentProps, PropsWithChildren } from 'react';

import { ViewModel } from '@cyferd/client-engine';
import { Layout } from '@components/elements/Layout';

import { ResizeObserverClass } from '@utils/ResizeObserverClass';

export type Props = PropsWithChildren<{
  node: ViewModel.Node;
}>;

export const NodeLayoutWrapper = ({ node, children }: Props) => {
  const isLayoutNode = node?.component === ViewModel.DisplayName.CY_LAYOUT;
  const props: Partial<ComponentProps<typeof Layout>> = isLayoutNode
    ? {
        type:
          node?.contains?.some(n => /Effect$/.test(n?.component)) ||
          [ViewModel.LayoutType.TAB, ViewModel.LayoutType.VERTICAL_TAB, ViewModel.LayoutType.RESPONSIVE].includes(node?.attrs?.type)
            ? ViewModel.LayoutType.FULL
            : node?.attrs?.type
      } // maybe allow some more in the future
    : { type: ViewModel.LayoutType.FULL };

  return (
    <Layout {...props} maxWidth={0} ResizeObserverClass={ResizeObserverClass}>
      {children}
    </Layout>
  );
};
