import { styles } from './styles';
import type { CalendarAddOption, CalendarConfigList } from '@cyferd/client-engine';
import { GeneralModel, ViewModel } from '@cyferd/client-engine';
import { useMemo } from 'react';
import { CalendarCard } from '../CalendarCard';
import type { Dayjs } from 'dayjs';
import dayjs from 'dayjs';
import { CTA, CTAType } from '@components/elements/CTA';
import { OptionMenu } from '@components/elements/OptionMenu';
import { Icon } from '@components/elements/Icon';
import { COLOR, TRANS } from '@constants';

export interface CalendarSidePanelProps {
  testid?: string;
  calendarConfigList: CalendarConfigList[];
  activeDay: GeneralModel.DateLike;
  actionListChildren?: ViewModel.CyCalendarProps['actionListChildren'];
  disabled?: boolean;
  addOptionList: CalendarAddOption[];
  onClose: () => void;
  onCellAction?: ViewModel.CyCalendarProps['onCellAction'];
}

export const CalendarSidePanel = ({
  testid = 'CalendarSidePanel',
  calendarConfigList,
  activeDay,
  actionListChildren,
  disabled,
  addOptionList,
  onClose,
  onCellAction
}: CalendarSidePanelProps) => {
  const $activeDay: Dayjs = useMemo(() => dayjs(activeDay).startOf('day'), [activeDay]);

  const unifiedList = useMemo(
    () =>
      calendarConfigList
        .map((config, configIndex) => config.items.map(item => ({ ...item, configIndex })))
        .flat()
        .filter(item => {
          const $from = dayjs(item.fullItem?.[calendarConfigList[item.configIndex]?.fromField]);
          const $to = !!item.fullItem?.[calendarConfigList[item.configIndex]?.toField] && dayjs(item.fullItem?.[calendarConfigList[item.configIndex]?.toField]);
          return $activeDay.isBetween($from.startOf('day'), $to?.isValid?.() ? $to.endOf('day') : $from.endOf('day'), 'D', '[]');
        })
        .sort((a, b) =>
          String(a.fullItem?.[calendarConfigList[a.configIndex]?.fromField]).localeCompare(String(b.fullItem?.[calendarConfigList[b.configIndex]?.fromField]))
        ),
    [$activeDay, calendarConfigList]
  );

  return (
    <div css={styles.container} data-testid={testid}>
      <div css={styles.header}>
        <div css={styles.titleContainer}>
          <p css={styles.title}>{dayjs(activeDay).format('MMM, ddd, D')}</p>
          {typeof onCellAction === 'function' && (
            <OptionMenu
              defaultBtnSize={ViewModel.CTASize.SMALL}
              defaultBtnType={CTAType.ACTION_SECONDARY}
              defaultBtnIcon="add"
              optionList={addOptionList.map(o => ({
                label: o.label,
                color: o.color,
                type: CTAType.ACTION_SECONDARY,
                size: ViewModel.CTASize.SMALL,
                testid: 'add-option',
                image: addOptionList.length === 1 ? 'add' : undefined,
                onClick: () =>
                  onCellAction({
                    collectionId: o.collectionId,
                    [o.fromField]:
                      o.fromFieldFormat === GeneralModel.JSONSchemaFormat.DATE ? $activeDay.format('YYYY-MM-DD') : $activeDay.set('hours', 12).toISOString()
                  })
              }))}
            />
          )}
        </div>
        <CTA type={CTAType.LINK} icon="close" onClick={onClose} color="NEUTRAL_2" tooltip={TRANS.client.buttons.close} testid="sidepanel-close" />
      </div>
      <div css={styles.list}>
        {unifiedList.map((item, index) => (
          <div key={`${item.fullItem?.id}${index}`}>
            <CalendarCard
              value={item}
              color={calendarConfigList[item.configIndex].color}
              from={item.fullItem?.[calendarConfigList[item.configIndex].fromField]}
              to={item.fullItem?.[calendarConfigList[item.configIndex].toField]}
              actionListChildren={actionListChildren}
              disabled={disabled}
              isFullDayEvent={calendarConfigList[item.configIndex].fromFieldFormat === GeneralModel.JSONSchemaFormat.DATE}
            />
          </div>
        ))}
        {!unifiedList.length && (
          <div data-testid="sidepanel-empty" css={styles.emptyState}>
            <Icon name="calendar_month" size="60px" fill={COLOR.NEUTRAL_2} />
            <p css={styles.emptyStateText}>No events</p>
          </div>
        )}
      </div>
    </div>
  );
};

CalendarSidePanel.displayName = 'CalendarSidePanel';
