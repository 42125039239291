// import reportWebVitals from './reportWebVitals';
import 'react-pdf/dist/esm/Page/AnnotationLayer.css';
import 'react-pdf/dist/esm/Page/TextLayer.css';
import 'react-quill/dist/quill.snow.css';
import './index.css';

import React from 'react';

import ReactDOM from 'react-dom/client';
import { Provider as ReduxProvider } from 'react-redux';
import { Route, RouterProvider, createBrowserRouter, createRoutesFromElements } from 'react-router-dom';
import { Subject } from 'rxjs';
import { w3cwebsocket } from 'websocket';

import { noop } from '@cyferd/client-engine';

import { ClientAppCyWrapper } from '@components/smart/CyWrapper';
import type { GeneralModel } from '@models';
import { Storage, getSocket, getStateSnapshotStorage, logger } from '@utils';
import { App } from './App';
import { GlobalProvider, combinedReducer, getInitialState } from './builder/state-mgmt/GlobalState';
import { ModalProvider } from './client-app/components/ModalProvider';
import { store } from './client-app/state-mgmt/configureStore';
import { EngineProviders } from './client-app/state-mgmt/Providers';
import { WSProvider, tokenStorage } from './client-app/state-mgmt/WSProvider';
import { configTranslations } from './translations/config';

import { UIProvider } from '@components/providers/UIprovider';
import dayjs from 'dayjs';
import isBetween from 'dayjs/plugin/isBetween';
dayjs.extend(isBetween);

configTranslations();

window.React = React;

const onVoidSession = () => {
  tokenStorage.remove();
  window.location.reload();
};

const root = ReactDOM.createRoot(document.getElementById('root'));

const deps: GeneralModel.Deps = {
  storage: new Storage(window.localStorage),
  stateSnapshot: getStateSnapshotStorage(),
  WebSocket: w3cwebsocket,
  logger,
  location: window.location,
  /** these are setup inside the ModalInteraction component */
  modalInteraction: {
    onConfirm: noop,
    onAlert: noop
  },
  refresh$: new Subject<string>()
};

root.render(
  // <React.StrictMode>
  <ReduxProvider store={store}>
    <RouterProvider
      router={createBrowserRouter(
        createRoutesFromElements(
          <Route
            path="/*"
            element={
              <GlobalProvider deps={deps} initialState={getInitialState()} combinedReducers={combinedReducer}>
                <WSProvider getSocket={getSocket} onVoidSession={onVoidSession}>
                  <UIProvider>
                    <ModalProvider>
                      <EngineProviders>
                        <ClientAppCyWrapper>
                          <App />
                        </ClientAppCyWrapper>
                      </EngineProviders>
                    </ModalProvider>
                  </UIProvider>
                </WSProvider>
              </GlobalProvider>
            }
          />
        )
      )}
    />
  </ReduxProvider>
  // </React.StrictMode>
);

// reportWebVitals(console.log);
