import type { MouseEvent } from 'react';
import { memo, useCallback, useContext, useLayoutEffect, useMemo, useState } from 'react';
import { Draggable, Droppable } from '@hello-pangea/dnd';
import { createPortal } from 'react-dom';

import { ErrorBoundary, Translate, ViewModel, createUUID, getClassnames, getComponentSchema } from '@cyferd/client-engine';
import { useGetElementSize, useTestingHelper } from '@utils';
import { COLOR, FONT_SIZE, FOREGROUND_COLOR, GENERAL, SECONDARY_COLOR, THEME, TRANS } from '@constants';

import { GlobalContext } from '../../../state-mgmt/GlobalState';
import { getComponentChildList } from '@utils/getComponentChildList';
import type { ViewNodeList } from '@utils/getViewNodeList';
import { getComponentIcon } from '../../ViewEditor/components/LayoutEditor/getComponentIcon';
import { AttributesEditor } from '../AttributesEditor';
import { ComponentAdder } from '../ComponentAdder';
import { NodeLayoutWrapper } from '../NodeLayoutWrapper';
import { NodeTooltip } from './NodeTooltip';
import { styles } from './styles';
import { getComponentConfig } from '@models/view';
import { Modal } from '@components/elements/Modal';
import { OptionMenu } from '@components/elements/OptionMenu';
import { CTAType } from '@components/elements/CTA';
import { ToolTip } from '@components/elements/Tooltip';
import { Icon } from '@components/elements/Icon';
import { UIContext } from '@components/providers/UIprovider';
import { EvaluatorInputContext } from '@components/elements/Evaluator/EvaluatorInput/EvaluatorInputContext';
import { DOC_ID } from '@components/elements/Docs/resources';
import { builderNodeForms } from '@models/builder';

export interface NodeCardProps {
  node: ViewModel.Node;
  canRemove?: boolean;
  canClone?: boolean;
  activeNodeId: string;
  path?: string;
  viewNodeList: ViewNodeList;
  allowsMove: boolean;
  highlightedId: string;
  toggleActiveNodeId: (id: string) => void;
  toggleHighlightedNodeId: (id: string) => void;
  onAdd: (path: string, newNode: ViewModel.Node) => void;
  onChange: (child: ViewModel.Node) => void;
  onRemove: (child: ViewModel.Node) => void;
  onMove: (currentPath: string, newPath: string) => void;
  onClone: (pointer: string, original: ViewModel.Node) => void;
  onNavigateToDoc?: (event: string) => void;
}

const renderId = createUUID(); // used to fix an issue with the drag & drop library

export const NodeCard = memo(
  ({
    node,
    canRemove,
    activeNodeId,
    path,
    viewNodeList,
    allowsMove,
    highlightedId,
    canClone,
    toggleActiveNodeId,
    toggleHighlightedNodeId,
    onChange,
    onRemove,
    onAdd,
    onMove,
    onClone,
    onNavigateToDoc
  }: NodeCardProps) => {
    const { getTestIdProps } = useTestingHelper('node-card');
    const { deps } = useContext(GlobalContext);
    const [detailContainer, setDetailContainer] = useState<HTMLElement>();
    const hasChildren = !!node?.contains.length;
    const isActive = node?.id === activeNodeId;
    const isHighlighted = node?.id === highlightedId;
    const cleanPath = path.replace(/\.contains$/, '');
    const eligibleChildList = useMemo(() => getComponentChildList(node?.component), [node?.component]);
    const [addModalVisible, setAddModalVisible] = useState<boolean>(false);
    const { ref, height } = useGetElementSize();
    const { runtimeTheme } = useContext(UIContext);

    const isHidden = !!node.attrs?.hidden;
    const componentSchema = useMemo(() => getComponentSchema(node.component), [node.component]);

    const nodeConfig = useMemo(() => getComponentConfig(node?.component), [node?.component]);

    const icon = useMemo(() => getComponentIcon(node), [node]);

    const isLayoutHorizontal =
      !node?.contains?.some(n => /Effect$/.test(n?.component)) &&
      node?.component === ViewModel.DisplayName.CY_LAYOUT &&
      node?.attrs?.type !== ViewModel.LayoutType.FULL &&
      node?.contains?.length > 1 &&
      node?.contains?.length <= 4;

    const toggleAddModal = useCallback(() => setAddModalVisible(p => !p), []);

    const foregroundColor = isActive ? FOREGROUND_COLOR[nodeConfig.color] : COLOR.NEUTRAL_1;

    const onInternalAdd = useCallback(
      (event: ViewModel.Node) => {
        onAdd(cleanPath, event);
        toggleAddModal();
      },
      [onAdd, cleanPath, toggleAddModal]
    );

    const onChangeChild = useCallback(
      (child: ViewModel.Node) => onChange({ ...node, contains: node.contains?.map(c => (c.id === child.id ? child : c)) }),
      [onChange, node]
    );

    const onRemoveChild = useCallback(
      (child: ViewModel.Node) => {
        deps.modalInteraction.onConfirm(
          {
            status: ViewModel.Status.INFO,
            icon: 'delete',
            title: `Remove ${getComponentConfig(child.component)?.label?.toLowerCase()} (${child.name})?`,
            description: '',
            confirmLabel: 'Remove'
          },
          () => onChange({ ...node, contains: node.contains?.filter(c => c !== child) })
        );
      },
      [deps.modalInteraction, onChange, node]
    );

    const showDetails = useCallback(
      (event: MouseEvent<HTMLDivElement>) => {
        event.stopPropagation?.();
        toggleActiveNodeId(node?.id);
      },
      [node?.id, toggleActiveNodeId]
    );
    const onInternalRemove = useCallback(() => onRemove(node), [onRemove, node]);

    useLayoutEffect(() => {
      setDetailContainer(document.getElementById(GENERAL.NODE_DETAIL_ID));
    }, []);

    const tabsIOptionMenu = useMemo(
      () =>
        ({
          optionList: [
            !!onNavigateToDoc && {
              image: 'help',
              important: true,
              testid: `action-help-btn`,
              tooltip: 'Help',
              onClick: () => onNavigateToDoc(`${DOC_ID.API_COMPONENTS}-${node?.component}`)
            },
            { important: true, image: 'close', tooltip: TRANS.client.buttons.close, color: 'NEUTRAL_1', onClick: showDetails }
          ].filter(Boolean)
        }) as any,
      [node?.component, onNavigateToDoc, showDetails]
    );

    return (
      <>
        {!!addModalVisible && (
          <Modal
            type={ViewModel.ModalType.FULL_SCREEN}
            open={true}
            icon={icon}
            title={`Add to ${getComponentConfig(node?.component)?.label}`}
            description={`Select the component to add to ${node?.name}`}
            onClose={toggleAddModal}
          >
            <ComponentAdder eligibleChildList={eligibleChildList} onSelect={onInternalAdd} />
          </Modal>
        )}
        <Droppable droppableId={`${renderId}${cleanPath}`} direction={isLayoutHorizontal ? 'horizontal' : 'vertical'} type={node?.id}>
          {droppableProvided => (
            <div
              data-selector={`node-card-content-${isLayoutHorizontal ? 'horizontal' : 'vertical'}` /** there are rules for this in index.css */}
              {...droppableProvided.droppableProps}
              ref={droppableProvided.innerRef}
              onClick={!hasChildren ? showDetails : undefined}
              {...getTestIdProps('container')}
              style={{
                backgroundColor: isActive
                  ? [ViewModel.DisplayName.CY_LAYOUT].includes(node.component)
                    ? COLOR.NEUTRAL_4
                    : COLOR[nodeConfig.color]
                  : SECONDARY_COLOR[nodeConfig.color],
                borderColor: [ViewModel.DisplayName.CY_LAYOUT, ViewModel.DisplayName.CY_VIEW, ViewModel.DisplayName.CY_VIEW_CONTENT].includes(node.component)
                  ? COLOR.NEUTRAL_2
                  : COLOR.BASE_BACKGROUND
              }}
              className={getClassnames(
                styles.container,
                isHidden && runtimeTheme === THEME.LIGHT && styles.hiddenLight,
                /* istanbul ignore next line */
                isHidden && runtimeTheme === THEME.DARK && styles.hiddenDark,
                isHighlighted && styles.highlighted
              )}
            >
              <div id={`node-${node.id}`}>
                <header className={styles.header} {...getTestIdProps('header')} onClick={!!node.contains?.length ? showDetails : undefined}>
                  <div className={styles.titleContainer}>
                    <ToolTip text={<NodeTooltip node={node} />}>
                      <div className={styles.title} {...getTestIdProps('title')}>
                        <span className={styles.titleInnerContainer}>
                          {!!isHidden && (
                            <span style={{ display: 'inline-block', marginRight: 3 }}>
                              <div style={{ display: 'flex' }}>
                                <Icon name="visibility_off" size={FONT_SIZE.M} fill={foregroundColor} />
                              </div>
                            </span>
                          )}
                          <span style={{ display: 'inline-block', marginRight: 3 }}>
                            <div style={{ display: 'flex' }}>
                              <Icon name={icon} size={FONT_SIZE.M} fill={foregroundColor} />
                            </div>
                          </span>
                          <span>
                            <p style={{ color: foregroundColor }} className={styles.title}>
                              {node?.name || <Translate>{getComponentConfig(node?.component)?.label}</Translate>}
                            </p>
                            <small style={{ color: foregroundColor }} className={styles.name}>
                              {!!node?.name && <Translate>{getComponentConfig(node?.component)?.label}</Translate>}
                            </small>
                          </span>
                        </span>
                      </div>
                    </ToolTip>
                  </div>
                  {node?.component !== ViewModel.DisplayName.VIEW_HEADER && node?.component !== ViewModel.DisplayName.GLOBAL_HEADER && (
                    <div
                      data-selector="node-options"
                      onClick={event => event.stopPropagation()}
                      className={styles.optionContainer}
                      style={{ backgroundColor: SECONDARY_COLOR[nodeConfig.color] }}
                    >
                      {!!eligibleChildList?.length && (
                        <div className={styles.optionListContainer}>
                          <OptionMenu
                            optionList={[
                              {
                                important: true,
                                testid: 'add-btn',
                                label: 'Add component',
                                image: 'add',
                                size: ViewModel.CTASize.SMALL,
                                type: CTAType.ACTION_SECONDARY,
                                onClick: toggleAddModal
                              }
                            ]}
                          />
                        </div>
                      )}
                      {!!allowsMove && (
                        <div className={styles.optionListContainer}>
                          <OptionMenu
                            defaultBtnSize={ViewModel.CTASize.SMALL}
                            defaultBtnTestid="move-btn"
                            defaultBtnIcon="low_priority"
                            defaultTooltip="Move to another component"
                            optionList={viewNodeList
                              .filter(item => item.config?.allowedChildren?.includes(node?.component) && !item.path?.startsWith(cleanPath))
                              .map(item => ({
                                testid: `move-${item.path}`,
                                label: `${Array(
                                  item.path
                                    .replace(/^contains\.[0-9]/, '')
                                    .split('.')
                                    .filter(p => !!p && !isNaN(Number(p))).length
                                )
                                  .fill('→')
                                  .join('')} ${item.node?.name}`,
                                image: getComponentIcon(item.node),
                                onClick: () => onMove(cleanPath, item.path),
                                onMouseEnter: () => toggleHighlightedNodeId(item.node?.id),
                                onMouseLeave: () => toggleHighlightedNodeId(undefined)
                              }))}
                          />
                        </div>
                      )}
                      <div className={styles.optionListContainer}>
                        <OptionMenu
                          defaultBtnSize={ViewModel.CTASize.SMALL}
                          optionList={[
                            !!canClone && {
                              testid: 'clone-btn',
                              label: 'Duplicate component',
                              image: 'add_to_photos',
                              type: CTAType.ACTION_SECONDARY,
                              onClick: () =>
                                onClone(
                                  cleanPath
                                    .split('.')
                                    .slice(0, cleanPath.split('.').length - 2)
                                    .join('.'),
                                  node
                                )
                            },
                            [
                              ViewModel.DisplayName.CY_LIST,
                              ViewModel.DisplayName.CY_KANBAN,
                              ViewModel.DisplayName.CY_FORM,
                              ViewModel.DisplayName.CY_CHART,
                              ViewModel.DisplayName.CY_MODAL
                            ].includes(node?.component) && {
                              label: TRANS.client.buttons.copyName,
                              testid: 'copy-name-btn',
                              image: 'content_copy',
                              type: CTAType.ACTION_SECONDARY,
                              onClick: () => navigator.clipboard.writeText(node?.name)
                            },
                            [ViewModel.DisplayName.CY_MODAL].includes(node?.component) && {
                              label: TRANS.client.buttons.copyId,
                              testid: 'copy-id-btn',
                              image: 'content_copy',
                              type: CTAType.ACTION_SECONDARY,
                              onClick: () => navigator.clipboard.writeText(node?.id)
                            },
                            !!canRemove && {
                              testid: getTestIdProps('remove-btn')['data-testid'],
                              image: 'delete',
                              label: TRANS.client.buttons.delete,
                              color: 'RD_4',
                              type: CTAType.ACTION_SECONDARY,
                              onClick: onInternalRemove
                            }
                          ]}
                        />
                      </div>
                    </div>
                  )}
                </header>
                <div className={getClassnames(node.contains?.length && styles.childContainer)}>
                  <NodeLayoutWrapper node={node}>
                    {node.contains?.map((child, i) => (
                      <Draggable key={child.id} draggableId={`${renderId}${cleanPath}.contains.${i}`} index={i} isDragDisabled={false}>
                        {draggableProvided => (
                          <div {...draggableProvided.dragHandleProps} {...draggableProvided.draggableProps} ref={draggableProvided.innerRef}>
                            <ErrorBoundary>
                              <NodeCard
                                canClone={true}
                                activeNodeId={activeNodeId}
                                highlightedId={highlightedId}
                                node={child}
                                toggleActiveNodeId={toggleActiveNodeId}
                                toggleHighlightedNodeId={toggleHighlightedNodeId}
                                onAdd={onAdd}
                                onChange={onChangeChild}
                                onRemove={onRemoveChild}
                                onMove={onMove}
                                onClone={onClone}
                                canRemove={true}
                                path={`${cleanPath}.contains.${i}`}
                                viewNodeList={viewNodeList}
                                allowsMove={true}
                                onNavigateToDoc={onNavigateToDoc}
                              />
                            </ErrorBoundary>
                          </div>
                        )}
                      </Draggable>
                    ))}
                  </NodeLayoutWrapper>
                  {droppableProvided.placeholder}
                </div>
              </div>
            </div>
          )}
        </Droppable>
        <EvaluatorInputContext.Provider value={{ titlePrefix: node?.name }}>
          <ErrorBoundary>
            {isActive &&
              !!detailContainer &&
              (createPortal(
                <div {...getTestIdProps('detail')} className={styles.detailContent} ref={ref}>
                  <div className={styles.nodeDetail}>
                    <ErrorBoundary>
                      <AttributesEditor
                        model={builderNodeForms[node.component]}
                        componentSchema={componentSchema}
                        node={node}
                        height={height}
                        onChange={onChange}
                        tabsIOptionMenu={tabsIOptionMenu}
                      />
                    </ErrorBoundary>
                  </div>
                </div>,
                document.getElementById(GENERAL.NODE_DETAIL_ID),
                String(!!detailContainer)
              ) as any)}
          </ErrorBoundary>
        </EvaluatorInputContext.Provider>
      </>
    );
  }
);
