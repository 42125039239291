import { GAP } from '@constants';
import { css } from '@emotion/react';

export const styles = {
  controls: css`
    display: flex;
    align-items: flex-end;
    justify-content: center;
    gap: ${GAP.XS};
  `,
  editionContainer: css`
    display: flex;
    flex-direction: column;
    gap: ${GAP.L};
  `,
  editionSubmit: css`
    display: flex;
    justify-content: flex-end;
  `
};
