import type { GeneralModel } from '@cyferd/client-engine';
import { DiagramElementType } from './LinkingPageDiagram';

export const PredefinedDiagrams = {
  appLinkedCollections: [
    {
      label: 'User',
      color: 'BRAND_2' as GeneralModel.Color.ThemeColor,
      type: DiagramElementType.NODE
    },
    {
      label: 'Has access',
      color: 'BRAND_2' as GeneralModel.Color.ThemeColor,
      type: DiagramElementType.EDGE
    },
    {
      label: 'App',
      color: 'BRAND_1' as GeneralModel.Color.ThemeColor,
      type: DiagramElementType.NODE
    },
    {
      label: 'Gives permissions',
      color: 'BRAND_1' as GeneralModel.Color.ThemeColor,
      type: DiagramElementType.EDGE
    },
    {
      label: 'Collection',
      color: 'BRAND_1' as GeneralModel.Color.ThemeColor,
      type: DiagramElementType.NODE
    }
  ],
  appLinkedFlows: [
    {
      label: 'User',
      color: 'BRAND_2' as GeneralModel.Color.ThemeColor,
      type: DiagramElementType.NODE
    },
    {
      label: 'Has access',
      color: 'BRAND_2' as GeneralModel.Color.ThemeColor,
      type: DiagramElementType.EDGE
    },
    {
      label: 'App',
      color: 'BRAND_1' as GeneralModel.Color.ThemeColor,
      type: DiagramElementType.NODE
    },
    {
      label: 'Gives permissions',
      color: 'BRAND_1' as GeneralModel.Color.ThemeColor,
      type: DiagramElementType.EDGE
    },

    {
      label: 'Flow',
      color: 'BRAND_1' as GeneralModel.Color.ThemeColor,
      type: DiagramElementType.NODE
    }
  ],
  appLinkedUsers: [
    {
      label: 'User',
      color: 'BRAND_2' as GeneralModel.Color.ThemeColor,
      type: DiagramElementType.NODE
    },
    {
      label: 'Has access',
      color: 'BRAND_2' as GeneralModel.Color.ThemeColor,
      type: DiagramElementType.EDGE
    },
    {
      label: 'App',
      color: 'BRAND_1' as GeneralModel.Color.ThemeColor,
      type: DiagramElementType.NODE
    },
    {
      label: 'Gives permissions',
      color: 'BRAND_1' as GeneralModel.Color.ThemeColor,
      type: DiagramElementType.EDGE
    },
    [
      {
        label: 'Collection',
        color: 'BRAND_1' as GeneralModel.Color.ThemeColor,
        type: DiagramElementType.NODE
      },
      {
        label: 'Flows',
        color: 'BRAND_1' as GeneralModel.Color.ThemeColor,
        type: DiagramElementType.NODE
      },
      {
        label: 'Views',
        color: 'BRAND_1' as GeneralModel.Color.ThemeColor,
        type: DiagramElementType.NODE
      },
      {
        label: 'Integrations',
        color: 'BRAND_1' as GeneralModel.Color.ThemeColor,
        type: DiagramElementType.NODE
      }
    ]
  ],
  appLinkedViews: [
    {
      label: 'User',
      color: 'BRAND_2' as GeneralModel.Color.ThemeColor,
      type: DiagramElementType.NODE
    },
    {
      label: 'Has access',
      color: 'BRAND_2' as GeneralModel.Color.ThemeColor,
      type: DiagramElementType.EDGE
    },
    {
      label: 'App',
      color: 'BRAND_1' as GeneralModel.Color.ThemeColor,
      type: DiagramElementType.NODE
    },
    {
      label: 'Gives permissions',
      color: 'BRAND_1' as GeneralModel.Color.ThemeColor,
      type: DiagramElementType.EDGE
    },

    {
      label: 'View',
      color: 'BRAND_1' as GeneralModel.Color.ThemeColor,
      type: DiagramElementType.NODE
    }
  ],
  appLinkedIntegrations: [
    {
      label: 'User',
      color: 'BRAND_2' as GeneralModel.Color.ThemeColor,
      type: DiagramElementType.NODE
    },
    {
      label: 'Has access',
      color: 'BRAND_2' as GeneralModel.Color.ThemeColor,
      type: DiagramElementType.EDGE
    },
    {
      label: 'App',
      color: 'BRAND_1' as GeneralModel.Color.ThemeColor,
      type: DiagramElementType.NODE
    },
    {
      label: 'Gives permissions',
      color: 'BRAND_1' as GeneralModel.Color.ThemeColor,
      type: DiagramElementType.EDGE
    },

    {
      label: 'Integration',
      color: 'BRAND_1' as GeneralModel.Color.ThemeColor,
      type: DiagramElementType.NODE
    }
  ],
  collectionLinkedViews: [
    {
      label: 'Collection',
      type: DiagramElementType.NODE
    },
    {
      label: 'Uses as table/detail',
      type: DiagramElementType.EDGE
    },
    {
      label: 'View',
      type: DiagramElementType.NODE
    }
  ],
  collectionLinkedFlows: [
    {
      label: 'Collection',
      type: DiagramElementType.NODE
    },
    {
      label: 'Triggers on event/Record actions',
      type: DiagramElementType.EDGE
    },
    {
      label: 'Flow',
      type: DiagramElementType.NODE
    }
  ]
};
