import { useSelector } from 'react-redux';

import { ProgressBar as WPLProgressBar } from '@components/elements/ProgressBar';

import type { GeneralModel } from '@models';
import { styles } from './styles';
import { GENERAL } from '@constants';

const progressBarHeight = 5;

export const ProgressBar = () => {
  const { loadingList } = useSelector((state: GeneralModel.State) => state.ui);

  return (
    <div css={styles.container} style={{ height: progressBarHeight * loadingList.length }}>
      <div css={[!!loadingList.includes(GENERAL.LOADING_KEY.LOAD) && styles.visible, styles.bar]} style={{ height: progressBarHeight }}>
        <WPLProgressBar color="BRAND_1" size={progressBarHeight} />
      </div>
    </div>
  );
};
