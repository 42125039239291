import { useMemo } from 'react';

import { ApiModel, createUUID } from '@cyferd/client-engine';
import { CyLayout } from '@components/smart/CyLayout';

import { BUILDER_DESCRIPTION, BUILDER_ICON, SECTION_NAME } from '@constants';
import { BuilderCyList } from '../../../shared/BuilderCyList';
import { TempHeader } from '../../../shared/TempHeader';
import { styles } from './styles';
import { useTestingHelper } from '@utils';
import { useRequest } from '@utils/useRequest';

export const GeneralList = () => {
  const id = useMemo(createUUID, []);
  const { getTestIdProps } = useTestingHelper('general-list');
  const request = useRequest();

  return (
    <div {...getTestIdProps('container')} className={styles.container}>
      <TempHeader title={SECTION_NAME.DEVICE_MANAGER} icon={BUILDER_ICON.DEVICE_MANAGER} subtitle={BUILDER_DESCRIPTION.DEVICE_MANAGER} />
      <CyLayout itemHeight="calc(100vh - 125px)">
        <BuilderCyList componentName={id} key={ApiModel.ApiEntity.DEVICE} showDelete={true} request={request} collectionId={ApiModel.ApiEntity.DEVICE} />
      </CyLayout>
    </div>
  );
};
