import { COLOR, GAP, RADIUS, SHADOW } from '@constants';
import { css } from '@emotion/react';

export const styles = {
  container: css``,
  listContainer: css`
    position: fixed;
    background-color: ${COLOR.TOP_BACKGROUND};
    z-index: 10;
    padding: ${GAP.M} ${GAP.S} ${GAP.XXXS} ${GAP.S};
    border-radius: ${RADIUS.S};
    box-shadow: ${SHADOW.S};
    max-height: 270px;
    overflow: auto;
  `
};
