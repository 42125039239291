import { COLOR, FONT_SIZE, FONT_WEIGHT, GAP } from '@constants';
import { css } from '@emotion/react';

export const styles = {
  container: css`
    display: flex;
    flex-direction: column;
    height: 100%;
  `,
  list: css`
    display: flex;
    flex-direction: column;
    gap: ${GAP.S};
    flex: 1;
    overflow: auto;
  `,
  header: css`
    margin-bottom: ${GAP.M};
    padding-bottom: ${GAP.M};
    border-bottom: 1px solid ${COLOR.NEUTRAL_3};
    display: flex;
    justify-content: space-between;
    align-items: baseline;
  `,
  titleContainer: css`
    display: flex;
    gap: ${GAP.S};
    align-items: center;
  `,
  title: css`
    color: ${COLOR.NEUTRAL_1};
    font-size: ${FONT_SIZE.M};
    font-weight: ${FONT_WEIGHT.EXTRA_BOLD};
  `,
  emptyState: css`
    height: 100%;
    display: flex;
    flex-direction: column;
    gap: ${GAP.XXXS};
    align-items: center;
    justify-content: center;
  `,
  emptyStateText: css`
    font-size: ${FONT_SIZE.XXM};
    color: ${COLOR.NEUTRAL_1};
    font-weight: ${FONT_WEIGHT.EXTRA_BOLD};
  `
};
