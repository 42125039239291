import { ApiModel, ClientEngineContext, GeneralModel, ViewModel, isObject, recursiveMap, useRegistry } from '@cyferd/client-engine';
import type { ComponentProps } from 'react';
import { useCallback, useContext, useMemo, useState } from 'react';
import { CyLayout } from '@components/smart/CyLayout';
import { CyList } from '@components/smart/CyList';
import { CyWrapperContext } from '@components/smart/CyWrapper';
import { CyText } from '@components/smart/CyText';
import { TRANS } from '@constants';
import { isValidCollectionId, useRequest } from '@utils';
import { styles } from './styles';
import { CTAType } from '../CTA';
import { Header } from '../Header';
import { SchemaForm } from '../../../builder/views/shared/SchemaForm';
import { BuilderCyList } from '../../../builder/views/shared/BuilderCyList';
import { getFlatNodeList } from '../../../builder/views/ViewEditor/components/LayoutEditor/getFlatNodeList';

const detectIds = (value: any) => {
  let detectedIds = { [ApiModel.ApiEntity.ENTITY]: [], [ApiModel.ApiEntity.FLOW]: [], [ApiModel.ApiEntity.TAG]: [] };
  recursiveMap(value, (item, path) => {
    if (path[path.length - 1] === 'collectionId' && typeof item === 'string') {
      detectedIds = { ...detectedIds, [ApiModel.ApiEntity.ENTITY]: [...detectedIds[ApiModel.ApiEntity.ENTITY], item.trim()] };
    }
    if (isObject(item) && [ApiModel.ApiEntity.FLOW, ApiModel.ApiEntity.TAG].includes(item.collectionId) && typeof item.id === 'string') {
      detectedIds = { ...detectedIds, [item.collectionId]: [...detectedIds[item.collectionId], item.id.trim()] };
    }
    return item;
  });
  return Object.fromEntries(
    Object.entries(detectedIds).map(([k, list]) => [
      k,
      Array.from(new Set(list)).filter(id => isValidCollectionId(id) && !Object.values(ApiModel.ApiEntity).includes(id))
    ])
  );
};

export const DevMenuInspector = () => {
  const { viewScopeRegistry, progRegistry } = useRegistry();
  const { useDataSelector, useUrl } = useContext(ClientEngineContext);
  const { useAction } = useContext(CyWrapperContext);
  const onNavigate = useAction('dispatchNavigateTo');
  const dispatchRefresh = useAction('dispatchRefresh');
  const [compoToRefresh, setCompoToRefresh] = useState<string>();
  const request = useRequest();

  const global = useDataSelector();
  const url = useUrl();
  const detectedIds = useMemo(() => detectIds({ progRegistry, viewScopeRegistry, url, global }), [global, progRegistry, url, viewScopeRegistry]);

  const getOnOpenBuilder = useCallback(
    (section: string) => (event: ApiModel.ApiRecord) => onNavigate({ path: `{{platform.builderUrl}}${section}`, external: true, qs: { id: event?.id } }),
    [onNavigate]
  );

  const getOptionMenu: ComponentProps<typeof SchemaForm>['getOptionMenu'] = useCallback(
    () => [
      {
        important: true,
        tooltip: 'Refresh',
        image: 'refresh',
        type: CTAType.LINK,
        onClick: /* istanbul ignore next */ () => dispatchRefresh({ componentNameList: compoToRefresh ? [compoToRefresh] : undefined })
      }
    ],
    [compoToRefresh, dispatchRefresh]
  );

  const refreshSchema = useMemo(
    () =>
      ({
        type: 'string',
        title: ' ',
        format: GeneralModel.JSONSchemaFormat.STRING_OPTION_LIST,
        metadata: {
          optionList: Array.from(
            new Set(
              Object.values(viewScopeRegistry)
                .map(reg => getFlatNodeList(reg.view))
                .flat()
                .filter(
                  compo =>
                    /** list of components that CAN'T refresh */
                    ![
                      ViewModel.DisplayName.CY_MODAL,
                      ViewModel.DisplayName.CY_INFO,
                      ViewModel.DisplayName.CY_LAYOUT,
                      ViewModel.DisplayName.CY_BLANK,
                      ViewModel.DisplayName.CY_SEARCH,
                      ViewModel.DisplayName.CY_ACTION,
                      ViewModel.DisplayName.CY_TEXT,
                      ViewModel.DisplayName.CY_IMAGE,
                      ViewModel.DisplayName.CY_VIEW,
                      ViewModel.DisplayName.CY_VIEW_CONTENT,
                      ViewModel.DisplayName.CY_DATA_EFFECT,
                      ViewModel.DisplayName.CY_ACTION_EFFECT,
                      ViewModel.DisplayName.CY_LOAD_EFFECT,
                      ViewModel.DisplayName.CY_UNLOAD_EFFECT,
                      ViewModel.DisplayName.CY_INTERVAL_EFFECT,
                      ViewModel.DisplayName.VIEW_HEADER,
                      ViewModel.DisplayName.GLOBAL_HEADER,
                      ViewModel.DisplayName.NOT_FOUND
                    ].includes(compo.node?.component)
                )
                .map(compo => compo.node?.name)
            )
          )
            .sort()
            .map(value => ({ value }))
        }
      }) as GeneralModel.JSONSchema,
    [viewScopeRegistry]
  );

  const builderListValue = useMemo(
    () => ({
      query: {} as any,
      list: Object.values(viewScopeRegistry).map(reg => ({
        recordColor: 'NEUTRAL_3',
        ...reg.view,
        recordTitle: reg.view?.name,
        recordDescription: reg.view?.description
      })),
      totalCount: Object.values(viewScopeRegistry).length
    }),
    [viewScopeRegistry]
  );

  return (
    <div data-testid="dev-menu-inspector">
      <CyLayout type={ViewModel.LayoutType.FULL}>
        <div>
          <Header title="Refresh component" />
          <SchemaForm schema={refreshSchema} value={compoToRefresh} onChange={setCompoToRefresh} getOptionMenu={getOptionMenu} />
        </div>
        <div>
          <div css={styles.headerContainer}>
            <Header title="Views" />
          </div>
          <CyList
            framed={false}
            density={ViewModel.Density.L}
            value={builderListValue}
            type={ViewModel.CyListType.LIST}
            onClickItem={getOnOpenBuilder('views/layout')}
            actionListChildren={[
              { label: 'Builder', icon: 'open_in_new', type: CTAType.ACTION_TERTIARY, important: true, onClick: getOnOpenBuilder('views/layout') as any }
            ]}
            paginationHidden={true}
            searchStringHidden={true}
            orderHidden={true}
            quickFiltersHidden={true}
            typeSelectorHidden={true}
            recordActionsHidden={true}
            advancedFiltersHidden={true}
          />
        </div>
        {[
          { title: 'Apps', path: 'tags/general', entity: ApiModel.ApiEntity.TAG },
          { title: 'Collections', path: 'data/definition', entity: ApiModel.ApiEntity.ENTITY },
          { title: 'Flows', path: 'flows/steps', entity: ApiModel.ApiEntity.FLOW }
        ]
          .filter(config => !!detectedIds[config.entity].length)
          .map(config => (
            <div key={config.path}>
              <BuilderCyList
                onClickItem={getOnOpenBuilder(config.path)}
                request={request}
                collectionId={config.entity}
                framed={false}
                title={config.title}
                initialFetchCriteria={{
                  collectionId: config.entity,
                  filter: { $or: detectedIds[config.entity].map(id => ({ id: { $eq: id } })) },
                  options: { limit: detectedIds[config.entity].length }
                }}
                advancedFiltersHidden={true}
                typeSelectorHidden={true}
                searchStringHidden={true}
                paginationHidden={true}
                actionListChildren={[
                  {
                    helperText: TRANS.client.buttons.open,
                    icon: 'open_in_new',
                    type: CTAType.ACTION_TERTIARY,
                    important: true,
                    onClick: getOnOpenBuilder(config.path) as any
                  }
                ]}
              >
                <CyText title="No results" titleSize={ViewModel.CyTextTitleSize.M} titleAlignment={ViewModel.Alignment.CENTER} />
              </BuilderCyList>
            </div>
          ))}
      </CyLayout>
    </div>
  );
};

DevMenuInspector.displayName = 'DevMenuInspector';
