import { useContext } from 'react';

import { ClientEngineContext, type CollectionModel } from '@cyferd/client-engine';

import { EmptyState } from '@components/elements/EmptyState';
import { hasFlag } from '@utils';
import { userInputList } from '@models/user';
import { EditorContext, type EditorContextValue } from '../../../shared/EditorHome';
import { SchemaForm } from '../../../shared/SchemaForm';
import { PreviewLayout } from '../PreviewLayout/PreviewLayout';
import { LegacyFilterEditor } from './LegacyFilterEditor';
import { detailGroupList } from './schemas';
import { useFilterEditorSchema } from './useFilterEditorSchema';

// TODO: use <HasFlagRenderer /> component to render conditionally
export const FilterEditor = () => {
  const { useUserSelector } = useContext(ClientEngineContext);
  const user = useUserSelector();

  // TODO: remove and cleanup all legacy when complete
  return !hasFlag(user, 'EARLY_ACCESS') ? <LegacyFilterEditor /> : <NewFilterEditor />;
};

export const NewFilterEditor = () => {
  const { item, setItem } = useContext<EditorContextValue<CollectionModel.Collection>>(EditorContext);
  const schema = useFilterEditorSchema(item);

  if (!item) return <EmptyState />;

  return (
    <PreviewLayout collection={item} onCollectionChange={setItem}>
      <SchemaForm onChange={setItem} schema={schema} detailGroupList={detailGroupList} value={item} wrapDetailGroups={true} inputList={userInputList} />
    </PreviewLayout>
  );
};
