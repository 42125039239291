import { useState } from 'react';
import type { SerializedStyles } from '@emotion/react';
import { css } from '@emotion/react';
import { ViewModel } from '@cyferd/client-engine';
import { CTA, CTAType } from '@components/elements/CTA';
import { TRANS } from '@constants';

export const usePrint = () => {
  const [printStyle, setPrintStyle] = useState<SerializedStyles>(null);

  const calculatePrintSizeInches = () => {
    const { clientWidth, scrollWidth, offsetWidth, clientHeight, scrollHeight, offsetHeight } = document.documentElement;
    const [width, height] = [Math.max(clientWidth, scrollWidth, offsetWidth), Math.max(clientHeight, scrollHeight, offsetHeight)];
    return css`
      @media print {
        @page {
          size: ${width / 96}in ${height / 96}in;
          margin: 0;
        }
      }
    `;
  };

  const onClick = () => {
    setPrintStyle(calculatePrintSizeInches());
    setTimeout(() => window.print());
  };

  return {
    printStyle,
    onClick
  };
};

export const Print = () => {
  const { onClick, printStyle } = usePrint();

  return (
    <div>
      <CTA
        testid="print-btn"
        icon="print"
        outlined={true}
        type={CTAType.LINK}
        size={ViewModel.CTASize.LARGE}
        color="NEUTRAL_1"
        tooltip={TRANS.client.nav.print}
        onClick={onClick}
      />
      <div css={printStyle} />
    </div>
  );
};
