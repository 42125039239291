import jwt_decode from 'jwt-decode';
import type { FC } from 'react';
import { useEffect, useState } from 'react';
import { map, takeUntil, tap } from 'rxjs';
import { ajax } from 'rxjs/ajax';

import type { TokenSet } from '@cyferd/client-engine';
import { decodeQueryString, swallowError, useFinalizeWhileMounted, useUnmountObservable } from '@cyferd/client-engine';

import { Spinner } from '@components/elements/Spinner';
import { logger } from '@utils';
import { challengeKey, getChallenge } from './getChallenge';
import { styles } from './styles';

/* istanbul ignore next line */
const defaultOnNavigate = (href: string) => {
  window.location.href = href;
};

export interface LoginPageProps {
  tokenUrl: string;
  authUrl: string;
  onTokenChange: (event: TokenSet) => void;
  onNavigate?: (event: string) => void;
  Logo: FC<{ width?: number; height?: number }>;
}

export const Login = ({ tokenUrl, authUrl, onTokenChange, Logo, onNavigate = defaultOnNavigate }: LoginPageProps) => {
  const { code } = decodeQueryString(window.location.href.split('?')[1]);
  const onDestroy$ = useUnmountObservable();
  const [codes, setCodes] = useState<{ verifier: string; challenge: string }>();
  const finalize = useFinalizeWhileMounted();

  useEffect(() => {
    if (!codes) return;

    if (typeof code !== 'string') {
      onNavigate(`${authUrl}?redirect_uri=${encodeURIComponent(window.location.href)}&code_challenge=${codes.challenge}`);
      return;
    }

    ajax
      .post<{ access_token: string; refresh_token: string }>(tokenUrl, `grant_type=code&code=${encodeURIComponent(code)}&code_verifier=${codes?.verifier}`, {
        Accept: 'application/json',
        'Content-Type': 'application/x-www-form-urlencoded'
      })
      .pipe(
        takeUntil(onDestroy$),
        map(({ response }) => {
          const exp = jwt_decode<any>(response?.access_token)?.exp;
          return { access: response?.access_token, refresh: response?.refresh_token, exp } as TokenSet;
        }),
        tap(tokenSet => {
          sessionStorage.removeItem(challengeKey);
          onTokenChange(tokenSet);
        }),
        swallowError()
      )
      .subscribe();
  }, [authUrl, code, codes, finalize, onDestroy$, onNavigate, onTokenChange, tokenUrl]);

  useEffect(() => {
    getChallenge().then(setCodes).catch(logger.error);
  }, []);

  return (
    <>
      <div css={styles.container} data-testid="login-container">
        <Spinner />
      </div>
      <div css={styles.bottomContainer}>
        <div css={styles.poweredByContainer}>
          <div>Powered by</div>
          <div>
            <Logo width={130} height={25} />
          </div>
        </div>
      </div>
    </>
  );
};
