import { COLOR, FONT_WEIGHT, GAP, RADIUS, SECONDARY_COLOR } from '@constants';
import type { GeneralModel } from '@cyferd/client-engine';
import { css } from '@emotion/css';

export const styles = {
  getCardContainer: (color: GeneralModel.Color.ThemeColor = 'BRAND_1') => css`
    min-height: 50px;
    padding: ${GAP.S};
    display: flex;
    align-items: center;
    justify-content: center;
    color: ${COLOR.NEUTRAL_1};
    background-color: ${SECONDARY_COLOR[color] ?? SECONDARY_COLOR.BRAND_1};
    font-weight: ${FONT_WEIGHT.SEMI_BOLD};
    display: flex;
    gap: ${GAP.XXS};
    flex-direction: column;
    align-items: center;
    justify-content: center;
    border: 2px ${COLOR[color] ?? COLOR.BRAND_1} solid;
    border-radius: ${RADIUS.M};
  `,
  initialStep: css`
    border-radius: 100% !important;
    padding: 0 !important;
    border: 0 !important;
    background-color: ${COLOR.BRAND_1}!important;
    width: 50px;
    height: 50px;
  `,
  stepName: css`
    text-align: center;
  `,
  getIconContainer: (color: GeneralModel.Color.ThemeColor = 'BRAND_1') => css`
    background-color: ${COLOR[color] ?? COLOR.BRAND_1};
    border-radius: 100%;
    padding: ${GAP.XS};
    display: flex;
    align-items: center;
    justify-content: center;
  `
};
