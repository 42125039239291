import { GAP } from '@constants';
import { css } from '@emotion/react';

export const styles = {
  container: css`
    width: 100%;
    overflow: hidden;
  `,
  content: css`
    display: flex;
    gap: ${GAP.S};
  `,
  mainContent: css`
    display: flex;
    flex-direction: column;
    gap: ${GAP.M};
    @supports (-webkit-hyphens: none) {
      flex-wrap: wrap;
    }
  `,
  stackedContent: css`
    flex-direction: column !important;
  `,
  imageContainer: css`
    display: inline-grid;

    @supports (-webkit-hyphens: none) {
      display: contents;
    }
  `,
  textContainer: css`
    flex: 1;
    min-width: 250px;
  `,
  actionsPositionRight: css`
    flex-direction: row;
  `,
  actionsPositionAbove: css`
    flex-direction: column-reverse;
  `,
  actionsPositionLeft: css`
    flex-direction: row-reverse;
  `,
  imagePositionStart: css`
    align-items: flex-start;
  `,
  imagePositionEnd: css`
    align-items: flex-end;
  `,
  imagePositionCenter: css`
    align-items: center;
  `,
  actionsDirectionHorozntal: css`
    flex-direction: row;
    justify-content: space-between;
  `,
  actionsDirectionVertical: css`
    flex-direction: column;
  `
};
