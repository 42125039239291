import { toastWidth } from '@components/elements/Toast';
import { FONT_SIZE, GAP } from '@constants';
import { css } from '@emotion/css';

export const styles = {
  container: css`
    position: fixed;
    bottom: 0;
    left: calc(50% - (${toastWidth} / 2));
    z-index: 1000;
    pointer-events: auto;
  `,
  item: css`
    margin: ${GAP.S};
  `,
  modalTitle: css`
    display: grid;
    grid-template-columns: 0fr 1fr;
    gap: ${GAP.M};
  `,
  modalSubtitle: css`
    font-size: ${FONT_SIZE.S};
    margin: ${GAP.M} 0 0 0;
  `,
  jsonContent: css`
    margin: 0;
  `
};
