import { CTAType } from '@components/elements/CTA';
import { type IOptionListItem, OptionMenu } from '@components/elements/OptionMenu';
import { PreventClickPropagation } from '@components/elements/PreventClickPropagation';
import { TRANS } from '@constants';
import { ApiModel, ViewModel } from '@cyferd/client-engine';
import { useCyActions } from '@utils';
import { memo, useCallback } from 'react';
import type { INavigationItem } from '../../types';
import { styles } from './styles';

export interface SubItemMenuProps extends Pick<INavigationItem, 'collections' | 'views'> {
  testid?: string;
  viewActiveId?: string;
  collectionActiveId?: string;
  renderFromPortal?: boolean;
}

interface ISubItemLabel {
  label: string;
}

interface ISubItemValue {
  title: string;
  isActive: boolean;
  optionList?: IOptionListItem[];
  onClick: () => void;
  renderFromPortal?: boolean;
}

const SubItemLabel = memo(function SubItemLabel({ label }: ISubItemLabel) {
  return (
    <li css={[styles.item, styles.title]}>
      <span css={styles.label}>{label}</span>
    </li>
  );
});

const SubItemValue = memo(function SubItemValue({ onClick, isActive, title, optionList, renderFromPortal }: ISubItemValue) {
  return (
    <li onClick={onClick} css={[styles.item, styles.subtitle, isActive && styles.activeOptionContent]}>
      <span css={styles.label}>{title}</span>
      {optionList && !renderFromPortal && (
        <div css={styles.actionContainer} data-selector="subItemMenu-actionContainer">
          <PreventClickPropagation>
            <OptionMenu defaultBtnType={CTAType.ACTION_TERTIARY} defaultBtnSize={ViewModel.CTASize.SMALL} optionList={optionList} />
          </PreventClickPropagation>
        </div>
      )}
    </li>
  );
});

const SubItemMenuComponent = ({ collections = [], views = [], testid, viewActiveId, collectionActiveId, renderFromPortal }: SubItemMenuProps) => {
  const { onDispatchFormModal, onDispatchNavigateTo } = useCyActions();
  const onCreateNew = useCallback(
    (collectionId: string) => (event?: unknown) =>
      onDispatchFormModal(
        {
          type: ApiModel.ApiEntity.ENTITY,
          title: TRANS.client.buttons.createNew,
          formType: ViewModel.CyFormType.STEPPER,
          collectionId
        },
        event
      ),
    [onDispatchFormModal]
  );
  const collectionNavigate = useCallback(
    (collectionId: string) => () => onDispatchNavigateTo({ path: `/TABLE?collectionId=${collectionId}` }),
    [onDispatchNavigateTo]
  );
  const viewNavigate = useCallback((viewId: string) => () => onDispatchNavigateTo({ path: `/${viewId}` }), [onDispatchNavigateTo]);

  return (
    <ul data-testid={testid || 'SubItemMenu'} css={styles.container}>
      {views.length > 0 && (
        <>
          <SubItemLabel label="VIEWS" />
          {views.map(data => (
            <SubItemValue onClick={viewNavigate(data.id)} isActive={viewActiveId === data.id} key={data.id} title={data.recordTitle} />
          ))}
        </>
      )}
      {collections.length > 0 && (
        <>
          <SubItemLabel label="DATA COLLECTIONS" />
          {collections.map(data => (
            <SubItemValue
              onClick={collectionNavigate(data.id)}
              isActive={collectionActiveId === data.id}
              title={data.recordTitle}
              key={data.id}
              optionList={[{ label: TRANS.client.buttons.createNew, image: 'add', onClick: onCreateNew(data.id) }]}
              renderFromPortal={renderFromPortal}
            />
          ))}
        </>
      )}
    </ul>
  );
};

type MemoizedSubItemMenuType = typeof MemoizedSubItemMenu & {
  Label: typeof SubItemLabel;
  Value: typeof SubItemValue;
};
const MemoizedSubItemMenu = memo(SubItemMenuComponent);
export const SubItemMenu = MemoizedSubItemMenu as MemoizedSubItemMenuType;

SubItemMenu.Label = SubItemLabel;
SubItemMenu.Value = SubItemValue;
