import { memo } from 'react';

import { CTA, CTAType } from '../../../CTA';
import { PreventClickPropagation } from '../../../PreventClickPropagation';
import { styles } from './styles';

export interface RecentSearchesProps {
  recentTerms: string[];
  onClearRecentSearches: () => void;
  onItemClick: (term: string) => void;
  onRemoveRecentSearchEntry: (term: string) => void;
}

export const RecentSearches = memo(({ recentTerms, onClearRecentSearches, onItemClick, onRemoveRecentSearchEntry }: RecentSearchesProps) => {
  return (
    <div css={styles.container} data-testid="recent-searches">
      <div css={styles.header}>
        <p css={styles.title}>Recent Searches</p>
        <CTA type={CTAType.LINK} testid="clear-recent-searches" label="Clear all" onClick={onClearRecentSearches} />
      </div>
      <ul css={styles.recentSearchList}>
        {Array.from(new Set(recentTerms)).map(term => (
          <li key={term} onClick={() => onItemClick(term)} css={styles.item} data-testid="recent-search-item">
            <span>{term}</span>
            <PreventClickPropagation>
              <CTA
                type={CTAType.LINK}
                icon="close"
                onClick={() => onRemoveRecentSearchEntry(term)}
                testid="remove-recent-term"
                tooltip={`Remove "${term}" from recent searches`}
              />
            </PreventClickPropagation>
          </li>
        ))}
      </ul>
    </div>
  );
});

RecentSearches.displayName = 'RecentSearches';
