import { COLOR, CONTAINER_WIDTH, FONT_SIZE, FONT_WEIGHT, GAP, RADIUS } from '@constants';
import { css } from '@emotion/react';

export const styles = {
  container: css`
    max-width: ${CONTAINER_WIDTH.MIN}px;
  `,
  clickable: css`
    cursor: pointer;
  `,
  innerContainer: css`
    display: flex;
    flex-direction: column;
    overflow: hidden;
    border: 1px solid ${COLOR.NEUTRAL_6};
    background-color: ${COLOR.BASE_BACKGROUND_ALT_1};
    border-radius: ${RADIUS.L};
  `,
  header: css`
    display: flex;
    align-items: center;
    gap: ${GAP.S};
    padding: ${GAP.S} ${GAP.M};
  `,
  iconContainer: css`
    width: 30px;
    height: 30px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 100%;
    background-color: ${COLOR.BASE_FOREGROUND};
    flex-shrink: 0;
  `,
  spinnerContainer: css`
    display: flex;
    align-items: center;
    justify-content: center;
    width: 30px;
    height: 30px;
    margin: ${GAP.XS} ${GAP.M} ${GAP.S};
    flex-shrink: 0;
  `,
  title: css`
    font-size: ${FONT_SIZE.M};
    font-weight: ${FONT_WEIGHT.NORMAL};
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
  `,
  subtitle: css`
    font-size: ${FONT_SIZE.XS};
    font-weight: ${FONT_WEIGHT.NORMAL};
    margin-top: ${GAP.XS};
    padding-bottom: ${GAP.XXXS};
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
  `,
  values: css`
    display: flex;
    flex-direction: column;
    justify-content: center;
    flex: 1;
  `,
  valueContainer: css`
    display: grid;
    grid-template-columns: auto 1fr;
  `,
  valueLabelContainer: css`
    display: flex;
    flex-wrap: wrap;
    align-items: flex-end;
    gap: 0 ${GAP.S};
  `,
  icon: css`
    margin-right: ${GAP.S};
    padding: 6px;
    width: 30px;
    height: 30px;
    display: flex;
    justify-content: center;
    align-items: center;
  `,
  iconPlaceholder: css`
    display: inline-block;
    width: 30px;
    margin-right: ${GAP.S};
  `,
  value: css`
    word-break: break-word;
    overflow: hidden;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
  `,
  valueTitle: css`
    font-size: ${FONT_SIZE.L};
    font-weight: ${FONT_WEIGHT.SEMI_BOLD};
  `,
  valueLabel: css`
    font-size: ${FONT_SIZE.XS};
    font-weight: ${FONT_WEIGHT.NORMAL};
  `
};
