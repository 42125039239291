import { useCallback, useContext } from 'react';

import type { CollectionModel } from '@cyferd/client-engine';
import { GeneralModel, ViewModel, createUUID } from '@cyferd/client-engine';
import { useTestingHelper } from '@utils';

import { TRANS, dateSchema, defaultFormDetailGroupList, defaultFormDetailGroupMap } from '@constants';
import type { EditorContextValue } from '../../../shared/EditorHome';
import { EditorContext } from '../../../shared/EditorHome';
import { EmptyState } from '@components/elements/EmptyState';
import { SchemaForm } from '../../../shared/SchemaForm';
import { styles } from './styles';
import { Layout } from '@components/elements/Layout';

const schema: GeneralModel.JSONSchema = {
  $id: createUUID(),
  type: 'object',
  required: ['name'],
  properties: {
    name: {
      type: 'string',
      label: TRANS.client.fields.titles.name,
      minLength: 1,
      metadata: { detailGroupId: defaultFormDetailGroupMap.basic.id }
    },
    title: {
      type: 'string',
      label: TRANS.client.fields.titles.publicName,
      info: 'A public name is the name visible to your users. If a public name is created, "Name" will only be visible to Admins in the builder.',
      metadata: { detailGroupId: defaultFormDetailGroupMap.basic.id }
    },
    description: {
      type: 'string',
      format: GeneralModel.JSONSchemaFormat.MULTILINE,
      label: TRANS.client.fields.titles.description,
      info: 'This description will be how it appears to your users.',
      metadata: { detailGroupId: defaultFormDetailGroupMap.basic.id }
    },
    recordColor: {
      type: 'string',
      label: TRANS.client.fields.titles.color,
      format: GeneralModel.JSONSchemaFormat.COLOR,
      info: TRANS.client.fields.info.color,
      metadata: { detailGroupId: defaultFormDetailGroupMap.basic.id }
    },
    recordImage: {
      type: 'string',
      label: TRANS.client.fields.titles.icon,
      format: GeneralModel.JSONSchemaFormat.ICON_IMAGE,
      info: TRANS.client.fields.info.image,
      metadata: { detailGroupId: defaultFormDetailGroupMap.basic.id }
    },
    notes: {
      type: 'string',
      label: TRANS.client.fields.titles.adminNotes,
      info: 'This description is only visible to admin users',
      format: GeneralModel.JSONSchemaFormat.MULTILINE,
      metadata: { detailGroupId: defaultFormDetailGroupMap.basic.id }
    },
    hideInApps: {
      type: 'boolean',
      default: false,
      label: TRANS.client.fields.titles.hideInApps,
      info: 'Hide this collection from appearing in any linked app home pages',
      format: GeneralModel.JSONSchemaFormat.CHECKBOX,
      metadata: { detailGroupId: defaultFormDetailGroupMap.basic.id }
    }
  }
};

export const GeneralInfo = () => {
  const { getTestIdProps } = useTestingHelper('general-info');
  const { item, setItem } = useContext<EditorContextValue<CollectionModel.Collection>>(EditorContext);

  const onChange = useCallback((entity: CollectionModel.Collection) => setItem(entity), [setItem]);

  if (!item) return <EmptyState />;

  return (
    <div {...getTestIdProps('container')} className={styles.container}>
      <Layout type={ViewModel.LayoutType.TWO_ALT_1}>
        <SchemaForm schema={schema} value={item} onChange={onChange} detailGroupList={defaultFormDetailGroupList} wrapDetailGroups={true} />
        <SchemaForm schema={dateSchema} value={item} onChange={onChange} detailGroupList={defaultFormDetailGroupList} wrapDetailGroups={true} />
      </Layout>
    </div>
  );
};
