import { COLOR, GAP } from '@constants';
import { css } from '@emotion/react';

export const styles = {
  container: css`
    display: flex;
    align-items: center;
    justify-content: center;
    min-height: 100vh;
  `,
  content: css`
    max-width: 1200px;
    width: 100%;
    justify-content: center;
    align-items: center;
    min-height: 100%;
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(min(100%, 400px), 1fr));
    padding: ${GAP.S};
  `,
  infoContainer: css`
    display: flex;
    justify-content: center;
  `,
  title: css`
    font-size: 28px;
    font-weight: bold;
    margin: 1em 0;
  `,
  subtitle: css`
    font-size: 18px;
    margin: ${GAP.L} 0;
  `,
  errorId: css`
    font-size: 12px;
    font-color: ${COLOR.NEUTRAL_2};
    margin: ${GAP.L} 0;
  `
};
