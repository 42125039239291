import type { FC } from 'react';
import { memo } from 'react';

import type { ComponentRecord } from '@cyferd/client-engine';

import { MappedComponent } from './MappedComponent';
import { WPLComponents } from './webPatternAdapters';

const NotFound = ({ children }) => children || null;

/** wrapper to place every component inside a MappedComponent */
const getComponentRecord = (PLComponentMap: Record<string, FC<any>>): ComponentRecord => {
  return Object.fromEntries(
    Object.entries(PLComponentMap).map(([key, Item]) => [
      `${key.charAt(0).toLowerCase()}${key.substring(1, key.length)}`,
      (() => {
        /* istanbul ignore next */
        const component = memo(props => (
          <MappedComponent {...props}>
            <Item {...props} />
          </MappedComponent>
        ));
        component.displayName = key;
        return component;
      })()
    ])
  );
};
export const componentRecord = { ...getComponentRecord(WPLComponents), NotFound } as ComponentRecord;
