import { useCallback, useContext, useRef, useState } from 'react';
import type { Observable } from 'rxjs';
import { EMPTY, Subject, catchError, shareReplay, takeUntil, tap } from 'rxjs';

import type { GeneralModel } from '@cyferd/client-engine';
import { ApiModel, ClientEngineContext, normalize, ofType, useFinalizeWhileMounted, useUnmountObservable } from '@cyferd/client-engine';

import { CyWrapperContext } from '../../smart/CyWrapper';
import { Storage } from '@utils/storage';

export const searchQuickFilters: GeneralModel.FetchCriteria['quickFilters'] = [
  { id: 'createdByMe', name: 'Created by me' },
  { id: 'updatedByMe', name: 'Updated by me' },
  { id: 'today', name: 'Today' },
  { id: 'yesterday', name: 'Yesterday' },
  { id: 'last7Days', name: 'Last 7 days' },
  { id: 'last30Days', name: 'Last 30 days' }
];

export interface SearchValueItem {
  href: string;
  record: ApiModel.ApiRecord;
}

export interface SearchValue {
  [key: string]: ApiModel.ApiValue;
}
export interface UseSearchOptions {
  id?: string;
  storage?: Storage;
  initialValue?: SearchValue;
  onSearch?: (payload: ApiModel.TriggerPayloadModel.CORE_SEARCH, meta?: any) => Observable<ApiModel.APIAction>;
}

export const useSearch = ({ id, storage = new Storage(), initialValue, onSearch: onCustomSearch }: UseSearchOptions) => {
  const { useAction } = useContext(CyWrapperContext);
  const onCoreGlobalSearch = useAction('coreSearch');
  const onGlobalSearch = onCustomSearch || onCoreGlobalSearch;
  const onDestroy$ = useUnmountObservable();
  const finalize = useFinalizeWhileMounted();
  const cancel$ = useRef(new Subject<void>());

  const [isSearching, setIsSearching] = useState<boolean>(false);
  const [isError, setIsError] = useState<boolean>(false);
  const [searchResults, setValue] = useState<SearchValue>(initialValue);
  const [recentTerms, setRecentTerms] = useState<string[]>();

  const { useUserSelector } = useContext(ClientEngineContext);
  const userId = useUserSelector()?.id;
  const storageKey = [userId, 'recentSearches', id].filter(Boolean).join('-');

  const onClearRecentSearches = useCallback(() => {
    storage.set(storageKey, []);
    setRecentTerms([]);
  }, [storage, storageKey]);

  const onRemoveRecentSearchEntry = (entry: string) => {
    const newEntries = (recentTerms || storage.get(storageKey))?.filter(t => t !== entry);
    setRecentTerms(newEntries);
    storage.set(storageKey, newEntries);
  };

  const updateStorage = useCallback(
    (event: string) => {
      const recentSearches: string[] = storage.get(storageKey);
      if (!Array.isArray(recentSearches)) {
        storage.set(storageKey, [event]);
        setRecentTerms([event]);
      } else {
        const newRecentSearches = Array.from(new Set([event, ...recentSearches]));
        storage.set(storageKey, newRecentSearches);
        setRecentTerms(newRecentSearches);
      }
    },
    [storage, storageKey]
  );

  const onClearSearchResults = useCallback(() => setValue(null), []);

  const onCancel = useCallback(() => {
    cancel$.current.next();
  }, []);

  const onSearch = useCallback(
    ({ searchString, apps, collections, quickFilters }: ApiModel.TriggerPayloadModel.CORE_SEARCH) => {
      const cleanSearchString = searchString?.trim();
      onCancel();
      onClearSearchResults();
      setIsSearching(true);
      setIsError(false);
      const observable = onGlobalSearch({ searchString: cleanSearchString, apps, collections, quickFilters }).pipe(
        takeUntil(cancel$.current),
        takeUntil(onDestroy$),
        ofType<ApiModel.TriggerPayloadModel.DISPATCH_SET_DATA>(ApiModel.TriggerActionType.DISPATCH_SET_DATA),
        tap((payload: any) => {
          updateStorage(cleanSearchString);

          const responseType = String(payload?.pointer)?.split('.')?.[1] as ApiModel.TriggerPayloadModel.CORE_SEARCH['types'][0];
          const responseValue = { ...payload?.value, query: normalize.collection(payload?.value?.query) } as ApiModel.ApiValue;

          setValue(prev => ({ ...prev, [responseType]: responseValue }));
        }),
        catchError(() => {
          setIsError(true);
          return EMPTY;
        }),
        finalize(() => {
          setIsSearching(false);
        }),
        shareReplay()
      );
      observable.subscribe();
      return observable;
    },
    [onCancel, onClearSearchResults, onGlobalSearch, onDestroy$, finalize, updateStorage]
  );

  return {
    isSearching,
    isError,
    searchResults,
    recentTerms: recentTerms || storage.get(storageKey),
    onCancel,
    onSearch,
    onClearSearchResults,
    onClearRecentSearches,
    onRemoveRecentSearchEntry
  };
};
