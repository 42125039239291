import { useContext, useMemo, useState } from 'react';
import { EMPTY } from 'rxjs';

import type { UserModel } from '@cyferd/client-engine';
import { ApiModel, createUUID, getParsedActionChildren } from '@cyferd/client-engine';

import { GlobalContext } from '../../../../state-mgmt/GlobalState';
import { BondEditor } from '../../../shared/BondEditor';
import type { EditorContextValue } from '../../../shared/EditorHome';
import { EditorContext } from '../../../shared/EditorHome';
import { UserTagSecurityModal } from '@components/elements/UserTagSecurityModal';

export const AppBondEditor = () => {
  const id = useMemo(createUUID, []);
  const [isSecurityOpen, setIsSecurityOpen] = useState<boolean>(false);
  const [selection, setSelection] = useState<ApiModel.ApiRecord>();
  const { item } = useContext<EditorContextValue<UserModel.User>>(EditorContext);
  const { deps } = useContext(GlobalContext);

  const cursor = useMemo(() => ({ associationKey: 'apps', relatedTo: { collectionId: ApiModel.ApiEntity.USER, id: item?.id } }), [item]);

  const onOpenPermissions = (event: ApiModel.ApiRecord) => {
    setIsSecurityOpen(true);
    setSelection({
      fullItem: event,
      id: event?.id,
      bondId: event?.$r?.id,
      bondValues: Object.entries({ ...event?.$r?.values }).map(([key, value]) => ({ key, value: `${value}` }))
    });
    return EMPTY;
  };

  const onSuccess = () => deps.refresh$.next(id);

  return (
    <>
      {isSecurityOpen && (
        <UserTagSecurityModal
          open={isSecurityOpen}
          onClose={() => setIsSecurityOpen(false)}
          onSuccess={onSuccess}
          fullItem={selection.fullItem}
          itemId={selection.id}
          collectionId={ApiModel.ApiEntity.TAG}
          bondId={selection.bondId}
          initialValue={selection?.bondValues}
          associationKey="hasAccess"
        />
      )}
      <BondEditor
        componentId={id}
        item={item}
        collectionId={ApiModel.ApiEntity.TAG}
        cursor={cursor}
        associationKey="apps"
        modalTitle="Unlinked apps"
        additionalActions={getParsedActionChildren([{ icon: 'security', label: 'Edit security', onClick: onOpenPermissions }])}
      />
    </>
  );
};
