import type { ChartDataOutput, ViewModel } from '@cyferd/client-engine';
import { Translate } from '@cyferd/client-engine';

import { COLOR, FONT_SIZE, FOREGROUND_COLOR, GAP } from '@constants';
import { getLabel } from '@utils';
import { Icon } from '../Icon';
import { Spinner } from '../Spinner';
import { ToolTip } from '../Tooltip';
import { styles } from './styles';
import type { Subscription } from 'rxjs';
import { useMemo } from 'react';

export type KPIChartProps = {
  id?: string;
  value?: ChartDataOutput;
  isLoading?: boolean;
  testid?: string;
  onClickItem?: (event: any, meta: any) => Subscription;
} & Pick<ViewModel.CyChartProps, 'title' | 'subtitle' | 'color' | 'icon'>;

export const KPIChart = ({ id, title, subtitle, icon, color, value, isLoading, testid, onClickItem }: KPIChartProps) => {
  const foregroundColor = useMemo(() => FOREGROUND_COLOR[color || 'BASE_BACKGROUND'], [color]);
  const backgroundColor = useMemo(() => (COLOR[color] ? COLOR[color] : undefined), [color]);
  const fullItem = useMemo(() => value?.data?.[0]?.fullItem, [value]);
  const atLeastOneValueHasIcon = (value?.data || []).some(col => !!col.icon);

  return (
    <div
      id={id}
      data-testid={testid || 'kpi-chart'}
      css={[styles.container, onClickItem && styles.clickable]}
      onClick={onClickItem && (() => onClickItem(fullItem, {}))}
    >
      <div css={[styles.innerContainer, { backgroundColor }]}>
        {!!(title || subtitle || isLoading) && (
          <div css={styles.header} data-testid="kpi-chart-title-container">
            {!isLoading && !!icon && (
              <div css={[styles.iconContainer, { backgroundColor: foregroundColor }]}>
                <Icon name={icon} size={FONT_SIZE.L} fill={backgroundColor || COLOR.BASE_BACKGROUND_ALT_1} />
              </div>
            )}
            {isLoading && (
              <div data-testid="loading" css={styles.spinnerContainer}>
                <Spinner />
              </div>
            )}
            <div>
              {!!title && (
                <ToolTip text={title}>
                  <h3 css={styles.title} style={{ color: foregroundColor }}>
                    <Translate>{title}</Translate>
                  </h3>
                </ToolTip>
              )}
              {!!subtitle && (
                <ToolTip text={subtitle}>
                  <p css={styles.subtitle} style={{ color: foregroundColor }}>
                    <Translate>{subtitle}</Translate>
                  </p>
                </ToolTip>
              )}
            </div>
          </div>
        )}
        <div css={styles.values}>
          {!isLoading &&
            (value.data || []).map((col, idx) => {
              const colBackgroundColor = COLOR[col.color] || backgroundColor;
              const colForegroundColor = FOREGROUND_COLOR[col.color] || foregroundColor;
              if (typeof col.value === 'object') return null;
              return (
                <div
                  key={col.definitionId}
                  data-testid={`kpi-row-${col.definitionId}`}
                  css={styles.valueContainer}
                  style={{
                    backgroundColor: colBackgroundColor,
                    padding: idx !== value.data.length - 1 ? `${GAP.XS} ${GAP.M}` : `${GAP.XS} ${GAP.M} ${GAP.S}`
                  }}
                >
                  {!!col.icon && (
                    <div css={styles.icon}>
                      <Icon name={col.icon} size={FONT_SIZE.L} fill={colForegroundColor} />
                    </div>
                  )}
                  {!col.icon && atLeastOneValueHasIcon && <span css={styles.iconPlaceholder} />}
                  <div
                    css={styles.valueLabelContainer}
                    style={{
                      maxWidth: atLeastOneValueHasIcon ? '196px' : '234px'
                    }}
                  >
                    <ToolTip text={getLabel([...col.label])}>
                      <div css={styles.value} style={{ color: colForegroundColor }}>
                        <span css={styles.valueTitle}>{col.value}</span>
                        <span>{' ' /** space between texts */}</span>
                        <span css={styles.valueLabel} style={{ color: colForegroundColor }}>
                          {getLabel([...col.label])}
                        </span>
                      </div>
                    </ToolTip>
                  </div>
                </div>
              );
            })}
        </div>
      </div>
    </div>
  );
};
