import { isEditable } from './constants';

type onResetArgs = { properties: any; change: any };

export const onReset = (e: MouseEvent, { properties, change }: onResetArgs) => {
  e.preventDefault();
  const items = document.querySelectorAll('[data-inselection="true"]');
  if (items?.length) {
    const resetValues = Array.from(items).reduce((acc, item) => {
      const rowId = item?.getAttribute('data-rowid');
      const definitionId = item?.getAttribute('data-definitionid');
      const initialValue = item?.getAttribute('data-initialvalue');
      const schema = properties?.[rowId]?.[definitionId];
      return !schema?.disabled && isEditable(schema) ? { ...acc, [rowId]: { ...acc[rowId], [definitionId]: initialValue } } : acc;
    }, {});
    change(resetValues);
  }
};
