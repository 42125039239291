import { useCyActions } from '@utils';
import { useCallback } from 'react';
import { ItemMenu } from '../ItemMenu';
import { ItemMenuPopover } from '../ItemMenuPopover';
import { SubItemMenu } from '../SubItemMenu';
import { styles } from './styles';
import type { NavigationMenuProps } from '../NavigationMenu';

interface IRecentViewsList {
  recentViews: NavigationMenuProps['recentViews'];
}

interface IRecentViews extends IRecentViewsList {
  isOpen: boolean;
  onClick: () => void;
  isShowMore: boolean;
}

export const RecentViews = ({ recentViews, onClick, isOpen, isShowMore }: IRecentViews) => (
  <>
    <div css={styles.divider} />
    <ItemMenuPopover disabled={isOpen} content={<RecentViewsList recentViews={recentViews} />}>
      <ItemMenu
        onClick={onClick}
        optionList={[]}
        containChildren={true}
        isActive={false}
        recordTitle="Recent views"
        isShowMore={isShowMore}
        recordImage="history"
        isOpen={isOpen}
        onToggle={onClick}
        id={''}
      />
    </ItemMenuPopover>
    <div css={isOpen && isShowMore ? styles.show : styles.hide}>
      <RecentViewsList recentViews={recentViews} />
    </div>
  </>
);

const RecentViewsList = ({ recentViews }: IRecentViewsList) => {
  const { onDispatchNavigateTo } = useCyActions();

  const handleClick = useCallback((path: string, qs: unknown) => () => onDispatchNavigateTo({ path, qs }), [onDispatchNavigateTo]);
  return recentViews.map(view => {
    const { navigationInfo, id, viewId, input } = view;
    const title = navigationInfo?.recordTitle || viewId;
    return <SubItemMenu.Value key={id} title={title} isActive={false} onClick={handleClick(viewId, input)} />;
  });
};
