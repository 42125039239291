import { safeParse } from '@cyferd/client-engine';

export const challengeKey = 'challengeCodes';

/** verifier */
const generateRandomString = () => {
  const array = new Uint32Array(56 / 2);
  window.crypto?.getRandomValues?.(array);
  return Array.from(array, dec => ('0' + dec.toString(16)).substr(-2)).join('');
};

const verifier = generateRandomString();

/** challenge */
const sha256 = (plain: string) => {
  // returns promise ArrayBuffer
  const encoder = new TextEncoder();
  const data = encoder.encode(plain);
  return window.crypto?.subtle?.digest?.('SHA-256', data);
};

const base64urlencode = (a: ArrayBuffer) => {
  let str = '';
  const bytes = new Uint8Array(a);
  const len = bytes.byteLength;
  for (let i = 0; i < len; i++) {
    str += String.fromCharCode(bytes[i]);
  }
  return btoa(str).replace(/\+/g, '-').replace(/\//g, '_').replace(/=+$/, '');
};

export const getChallenge = async () => {
  const prev = safeParse<any>(sessionStorage.getItem(challengeKey));
  if (typeof prev?.verifier === 'string' && typeof prev?.challenge === 'string') return prev;
  const challenge = await sha256(verifier);
  const codes = { verifier, challenge: base64urlencode(challenge) };
  sessionStorage.setItem(challengeKey, JSON.stringify(codes));
  return codes;
};
