import { useContext, useMemo } from 'react';

import type { CollectionModel } from '@cyferd/client-engine';
import { ViewModel } from '@cyferd/client-engine';

import type { EditorContextValue } from '../../../shared/EditorHome';
import { EditorContext } from '../../../shared/EditorHome';
import { TriggerSelector } from './components';
import { styles } from './styles';
import { LinkedFlowType } from './types';
import { LinkingPageDiagram, PredefinedDiagrams } from '@components/elements/LinkingPageDiagram';
import { Layout } from '@components/elements/Layout';
import { Fieldset } from '@components/elements/Fieldset';

export const TriggerEditor = () => {
  const { item } = useContext<EditorContextValue<CollectionModel.Collection>>(EditorContext);
  const entityId = useMemo(() => item?.id, [item]);

  const infoLabelValue = useMemo(
    () => (
      <div className={styles.infoLabelValueDiagram}>
        <LinkingPageDiagram value={PredefinedDiagrams.collectionLinkedFlows} />
      </div>
    ),
    []
  );

  return (
    <Layout type={ViewModel.LayoutType.FULL} fitToPage={true}>
      <Fieldset
        isDetailGroup={false}
        title="Linked flows"
        description="Flows are custom processes that can run manually, on schedule, or be triggered by other actions."
        info={infoLabelValue as any}
      />
      <TriggerSelector
        title="Triggers"
        subtitle="These flows are triggers to run after the action is completed."
        ctaLabel="Add trigger"
        ctaIcon="add"
        linkedFlowType={LinkedFlowType.TRIGGER}
        entityId={entityId}
        associationKey="watcher"
      />
      <TriggerSelector
        linkedFlowType={LinkedFlowType.LIST}
        title="List actions"
        subtitle="These flows are presented as list actions in your CORE views."
        entityId={entityId}
        associationKey="listActions"
        ctaLabel="Add list action"
        ctaIcon="add"
      />
      <TriggerSelector
        linkedFlowType={LinkedFlowType.RECORD}
        title="Record actions"
        subtitle="These flows are presented as actions for each record in your CORE views."
        entityId={entityId}
        associationKey="recordActions"
        ctaLabel="Add record action"
        ctaIcon="add"
      />
    </Layout>
  );
};
