import { COLOR, GAP, SHADOW } from '@constants';
import { css, keyframes } from '@emotion/react';

const sidebarWidth = '270px';

const animation = keyframes`
from { right: -${sidebarWidth}; }
to { right: 0; }
`;

export const styles = {
  container: css`
    height: 100%;
    width: 100%;
    display: flex;
    gap: ${GAP.M};
    overflow: auto;
  `,
  leftBar: css`
    display: flex;
    flex-direction: column;
    gap: ${GAP.M};
  `,
  bbcontainer: css`
    width: 100%;
  `,
  main: css`
    height: 100%;
    flex: 1;
    display: flex;
    overflow: auto;
    position: relative;
  `,
  header: css`
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: ${GAP.S};
    flex-wrap: wrap;
  `,
  progress: css`
    padding-top: ${GAP.S};
  `,
  mainContent: css`
    height: 100%;
    flex: 1;
    display: flex;
    flex-direction: column;
  `,
  canvas: css`
    overflow: hidden;
  `,
  sidePanelContainer: css`
    position: absolute;
    right: 0;
    top: 0;
    z-index: 2;
    width: ${sidebarWidth};
    height: 100%;
    box-shadow: ${SHADOW.M};
    animation: ${animation} 100ms linear;
  `,
  sidePanel: css`
    height: 100%;
  `,
  division: css`
    border-top: 1px solid ${COLOR.NEUTRAL_4};
  `
};
