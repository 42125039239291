/* istanbul ignore file */
import { memo, useContext, useEffect } from 'react';
import { Route, Routes } from 'react-router-dom';
import { ApiModel, ClientEngineContext, actionHooks } from '@cyferd/client-engine';
import { BuilderApp } from './builder/BuilderApp';
import { ModalInteraction } from './builder/views/ModalInteraction';
import { MessageList } from './client-app/components/MessageList';
import { ProgressBar } from './client-app/components/ProgressBar';
import { ToastList } from './client-app/components/ToastList';
import { LoginPage } from './client-app/pages/LoginPage';
import { MobilePage } from './client-app/pages/MobilePage';
import { ViewPage } from './client-app/pages/ViewPage';
import { NOTIFICATION_LATEST_POINTER } from './client-app/state-mgmt/notification';
import { useWSContext } from './client-app/state-mgmt/WSProvider';
import { LoadingPageOverlay } from '@components/platform/LoadingPageOverlay';
import { EVALUATOR_PORTAL_ID } from '@components/elements/Evaluator/resources';
import { POPOVER_PORTAL_ID } from '@components/elements/Popover';
import { AppWrapper } from './client-app/components/AppWrapper';
import { ErrorPage } from '@components/platform/ErrorPage';
import { DROPDOWN_PORTAL_ID } from '@components/elements/DropDownOptionEditTable';

export const App = memo(() => {
  const { isAuthenticated, isLoading } = useWSContext();

  const onList = actionHooks.useAction('coreList');
  const { useUserSelector } = useContext(ClientEngineContext);
  const user = useUserSelector();

  useEffect(() => {
    if (isAuthenticated)
      onList({
        pointer: NOTIFICATION_LATEST_POINTER,
        query: {
          cursor: {
            collectionId: ApiModel.ApiEntity.NOTIFICATION,
            options: { orderBy: 'createdAt', descending: true, limit: 1 },
            filter: { $and: [{ notified: { $matches: { id: user?.id } } }, { read: { $eq: false } }] }
          }
        }
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isAuthenticated]);

  useEffect(() => {
    const checkPageLoaded = () => {
      if (document.readyState === 'complete') {
        const loadingElement = document.getElementById('loading-container');
        const themeScript = document.getElementById('theme-script');

        if (loadingElement) {
          loadingElement.remove();
        }

        if (themeScript) {
          themeScript.remove();
        }

        window.removeEventListener('load', checkPageLoaded);
      }
    };

    if (document.readyState === 'complete') {
      checkPageLoaded();
    } else {
      window.addEventListener('load', checkPageLoaded);
    }

    return () => window.removeEventListener('load', checkPageLoaded);
  }, []);

  return (
    <>
      {!!isLoading && <LoadingPageOverlay />}
      <ProgressBar />
      {isAuthenticated ? (
        <AppWrapper>
          <Routes>
            <Route path="/builder/*" element={<BuilderApp />} />
            <Route path="/error" element={<ErrorPage />} />
            <Route path="/m/*" element={<MobilePage />} />
            <Route path="/*" element={<ViewPage />} />
          </Routes>
          <ToastList />
          <MessageList />
          <ModalInteraction />
          <div id={EVALUATOR_PORTAL_ID} />
          <div id={POPOVER_PORTAL_ID} />
          <div id={DROPDOWN_PORTAL_ID} />
        </AppWrapper>
      ) : (
        <Routes>
          <Route path="/error" element={<ErrorPage />} />
          <Route path="/*" element={<LoginPage />} />
        </Routes>
      )}
    </>
  );
});
