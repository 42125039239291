import { COLOR, EMPTY_CONTAINER_HEIGHT, FONT_WEIGHT, GAP, SECONDARY_COLOR, TRANSITION_SPEED } from '@constants';
import { css } from '@emotion/react';

export const styles = {
  container: css`
    display: grid;
    gap: ${GAP.XS};
    grid-template-columns: 280px auto;
    height: 100%;
  `,
  ctaContainer: css`
    display: flex;
    justify-content: flex-end;
  `,
  controls: css`
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    margin-bottom: ${GAP.XS};
  `,
  addBtn: css`
    flex: 1;
    align-items: center;
    justify-content: flex-start;
    align-self: stretch;
    align-items: center;
    padding: ${GAP.XS} 0;
    transition: background-color ${TRANSITION_SPEED};
    cursor: pointer;
    margin: -${GAP.XXS} -${GAP.S} -${GAP.S} -${GAP.M};
    padding: ${GAP.XS} ${GAP.S};
    :hover {
      background-color: ${SECONDARY_COLOR.BRAND_1};
    }
  `,
  tableHeader: css`
    background-color: ${COLOR.NEUTRAL_5};
    border-bottom: 1px solid ${COLOR.NEUTRAL_4};
    padding: ${GAP.S} 0;
    gap: 0;
  `,
  propRow: css`
    display: grid;
    grid-template-columns: 200px 1fr 1fr;
    gap: 0 ${GAP.M};
  `,
  division: css`
    border-bottom: 1px solid ${COLOR.NEUTRAL_4};
  `,
  propTitle: css`
    padding: 0 ${GAP.S};
    font-weight: ${FONT_WEIGHT.BOLD};
  `,
  propCellBase: css`
    padding: ${GAP.XXS} ${GAP.S} ${GAP.S} 0;
    border-right: 1px solid ${COLOR.NEUTRAL_4};
    display: flex;
  `,
  propCellCenter: css`
    display: flex;
    align-items: center;
  `,
  propCellLabel: css`
    background-color: ${COLOR.NEUTRAL_5};
  `,
  emptyState: css`
    display: flex;
    width: 100%;
    height: 100%;
    flex: 1;
    align-items: center;
    justify-content: center;
    min-height: ${EMPTY_CONTAINER_HEIGHT.XS}px;
  `
};
