import { APP_WIDTH, COLOR, FONT_SIZE, GAP } from '@constants';
import { css } from '@emotion/react';

export const styles = {
  container: css`
    background-color: ${COLOR.BASE_BACKGROUND};
    position: fixed;
    top: 0;
    left: 0;
    z-index: 999999;
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100vh;
    overflow: hidden;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: top left;

    /* This is a forced user-agent-stylesheet rule */
    p {
      display: block;
      margin-block-start: 1em;
      margin-block-end: 1em;
      margin-inline-start: 0px;
      margin-inline-end: 0px;
    }
  `,

  content: css`
    display: flex;
    flex-direction: column;
    margin-top: calc(${GAP.XXL} * 1.25);
    margin-left: calc(${GAP.XL} * 1.25);
    padding: ${GAP.M};
    @media (min-width: ${APP_WIDTH.S}px) {
      margin-top: calc(${GAP.XXL} * 3);
      margin-left: calc(${GAP.XL} * 3);
    }
  `,
  text: css`
    color: ${COLOR.NEUTRAL_2};
    font-size: ${FONT_SIZE.XL};
    line-height: ${FONT_SIZE.XXL};
  `,

  imagesWrapper: css`
    position: relative;
    margin-bottom: ${GAP.L};
    max-width: 100%;
  `,
  imageContainer: css`
    padding: 0 ${GAP.M};
    margin: 0 auto;
    display: flex;
    justify-content: center;

    svg {
      min-width: 850px;
    }
  `,
  sliderContainer: css`
    position: absolute;
    display: flex;
    width: 2790px;
    height: 150px;
    top: 150px;

    @media (min-width: 1200px) {
      height: 175px;
      top: 100px;
    }

    animation: slide 30s linear infinite;
    @keyframes slide {
      100% {
        transform: translateX(-66%);
      }
    }
  `
};
