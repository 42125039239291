import { useContext, useMemo } from 'react';

import type { FlowModel } from '@cyferd/client-engine';

import { BuilderCyList } from '../../../shared/BuilderCyList';
import type { EditorContextValue } from '../../../shared/EditorHome';
import { EditorContext } from '../../../shared/EditorHome';
import { EmptyState } from '@components/elements/EmptyState';
import { styles } from './styles';
import { useRequest } from '@utils/useRequest';

export const FlowLogs = () => {
  const request = useRequest();
  const { item } = useContext<EditorContextValue<FlowModel.Flow>>(EditorContext);

  const collectionId = 'FlowLog';

  const fixedFilter = useMemo(() => ({ $and: [{ flowId: { $eq: item?.id } }] }), [item?.id]);
  if (!item) return <EmptyState />;

  return (
    <div data-testid="flow-logs" className={styles.container}>
      <BuilderCyList request={request} id={collectionId} key={collectionId} initialFetchCriteria={{ collectionId }} fixedFilter={fixedFilter} />
    </div>
  );
};
