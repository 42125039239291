/* istanbul ignore file | @todo */
import { useCallback, useContext, useMemo, useState } from 'react';

import { ApiModel, ClientEngineContext, ErrorBoundary, ViewModel } from '@cyferd/client-engine';

import { NotificationCard } from '@components/elements/NotificationCard';
import { styles } from './styles';
import { styles as accountMenuStyles } from '../AccountMenu/styles';
import { BuilderCyList } from '../../../builder/views/shared/BuilderCyList';
import { useRequest } from '@utils/useRequest';
import { CyWrapperContext } from '@components/smart/CyWrapper';
import { CyListContext } from '@components/smart/CyList';
import type { CyListContextValue } from '../../smart/CyList/CyList';
import { TRANS } from '@constants';
import { NotificationModal } from '@components/platform/NotificationModal';
import { concat, mergeMap, takeLast } from 'rxjs';
import { GlobalMenuHeader } from '../GlobalMenuHeader';
import { NotificationPreferences } from '../NotificationPreferences';
import { NOTIFICATION_LATEST_POINTER } from '../../../client-app/state-mgmt/notification';

const initialFetchCriteria = { collectionId: ApiModel.ApiEntity.NOTIFICATION, options: { orderBy: 'createdAt', descending: true } };

export const NotificationMenu = () => {
  const request = useRequest();
  const { useUserSelector } = useContext(ClientEngineContext);
  const { useAction } = useContext(CyWrapperContext);
  const onRefresh = useAction('dispatchRefresh');
  const onList = useAction('coreList');
  const userId = useUserSelector()?.id;
  const [active, setActive] = useState<ApiModel.ApiRecord>();
  const onNotificationRead = useAction('notificationMarkAsRead');
  const [tab, setTab] = useState<'main' | 'settings'>('main');

  const onMarkItemAsReadInternal = useCallback(
    () =>
      concat(
        onRefresh({ componentNameList: [ApiModel.ApiEntity.NOTIFICATION] }),
        onList({
          pointer: NOTIFICATION_LATEST_POINTER,
          query: {
            cursor: {
              collectionId: ApiModel.ApiEntity.NOTIFICATION,
              options: { orderBy: 'createdAt', descending: true, limit: 1 },
              filter: { $and: [{ notified: { $matches: { id: userId } } }, { read: { $eq: false } }] }
            }
          }
        })
      ),
    [onList, onRefresh, userId]
  );

  const onMarkAllRead = useCallback(
    () => onNotificationRead({ all: true, read: true }).pipe(takeLast(1), mergeMap(onMarkItemAsReadInternal)),
    [onMarkItemAsReadInternal, onNotificationRead]
  );

  const cyListContextValue = useMemo(
    () =>
      ({
        renderCustomListType: value => {
          return (
            <div css={styles.list}>
              {value?.list?.map(item => (
                <div key={item.id}>
                  <ErrorBoundary>
                    <NotificationCard notification={item} onMarkAsReadSuccess={onMarkItemAsReadInternal} onClick={() => setActive(item)} />
                  </ErrorBoundary>
                </div>
              ))}
            </div>
          );
        }
      }) as CyListContextValue,
    [onMarkItemAsReadInternal]
  );

  const headerListChildren = useMemo(
    () => [
      {
        type: ViewModel.CTAType.TERTIARY,
        size: ViewModel.CTASize.SMALL,
        label: TRANS.client.nav.notifMark,
        important: true,
        onClick: onMarkAllRead
      },
      {
        type: ViewModel.CTAType.LINK,
        size: ViewModel.CTASize.MEDIUM,
        icon: 'settings' as any,
        helperText: TRANS.client.nav.accountMenu.notifPreferences,
        important: true,
        onClick: () => setTab('settings') as any
      }
    ],
    [onMarkAllRead]
  );

  return (
    <div data-testid="notifications-tab">
      {active && <NotificationModal notification={active as any} onClose={() => setActive(null)} />}
      {tab === 'settings' && (
        <div css={accountMenuStyles.container} data-testid="notifications-tab">
          <GlobalMenuHeader title={TRANS.client.nav.accountMenu.notifPreferences} onBack={() => setTab('main')} />
          <div css={accountMenuStyles.content}>
            <NotificationPreferences />
          </div>
        </div>
      )}
      {tab === 'main' && (
        <CyListContext.Provider value={cyListContextValue}>
          <BuilderCyList
            request={request}
            title={TRANS.client.nav.notifs}
            componentName={ApiModel.ApiEntity.NOTIFICATION}
            initialFetchCriteria={initialFetchCriteria}
            fixedFilter={{ $and: [{ notified: { $matches: { id: userId } } }] }}
            key={ApiModel.ApiEntity.NOTIFICATION}
            collectionId={ApiModel.ApiEntity.NOTIFICATION}
            type={ViewModel.CyListType.CARD}
            headerListChildren={headerListChildren}
            fitToPage={true}
            typeSelectorHidden={true}
            quickFiltersHidden={false}
            framed={false}
            paginationHidden={false}
            orderHidden={false}
            advancedFiltersHidden={false}
            searchStringHidden={false}
            recordActionsHidden={true}
          />
        </CyListContext.Provider>
      )}
    </div>
  );
};
