import type { CalendarAddOption, CalendarConfigList } from '@cyferd/client-engine';
import { GeneralModel, ViewModel, getCalendarMap, parseCalendarItems } from '@cyferd/client-engine';
import { styles } from './styles';
import { getValidDayjs } from '@utils/getValidDayjs';
import type { Dayjs } from 'dayjs';
import dayjs from 'dayjs';
import { useContext, useLayoutEffect, useMemo, useRef } from 'react';
import { PopoverTrigger } from '../Popover';
import { CalendarPopoverContent } from '../CalendarPopoverContent';
import { CTAType } from '../CTA';
import { OptionMenu } from '../OptionMenu';
import { UIContext } from '@components/providers/UIprovider';
import { THEME } from '@constants';

export interface CalendarWeekProps {
  testid?: string;
  calendarConfigList: CalendarConfigList[];
  week: GeneralModel.DateLike;
  referenceDate: GeneralModel.DateLike;
  activeDay?: GeneralModel.DateLike;
  firstVisibleHour?: number;
  actionListChildren?: ViewModel.CyCalendarProps['actionListChildren'];
  disabled?: boolean;
  addOptionList: CalendarAddOption[];
  type: ViewModel.CyCalendarType;
  onCellAction?: ViewModel.CyCalendarProps['onCellAction'];
  onChangeActiveDay: (event: GeneralModel.DateLike) => void;
}

export const CalendarWeek = ({
  testid = 'CalendarWeek',
  week,
  referenceDate,
  activeDay,
  firstVisibleHour = 7,
  calendarConfigList,
  actionListChildren,
  disabled,
  addOptionList,
  type,
  onCellAction,
  onChangeActiveDay
}: CalendarWeekProps) => {
  const { runtimeTheme } = useContext(UIContext);
  const ref = useRef<HTMLDivElement>();
  const $week: Dayjs = useMemo(() => getValidDayjs(dayjs(week)).startOf('day'), [week]);
  const $referenceDate: Dayjs = useMemo(() => getValidDayjs(dayjs(referenceDate)).startOf('day'), [referenceDate]);
  const $activeDay: Dayjs = !!activeDay && getValidDayjs(dayjs(activeDay)).startOf('day');
  const weekDayCount = type === ViewModel.CyCalendarType.WEEK ? 7 : 1;
  const weekList = useMemo(() => {
    if (type === ViewModel.CyCalendarType.WEEK) return Array.from(Array(weekDayCount)).map((_, i) => $week.day(i).startOf('day'));
    return [$week.startOf('day')];
  }, [$week, type, weekDayCount]);

  const safeCalendarConfigList = useMemo(
    () => (Array.isArray(calendarConfigList) ? calendarConfigList.map(parseCalendarItems).flat() : []),
    [calendarConfigList]
  );

  const calendarMap = useMemo(() => getCalendarMap(safeCalendarConfigList, 'hour'), [safeCalendarConfigList]);

  const canAdd = typeof onCellAction === 'function';

  const theme: GeneralModel.Color.Theme = {
    [THEME.LIGHT]: GeneralModel.Color.LightTheme,
    [THEME.DARK]: GeneralModel.Color.DarkTheme
  }[runtimeTheme];

  useLayoutEffect(() => {
    setTimeout(() => ref.current?.scrollIntoView?.());
  }, []);

  return (
    <div css={styles.container} data-testid={testid}>
      <header css={styles.grid(weekDayCount)}>
        {[null, ...weekList].map((day, dayIndex) => {
          const isActiveDay = !!dayIndex && day?.format() === $activeDay?.format?.();
          const isReferenceDay = day?.format() === $referenceDate?.format?.();
          return (
            <div
              key={`${day?.format()}${dayIndex}`}
              css={[styles.headerItem, isActiveDay && styles.activeHeaderItem]}
              data-testid="week-header-item"
              onClick={() => !!day && onChangeActiveDay(day.toISOString())}
            >
              {!!day && (
                <div css={[styles.headerItemContent, isReferenceDay && styles.referenceHeaderItemContent]}>
                  <span data-testid="day-num" css={[styles.headerNum, isActiveDay && styles.activeDay, isReferenceDay && styles.referenceDay]}>
                    {day?.format('D')}
                  </span>
                  <span css={styles.headerTxt}>{day?.format('ddd')}</span>
                </div>
              )}
            </div>
          );
        })}
      </header>
      <div css={styles.content}>
        <div css={styles.grid(weekDayCount)}>
          {[null, ...weekList].map((day, dayIndex) => {
            return (
              <div key={`${day?.format()}${dayIndex}}`}>
                {Array.from(Array(24)).map((_, cellIndex) => {
                  const cellDate = getValidDayjs(day).set('hour', cellIndex).startOf('seconds');
                  const isHourCell = !day;
                  const isFirstVisibleHour = !dayIndex && cellIndex === firstVisibleHour;
                  const cellEvents = calendarMap[cellDate.format()] || [];

                  return (
                    <div ref={isFirstVisibleHour ? ref : undefined} key={`${cellDate.format()}${dayIndex}`} css={styles.cell}>
                      <div
                        css={[
                          styles.cellContent,
                          cellEvents.length > 6 && styles.cellContentFlooded,
                          isHourCell && styles.hourCell,
                          !isHourCell && cellEvents?.length && canAdd && styles.cellWithContent(cellEvents.length)
                        ]}
                        data-testid="calendar-cell"
                      >
                        {isHourCell && cellDate.format('H A')}
                        {!isHourCell && canAdd && (
                          <div css={styles.optionMenu}>
                            <div data-selector="calendar-add-options">
                              {addOptionList.length === 1 ? (
                                <div
                                  data-testid="add-option"
                                  css={styles.optionsTrigger}
                                  onClick={() =>
                                    onCellAction({ collectionId: addOptionList[0].collectionId, [addOptionList[0].fromField]: cellDate.toISOString() })
                                  }
                                />
                              ) : (
                                <OptionMenu
                                  renderButton={({ onClick, ref }) => <div data-testid="add-in-cell" css={styles.optionsTrigger} onClick={onClick} ref={ref} />}
                                  optionList={addOptionList.map(o => ({
                                    label: o.label,
                                    color: o.color,
                                    type: CTAType.LINK,
                                    testid: 'add-option',
                                    onClick: () =>
                                      onCellAction({
                                        collectionId: o.collectionId,
                                        [o.fromField]:
                                          o.fromFieldFormat === GeneralModel.JSONSchemaFormat.DATE ? cellDate.format('YYYY-MM-DD') : cellDate.toISOString()
                                      })
                                  }))}
                                />
                              )}
                            </div>
                          </div>
                        )}
                        {cellEvents.map((cellEvent, cellIndex) => {
                          const startsBeforeCell = cellEvent.from.isBefore(cellDate);
                          const eventStart = startsBeforeCell ? cellDate : cellEvent.from;
                          const oneHourMs = 60 * 60 * 1000;
                          const timeLeftInDay = cellDate.endOf('day').diff(cellDate);
                          const topOffset = startsBeforeCell ? 0 : Math.min(100, Math.round((eventStart.diff(cellDate) * 100) / oneHourMs));
                          const duration = Math.round((Math.min(cellEvent.to.diff(eventStart), timeLeftInDay) * 100) / oneHourMs);
                          const isFullDayEvent = cellEvent.fromFieldFormat === GeneralModel.JSONSchemaFormat.DATE;

                          return (
                            <PopoverTrigger
                              containerCss={styles.cellEvent({
                                color: cellEvent.color,
                                topOffset,
                                duration,
                                hex: theme?.[cellEvent.color]?.secondary,
                                hasHover: !isFullDayEvent
                              })}
                              contentCss={styles.popoverChildren}
                              testId="calendar-event"
                              key={`${cellEvent.item?.fullItem?.id}${cellIndex}`}
                              label={cellEvent.item?.title}
                              color={cellEvent.color}
                              value={
                                <CalendarPopoverContent
                                  from={cellEvent.fromRaw}
                                  to={cellEvent.toRaw}
                                  value={cellEvent.item}
                                  disabled={disabled}
                                  actionListChildren={actionListChildren}
                                  isFullDayEvent={isFullDayEvent}
                                />
                              }
                            >
                              <div data-testid="event-position" data-position={JSON.stringify({ topOffset, duration })} css={styles.eventContent}>
                                <p css={styles.cellEventTitle} data-testid="calendar-event-title">
                                  {cellEvent.item?.title}
                                </p>
                                {duration >= 75 && !isFullDayEvent && (
                                  <p css={styles.cellEventSubtitle} data-testid="calendar-event-subtitle">
                                    {Array.from(
                                      new Set([cellEvent.fromRaw, cellEvent.toRaw].filter(d => !!d && dayjs(d).isValid()).map(d => dayjs(d).format('h:mm A')))
                                    ).join(' - ')}
                                  </p>
                                )}
                              </div>
                            </PopoverTrigger>
                          );
                        })}
                      </div>
                    </div>
                  );
                })}
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
};

CalendarWeek.displayName = 'CalendarWeek';
