import { ApiModel } from '@cyferd/client-engine';
import type { GeneralModel } from '@models';

import { ACTION_TYPE } from './actions';
import type { UIState } from './state';
import { getInitialState } from './state';

export const reducer = (state: UIState = getInitialState(), { type, payload }: GeneralModel.Action): UIState => {
  switch (type) {
    case ACTION_TYPE.SET_LOADING_LIST:
      return { ...state, loadingList: payload.list };
    case ACTION_TYPE.ADD_LOADING:
      return { ...state, loadingList: Array.from(new Set([...state.loadingList, payload.id])) };
    case ACTION_TYPE.REMOVE_LOADING:
      return { ...state, loadingList: state.loadingList.filter(id => id !== payload.id) };
    case ACTION_TYPE.ADD_TOAST:
      return { ...state, toastList: [...state.toastList, payload] };
    case ACTION_TYPE.REMOVE_TOAST:
      return { ...state, toastList: state.toastList.filter(({ toast }) => toast.id !== payload.id) };
    case ACTION_TYPE.ADD_MESSAGE:
      return { ...state, messageList: [...state.messageList, payload] };
    case ACTION_TYPE.REMOVE_MESSAGE:
      return { ...state, messageList: state.messageList.filter(({ id }) => id !== payload.id) };
    /** reseting data when view changes */
    case ApiModel.TriggerActionType.DISPATCH_SET_VIEW:
      return { ...state, activeModalList: [] };
    case ApiModel.TriggerActionType.DISPATCH_TOGGLE_MODAL:
      return {
        ...state,
        activeModalList: (() => {
          switch ((payload as ApiModel.TriggerPayloadModel.DISPATCH_TOGGLE_MODAL).action) {
            case 'open':
              return [payload.modalId];
            case 'close':
              return [];
            case 'toggle':
            default:
              return state.activeModalList.includes(payload.modalId) ? [] : [payload.modalId];
          }
        })()
      };
    default:
      return state;
  }
};
