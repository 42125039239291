import { css } from '@emotion/react';

import { COLOR, FONT_SIZE, GAP } from '@constants';

export const styles = {
  container: css`
    display: flex;
    flex-direction: column;
  `,
  header: css`
    display: flex;
    justify-content: space-between;
    align-items: center;
  `,
  title: css`
    color: ${COLOR.NEUTRAL_2};
    font-weight: bold;
    font-size: ${FONT_SIZE.XS};
  `,
  item: css`
    padding: ${GAP.S} 0;
    cursor: pointer;
    color: ${COLOR.NEUTRAL_1};
    display: flex;
    align-items: center;
    justify-content: space-between;
  `,
  recentSearchList: css`
    max-height: 49vh;
    overflow: auto;
  `
};
