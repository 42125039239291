import * as React from 'react';

export const Logo = props => (
  <svg width={228} height={48} viewBox="0 0 285 60" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      d="M56.019 15.364 30.542 1 3.982 15.364v29L30.541 59 56.02 44.364l-42.823-23.85 17.346-10.028L56.02 25.122 30.542 38.944l-17.346-9.215 17.346-9.215L56.02 34.608 30.542 49.242l-17.346-10.3L56.02 15.366Z"
      stroke="var(--BRAND_1)"
      strokeWidth={1.3}
    />
    <path
      d="M83.884 19.312c-1.476 0-3.06.396-4.356 1.512-1.26 1.08-2.088 2.844-2.088 4.896v10.908c0 2.016.828 3.78 2.088 4.896 1.296 1.08 2.88 1.476 4.356 1.476h20.34c1.512 0 3.06-.396 4.356-1.476 1.296-1.116 2.124-2.88 2.124-4.896l-.072-1.656h-4.608v1.872c0 1.044-.288 1.476-.684 1.8-.36.324-1.008.504-1.692.504H84.46c-.684 0-1.332-.18-1.692-.504-.396-.324-.684-.756-.684-1.8V25.468c0-1.044.288-1.44.684-1.764.36-.324 1.008-.54 1.692-.54h19.188c.684 0 1.332.216 1.692.54.396.324.684.72.684 1.764v1.872h4.608l.072-1.62c0-2.052-.828-3.816-2.124-4.932-1.296-1.08-2.844-1.476-4.356-1.476h-20.34Zm27.71 0 6.48 6.048 6.192 5.76.756.684.9.864V43h4.68V32.668l.936-.864.756-.684 6.156-5.76 6.516-6.048h-6.516l-6.48 6.048-2.952 2.7-.756.72-.756-.72-2.916-2.7-6.516-6.048h-6.48Zm35.936 0V43h4.644v-9.936h23.904v-3.888h-23.904v-6.012h25.416v-3.852h-30.06Zm33.424 0V43h30.06v-3.852h-25.416v-6.084h23.868v-3.888h-23.868v-6.012h25.416v-3.852h-30.06ZM214.905 43h4.644v-8.028h19.008c.684 0 1.296.216 1.692.54.36.324.648.72.648 1.8v5.724h4.68v-5.508c0-1.8-.648-3.384-1.656-4.464 1.008-1.116 1.656-2.7 1.656-4.5V25.72c0-2.052-.828-3.816-2.088-4.896-1.296-1.116-2.88-1.512-4.356-1.512h-24.228V43Zm4.644-19.836h19.008c.684 0 1.296.216 1.692.54.36.324.648.72.648 1.764v3.348c0 1.044-.288 1.44-.648 1.764-.396.324-1.008.54-1.692.54h-19.008v-7.956Zm30.467-3.852V43h26.748c1.476 0 3.06-.396 4.356-1.476 1.26-1.116 2.088-2.88 2.088-4.896V25.72c0-2.052-.828-3.816-2.088-4.896-1.296-1.116-2.88-1.512-4.356-1.512h-26.748Zm4.644 3.852h21.528c.684 0 1.332.216 1.692.54.396.324.684.72.684 1.764v11.376c0 1.044-.288 1.476-.684 1.8-.36.324-1.008.504-1.692.504H254.66V23.164Z"
      fill="var(--BRAND_1)"
    />
  </svg>
);
