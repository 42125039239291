import { COLOR } from '@constants';
import { css } from '@emotion/react';

export const styles = {
  container: css`
    position: fixed;
    top: 0;
    left: 0;
    z-index: 999999;
    flex-direction: column;
    width: 100%;
    height: 100%;
    overflow: hidden;
    display: flex;
    align-items: center;
    justify-content: center;
  `,
  overlay: css`
    background-color: ${COLOR.ABSOLUTE_BLACK};
    opacity: 0.4;
    width: 100%;
    height: 100%;
  `,
  content: css`
    position: absolute;
    z-index: 1;
    display: flex;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
  `
};
