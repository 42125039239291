import { useContext } from 'react';

import type { UserModel } from '@cyferd/client-engine';
import { ViewModel } from '@cyferd/client-engine';
import { useTestingHelper } from '@utils';

import type { EditorContextValue } from '../../../shared/EditorHome';
import { EditorContext } from '../../../shared/EditorHome';
import { EmptyState } from '@components/elements/EmptyState';
import { SchemaForm } from '../../../shared/SchemaForm';
import { detailGroupList, enabledSchema, schema } from './schemas';
import { styles } from './styles';
import { Layout } from '@components/elements/Layout';
import { dateSchema } from '@constants';

export const GeneralInfo = () => {
  const { getTestIdProps } = useTestingHelper('general-info');
  const { item, setItem } = useContext<EditorContextValue<UserModel.User>>(EditorContext);

  if (!item) return <EmptyState />;

  return (
    <div {...getTestIdProps('container')} className={styles.container}>
      <Layout type={ViewModel.LayoutType.TWO_ALT_1}>
        <div>
          <SchemaForm schema={enabledSchema} value={item} onChange={setItem} detailGroupList={detailGroupList} wrapDetailGroups={true} />
          <br />
          <SchemaForm schema={schema} value={item} onChange={setItem} detailGroupList={detailGroupList} wrapDetailGroups={true} />
        </div>
        <SchemaForm schema={dateSchema} value={item} onChange={setItem} detailGroupList={detailGroupList} wrapDetailGroups={true} />
      </Layout>
    </div>
  );
};
