import { GAP } from '@constants';
import { css } from '@emotion/react';

export const styles = {
  container: css`
    display: grid;
    gap: ${GAP.M};
  `,
  sidepanelContent: css`
    width: 100%;
  `,
  tabContent: css`
    display: grid;
  `
};
