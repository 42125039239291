import { ViewModel } from '@cyferd/client-engine';
import { getComponentConfig } from '@models/view';

export const getComponentIcon = (node: ViewModel.Node) => {
  const componentConfig = getComponentConfig(node?.component);
  return (
    ViewModel.PatternComponentPropsSchemaMap[`${node?.component?.replace(/^cy/i, 'Cy')}Props`]?.properties?.type?.metadata?.optionList?.find?.(
      option => option?.value === node?.attrs?.type
    )?.image || componentConfig.icon
  );
};
