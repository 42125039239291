import { Translate } from '@cyferd/client-engine';
import { CTA, CTAType } from '../CTA';
import { styles } from './styles';

export interface GlobalMenuHeaderProps {
  title: string;
  onBack?: () => void;
  onClose?: () => void;
}

export const GlobalMenuHeader = ({ title, onBack, onClose }: GlobalMenuHeaderProps) => {
  return (
    <header css={styles.container}>
      <div css={styles.editionHeader}>
        {!!onBack && (
          <div css={styles.cancelBtn}>
            <CTA testid="back" type={CTAType.LINK} icon="arrow_right_alt" onClick={onBack} />
          </div>
        )}
        <p data-testid="title" css={styles.editionTitle}>
          <Translate>{title}</Translate>
        </p>
      </div>
      {!!onClose && <CTA testid="close" type={CTAType.LINK} color="NEUTRAL_2" icon="close" onClick={onClose} />}
    </header>
  );
};

GlobalMenuHeader.displayName = 'GlobalMenuHeader';
