import { CTA, CTAType } from '../CTA';
import { styles } from './styles';
import { ViewModel } from '@cyferd/client-engine';

export interface EmptyLinkingPageProps {
  title: string | React.ReactNode;
  subtitle?: string;
  diagram?: React.ReactNode;
  ctaLabel?: string;
  ctaOnClick?: () => void;
  testid?: string;
}

export const EmptyLinkingPage = ({ title, subtitle, diagram, ctaLabel, ctaOnClick, testid = 'empty-linking-page' }: EmptyLinkingPageProps) => {
  return (
    <div css={styles.container} data-testid={testid}>
      <div css={styles.titleContainer}>
        <div css={styles.title}>{title}</div>
        {!!subtitle && <div css={styles.subtitle}>{subtitle}</div>}
      </div>
      {!!ctaLabel && !!ctaOnClick && <CTA type={CTAType.PRIMARY} size={ViewModel.CTASize.SMALL} label={ctaLabel} onClick={ctaOnClick} icon="link" />}
      {!!diagram && diagram}
    </div>
  );
};
