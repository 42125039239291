import { COLOR, GAP, RADIUS, TRANSITION_SPEED } from '@constants';
import { css } from '@emotion/react';

export const styles = {
  content: css`
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    gap: ${GAP.XS};
  `,
  mainContent: css`
    flex: 1;
    overflow: auto;
  `,
  option: css`
    width: 100%;
    padding: 0 ${GAP.XS};
  `,
  optionPrevent: css`
    display: block;
    width: 100%;
  `,
  optionContent: css`
    padding: 0 ${GAP.M};
    cursor: pointer;
    border-radius: ${RADIUS.S};
    height: 32px;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    transition: opacity ${TRANSITION_SPEED};
    :hover {
      opacity: 0.6;
    }
  `,
  collapsed: css`
    padding: 0 ${GAP.S};
  `,
  activeOptionContent: css`
    background-color: ${COLOR.BRAND_1};
  `,
  divider: css`
    width: 100%;
    border-top: 1px ${COLOR.NEUTRAL_6} solid;
  `
};
