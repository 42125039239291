import { ENV, TRANS } from '@constants';
import type { CollectionModel } from '@cyferd/client-engine';
import { GeneralModel, createUUID, getOptionListFieldIdList, parseList } from '@cyferd/client-engine';

export const settingsDetailGroupMap = {
  attributes: {
    id: createUUID(),
    name: TRANS.client.schemaEditor.groups.titles.recordAttrs,
    order: 1,
    description: 'These fields are the attributes of the record, they will appear in the record in the different views (detail view, kanban, list and tiles).'
  },
  search: { id: createUUID(), name: TRANS.client.schemaEditor.groups.titles.search, order: 2 },
  defaultDisplay: { id: createUUID(), name: TRANS.client.schemaEditor.groups.titles.defaultDisplay, order: 3 }
};

export const settingsDetailGroupList = Object.values(settingsDetailGroupMap);

export const getSettingsSchema = (item: CollectionModel.Collection, parseData: GeneralModel.ParseDataFn): GeneralModel.JSONSchema => ({
  type: 'object',
  properties: {
    recordTitle: {
      format: GeneralModel.JSONSchemaFormat.EVALUATION,
      type: 'any',
      label: TRANS.client.schemaEditor.fields.titles.recordTitle,
      minLength: 1,
      metadata: { detailGroupId: settingsDetailGroupMap.attributes.id, allowFormula: true }
    },
    recordDescription: {
      format: GeneralModel.JSONSchemaFormat.EVALUATION,
      type: 'any',
      label: TRANS.client.schemaEditor.fields.titles.recordDescription,
      metadata: { detailGroupId: settingsDetailGroupMap.attributes.id, allowFormula: true }
    },
    recordImage: {
      type: 'any',
      label: TRANS.client.schemaEditor.fields.titles.recordImage,
      format: GeneralModel.JSONSchemaFormat.EVALUATION,
      metadata: { detailGroupId: settingsDetailGroupMap.attributes.id, allowFormula: true }
    },
    recordColor: {
      type: 'any',
      label: TRANS.client.schemaEditor.fields.titles.recordColor,
      format: GeneralModel.JSONSchemaFormat.EVALUATION,
      metadata: { detailGroupId: settingsDetailGroupMap.attributes.id, allowFormula: true }
    },
    globalSearchable: {
      type: 'boolean',
      format: GeneralModel.JSONSchemaFormat.CHECKBOX,
      label: TRANS.client.fields.titles.globalSearch,
      default: false,
      metadata: { detailGroupId: settingsDetailGroupMap.search.id },
      description: 'This will allow the data collection to to appear in the global search'
    },
    defaultTab: {
      type: 'string',
      label: 'Default table core view tab',
      metadata: {
        detailGroupId: settingsDetailGroupMap.defaultDisplay.id,
        optionList: [
          { label: 'List', value: 'list' },
          { label: 'Kanban', value: 'kanban', description: 'If no Kanban field is available, the core view will default to the List' }
        ]
      }
    },
    limit: {
      type: 'number',
      label: TRANS.client.schemaEditor.fields.titles.defaultLimit,
      minimum: 1,
      metadata: { detailGroupId: settingsDetailGroupMap.defaultDisplay.id }
    },
    defaultKanbanField: {
      type: 'string',
      label: TRANS.client.schemaEditor.fields.titles.defaultKanbanField,
      description: "Fields available in kanban display are listed here. The default will be visible, provided it's available to the end user",
      metadata: {
        hidden: !ENV.IS_TEST,
        detailGroupId: settingsDetailGroupMap.defaultDisplay.id,
        optionList: (() => {
          const parsedList = parseList({ entity: item, parseData, list: [] });
          return getOptionListFieldIdList(parsedList.definitionList).map(optionId => ({
            value: optionId,
            label: parsedList.definitionMap[optionId].property.title
          }));
        })()
      }
    },
    orderBy: {
      type: 'string',
      label: TRANS.client.schemaEditor.fields.titles.defaultOrderBy,
      metadata: {
        detailGroupId: settingsDetailGroupMap.defaultDisplay.id,
        allowFormula: true,
        optionList: Object.entries(item?.schema?.properties || {})
          .filter(([_, option]) => GeneralModel.formatIsSortableMap[option.format])
          .map(([optionId, option]) => ({ value: optionId, label: option.title }))
      }
    },
    descending: {
      type: 'boolean',
      format: GeneralModel.JSONSchemaFormat.CHECKBOX,
      label: TRANS.client.schemaEditor.fields.titles.defaultDescending,
      metadata: { detailGroupId: settingsDetailGroupMap.defaultDisplay.id }
    }
  }
});
