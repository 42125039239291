import type { GeneralModel } from '@cyferd/client-engine';
import { ViewModel, getDaysInMonthWeeks, splitArrayInChunks } from '@cyferd/client-engine';
import { styles } from './styles';
import type { Dayjs } from 'dayjs';
import dayjs from 'dayjs';
import { ToolTip } from '../Tooltip';
import { CTA, CTAType } from '@components/elements/CTA';
import { getValidDayjs } from '@utils/getValidDayjs';
import { useMemo } from 'react';

export interface CalendarControlProps {
  testid?: string;
  highlightedWeek: GeneralModel.DateLike;
  month: GeneralModel.DateLike;
  activeDay?: GeneralModel.DateLike;
  referenceDate: GeneralModel.DateLike;
  disabled?: boolean;
  calendarType: ViewModel.CyCalendarType;
  onChangeMonth: (event: GeneralModel.DateLike) => void;
  onChangeActiveDay: (event: GeneralModel.DateLike) => void;
}

export const CalendarControl = ({
  testid = 'CalendarControl',
  month,
  highlightedWeek,
  activeDay,
  referenceDate,
  disabled,
  calendarType,
  onChangeMonth,
  onChangeActiveDay
}: CalendarControlProps) => {
  const $month: Dayjs = getValidDayjs(dayjs(month)).startOf('day');
  const $highlightedWeek: Dayjs = !!highlightedWeek && getValidDayjs(dayjs(highlightedWeek)).startOf('day');
  const $activeDay: Dayjs = !!activeDay && getValidDayjs(dayjs(activeDay)).startOf('day');
  const $referenceDate: Dayjs = getValidDayjs(dayjs(referenceDate)).startOf('day');
  const daysInMonthWeeks = useMemo(() => getDaysInMonthWeeks($month), [$month]);

  const onInternalChangeMonth = (method: 'add' | 'subtract') => {
    onChangeMonth($month[method](1, 'month').toISOString());
  };

  return (
    <div css={styles.container} data-testid={testid}>
      <div css={styles.controls}>
        <CTA testid="prev-month-btn" onClick={() => onInternalChangeMonth('subtract')} type={CTAType.LINK} icon="keyboard_arrow_left" tooltip="Previous" />
        <div css={styles.controlsTitle}>{$month.format('MMMM YYYY')}</div>
        <CTA testid="next-month-btn" onClick={() => onInternalChangeMonth('add')} type={CTAType.LINK} icon="keyboard_arrow_right" tooltip="Next" />
      </div>
      <div css={styles.header}>
        {Array.from(Array(7)).map((_, i) => (
          <div key={i} css={[styles.day, styles.headerItem]}>
            <ToolTip text={dayjs().day(i).format('dddd')}>
              <div>{dayjs().day(i).format('dd')}</div>
            </ToolTip>
          </div>
        ))}
      </div>
      <div>
        {splitArrayInChunks(daysInMonthWeeks, 7).map(row => {
          const isActiveWeek = $highlightedWeek?.isBetween?.(row[0], row[row.length - 1], 'D', '[]');
          return (
            <div
              key={row.join()}
              css={[styles.week, isActiveWeek && calendarType !== ViewModel.CyCalendarType.DAY && styles.isActiveWeek]}
              data-testid={isActiveWeek && 'active-week'}
            >
              {row.map(day => {
                const formattedDay = day.format();
                const isActiveDay = formattedDay === $activeDay?.startOf?.('day').format();
                const isReferenceDay = formattedDay === $referenceDate.startOf('day').format();
                const isFromCurrentMonth = day.month() === $month.month();
                const isDayHighlighted = formattedDay === $highlightedWeek?.startOf?.('day').format();

                return (
                  <div
                    key={formattedDay}
                    css={[
                      styles.day,
                      styles.selectableDay,
                      isDayHighlighted && styles.highlightedDay,
                      isFromCurrentMonth && styles.isFromCurrentMonth,
                      isReferenceDay && styles.referenceDay,
                      !isActiveWeek && styles.inactiveWeekDay,
                      isActiveDay && styles.activeDay
                    ]}
                    data-testid={isActiveDay ? 'active-day' : 'day-btn'}
                    onClick={() => !disabled && onChangeActiveDay(day.toISOString())}
                  >
                    {day.format('D')}
                  </div>
                );
              })}
            </div>
          );
        })}
      </div>
    </div>
  );
};

CalendarControl.displayName = 'CalendarControl';
