import { useContext, useMemo } from 'react';

import { type CollectionModel, GeneralModel, createUUID, mergeTruthy } from '@cyferd/client-engine';

import { EmptyState } from '@components/elements/EmptyState';
import { TRANS } from '@constants';
import { userInputList } from '@models/user';
import { EditorContext, type EditorContextValue } from '../../../shared/EditorHome';
import { SchemaForm } from '../../../shared/SchemaForm';
import { PreviewLayout } from '../PreviewLayout/PreviewLayout';

export const detailGroupMap = {
  defaultFilter: {
    id: createUUID(),
    name: 'Table view: default filters',
    image: 'table_rows_narrow',
    order: 1,
    description: 'This filter will be applied by default to the table view only'
  },
  quickFilters: {
    id: createUUID(),
    name: 'Everywhere: quick filters',
    description: 'These will be visible everywhere the collection is',
    image: 'filter_list',
    order: 2
  }
};

export const detailGroupList = Object.values(detailGroupMap);

const baseSchema: GeneralModel.JSONSchema = {
  type: 'object',
  properties: {
    defaultFilter: {
      label: TRANS.client.schemaEditor.fields.titles.defaultFilter,
      type: 'object',
      format: GeneralModel.JSONSchemaFormat.COLLECTION_FILTER,
      metadata: { allowFormula: true, fieldSize: GeneralModel.FieldSize.FULL, detailGroupId: detailGroupMap.defaultFilter.id }
    },
    quickFilters: {
      type: 'array',
      label: ' ',
      description: 'The filters will be displayed in this order',
      metadata: {
        ctaConfig: { label: 'Add quick filter', image: 'add' },
        unlimitedHeight: true,
        fieldSize: GeneralModel.FieldSize.FULL,
        detailGroupId: detailGroupMap.quickFilters.id
      },
      items: {
        type: 'object',
        label: 'Filter',
        required: ['name'],
        properties: {
          id: {
            type: 'string',
            metadata: { hidden: true, calculation: { $cyf_coalesce: ['{{event.value}}', { $cyf_guid: [] }] } }
          },
          name: { type: 'string', label: TRANS.client.fields.titles.name },
          description: { type: 'string', label: TRANS.client.fields.titles.description, format: GeneralModel.JSONSchemaFormat.MULTILINE },
          notes: { type: 'string', label: TRANS.client.fields.titles.adminNotes, format: GeneralModel.JSONSchemaFormat.MULTILINE },
          color: { type: 'string', label: TRANS.client.fields.titles.color, format: GeneralModel.JSONSchemaFormat.COLOR },
          icon: { type: 'string', label: TRANS.client.fields.titles.image, format: GeneralModel.JSONSchemaFormat.ICON_IMAGE },
          hidden: {
            type: 'boolean',
            label: TRANS.client.fields.titles.hidden,
            format: GeneralModel.JSONSchemaFormat.CHECKBOX,
            metadata: { allowFormula: true }
          },
          selectedByDefault: {
            type: 'boolean',
            label: 'Marked by default',
            format: GeneralModel.JSONSchemaFormat.CHECKBOX,
            metadata: { allowFormula: true }
          },
          filter: {
            type: 'object',
            label: 'Filter',
            format: GeneralModel.JSONSchemaFormat.COLLECTION_FILTER,
            metadata: { fieldSize: GeneralModel.FieldSize.FULL, allowFormula: true }
          }
        }
      }
    }
  }
};

export const LegacyFilterEditor = () => {
  const { item, setItem } = useContext<EditorContextValue<CollectionModel.Collection>>(EditorContext);

  const schema: GeneralModel.JSONSchema = useMemo(
    () =>
      mergeTruthy(baseSchema, {
        properties: {
          defaultFilter: { metadata: { entity: item } },
          quickFilters: { items: { properties: { filter: { metadata: { entity: item } } } } }
        }
      }),
    [item]
  );

  if (!item) return <EmptyState />;

  return (
    <PreviewLayout collection={item} onCollectionChange={setItem}>
      <SchemaForm onChange={setItem} schema={schema} detailGroupList={detailGroupList} value={item} wrapDetailGroups={true} inputList={userInputList} />
    </PreviewLayout>
  );
};
