import { CTA, CTAType } from '@components/elements/CTA';
import { IconImage } from '@components/elements/Icon/renderIcon';
import { type IOptionListItem, OptionMenu } from '@components/elements/OptionMenu';
import { PreventClickPropagation } from '@components/elements/PreventClickPropagation';
import { ToolTip } from '@components/elements/Tooltip';
import { COLOR, FONT_SIZE } from '@constants';
import { ViewModel } from '@cyferd/client-engine';
import useStyleListener from '@utils/useStyleListener';
import { useMemo, useRef } from 'react';
import type { INavigationItem } from '../../types';
import { getStyles } from './styles';

export interface ItemMenuProps extends INavigationItem {
  optionList?: IOptionListItem[];
  isActive: boolean;
  isOpen: boolean;
  testid?: string;
  onToggle: (id: string) => void;
  onClick: (id: string) => void;
  isShowMore: boolean;
  containChildren: boolean;
}

export const ItemMenu = ({
  recordTitle,
  recordImage,
  optionList,
  recordColor,
  onToggle,
  isActive,
  isOpen,
  isShowMore,
  onClick,
  testid,
  containChildren,
  id
}: ItemMenuProps) => {
  const containerRef = useRef<HTMLDivElement>(null);
  const { fill } = useStyleListener({ elementRef: containerRef, stylesToWatch: ['fill'] });
  const styles = useMemo(() => getStyles(recordColor), [recordColor]);
  const defaultColor = isActive ? COLOR[recordColor] : COLOR.NEUTRAL_1;
  const showChevron = isOpen && containChildren;
  return (
    <div data-testid={testid || 'ItemMenu'} css={styles.container}>
      <div
        ref={containerRef}
        css={[styles.optionContent, isOpen && styles.optionContentOpen, isActive ? styles.optionContentActive : styles.optionContentIdle]}
        onClick={() => {
          if (isOpen) {
            onClick(id);
          }
        }}
      >
        {showChevron && (
          <PreventClickPropagation>
            <CTA type={CTAType.SEEMLESS} icon={{ name: isShowMore ? 'expand_more' : 'chevron_right', size: FONT_SIZE.XXM }} onClick={() => onToggle(id)} />
          </PreventClickPropagation>
        )}
        <ToolTip text={!isOpen ? `${recordTitle}` : ''} side="right">
          <div css={[styles.wrapperIcon, isOpen && styles.wrapperIconOpen]} data-selector="wrapperIcon">
            <IconImage icon={recordImage || 'apps'} title="" iconProps={{ size: FONT_SIZE.L, fill: fill || defaultColor }} imageProps={{ size: FONT_SIZE.L }} />
          </div>
        </ToolTip>
        {isOpen && <div css={styles.recordTitle}>{recordTitle}</div>}
        {isOpen && (
          <div css={styles.actionContainer} data-selector="actionContainer">
            <PreventClickPropagation>
              <OptionMenu defaultBtnType={CTAType.ACTION_TERTIARY} defaultBtnSize={ViewModel.CTASize.SMALL} optionList={optionList} maxImportant={0} />
            </PreventClickPropagation>
          </div>
        )}
      </div>
    </div>
  );
};
