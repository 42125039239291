import * as React from 'react';

const Error = props => (
  <svg width={237} height={200} fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path d="M96.183 1.53v7.615M99.99 5.338h-7.614" stroke="var(--BRAND_1)" strokeLinecap="round" />
    <circle cx={117.038} cy={4.465} r={3.965} stroke="var(--BRAND_1)" />
    <path d="M230.454 39.462v5.371M227.769 42.148h5.37" stroke="var(--BRAND_1)" strokeLinecap="round" />
    <circle r={2.633} transform="scale(-1 1) rotate(15 -338.777 -805.453)" stroke="var(--BRAND_1)" />
    <path d="M51.438 153.809v7.614M55.245 157.616H47.63" stroke="var(--BE_2)" strokeLinecap="round" />
    <circle cx={29.516} cy={171.472} r={3.965} stroke="var(--NEUTRAL_4)" />
    <circle cx={14.78} cy={155.843} r={2.626} stroke="var(--BRAND_1)" />
    <path
      d="M33.687 101.812c-3.126-9.824-8.484-21.88-18.755-29.919-7.328-5.735 12.69-19.506 8.484-27.239C17.21 33.242-1.584 36.17.21 20.541 1.942-9.377 25.772 6.251 33.687 6.251c9.377 0 14.774 13.25 17.912 25.007 4.33 16.218-12.393 32.384-6.698 33.937 9.824 2.68 20.84-.755 16.025 14.29-3.572 11.163 3.126 21.88 15.629 18.308 12.022-3.435 33.567 9.321 37.586 21.824 4.019 12.504-10.717 8.038-11.61 7.145-.893-.893-16.588.056-16.14-9.321.446-9.378-6.264-1.787-9.836-6.252s-6.82 0-12.503 0c-8.496 0-27.24.447-30.365-9.377Z"
      fill="url(#a)"
    />
    <path
      d="M96.726 114.259c3.126 8.931-4.019 17.862 8.484 23.22 8.485 2.233 21.434 0 23.22-15.182 1.429-12.146-7.74-16.969-12.503-17.862 1.191 2.084 2.948 4.287-1.339 5.358-2.252.563-4.214-1.339-4.466-6.698-.233-4.969-6.251-.893-6.698-8.484-.595.447-4.019 10.717-1.786 13.843 2.552 3.572 10.271 3.572 4.912 12.056-1.783 2.823-4.465-6.698-9.824-6.251Z"
      fill="var(--BRAND_1)"
    />
    <path
      d="M107.443 127.655c5.125-4.584 6.899-6.078 7.145-6.252M110.122 128.548l11.611-8.931M116.374 126.762l5.359-3.572M108.336 132.12l13.397-6.251M111.909 133.907l9.824-6.252"
      stroke="var(--BRAND_1)"
    />
    <path d="M88.134 99.405c1.93 3.653-2.703 9.334 4.248 12.175-2.318-2.841.772-1.624.772-6.088 0-4.463-6.95-9.739-5.02-6.087Z" fill="var(--BRAND_1)" />
    <path
      d="m188.36 178.134-21.912-36.218c-5.238-2.022-20.874.456-20.874.456s20.44 32.933 29.43 41.086c5.797 1.57 13.356-5.324 13.356-5.324Z"
      fill="var(--NEUTRAL_4)"
      stroke="var(--NEUTRAL_3)"
    />
    <path
      opacity={0.3}
      d="m171.11 178.593 12.657-7.886m-18.06 1.179 14.309-7.786m-19.83.146 15.699-6.879m-21.089-1.164 17.718-5.318m-22.858-2.196 18.988-5.344"
      stroke="var(--NEUTRAL_3)"
    />
    <path
      d="m176.601 186.027 20.615-19.638 16.179 4.38-20.149 25.553c-1.354-.091-5.175-.74-9.63-2.607-4.456-1.868-6.533-5.904-7.015-7.688ZM67.187 129.858c2.917-.617 14.2 1.692 19.477 2.925-2.247 3.334-3.434 21.982-3.746 30.889l-17.742-4.625c-.545-9.472-.906-28.571 2.011-29.189Z"
      fill="var(--NEUTRAL_4)"
      stroke="var(--NEUTRAL_3)"
    />
    <path d="m78.04 130.484 45.471-.558 10.942 14.284H78.04v-13.726Z" fill="var(--NEUTRAL_4)" stroke="var(--NEUTRAL_3)" />
    <path
      opacity={0.3}
      d="m85.016 130.669.547 13.062m8.3-13.368.9 13.48m7.868-13.481 1.332 13.593m7.004-13.706 2.196 13.818m8.256.192-2.196-13.817"
      stroke="var(--NEUTRAL_3)"
    />
    <path
      d="m116.484 135.973 12.354-40.134c5.651.61 19.909 2.212 31.74 3.733 11.83 1.522 27.521 2.759 33.888 3.187-4.447 19.438-11.396 30.107-19.149 46.777-2.858.886-10.253 9.454-28.413 5.09-18.16-4.365-27.618-16.838-30.42-18.653Z"
      fill="var(--NEUTRAL_4)"
      stroke="var(--BRAND_1)"
      strokeOpacity={0.5}
    />
    <path d="M170.783 100.979c.313-2.481.956-8.86 1.023-14.522l-23.596.801-.154 10.851 22.727 2.87Z" fill="var(--NEUTRAL_4)" stroke="var(--NEUTRAL_3)" />
    <path d="m148.187 93.872 22.921 2.21m.236-5.094-23.09-.865" stroke="var(--NEUTRAL_3)" />
    <path
      d="M99.405 60.674 88.242 71.837c4.316 4.168 14.646 13.754 21.434 18.755 8.484 6.252 11.61 9.378 21.434 15.629 8.216.715 8.782-5.358 8.038-8.484-3.424-3.424-12.146-12.057-19.648-19.201-7.502-7.145-16.522-14.885-20.095-17.862Z"
      fill="var(--NEUTRAL_4)"
    />
    <path
      d="m88.242 71.837-.354-.353-.36.36.366.353.348-.36Zm11.163-11.163.32-.384-.35-.293-.323.323.353.354ZM119.5 78.535l.345-.361-.345.361Zm19.648 19.202.486-.116-.033-.138-.1-.1-.353.354Zm-8.038 8.484-.269.422.104.066.122.01.043-.498Zm-21.434-15.629.296-.402-.296.402Zm-21.08-18.401 11.163-11.164-.707-.707-11.164 11.164.707.707Zm10.49-11.133c3.567 2.973 12.578 10.705 20.069 17.84l.69-.724c-7.513-7.156-16.542-14.903-20.12-17.884l-.64.768Zm20.069 17.84c7.498 7.14 16.217 15.77 19.639 19.192l.707-.707c-3.425-3.425-12.15-12.061-19.656-19.21l-.69.725Zm19.506 18.955c.356 1.492.383 3.645-.603 5.312-.952 1.611-2.933 2.903-6.905 2.558l-.086.996c4.244.369 6.654-1.017 7.852-3.046 1.166-1.972 1.104-4.419.715-6.052l-.973.232Zm-7.283 7.946c-9.807-6.24-12.882-9.329-21.406-15.61l-.593.806c8.445 6.222 11.622 9.386 21.462 15.648l.537-.844Zm-21.406-15.61c-6.758-4.979-17.067-14.544-21.383-18.711l-.695.72c4.317 4.167 14.668 13.774 21.485 18.797l.593-.805Z"
      fill="var(--NEUTRAL_3)"
    />
    <path
      opacity={0.3}
      d="m95.386 78.089 11.164-11.164m-3.126 18.309 10.717-11.164m-2.233 17.415 10.271-10.27m-2.233 16.968 10.271-8.484m-2.233 13.843 8.931-7.591"
      stroke="var(--NEUTRAL_3)"
    />
    <path
      d="m69.04 64.246 17.862 2.68 9.824-8.485 1.34-20.987-17.416-4.466-1.34 9.824 8.485 1.786-1.786 12.057h-10.27l-1.787-12.503-11.163 3.126 6.251 16.968Z"
      fill="var(--NEUTRAL_4)"
      stroke="var(--NEUTRAL_4)"
    />
    <path
      d="M85.563 59.78c-.894 1.34-3.126 5.36 2.679 9.825l8.93-8.931c-5.805-7.145-10.716-2.233-11.61-.893Z"
      fill="var(--NEUTRAL_4)"
      stroke="var(--NEUTRAL_3)"
    />
    <path d="M190.007 49.908 173.4 104.835l18.208 1.331 11.229-53.726-12.83-2.532Z" fill="var(--NEUTRAL_4)" stroke="var(--NEUTRAL_3)" />
    <path
      opacity={0.3}
      d="m176.144 96.112 16.622 3.445M179.465 85.99l15.212 3.357m-12.005-13.909 14.234 3.154m-11.459-13.592 13.371 3.383m-10.34-11.117 12.913 1.657"
      stroke="var(--NEUTRAL_3)"
    />
    <path
      d="M177.42 35.908 188.3 47.1l15.916-.262 7.635-6.63 7.634-6.628-8.702-14.556-8.791 5.469 3.823 8.829-8.975 7.225-7.377-6.29 7.659-10.35-7.309-3.448-12.393 15.449Z"
      fill="var(--NEUTRAL_4)"
      stroke="var(--NEUTRAL_4)"
    />
    <path
      d="m188.17 49.09 16.352-.037c-.022-2.707-1.643-8.13-7.954-8.169-6.311-.038-8.228 5.455-8.398 8.207Z"
      fill="var(--NEUTRAL_4)"
      stroke="var(--NEUTRAL_3)"
    />
    <path d="M123.519 96.844 107.89 52.19l70.553-24.56 15.629 53.585-70.553 15.629Z" fill="var(--NEUTRAL_4)" stroke="var(--BRAND_1)" strokeOpacity={0.5} />
    <path
      d="M120.839 62.46c-2.233-4.912 1.786-9.377 9.377-12.057 7.591-1.34 11.164 3.126 12.504 5.805 1.339 2.68.893 9.824-7.145 11.61-8.038 1.787-12.503-.446-14.736-5.358Z"
      fill="var(--BASE_BACKGROUND)"
      stroke="var(--BRAND_1)"
    />
    <path d="m121.732 54.995 23.22 7.557m-7.41-12.595-11.363 19.648" stroke="var(--BRAND_1)" strokeWidth={3} />
    <path
      d="M175.318 55.762c-4.019.893-9.824 1.34-12.95-5.805-1.786-7.591 4.019-10.717 8.484-12.503 4.466-1.787 9.824 0 12.503 6.251 2.68 6.252-4.019 11.164-8.037 12.057Z"
      fill="var(--BASE_BACKGROUND)"
      stroke="var(--BRAND_1)"
    />
    <path d="m161.921 42.967 22.327 6.796M178.909 37.143l-12.134 18.93" stroke="var(--BRAND_1)" strokeWidth={4} />
    <path
      d="M139.148 41.472c-3.275-4.167-11.075-12.503-16.076-12.503-5.001 0-4.168 3.87-3.126 5.805L92.261 13.34M87.349 9.321c0 1.787 2.232 3.573 3.126 2.68.893-.893-2.233-4.912-3.126-2.68Z"
      stroke="var(--BRAND_1)"
    />
    <path
      d="m132.449 89.699-2.679-11.163c4.168-1.787 15.808-6.52 29.025-11.164 13.218-4.644 22.774-5.21 25.9-4.912l3.126 10.717c-4.317 2.381-15.451 7.86-25.453 10.717-10.003 2.858-24.114 5.06-29.919 5.805Z"
      fill="var(--BASE_BACKGROUND)"
      stroke="var(--BRAND_1)"
    />
    <path d="m140.934 74.517 3.126 12.95M152.544 70.051l4.019 15.183M164.154 66.032l5.358 15.183M174.424 63.8l5.359 13.396" stroke="var(--BRAND_1)" />
    <path
      d="M148.971 23.164c-8.484-12.057-5.359-15.182-2.233-15.182 2.501 0 2.531 10.121 2.233 15.182ZM158.795 23.164c-1.34-10.27-.893-12.057 1.786-12.057 2.144 0-.297 8.038-1.786 12.057ZM141.826 28.076c-6.251-1.786-8.484-4.019-7.591-5.805.893-1.786 6.252.893 7.591 5.805Z"
      fill="var(--BRAND_1)"
      fillOpacity={0.3}
    />
    <path
      d="M148.971 23.164c-8.484-12.057-5.359-15.182-2.233-15.182 2.501 0 2.531 10.121 2.233 15.182ZM158.795 23.164c-1.34-10.27-.893-12.057 1.786-12.057 2.144 0-.297 8.038-1.786 12.057ZM141.826 28.076c-6.251-1.786-8.484-4.019-7.591-5.805.893-1.786 6.252.893 7.591 5.805Z"
      stroke="var(--BRAND_1)"
      strokeOpacity={0.2}
    />
    <defs>
      <linearGradient id="a" x1={78.788} y1={112.529} x2={7.341} y2={1.786} gradientUnits="userSpaceOnUse">
        <stop stopColor="var(--NEUTRAL_5)" />
        <stop offset={1} stopColor="var(--NEUTRAL_5)" stopOpacity={0} />
      </linearGradient>
    </defs>
  </svg>
);

export default Error;
