import { useContext, useEffect, useMemo } from 'react';
import { useLocation, useNavigate } from 'react-router';

import type { CollectionModel, ViewModel } from '@cyferd/client-engine';
import { ApiModel } from '@cyferd/client-engine';
import { CyLayout } from '@components/smart/CyLayout';
import { useTestingHelper } from '@utils';

import { BUILDER_DESCRIPTION, BUILDER_ICON, BUILDER_ROUTE, ENV, QUERY_PARAM, SECTION_NAME, TRANS, getTempId } from '@constants';
import { getNewEntity } from '@models/entity';
import type { getNavigateToArgs } from '@utils/getNavigateToTab';
import { getNavigateToTab } from '@utils/getNavigateToTab';
import { useOnNavigateToItem } from '@utils/useOnNavigateToItem';
import { useOnOpenExternalUrl } from '@utils/useOnOpenExternalUrl';
import { BuilderCyList } from '../../../shared/BuilderCyList';
import type { EditorContextValue } from '../../../shared/EditorHome';
import { EditorContext } from '../../../shared/EditorHome';
import { TempHeader } from '../../../shared/TempHeader';
import { styles } from './styles';
import { useRequest } from '@utils/useRequest';

export const GeneralList = () => {
  const { getTestIdProps } = useTestingHelper('general-list');
  const request = useRequest();
  const { setItem } = useContext<EditorContextValue<CollectionModel.Collection>>(EditorContext);
  const { search } = useLocation();
  const navigate = useNavigate();

  const baseConfig: getNavigateToArgs = useMemo(
    () => ({ id: null, path: null, root: BUILDER_ROUTE.ENTITY_EDITOR.ROOT, search, key: QUERY_PARAM.RECORD_ID, push: navigate }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  const onCreate = meta => {
    const item: CollectionModel.Collection = getNewEntity();
    setItem(item);
    getNavigateToTab({ ...baseConfig, id: getTempId(), path: BUILDER_ROUTE.ENTITY_EDITOR.CHILDREN.GENERAL })(meta);
  };

  const openExternalUrl = useOnOpenExternalUrl();

  const actionListChildren = useMemo(
    () =>
      [
        {
          icon: 'edit',
          label: TRANS.client.buttons.edit,
          important: true,
          onClick: (item: CollectionModel.Collection, event) =>
            getNavigateToTab({ ...baseConfig, id: item.id, path: BUILDER_ROUTE.ENTITY_EDITOR.CHILDREN.SCHEMA })(event)
        },
        { icon: 'zoom_in', label: TRANS.client.buttons.open, onClick: item => openExternalUrl(`${ENV.CLIENT_APP_URL}/TABLE?collectionId=${item.id}`) }
      ] as ViewModel.CyListProps['actionListChildren'],
    [baseConfig, openExternalUrl]
  );

  const onClickItem = useOnNavigateToItem(baseConfig, BUILDER_ROUTE.ENTITY_EDITOR.CHILDREN.SCHEMA);

  useEffect(() => {
    setItem(undefined);
  }, [setItem]);

  return (
    <div {...getTestIdProps('container')} className={styles.container}>
      <TempHeader
        title={SECTION_NAME.DATA}
        subtitle={BUILDER_DESCRIPTION.DATA}
        icon={BUILDER_ICON.DATA}
        actionListChildren={[{ label: TRANS.client.buttons.createNew, icon: 'add', onClick: onCreate as any, important: true }]}
      />
      <CyLayout itemHeight="calc(100vh - 125px)">
        <BuilderCyList
          onClickItem={onClickItem}
          componentName={ApiModel.ApiEntity.ENTITY}
          showDelete={true}
          id={ApiModel.ApiEntity.ENTITY}
          key={ApiModel.ApiEntity.ENTITY}
          request={request}
          collectionId={ApiModel.ApiEntity.ENTITY}
          actionListChildren={actionListChildren}
        />
      </CyLayout>
    </div>
  );
};
