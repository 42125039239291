import { memo, useMemo, useState } from 'react';

import type { FlowModel } from '@cyferd/client-engine';
import { ApiModel, ViewModel, getClassnames } from '@cyferd/client-engine';

import { defaultStepImage, getPrettyActionTitle, listItemBase, tabConfigMap } from '../../resources';
import { styles } from './styles';
import { COLOR } from '@constants';
import { ListItem } from '@components/elements/List';
import { CTAType } from '@components/elements/CTA';
import { SearchInput } from '@components/elements/SearchInput';

export interface StepListProps {
  flow: FlowModel.Flow;
  activeStepKey: string;
  activeTab: string;
  compact: boolean;
  onActiveStepChange: (stepKey: string, tabKey: string) => void;
  onRemove: (stepKey: string) => void;
}

const getResultIdList = (list: FlowModel.FlowRouting[]) => (list || []).map(r => r?.goTo).filter(Boolean);

const getNextIdListRecursive = (nextId: string, steps: FlowModel.Flow['steps'], total: string[]) => {
  if (total.includes(nextId)) return total;
  const nextList = Array.from(new Set([...getResultIdList(steps?.[nextId]?.onResult), ...getResultIdList(steps?.[nextId]?.onError)]));

  return nextList.reduce((t, curr) => getNextIdListRecursive(curr, steps, t).flat(), [...total, nextId]);
};

export const StepList = memo(({ flow, activeTab, activeStepKey, compact, onActiveStepChange, onRemove }: StepListProps) => {
  const [filter, setFilter] = useState<string>('');

  const stepEntries = useMemo(() => {
    const keys = Object.keys(flow?.steps || {});
    const orderedKeys = getResultIdList(flow.onStart)
      .map(id => getNextIdListRecursive(id, flow?.steps, []))
      .flat();
    const completeKeys = Array.from(new Set([...orderedKeys, ...keys]));
    const entries = completeKeys.filter(key => flow?.steps?.[key]).map(key => [key, flow?.steps?.[key]]) as [string, FlowModel.FlowStep][];
    return entries;
  }, [flow?.steps, flow.onStart]);

  return (
    <div className={styles.container} data-testid="step-list">
      <SearchInput testid="step-filter" value={filter} onChange={setFilter} />
      {stepEntries
        .filter(([key, step]) => !filter || [key, step?.name].some(s => s?.toLowerCase()?.includes(filter.toLowerCase())))
        .map(([key, step]) => {
          const isActiveStep = key === activeStepKey;
          const color = COLOR[step?.metadata?.color] ? step?.metadata?.color : 'BRAND_1';
          const onDetail = () => onActiveStepChange(key, tabConfigMap.step_detail.key) as any;
          const onAction = () => onActiveStepChange(key, tabConfigMap.step_action.key) as any;
          const onNext = () => onActiveStepChange(key, tabConfigMap.step_next.key) as any;
          const onError = () => onActiveStepChange(key, tabConfigMap.step_error.key) as any;
          return (
            <div key={key} id={key}>
              <div
                data-testid="step-title"
                className={getClassnames(styles.step, !!isActiveStep && activeTab === tabConfigMap.step_detail.key && styles.activeStep)}
              >
                <ListItem
                  item={{ ...listItemBase, title: step?.name, description: step?.description, image: step?.metadata?.image || defaultStepImage, color }}
                  showDescription={false}
                  isClickable={true}
                  onClickItem={onDetail}
                  optionMenuOverride={{ defaultBtnType: CTAType.ACTION_SECONDARY, defaultBtnSize: ViewModel.CTASize.SMALL }}
                  actionListChildren={[
                    { type: ViewModel.CTAType.LINK, color: 'RD_4', icon: 'delete', label: 'Delete step', onClick: () => onRemove(key) as any }
                  ]}
                />
              </div>
              {!compact && (
                <div>
                  <div
                    data-testid="step-action"
                    className={getClassnames(styles.step, !!isActiveStep && activeTab === tabConfigMap.step_action.key && styles.activeStep)}
                  >
                    <ListItem
                      showDescription={false}
                      isClickable={true}
                      onClickItem={onAction}
                      item={{
                        ...listItemBase,
                        color,
                        title: [tabConfigMap.step_action.title, getPrettyActionTitle(step?.action)?.toLowerCase()].filter(Boolean).join(' '),
                        description: ApiModel.FlowSchemaMap[step?.action]?.description
                      }}
                    />
                  </div>
                  <div
                    data-testid="step-next"
                    className={getClassnames(styles.step, !!isActiveStep && activeTab === tabConfigMap.step_next.key && styles.activeStep)}
                  >
                    <ListItem
                      isClickable={true}
                      onClickItem={onNext}
                      item={{
                        ...listItemBase,
                        color: 'GN_2',
                        title: `${tabConfigMap.step_next.title} ${step?.onResult?.length ? `(${step?.onResult?.length})` : ''}`
                      }}
                    />
                  </div>
                  <div
                    data-testid="step-error"
                    className={getClassnames(styles.step, !!isActiveStep && activeTab === tabConfigMap.step_error.key && styles.activeStep)}
                  >
                    <ListItem
                      isClickable={true}
                      onClickItem={onError}
                      item={{
                        ...listItemBase,
                        color: 'RD_3',
                        title: `${tabConfigMap.step_error.title} ${step?.onError?.length ? `(${step?.onError?.length})` : ''}`
                      }}
                    />
                  </div>
                </div>
              )}
            </div>
          );
        })}
    </div>
  );
});
