import type { CalendarAddOption, CalendarConfigList } from '@cyferd/client-engine';
import { GeneralModel, ViewModel, getCalendarMap, getDaysInMonthWeeks, parseCalendarItems, splitArrayInChunks } from '@cyferd/client-engine';
import { styles } from './styles';
import type { Dayjs } from 'dayjs';
import dayjs from 'dayjs';
import { getValidDayjs } from '@utils/getValidDayjs';
import type { ReactNode } from 'react';
import { Fragment, useMemo } from 'react';
import { PopoverTrigger } from '../Popover';
import { CTA, CTAType } from '../CTA';
import { CalendarPopoverContent } from '../CalendarPopoverContent';
import { OptionMenu } from '../OptionMenu';
import { PreventClickPropagation } from '../PreventClickPropagation';
import { Icon } from '../Icon';
import { COLOR, FONT_SIZE } from '@constants';

export interface CalendarMonthProps {
  testid?: string;
  calendarConfigList: CalendarConfigList[];
  month: GeneralModel.DateLike;
  referenceDate: GeneralModel.DateLike;
  activeDay?: GeneralModel.DateLike;
  actionListChildren?: ViewModel.CyCalendarProps['actionListChildren'];
  disabled?: boolean;
  addOptionList: CalendarAddOption[];
  onCellAction?: ViewModel.CyCalendarProps['onCellAction'];
  onChangeActiveDay: (event: GeneralModel.DateLike) => void;
}

export const CalendarMonth = ({
  calendarConfigList,
  month,
  referenceDate,
  activeDay,
  actionListChildren,
  disabled,
  addOptionList,
  onCellAction,
  onChangeActiveDay
}: CalendarMonthProps) => {
  const maxEventsPerCell = 2;
  const $activeDay: Dayjs = !!activeDay && getValidDayjs(dayjs(activeDay)).startOf('day');
  const $referenceDate: Dayjs = getValidDayjs(dayjs(referenceDate)).startOf('day');
  const $month: Dayjs = getValidDayjs(dayjs(month)).startOf('day');

  const daysInMonthWeeks = useMemo(() => getDaysInMonthWeeks($month), [$month]);

  const safeCalendarConfigList = useMemo(
    () => (Array.isArray(calendarConfigList) ? calendarConfigList.map(parseCalendarItems).flat() : []),
    [calendarConfigList]
  );

  const calendarMap = useMemo(() => getCalendarMap(safeCalendarConfigList, 'day'), [safeCalendarConfigList]);

  const canAdd = typeof onCellAction === 'function';

  const getAddOptions = (day: Dayjs) => {
    return (
      <div data-selector="add-options">
        <PreventClickPropagation>
          {addOptionList.length === 1 ? (
            <CTA
              testid="add-option"
              size={ViewModel.CTASize.SMALL}
              type={CTAType.LINK}
              icon="add"
              onClick={() => onCellAction({ collectionId: addOptionList[0].collectionId, [addOptionList[0].fromField]: day.toISOString() })}
            />
          ) : (
            <OptionMenu
              defaultBtnIcon="add"
              defaultBtnSize={ViewModel.CTASize.SMALL}
              defaultBtnType={CTAType.LINK}
              defaultBtnTestid="add-in-cell"
              optionList={addOptionList.map(o => ({
                label: o.label,
                color: o.color,
                type: CTAType.LINK,
                testid: 'add-option',
                onClick: () =>
                  onCellAction({
                    collectionId: o.collectionId,
                    [o.fromField]: o.fromFieldFormat === GeneralModel.JSONSchemaFormat.DATE ? day.format('YYYY-MM-DD') : day.toISOString()
                  })
              }))}
            />
          )}
        </PreventClickPropagation>
      </div>
    );
  };

  return (
    <div css={styles.container} data-testid="CalendarMonth">
      <header css={styles.grid}>
        {Array.from(Array(7)).map((_, i) => (
          <div key={i} css={styles.headerItem}>
            <p css={styles.headerTxt}>{dayjs().day(i).format('dddd')}</p>
          </div>
        ))}
      </header>
      <div css={styles.content}>
        {splitArrayInChunks(daysInMonthWeeks, 7).map(row => (
          <div key={row.join()} css={styles.grid}>
            {row.map((day, dayIndex) => {
              const formattedDay = day.format();
              const isFromCurrentMonth = day.month() === $month.month();
              const isActiveDay = formattedDay === $activeDay?.startOf?.('day').format();
              const isReferenceDay = formattedDay === $referenceDate.startOf('day').format();

              return (
                <div key={formattedDay} css={styles.dayCell} onClick={() => onChangeActiveDay(day.toISOString())}>
                  <header css={[styles.dayCellHeader, isActiveDay && styles.activeDay]} data-testid="month-header-item">
                    <p
                      css={[
                        styles.dayCellHeaderText,
                        isFromCurrentMonth && styles.isFromCurrentMonth,
                        isActiveDay && styles.activeDayText,
                        isReferenceDay && styles.referenceDayText
                      ]}
                    >
                      {day.format('D')}
                    </p>
                    {canAdd && !!calendarMap[formattedDay]?.length && getAddOptions(day)}
                  </header>
                  <div css={styles.dayCellBody}>
                    {canAdd && !calendarMap[formattedDay]?.length && <div css={styles.addOptionsContainer}>{getAddOptions(day)}</div>}
                    {(() => {
                      const events = calendarMap[formattedDay]?.reduce(
                        (total, cellEvent, cellIndex) => {
                          if (total.list.length >= maxEventsPerCell) return total;
                          const weekDay = day.get('day');
                          const isFullDayEvent = cellEvent.fromFieldFormat === GeneralModel.JSONSchemaFormat.DATE;
                          const nextInRowCellFormattedDay = row[dayIndex + 1]?.format?.();
                          const prevInRowCellFormattedDay = row[dayIndex - 1]?.format?.();
                          const continuesInRow = !!calendarMap[nextInRowCellFormattedDay]?.find(e => e.item.fullItem?.id === cellEvent.item.fullItem?.id);
                          const startedBeforeInRow = !!calendarMap[prevInRowCellFormattedDay]?.find(e => e.item.fullItem?.id === cellEvent.item.fullItem?.id);
                          const isOneOfMany = cellEvent.totalDays > 1;
                          const isFirstOfManyDaysInRow = continuesInRow && !startedBeforeInRow;
                          const isLastOfManyDaysInRow = !continuesInRow && cellEvent.dayIndex < cellEvent.totalDays;
                          const isEndOfMany = isOneOfMany && cellEvent.dayIndex + 1 === cellEvent.totalDays;
                          const isStartOfMany = isOneOfMany && !cellEvent.dayIndex;
                          const cellsCovered = Math.min(7 - weekDay, cellEvent.totalDays - cellEvent.dayIndex);
                          const isTitleCell = !isOneOfMany || isFirstOfManyDaysInRow || (isOneOfMany && !cellEvent.dayIndex);
                          const isLargeEndingCell = isEndOfMany && !!weekDay;
                          const isMidSection = isOneOfMany && !isFirstOfManyDaysInRow && !isLastOfManyDaysInRow;
                          const dayTheEventStartedOnThisRow = dayjs(Math.max(cellEvent.from.toDate().getTime(), row[0].toDate().getTime()));
                          const indexOfEventInThePrevCell = calendarMap[dayTheEventStartedOnThisRow.format()]?.findIndex?.(
                            i => i.item.fullItem?.id === cellEvent.item.fullItem?.id
                          );
                          /** placeholder count to move event down in cases an event in the prev cell ended on top of this on going one */
                          const placeholderCount = Math.max(Math.min(Math.max(indexOfEventInThePrevCell - cellIndex, 0), maxEventsPerCell - cellIndex), 0) || 0;
                          return {
                            list: [
                              ...total.list,
                              ...Array.from(Array(placeholderCount)).map((_, placeholderIndex) => (
                                <div key={placeholderIndex} css={styles.cellEventContainer} />
                              )),
                              <div
                                css={styles.cellEventContainer}
                                data-testid="calendar-event"
                                data-meta={JSON.stringify({
                                  title: cellEvent.item?.title,
                                  isFullDayEvent,
                                  isFirstOfManyDaysInRow,
                                  isEndOfMany,
                                  isStartOfMany,
                                  cellsCovered,
                                  isTitleCell,
                                  isLargeEndingCell,
                                  isMidSection,
                                  placeholderCount
                                })}
                              >
                                <PopoverTrigger
                                  containerCss={styles.cellEvent({
                                    color: cellEvent.color,
                                    withBorder: !cellEvent.dayIndex,
                                    cellsCovered,
                                    isFirstOfManyDaysInRow,
                                    isLargeEndingCell,
                                    isMidSection
                                  })}
                                  testId="calendar-event-popover"
                                  label={cellEvent.item?.title}
                                  color={cellEvent.color}
                                  value={
                                    <CalendarPopoverContent
                                      from={cellEvent.fromRaw}
                                      to={cellEvent.toRaw}
                                      value={cellEvent.item}
                                      disabled={disabled}
                                      actionListChildren={actionListChildren}
                                      isFullDayEvent={isFullDayEvent}
                                    />
                                  }
                                >
                                  <div key={`${cellEvent.item?.fullItem?.id}${cellIndex}`}>
                                    <p css={[styles.cellEventTitle, isEndOfMany && styles.endOfManyTitle]}>
                                      {!!isFirstOfManyDaysInRow && !isStartOfMany && /* istanbul ignore next */ `${cellEvent.from.format('MMM D')} `}
                                      {cellEvent.fromFieldFormat !== GeneralModel.JSONSchemaFormat.DATE &&
                                        !isEndOfMany &&
                                        `${cellEvent.from.format('h:mm A')} `}
                                      {isTitleCell ? <strong>{cellEvent.item.title}</strong> : <span css={styles.invisible}>-</span>}
                                      {isEndOfMany && <Icon name="last_page" fill={COLOR.NEUTRAL_1} size={FONT_SIZE.XM} />}
                                    </p>
                                  </div>
                                </PopoverTrigger>
                              </div>
                            ],
                            placeholderCount: total.placeholderCount + placeholderCount
                          };
                        },
                        { list: [], placeholderCount: 0 } as { list: ReactNode[]; placeholderCount: number }
                      );
                      if (!events) return null;
                      return (
                        <Fragment>
                          {/** visible events */
                          events.list
                            .filter(Boolean)
                            .slice?.(0, maxEventsPerCell)
                            .map((n, i) => <Fragment key={i}>{n}</Fragment>)}
                          {
                            /** overflow events */
                            calendarMap[formattedDay]?.length > maxEventsPerCell - events.placeholderCount && (
                              <div data-testid="event-more" onClick={() => onChangeActiveDay(day.toISOString())} css={styles.eventMore('BRAND_1')}>
                                {calendarMap[formattedDay]?.length}
                              </div>
                            )
                          }
                        </Fragment>
                      );
                    })()}
                  </div>
                </div>
              );
            })}
          </div>
        ))}
      </div>
    </div>
  );
};

CalendarMonth.displayName = 'CalendarMonth';
