import { GAP } from '@constants';
import { css } from '@emotion/react';

export const styles = {
  container: css`
    display: flex;
    align-items: center;
    justify-content: center;
    min-height: 100vh;
  `,
  bottomContainer: css`
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    position: fixed;
    bottom: ${GAP.L};
  `,
  poweredByContainer: css`
    display: flex;
    align-items: center;
  `
};
