import { COLOR, FONT_SIZE, FONT_WEIGHT, GAP, INPUT_HEIGHT, RADIUS, SECONDARY_COLOR, SHADOW, TRANSITION_SPEED } from '@constants';
import type { GeneralModel } from '@cyferd/client-engine';
import { css } from '@emotion/react';

export const cellHeight = 50;

export const styles = {
  container: css`
    display: flex;
    flex-direction: column;
    height: 100%;
  `,
  headerItem: css`
    cursor: pointer;
    padding: ${GAP.XS} ${GAP.M};
    display: flex;
    gap: ${GAP.S};
    align-items: center;
    border-top: 5px solid ${COLOR.BASE_BACKGROUND_ALT_1};
    color: ${COLOR.NEUTRAL_1};
    font-size: ${FONT_SIZE.M};
    margin-top: 5px;
    transition: all ${TRANSITION_SPEED};
    height: calc(${INPUT_HEIGHT} - 2px);
    background-color: ${COLOR.NEUTRAL_5};
    :hover {
      background-color: ${COLOR.NEUTRAL_4};
    }
  `,
  headerItemContent: css`
    display: flex;
    gap: ${GAP.S};
    align-items: flex-end;
  `,
  activeHeaderItem: css`
    color: ${COLOR.BRAND_1};
    border-color: ${COLOR.BRAND_1};
    align-items: center;
  `,
  referenceHeaderItemContent: css`
    align-items: center;
  `,
  activeDay: css`
    font-size: ${FONT_SIZE.M};
  `,
  referenceDay: css`
    background-color: ${SECONDARY_COLOR.BRAND_1};
    border-radius: 100%;
    font-size: ${FONT_SIZE.S};
    width: 24px;
    height: 24px;
    min-width: 24px;
    min-height: 24px;
    display: flex;
    align-items: center;
    justify-content: center;
  `,
  headerNum: css`
    font-size: ${FONT_SIZE.M};
    font-weight: ${FONT_WEIGHT.EXTRA_BOLD};
  `,
  headerTxt: css`
    font-size: ${FONT_SIZE.S};
    font-weight: ${FONT_WEIGHT.NORMAL};
  `,
  content: css`
    flex: 1;
    overflow: auto;
  `,
  grid: (visibleDays: number) => css`
    display: grid;
    grid-template-columns: 70px repeat(${visibleDays}, 1fr);
  `,
  cell: css`
    height: ${cellHeight}px;
    border: 0.7px solid ${COLOR.NEUTRAL_4};
    position: relative;
    [data-selector='calendar-add-options'] {
      display: none;
    }
    :hover {
      [data-selector='calendar-add-options'] {
        display: block;
      }
    }
  `,
  cellContent: css`
    display: grid;
    grid-auto-flow: column;
    grid-auto-columns: 1fr;
    position: absolute;
    width: 100%;
  `,
  cellWithContent: (length: number) => css`
    grid-template-columns: 10px repeat(${length}, 1fr);
  `,
  cellContentFlooded: css`
    overflow: auto;
  `,
  hourCell: css`
    display: flex;
    align-items: center;
    height: ${cellHeight}px;
    padding: ${GAP.S} ${GAP.M};
    color: ${COLOR.NEUTRAL_1};
    font-weight: ${FONT_WEIGHT.NORMAL};
    font-size: ${FONT_SIZE.S};
  `,
  cellEvent: ({
    color,
    topOffset,
    duration,
    hex,
    hasHover
  }: {
    color: GeneralModel.Color.ThemeColor;
    topOffset: number;
    duration: number;
    hex: string;
    hasHover: boolean;
  }) => css`
    border-radius: ${RADIUS.S};
    background-color: ${SECONDARY_COLOR[color]};
    border: 0.5px solid ${COLOR.TRANSPARENT};
    border-left: 4px solid ${COLOR[color]};
    box-shadow: ${SHADOW.S};
    overflow: hidden;
    z-index: 1;
    margin-top: ${(topOffset / 100) * cellHeight}px;
    height: ${Math.max((duration / 100) * cellHeight, cellHeight / 2)}px;
    min-height: ${cellHeight / 2}px;
    transition: all calc(${TRANSITION_SPEED} / 2);

    ${!!hasHover &&
    `
    :hover {
      z-index: 10;
      border-color: ${COLOR[color]};
      background-color: ${hex}e0;
    }
    `}
  `,
  popoverChildren: css`
    cursor: pointer;
    display: block;
    height: 100%;
    padding: ${GAP.XS} ${GAP.XXS} 0 ${GAP.S};
  `,
  eventContent: css`
    height: 100%;
    width: 100%;
    overflow: hidden;
  `,
  cellEventTitle: css`
    font-size: ${FONT_SIZE.XXS};
    font-weight: ${FONT_WEIGHT.EXTRA_BOLD};
    color: ${COLOR.NEUTRAL_1};
    word-break: break-word;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 1;
  `,
  cellEventSubtitle: css`
    font-size: ${FONT_SIZE.XXS};
    font-weight: ${FONT_WEIGHT.NORMAL};
    color: ${COLOR.NEUTRAL_2};
    word-break: break-word;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 1;
  `,
  optionMenu: css`
    width: 100%;
    [data-selector='dropdown-menu-container'] {
      display: block;
      width: 100%;
    }
  `,
  optionsTrigger: css`
    cursor: pointer;
    width: 100%;
    height: ${cellHeight}px;
    background-color: ${COLOR.NEUTRAL_6};
    display: flex;
    align-items: center;
    justify-content: center;
  `
};
