import { ViewModel } from '@cyferd/client-engine';
import { componentConfig } from '@models/view';

const getFlatNodeListInternal = (
  path: string[],
  contains: ViewModel.Node[],
  options?: { ignoreIndex?: boolean; addNodeId?: boolean }
): { path: string[]; node: ViewModel.Node }[] => {
  if (!Array.isArray(contains)) return [];
  return [
    ...contains.map((node, index) => {
      const currentPath = [...path, !options?.ignoreIndex && String(index), options?.addNodeId && node?.id].filter(Boolean);
      return [{ path: currentPath, node }, ...getFlatNodeListInternal([...currentPath, 'contains'], node?.contains)];
    })
  ]
    .filter(Boolean)
    .flat();
};

export const getFlatNodeList = (view: ViewModel.View): { path: string[]; node: ViewModel.Node }[] => {
  return [
    {
      path: ['globalHeader'],
      node: {
        id: `${view?.header?.id}-globalHeader`,
        component: ViewModel.DisplayName.GLOBAL_HEADER,
        name: componentConfig.globalHeader.label,
        attrs: view?.globalHeader
      } as ViewModel.Node
    },
    {
      path: ['header'],
      node: {
        id: view?.header?.id,
        component: ViewModel.DisplayName.VIEW_HEADER,
        name: componentConfig.viewHeader.label,
        attrs: view?.header
      } as ViewModel.Node
    },
    ...getFlatNodeListInternal(['modals'], Object.values(view?.modals || {}), { ignoreIndex: true, addNodeId: true }),
    ...getFlatNodeListInternal(['contains'], view?.contains)
  ];
};
