import { css } from '@emotion/css';

export const styles = {
  container: css`
    display: flex;
    flex-direction: column;
    flex: 1;
    overflow: hidden;
  `
};
