import { GAP } from '@constants';
import { css } from '@emotion/css';

export const styles = {
  container: css`
    text-align: center;
    padding: ${GAP.XXL} ${GAP.XXL};
    margin: 0 auto;
  `
};
