import { COLOR, FONT_SIZE, FONT_WEIGHT, GAP } from '@constants';
import { css } from '@emotion/react';

export const styles = {
  container: css`
    display: flex;
    flex-direction: column;
    gap: ${GAP.M};
    [data-selector='checkbox-container'] {
      min-height: 20px;
    }
  `,
  title: css`
    color: ${COLOR.NEUTRAL_1};
    font-size: ${FONT_SIZE.M};
    font-weight: ${FONT_WEIGHT.EXTRA_BOLD};
  `,
  options: css`
    display: flex;
    flex-direction: column;
    gap: ${GAP.M};
  `,
  option: css`
    display: flex;
    align-items: center;
    justify-content: space-between;
  `
};
