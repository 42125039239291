import type { FormulaInputRow } from '@components/elements/Evaluator/resources';
import { getFormulaInputRowItem } from '@components/elements/Evaluator/resources';
import type { FlowModel } from '@cyferd/client-engine';
import { ApiModel, GeneralModel, ViewModel, createUUID, getFlatSchemaProps, normalize, prepareTermForReg } from '@cyferd/client-engine';

export const getNewFlow = (): FlowModel.Flow => {
  return {
    id: createUUID(),
    name: 'New flow',
    description: '',
    verboseLog: false,
    enabled: true,
    onStart: [],
    steps: {}
  };
};

export const flowSessionInputList: FormulaInputRow[] = [
  getFormulaInputRowItem({ groupName: 'Session', prefix: 'flow', base: 'session' }),
  getFormulaInputRowItem({ groupName: 'Session', prefix: 'flow', base: 'session', path: 'id' }),
  getFormulaInputRowItem({ groupName: 'Session', prefix: 'flow', base: 'session', path: 'device' }),

  getFormulaInputRowItem({ groupName: 'User', prefix: 'flow', base: 'session', path: 'user', label: 'User' }),
  getFormulaInputRowItem({ groupName: 'User', prefix: 'flow', base: 'session', path: 'user.id', format: GeneralModel.JSONSchemaFormat.TEXT, label: 'User ID' }),
  getFormulaInputRowItem({
    groupName: 'User',
    prefix: 'flow',
    base: 'session',
    path: 'user.email',
    format: GeneralModel.JSONSchemaFormat.EMAIL,
    label: 'User email'
  }),
  getFormulaInputRowItem({
    groupName: 'User',
    prefix: 'flow',
    base: 'session',
    path: 'user.fullName',
    format: GeneralModel.JSONSchemaFormat.TEXT,
    label: 'User full name'
  }),
  getFormulaInputRowItem({
    groupName: 'User',
    prefix: 'flow',
    base: 'session',
    path: 'user.roles',
    format: GeneralModel.JSONSchemaFormat.ARRAY,
    label: 'User roles'
  }),
  getFormulaInputRowItem({
    groupName: 'User',
    prefix: 'flow',
    base: 'session',
    path: 'user.values',
    format: GeneralModel.JSONSchemaFormat.OBJECT,
    label: 'User values'
  }),
  getFormulaInputRowItem({
    groupName: 'User',
    prefix: 'flow',
    base: 'session',
    path: 'user.recordImage',
    format: GeneralModel.JSONSchemaFormat.ICON_IMAGE,
    label: 'User avatar'
  }),
  getFormulaInputRowItem({
    groupName: 'Tenant',
    prefix: 'flow',
    base: 'session',
    path: 'tenant',
    label: 'Tenant',
    format: GeneralModel.JSONSchemaFormat.OBJECT
  }),
  getFormulaInputRowItem({
    groupName: 'Tenant',
    prefix: 'flow',
    base: 'session',
    path: 'tenant.id',
    format: GeneralModel.JSONSchemaFormat.TEXT,
    label: 'Tenant ID'
  }),
  getFormulaInputRowItem({
    groupName: 'Tenant',
    prefix: 'flow',
    base: 'session',
    path: 'tenant.name',
    format: GeneralModel.JSONSchemaFormat.TEXT,
    label: 'Tenant name'
  }),
  getFormulaInputRowItem({
    groupName: 'Tenant',
    prefix: 'flow',
    base: 'session',
    path: 'tenant.origin',
    format: GeneralModel.JSONSchemaFormat.TEXT,
    label: 'Tenant origin'
  })
];

export const recordActionsInputList: FormulaInputRow[] = [
  getFormulaInputRowItem({ groupName: 'Record', prefix: 'recordActions', base: 'record', format: GeneralModel.JSONSchemaFormat.OBJECT }),
  getFormulaInputRowItem({ groupName: 'Record', prefix: 'recordActions', base: 'record', path: 'id', format: GeneralModel.JSONSchemaFormat.TEXT }),
  getFormulaInputRowItem({ groupName: 'Record', prefix: 'recordActions', base: 'record', path: 'collectionId', format: GeneralModel.JSONSchemaFormat.TEXT }),
  getFormulaInputRowItem({ groupName: 'Record', prefix: 'recordActions', base: 'record', path: 'createdAt', format: GeneralModel.JSONSchemaFormat.TEXT }),
  getFormulaInputRowItem({ groupName: 'Record', prefix: 'recordActions', base: 'record', path: 'createdBy', format: GeneralModel.JSONSchemaFormat.TEXT }),
  getFormulaInputRowItem({ groupName: 'Record', prefix: 'recordActions', base: 'record', path: 'updatedAt', format: GeneralModel.JSONSchemaFormat.TEXT }),
  getFormulaInputRowItem({ groupName: 'Record', prefix: 'recordActions', base: 'record', path: 'updatedBy', format: GeneralModel.JSONSchemaFormat.TEXT }),
  getFormulaInputRowItem({ groupName: 'Record', prefix: 'recordActions', base: 'record', path: 'recordTitle', format: GeneralModel.JSONSchemaFormat.TEXT }),
  getFormulaInputRowItem({ groupName: 'Record', prefix: 'recordActions', base: 'record', path: 'recordColor', format: GeneralModel.JSONSchemaFormat.COLOR }),
  getFormulaInputRowItem({
    groupName: 'Record',
    prefix: 'recordActions',
    base: 'record',
    path: 'recordImage',
    format: GeneralModel.JSONSchemaFormat.ICON_IMAGE
  })
];

export const listActionsInputList: FormulaInputRow[] = [
  getFormulaInputRowItem({ groupName: 'List', prefix: 'listActions', label: 'Event item', path: 'item', base: ViewModel.InputKey.EVENT }),
  getFormulaInputRowItem({
    groupName: 'List',
    prefix: 'listActions',
    path: 'item.totalCount',
    label: 'Total count',
    base: ViewModel.InputKey.EVENT,
    format: GeneralModel.JSONSchemaFormat.NUMBER
  }),
  getFormulaInputRowItem({
    groupName: 'List',
    prefix: 'listActions',
    path: 'item.list',
    label: 'List',
    base: ViewModel.InputKey.EVENT,
    format: GeneralModel.JSONSchemaFormat.ARRAY
  }),
  getFormulaInputRowItem({
    groupName: 'List',
    prefix: 'listActions',
    path: 'item.query',
    label: 'Query',
    base: ViewModel.InputKey.EVENT,
    format: GeneralModel.JSONSchemaFormat.OBJECT
  }),
  getFormulaInputRowItem({
    groupName: 'List',
    prefix: 'listActions',
    path: 'item.query.cursor',
    label: 'Cursor',
    base: ViewModel.InputKey.EVENT,
    format: GeneralModel.JSONSchemaFormat.OBJECT
  })
];

export const flowInputList: FormulaInputRow[] = [
  getFormulaInputRowItem({ groupName: 'Flow', prefix: 'flow', base: 'flow' }),
  getFormulaInputRowItem({ groupName: 'Flow', prefix: 'flow', base: 'flow', path: 'name', format: GeneralModel.JSONSchemaFormat.TEXT }),
  getFormulaInputRowItem({ groupName: 'Flow', prefix: 'flow', base: 'flow', path: 'description', format: GeneralModel.JSONSchemaFormat.TEXT }),
  getFormulaInputRowItem({ groupName: 'Flow', prefix: 'flow', base: 'flow', path: 'status', format: GeneralModel.JSONSchemaFormat.TEXT }),
  getFormulaInputRowItem({
    groupName: 'Flow',
    prefix: 'flow',
    base: 'flow',
    path: 'consecutiveErrorCount',
    format: GeneralModel.JSONSchemaFormat.NUMBER,
    label: 'Consecutive error count'
  }),
  getFormulaInputRowItem({ groupName: 'Flow', prefix: 'flow', base: 'flow', path: 'id', format: GeneralModel.JSONSchemaFormat.TEXT }),
  getFormulaInputRowItem({
    groupName: 'Flow',
    prefix: 'flow',
    base: 'flow',
    path: 'lastExecutedAt',
    format: GeneralModel.JSONSchemaFormat.TEXT,
    label: 'Last executed at'
  }),
  getFormulaInputRowItem({
    groupName: 'Flow',
    prefix: 'flow',
    base: 'flow',
    path: 'lastFinishedAt',
    format: GeneralModel.JSONSchemaFormat.TEXT,
    label: 'Last finished at'
  }),
  getFormulaInputRowItem({ groupName: 'Flow', prefix: 'flow', base: 'flow', path: 'lastError', format: GeneralModel.JSONSchemaFormat.JSON }),
  getFormulaInputRowItem({ groupName: 'Flow', prefix: 'flow', base: 'flow', path: 'createdAt', format: GeneralModel.JSONSchemaFormat.TEXT }),
  getFormulaInputRowItem({ groupName: 'Flow', prefix: 'flow', base: 'flow', path: 'updatedAt', format: GeneralModel.JSONSchemaFormat.TEXT }),
  getFormulaInputRowItem({ groupName: 'Flow', prefix: 'flow', base: 'flow', path: 'createdBy', format: GeneralModel.JSONSchemaFormat.TEXT }),
  getFormulaInputRowItem({ groupName: 'Flow', prefix: 'flow', base: 'flow', path: 'updatedBy', format: GeneralModel.JSONSchemaFormat.TEXT }),
  getFormulaInputRowItem({ groupName: 'Flow', prefix: 'flow', base: 'flow', path: 'state', format: GeneralModel.JSONSchemaFormat.OBJECT }),

  getFormulaInputRowItem({
    groupName: 'Error',
    prefix: 'error',
    base: 'error',
    path: 'code',
    label: 'Error code',
    format: GeneralModel.JSONSchemaFormat.TEXT
  }),
  getFormulaInputRowItem({
    groupName: 'Error',
    prefix: 'error',
    base: 'error',
    path: 'description',
    label: 'Error description',
    format: GeneralModel.JSONSchemaFormat.TEXT
  }),
  getFormulaInputRowItem({
    groupName: 'Error',
    prefix: 'error',
    base: 'error',
    path: 'details',
    label: 'Error details',
    format: GeneralModel.JSONSchemaFormat.OBJECT
  }),
  getFormulaInputRowItem({
    groupName: 'Error',
    prefix: 'error',
    base: 'error',
    path: 'timestamp',
    label: 'Error timestamp',
    format: GeneralModel.JSONSchemaFormat.DATE_TIME_U
  }),

  getFormulaInputRowItem({
    groupName: 'Execution',
    prefix: 'execution',
    base: 'execution',
    path: 'correlationId',
    label: 'Correlation ID',
    format: GeneralModel.JSONSchemaFormat.TEXT
  }),
  getFormulaInputRowItem({
    groupName: 'Execution',
    prefix: 'execution',
    base: 'execution',
    path: 'id',
    label: 'Execution ID',
    format: GeneralModel.JSONSchemaFormat.TEXT
  }),

  ...flowSessionInputList
];

export const triggerInputList: FormulaInputRow[] = [
  ...recordActionsInputList,

  getFormulaInputRowItem({ groupName: 'Query', prefix: 'trigger', base: 'query', format: GeneralModel.JSONSchemaFormat.OBJECT }),
  getFormulaInputRowItem({ groupName: 'Query', prefix: 'trigger', base: 'query', path: 'schema', format: GeneralModel.JSONSchemaFormat.OBJECT }),
  getFormulaInputRowItem({ groupName: 'Query', prefix: 'trigger', base: 'query', path: 'cursor', format: GeneralModel.JSONSchemaFormat.OBJECT }),

  getFormulaInputRowItem({
    groupName: 'Query',
    prefix: 'trigger',
    base: 'query',
    path: 'cursor.collectionId',
    format: GeneralModel.JSONSchemaFormat.TEXT
  }),
  getFormulaInputRowItem({ groupName: 'Query', prefix: 'trigger', base: 'query', path: 'cursor.id', format: GeneralModel.JSONSchemaFormat.TEXT }),

  getFormulaInputRowItem({ groupName: 'Changed', prefix: 'trigger', base: 'changed', format: GeneralModel.JSONSchemaFormat.OBJECT }),

  getFormulaInputRowItem({ isAlias: true, groupName: 'Result', prefix: 'trigger', base: 'result', format: GeneralModel.JSONSchemaFormat.OBJECT }),
  getFormulaInputRowItem({
    isAlias: true,
    groupName: 'Result',
    prefix: 'trigger',
    base: 'result',
    path: 'record',
    format: GeneralModel.JSONSchemaFormat.OBJECT
  }),

  getFormulaInputRowItem({
    isAlias: true,
    groupName: 'Result',
    prefix: 'trigger',
    base: 'result',
    path: 'record.id',
    format: GeneralModel.JSONSchemaFormat.TEXT
  }),
  getFormulaInputRowItem({
    isAlias: true,
    groupName: 'Result',
    prefix: 'trigger',
    base: 'result',
    path: 'record.collectionId',
    format: GeneralModel.JSONSchemaFormat.TEXT
  }),
  getFormulaInputRowItem({
    isAlias: true,
    groupName: 'Result',
    prefix: 'trigger',
    base: 'result',
    path: 'record.createdAt',
    format: GeneralModel.JSONSchemaFormat.TEXT
  }),
  getFormulaInputRowItem({
    isAlias: true,
    groupName: 'Result',
    prefix: 'trigger',
    base: 'result',
    path: 'record.createdBy',
    format: GeneralModel.JSONSchemaFormat.TEXT
  }),
  getFormulaInputRowItem({
    isAlias: true,
    groupName: 'Result',
    prefix: 'trigger',
    base: 'result',
    path: 'record.updatedAt',
    format: GeneralModel.JSONSchemaFormat.TEXT
  }),
  getFormulaInputRowItem({
    isAlias: true,
    groupName: 'Result',
    prefix: 'trigger',
    base: 'result',
    path: 'record.updatedBy',
    format: GeneralModel.JSONSchemaFormat.TEXT
  }),
  getFormulaInputRowItem({
    isAlias: true,
    groupName: 'Result',
    prefix: 'trigger',
    base: 'result',
    path: 'record.recordTitle',
    format: GeneralModel.JSONSchemaFormat.TEXT
  }),
  getFormulaInputRowItem({
    isAlias: true,
    groupName: 'Result',
    prefix: 'trigger',
    base: 'result',
    path: 'record.recordColor',
    format: GeneralModel.JSONSchemaFormat.TEXT
  }),
  getFormulaInputRowItem({
    isAlias: true,
    groupName: 'Result',
    prefix: 'trigger',
    base: 'result',
    path: 'record.recordImage',
    format: GeneralModel.JSONSchemaFormat.TEXT
  }),

  getFormulaInputRowItem({
    isAlias: true,
    groupName: 'Result',
    prefix: 'trigger',
    base: 'result',
    path: 'query',
    format: GeneralModel.JSONSchemaFormat.OBJECT
  }),
  getFormulaInputRowItem({
    isAlias: true,
    groupName: 'Result',
    prefix: 'trigger',
    base: 'result',
    path: 'query.schema',
    format: GeneralModel.JSONSchemaFormat.OBJECT
  }),
  getFormulaInputRowItem({
    isAlias: true,
    groupName: 'Result',
    prefix: 'trigger',
    base: 'result',
    path: 'query.cursor',
    format: GeneralModel.JSONSchemaFormat.OBJECT
  }),
  getFormulaInputRowItem({
    isAlias: true,
    groupName: 'Result',
    prefix: 'trigger',
    base: 'result',
    path: 'query.cursor.collectionId',
    format: GeneralModel.JSONSchemaFormat.TEXT
  }),
  getFormulaInputRowItem({
    isAlias: true,
    groupName: 'Result',
    prefix: 'trigger',
    base: 'result',
    path: 'query.cursor.id',
    format: GeneralModel.JSONSchemaFormat.TEXT
  }),

  getFormulaInputRowItem({
    isAlias: true,
    groupName: 'Result',
    prefix: 'trigger',
    base: 'result',
    path: 'changed',
    format: GeneralModel.JSONSchemaFormat.OBJECT
  }),

  getFormulaInputRowItem({ groupName: 'Input', prefix: 'trigger', base: 'input', format: GeneralModel.JSONSchemaFormat.OBJECT }),
  getFormulaInputRowItem({ groupName: 'Action', prefix: 'trigger', base: 'action', format: GeneralModel.JSONSchemaFormat.TEXT }),

  ...flowSessionInputList
];

export const getFlowInputList = (item: FlowModel.Flow): FormulaInputRow[] => {
  const modelInputList = getFlatSchemaProps(item?.model?.schema)
    .filter(p => p.path !== item?.model?.schema?.$id)
    .map(p =>
      getFormulaInputRowItem({
        groupName: 'Input',
        prefix: 'flow-input',
        base: 'input',
        path: item?.model?.schema?.$id ? `${p.path.replace(new RegExp(`^(${prepareTermForReg(item?.model?.schema?.$id)}).`), '')}` : p.path,
        label: p.definition.title || p.definition.label || p.path || 'Input (root)',
        description: p.definition.description,
        format: p.definition.format
      })
    );
  const resultInputList = Object.values(item?.steps || [])
    .filter(step => !!step?.id)
    .map<[string, ReturnType<typeof getFlatSchemaProps>]>(step => [
      step.id,
      getFlatSchemaProps(normalize.schema(ApiModel.FlowSchemaMap[step.action]?.output?.schema))
    ])
    .filter(([_, flatSchemaProps]) => flatSchemaProps.length)
    .map(([stepId, flatSchemaProps]) =>
      [
        getFormulaInputRowItem({
          groupName: `Step ${item.steps[stepId]?.name}`,
          prefix: 'flow-result',
          base: `result.${stepId}`,
          path: '',
          label: `${item.steps[stepId]?.name} result (root)`,
          format: GeneralModel.JSONSchemaFormat.JSON
        }),
        ...flatSchemaProps.slice(1, flatSchemaProps.length).map(p =>
          getFormulaInputRowItem({
            groupName: `Step ${item.steps[stepId]?.name}`,
            prefix: 'flow-result',
            base: `result.${stepId}`,
            path: `${p.path.replace(new RegExp(`^(${prepareTermForReg(flatSchemaProps[0].definition?.$id)}).`), '')}`,
            label: p.displayNamePath.join(' '),
            description: `Result from step ${item.steps[stepId]?.name}`,
            format: p.definition.format
          })
        ),
        getFormulaInputRowItem({
          groupName: `Step ${item.steps[stepId]?.name}`,
          prefix: 'flow-meta',
          base: 'meta',
          path: `${stepId}.error`,
          label: `${item.steps[stepId]?.name} error`,
          format: GeneralModel.JSONSchemaFormat.OBJECT
        }),
        getFormulaInputRowItem({
          groupName: `Step ${item.steps[stepId]?.name}`,
          prefix: 'flow-meta',
          base: 'meta',
          path: `${stepId}.runs`,
          label: `${item.steps[stepId]?.name} runs`,
          format: GeneralModel.JSONSchemaFormat.NUMBER
        }),
        getFormulaInputRowItem({
          groupName: `Step ${item.steps[stepId]?.name}`,
          prefix: 'flow-meta',
          base: 'meta',
          path: `${stepId}.successCount`,
          label: `${item.steps[stepId]?.name} success count`,
          format: GeneralModel.JSONSchemaFormat.NUMBER
        }),
        getFormulaInputRowItem({
          groupName: `Step ${item.steps[stepId]?.name}`,
          prefix: 'flow-meta',
          base: 'meta',
          path: `${stepId}.errorCount`,
          label: `${item.steps[stepId]?.name} error count`,
          format: GeneralModel.JSONSchemaFormat.NUMBER
        }),
        getFormulaInputRowItem({
          groupName: `Step ${item.steps[stepId]?.name}`,
          prefix: 'flow-meta',
          base: 'meta',
          path: `${stepId}.consecutiveErrorCount`,
          label: `${item.steps[stepId]?.name} consecutive error count`,
          format: GeneralModel.JSONSchemaFormat.NUMBER
        })
      ].filter(Boolean)
    )
    .flat();
  const stateInputList = Object.keys(item?.state || {}).map(p =>
    getFormulaInputRowItem({
      groupName: `State`,
      prefix: 'state',
      base: `state`,
      path: p,
      label: `State - ${p}`,
      format: GeneralModel.JSONSchemaFormat.JSON
    })
  );
  return [...modelInputList, ...resultInputList, ...flowInputList, ...stateInputList];
};
