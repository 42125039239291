import * as React from 'react';

const NoResults = props => (
  <svg width={163} height={202} fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <rect x={137.32} y={164.712} width={35.962} height={16.064} rx={8.032} transform="rotate(45 137.32 164.712)" fill="var(--NEUTRAL_4)" />
    <rect x={137.32} y={165.419} width={34.962} height={15.064} rx={7.532} transform="rotate(45 137.32 165.419)" stroke="var(--BRAND_1)" strokeOpacity={0.2} />
    <path d="m126 169.902 3.999 3.999m.5-8.498 3.499 3.499m7.498 5.999 7.999 7.999" stroke="var(--BRAND_1)" strokeLinecap="round" />
    <circle cx={101.005} cy={139.908} r={38.992} fill="var(--NEUTRAL_4)" stroke="var(--NEUTRAL_4)" />
    <circle cx={101.005} cy={139.908} r={31.993} fill="var(--BASE_BACKGROUND)" stroke="var(--NEUTRAL_4)" />
    <ellipse cx={86.008} cy={142.408} rx={2.499} ry={3.999} fill="var(--BRAND_1)" />
    <ellipse cx={117.001} cy={142.408} rx={2.499} ry={3.999} fill="var(--BRAND_1)" />
    <path d="M92.748 154.468c0 2.666 6.675 3 9.755 3 3.081 0 9.241-.5 9.241-3" stroke="var(--BRAND_1)" strokeLinecap="round" />
    <path d="M44.517 7.213v5.999M47.516 10.212h-5.999" stroke="var(--BRAND_1)" strokeLinecap="round" />
    <circle cx={61.013} cy={26.709} r={2.999} stroke="var(--BRAND_1)" />
    <path d="M150.304 37.098v4.232M148.188 39.214h4.232" stroke="var(--BRAND_1)" strokeLinecap="round" />
    <circle r={1.968} transform="scale(-1 1) rotate(15 -274.093 -512.855)" stroke="var(--BRAND_1)" />
    <path d="M20.522 127.188v5.999M23.52 130.188l-5.998-.001" stroke="var(--BE_2)" strokeLinecap="round" />
    <circle cx={37.018} cy={146.684} r={2.999} stroke="var(--NEUTRAL_4)" />
    <circle cx={14.023} cy={162.681} r={1.999} stroke="var(--BRAND_1)" />
    <path
      d="M7.865 50.172H2.753a2 2 0 0 0-2 2v58.019a2 2 0 0 0 2 2h43.795a2 2 0 0 0 2-2v-4.828"
      stroke="var(--NEUTRAL_4)"
      strokeLinecap="round"
      strokeDasharray="6 6"
    />
    <path
      d="M41.72 42.206H10.15a2 2 0 0 0-2 2v58.588a2 2 0 0 0 2 2h44.364a2 2 0 0 0 2-2V54.983a2 2 0 0 0-.586-1.414l-9.087-9.087c-.63-.63-1.707-.184-1.707.707v6.965a2 2 0 0 0 2 2h7.104"
      stroke="var(--NEUTRAL_3)"
      strokeLinecap="round"
    />
    <path
      d="m22.375 60.129 3.13 3.13m-3.13-3.13 3.13 3.13M25.505 60.129l-3.13 3.13m3.13-3.13-3.13 3.13M39.728 60.413l3.13 3.13m-3.13-3.13 3.13 3.13M42.858 60.413l-3.13 3.13m3.13-3.13-3.13 3.13M30.747 68.486h4"
      stroke="var(--NEUTRAL_3)6D6D6D"
      strokeLinecap="round"
    />
    <path
      d="M15.547 78.334h34.139M15.547 83.334h34.139M15.547 88.334h34.139M15.547 93.334h34.139M15.547 98.334h34.139"
      stroke="var(--NEUTRAL_4)"
      strokeLinecap="round"
    />
    <path
      d="M117.144 48.205h3.356a2 2 0 0 0 2-2V3.5a2 2 0 0 0-2-2H88.508a2 2 0 0 0-2 2v3.142"
      stroke="var(--NEUTRAL_4)"
      strokeLinecap="round"
      strokeDasharray="6 6"
    />
    <path
      d="M105.361 7.499h-23.28a2 2 0 0 0-2 2v43.133a2 2 0 0 0 2 2h32.42a2 2 0 0 0 2-2V17.325a2 2 0 0 0-.585-1.414l-6.277-6.277c-.63-.63-1.707-.183-1.707.707v4.156a2 2 0 0 0 2 2h4.855"
      stroke="var(--NEUTRAL_3)"
      strokeLinecap="round"
    />
    <path
      d="m90.793 20.996 2.357 2.357m-2.358-2.357 2.358 2.357M93.15 20.996l-2.357 2.357m2.357-2.357-2.357 2.357M103.862 21.21l2.357 2.357m-2.357-2.357 2.357 2.357M106.219 21.21l-2.358 2.357m2.358-2.357-2.358 2.357M96.733 27.495h3"
      stroke="var(--NEUTRAL_3)6D6D6D"
      strokeLinecap="round"
    />
    <path d="M85.65 34.707h25.71M85.65 39.707h25.71M85.65 44.707h25.71M85.65 49.707h25.71" stroke="var(--NEUTRAL_4)" strokeLinecap="round" />
    <path
      d="M128.47 91.743c0-3.246 2.066-5.017 5.608-5.017 3.541 0 5.312 2.656 5.312 4.427 0 3.542-2.656 4.722-3.837 5.608-1.18.885-1.771 2.066-1.771 3.246"
      stroke="var(--BRAND_1)"
      strokeWidth={2}
      strokeLinecap="round"
    />
    <circle cx={133.918} cy={103.331} r={1} fill="var(--BRAND_1)" />
  </svg>
);

export default NoResults;
