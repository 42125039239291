import React from 'react';
import { Spinner } from '@components/elements/Spinner';

import { styles } from './styles';

export const LoadingPageOverlay = () => {
  return (
    <div css={styles.container} data-testid="loading-container">
      <div css={styles.overlay} />
      <div css={styles.content}>
        <Spinner size="35px" />
      </div>
    </div>
  );
};
