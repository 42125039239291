import { css } from '@emotion/react';

import { COLOR, SECONDARY_COLOR } from '@constants';

const avatar = css`
  border-radius: 50%;
  overflow: hidden;
`;

export const styles = {
  avatarImage: css`
    ${avatar}
    object-fit: cover;
  `,

  avatarIcon: css`
    ${avatar}
    aspect-ratio: 1;
    background-color: ${SECONDARY_COLOR.NEUTRAL_2};
    color: ${COLOR.NEUTRAL_2};
    display: flex;
    justify-content: center;
    align-items: center;
  `,
  iconSizes: {
    xs: css`
      height: 12px;
      width: 12px;
    `,

    sm: css`
      height: 16px;
      width: 16px;
    `,

    md: css`
      height: 20px;
      width: 20px;
    `,

    lg: css`
      height: 40px;
      width: 40px;
    `,

    xl: css`
      height: 60px;
      width: 60px;
    `,

    fill: css`
      height: 100%;
      width: 100%;
    `
  }
};
