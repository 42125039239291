import { useContext, useMemo } from 'react';

import type { ViewModel } from '@cyferd/client-engine';

import { BuilderCyList } from '../../../shared/BuilderCyList';
import type { EditorContextValue } from '../../../shared/EditorHome';
import { EditorContext } from '../../../shared/EditorHome';
import { EmptyState } from '@components/elements/EmptyState';
import { styles } from './styles';
import { useRequest } from '@utils/useRequest';

export const ViewLogs = () => {
  const request = useRequest();
  const { item } = useContext<EditorContextValue<ViewModel.View>>(EditorContext);

  const collectionId = 'ViewLog';

  const fixedFilter = useMemo(() => ({ $and: [{ actualViewId: { $eq: item?.id } }] }), [item?.id]);
  if (!item) return <EmptyState />;

  return (
    <div data-testid="view-logs" className={styles.container}>
      <BuilderCyList
        request={request}
        id={collectionId}
        key={collectionId}
        initialFetchCriteria={{ collectionId }}
        fixedFilter={fixedFilter}
        omit={['viewId']}
      />
    </div>
  );
};
