import { COLOR } from '@constants';
import { tooltipStyles } from './styles';

export const ScatterChartTooltip = ({ series, itemData, measures }) => {
  const value = series?.data[itemData?.dataIndex];
  const color = COLOR[value?.color] ?? series?.color;

  return (
    <div css={tooltipStyles.container}>
      <div css={tooltipStyles.label}>
        {color && <div css={tooltipStyles.dot} style={{ background: color }} data-testid="dot" />}
        {series?.label}
        {value?.label && `: ${value?.label}`}
      </div>
      <div css={tooltipStyles.measure}>
        <span css={tooltipStyles.measureLabel}>{measures?.[0]?.label}:</span> {value?.x}
      </div>
      <div css={tooltipStyles.measure}>
        <span css={tooltipStyles.measureLabel}>{measures?.[1]?.label}:</span> {value?.y}
      </div>
    </div>
  );
};
