import { memo, useCallback, useContext } from 'react';

import { ApiModel } from '@cyferd/client-engine';
import { useTestingHelper } from '@utils';

import { useGetCollectionDefinition } from '@utils/useGetCollectionDefinition';
import type { EditorContextValue } from '../../../shared/EditorHome';
import { EditorContext } from '../../../shared/EditorHome';
import { EmptyState } from '@components/elements/EmptyState';
import { SchemaForm } from '../../../shared/SchemaForm';
import { styles } from './styles';

export const GeneralInfo = memo(() => {
  const { getTestIdProps } = useTestingHelper('general-info');
  const { item, setItem } = useContext<EditorContextValue<ApiModel.ApiEntity.ENTITY>>(EditorContext);

  const onChange = useCallback((entity: ApiModel.ApiEntity.ENTITY) => setItem(entity), [setItem]);

  const collection = useGetCollectionDefinition(ApiModel.ApiEntity.INVITATION);

  if (!item || !collection) return <EmptyState />;

  return (
    <div {...getTestIdProps('container')} className={styles.container}>
      <SchemaForm schema={collection?.schema} value={item} onChange={onChange} wrapDetailGroups={true} detailGroupList={collection?.detailGroupList} />
    </div>
  );
});
