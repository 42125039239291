import { COLOR, EMPTY_CONTAINER_HEIGHT, GAP } from '@constants';
import { css } from '@emotion/react';

export const styles = {
  spinnerContainer: css`
    display: flex;
    width: 100%;
    height: 100%;
    flex: 1;
    align-items: center;
    justify-content: center;
    min-height: ${EMPTY_CONTAINER_HEIGHT.XS}px;
    padding: ${GAP.L};
  `,
  chartWrapper: css`
    max-height: 645px;
    padding-bottom: ${GAP.M};
  `,
  chartContainer: css`
    aspect-ratio: 12 / 6;

    .MuiBarElement-root {
      shape-rendering: auto;
      ry: 2px;
    }

    .MuiChartsGrid-line {
      stroke: ${COLOR.NEUTRAL_2} !important;
      opacity: 0.12;
    }
  `,
  chartTooltip: `
    background-color: ${COLOR.TOP_BACKGROUND};
    * {
      font-family: 'Inter', sans-serif !important;
    }
  `,
  empty: css`
    display: flex;
    justify-content: center;
    align-items: center;
    min-height: ${EMPTY_CONTAINER_HEIGHT.XS}px;
  `
};

export const tooltipStyles = {
  container: css`
    position: relative;
    left: ${GAP.M};
    display: flex;
    flex-direction: column;
    gap: ${GAP.XS};
    background: ${COLOR.BASE_BACKGROUND};
    border-radius: ${GAP.XS};
    padding: ${GAP.S} ${GAP.M};
    border-style: solid;
    border-width: 1px;
    border-color: ${COLOR.NEUTRAL_3};
  `,
  label: css`
    display: flex;
    align-items: center;
    color: ${COLOR.NEUTRAL_2};
  `,
  dot: css`
    height: ${GAP.M};
    width: ${GAP.M};
    margin-right: ${GAP.S};
    border-radius: 50%;
    background-color: inherit;
  `,
  measure: css`
    color: ${COLOR.NEUTRAL_1};
  `,
  measureLabel: css`
    padding-right: ${GAP.S};
  `
};
