/* istanbul ignore file | @todo */
import {
  ApiModel,
  ClientEngineContext,
  GeneralModel,
  ViewModel,
  ofTypeSetData,
  swallowError,
  tapOnSuccess,
  useFinalizeWhileMounted
} from '@cyferd/client-engine';
import { Layout } from '../Layout';
import { useContext, useState } from 'react';
import { Switch } from '../Switch';
import { FieldsetContent } from '../Fieldset';
import { CyWrapperContext } from '@components/smart/CyWrapper/CyWrapper';

export const NotificationPreferences = () => {
  const { useAction } = useContext(CyWrapperContext);
  const { useUserSelector } = useContext(ClientEngineContext);
  const user = useUserSelector();
  const onUpsert = useAction('coreUpsert');
  const onSetUser = useAction('dispatchSetUser');
  const [loading, setLoading] = useState<boolean>(false);
  const finalize = useFinalizeWhileMounted();

  const onChange = (field: string) => (setting: boolean) => {
    setLoading(true);
    onUpsert({
      query: { cursor: { collectionId: ApiModel.ApiEntity.USER, id: user.id } },
      pointer: GeneralModel.IGNORED_POINTER_ID,
      record: { ...user, notifications: { ...user.notifications, [field]: setting } }
    })
      .pipe(
        ofTypeSetData<ApiModel.ApiValue>(),
        tapOnSuccess(res => onSetUser(res.record as any)),
        finalize(() => setLoading(false)),
        swallowError()
      )
      .subscribe();
  };

  return (
    <Layout type={ViewModel.LayoutType.FULL}>
      <FieldsetContent>
        <Switch onChange={onChange('email')} disabled={loading} label="Email" value={user.notifications?.email !== false} />
        <Switch onChange={onChange('mobile')} disabled={loading} label="Mobile push" value={user.notifications?.mobile !== false} />
        <Switch onChange={onChange('inApp')} disabled={loading} label="In-platform" value={user.notifications?.inApp !== false} />
      </FieldsetContent>
    </Layout>
  );
};
