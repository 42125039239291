import { CTA, CTAType } from '@components/elements/CTA';
import { Logo } from '../LoadingPage/assets/Logo';
import { styles } from './styles';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { useCallback } from 'react';
import { TRANS } from '@constants';

export interface ErrorPageProps {
  testid?: string;
}

export interface ErrorPageChildProps extends ErrorPageProps {
  message?: string;
  redirectTo?: string;
  errorId?: string;
  handleRedirect: () => void;
}

export const ErrorPage = ({ testid = 'ErrorPage' }: ErrorPageProps) => {
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const message = searchParams.get('message');
  const redirectTo = searchParams.get('redirectTo');
  const errorId = searchParams.get('errorId');

  const handleRedirect = useCallback(() => {
    navigate(redirectTo ? redirectTo : '/');
  }, [navigate, redirectTo]);

  return <ErrorChild message={message} redirectTo={redirectTo} errorId={errorId} handleRedirect={handleRedirect} testid={testid} />;
};

export const ErrorChild = ({ message, redirectTo, errorId, handleRedirect, testid }: ErrorPageChildProps) => (
  <div css={styles.container} data-testid={testid}>
    <div css={styles.content}>
      <div css={styles.infoContainer}>
        <div>
          <Logo />
          <p css={styles.title}>Uh-oh! Something went wrong.</p>
          {message && (
            <p data-testid={`${testid}-error`} css={styles.subtitle}>
              {message}
            </p>
          )}
          {errorId && (
            <p data-testid={`${testid}-error-id`} css={styles.errorId}>
              Error ID: {errorId}
            </p>
          )}
          <CTA
            testid={`${testid}-cta`}
            onClick={handleRedirect}
            label={redirectTo ? TRANS.client.buttons.goBack : TRANS.client.buttons.goHome}
            type={CTAType.PRIMARY}
          />
        </div>
      </div>
    </div>
  </div>
);

ErrorPage.displayName = 'ErrorPage';
