import { COLOR, FONT_SIZE, FONT_WEIGHT, GAP, RADIUS } from '@constants';
import { css } from '@emotion/css';

export const styles = {
  mainContainer: css`
    flex: 1;
    display: grid;
    grid-template-columns: 250px auto;
    grid-gap: ${GAP.S};
    overflow: hidden;
    height: 100%;
  `,
  container: css`
    display: grid;
    grid-template-rows: 45fr minmax(300px, 55fr);
    overflow: hidden;
  `,
  sidebar: css`
    display: flex;
    flex-direction: column;
    gap: ${GAP.S};
    overflow: hidden;
    padding: ${GAP.S};
    border-radius: ${RADIUS.M};
    background-color: ${COLOR.BASE_BACKGROUND_ALT_2};
  `,
  fullCanvas: css`
    grid-template-rows: minmax(200px, 1fr) 0 !important;
  `,
  containerNoSelection: css`
    display: flex;
    align-items: center;
    justify-content: center;
  `,
  nodesContainer: css`
    overflow: auto;
    scroll-behavior: smooth;
  `,
  detailContainer: css`
    height: 100%;
    overflow: hidden;
  `,
  title: css`
    font-size: ${FONT_SIZE.XM};
    font-weight: ${FONT_WEIGHT.BOLD};
    padding: ${GAP.L} 0 ${GAP.XS} 0;
    margin: ${GAP.L} 0 ${GAP.M} 0;
    border-bottom: 1px solid ${COLOR.BRAND_1};
  `,
  subtitle: css`
    font-size: ${FONT_SIZE.M};
    font-weight: ${FONT_WEIGHT.BOLD};
  `,
  tabContent: css`
    overflow: auto;
    scroll-behavior: smooth;
    padding-bottom: ${GAP.M};
    flex: 1;
  `,
  content: css`
    display: flex;
    flex-direction: column;
    gap: ${GAP.XS};
  `,
  viewTab: css`
    display: none;
  `,
  viewTabActive: css`
    display: block !important;
  `,
  addBtnContainer: css`
    display: flex;
    justify-content: flex-end;
  `
};
