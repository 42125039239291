import { useContext, useMemo } from 'react';

import type { ViewModel } from '@cyferd/client-engine';
import { ApiModel } from '@cyferd/client-engine';

import { BondEditor } from '../../../shared/BondEditor';
import type { EditorContextValue } from '../../../shared/EditorHome';
import { EditorContext } from '../../../shared/EditorHome';
import { EmptyLinkingPage } from '../../../../../@components/elements/EmptyLinkingPage';
import { LinkingPageDiagram, PredefinedDiagrams } from '../../../../../@components/elements/LinkingPageDiagram';

export const EntityBondEditor = () => {
  const { item } = useContext<EditorContextValue<ViewModel.View>>(EditorContext);

  const cursor = useMemo(() => ({ associationKey: 'viewCollections', relatedTo: { collectionId: ApiModel.ApiEntity.VIEW, id: item?.id } }), [item]);

  return (
    <BondEditor
      item={item}
      collectionId={ApiModel.ApiEntity.ENTITY}
      cursor={cursor}
      associationKey="viewCollections"
      modalTitle="Unlinked collections"
      hideLinkingModalBtn={true}
      hideActionListChildren={true}
      customEmptyState={
        <EmptyLinkingPage
          title="Nothing linked"
          subtitle="A collection is data structure that the admin creates by adding fields and associations"
          diagram={<LinkingPageDiagram value={PredefinedDiagrams.collectionLinkedViews} />}
        />
      }
    />
  );
};
