import { css } from '@emotion/css';

export const styles = {
  container: css`
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    max-height: 100%;
  `
};
