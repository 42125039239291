import { COLOR, FONT_SIZE, GAP } from '@constants';
import { ViewModel } from '@cyferd/client-engine';
import type { SerializedStyles } from '@emotion/react';
import { css } from '@emotion/react';

export const iconSizeMap = {
  [ViewModel.CyInfoIconSize.S]: '30px',
  [ViewModel.CyInfoIconSize.M]: '70px',
  [ViewModel.CyInfoIconSize.L]: '125px'
};

export const styles = {
  container: css`
    display: flex;
    flex-direction: column;
    align-items: center;
  `,
  infoContainer: css`
    flex: 1;
    display: inline-flex;
    align-items: center;
    width: 100%;
    flex-wrap: wrap;
  `,
  imageContainer: css`
    display: flex;
    padding-right: ${GAP.S};
  `,
  imageContainerVertical: css`
    justify-content: center;
    width: 100%;
    padding-right: 0;
  `,
  img: css`
    max-width: 100%;
  `,
  textContainer: css`
    display: flex;
    flex-direction: column;
    justify-content: center;
    word-break: break-word;
  `,
  textContainerVertical: css`
    text-align: center;
    margin-top: ${GAP.XL};
    width: 100%;
  `,
  title: css`
    color: ${COLOR.BRAND_1};
    font-size: ${FONT_SIZE.XM};
    font-weight: 600;
    margin-bottom: ${GAP.XXS};
    line-height: 1.25;
  `,
  subtitle: css`
    color: ${COLOR.BRAND_1};
    font-size: ${FONT_SIZE.XM};
    line-height: 1.25;
  `,
  actionsContainer: css`
    display: flex;
    align-items: center;
    margin-top: ${GAP.XL};
    flex-wrap: wrap;
    width: 100%;
  `,
  actionButton: css`
    margin: 0 ${GAP.XXS};
    margin-bottom: ${GAP.XXS};
  `
};

export const getTextAlignmentStyle = (() => {
  const styles = {
    [ViewModel.Alignment.LEFT]: css`
      text-align: left;
    `,
    [ViewModel.Alignment.RIGHT]: css`
      text-align: right;
    `,
    [ViewModel.Alignment.CENTER]: css`
      text-align: center;
    `
  };

  return (alignment: ViewModel.Alignment, fallback?: string): SerializedStyles =>
    styles[alignment] ||
    (fallback
      ? css`
          text-align: ${fallback};
        `
      : styles[ViewModel.Alignment.LEFT]);
})();

export const getFlexAlignmentStyle = (() => {
  const styles = {
    [ViewModel.Alignment.LEFT]: css`
      justify-content: flex-start;
    `,
    [ViewModel.Alignment.RIGHT]: css`
      justify-content: flex-end;
    `,
    [ViewModel.Alignment.CENTER]: css`
      justify-content: center;
    `
  };

  return (alignment: ViewModel.Alignment, fallback?: string): SerializedStyles => {
    return (
      styles[alignment] ||
      (fallback
        ? css`
            justify-content: ${fallback};
          `
        : styles[ViewModel.Alignment.CENTER])
    );
  };
})();
