import { GAP } from '@constants';
import { css } from '@emotion/react';

export const styles = {
  actionsContainer: css`
    display: flex;
    align-items: center;
    margin-top: ${GAP.S};
    flex-wrap: wrap;
    width: 100%;
    gap: ${GAP.XS};
  `,
  chartWrapper: css`
    max-width: 1000px;
    margin: none;
  `
};
