import { useContext } from 'react';

import type { ViewModel } from '@cyferd/client-engine';
import { useTestingHelper } from '@utils';

import { ENV, isSavedRecord } from '@constants';
import type { EditorContextValue } from '../../../shared/EditorHome';
import { EditorContext } from '../../../shared/EditorHome';
import { EmptyState } from '@components/elements/EmptyState';
import { styles } from './styles';

export const Preview = () => {
  const { getTestIdProps } = useTestingHelper('preview');
  const { item } = useContext<EditorContextValue<ViewModel.View>>(EditorContext);

  if (!item) return <EmptyState />;

  return (
    <div {...getTestIdProps('container')} className={styles.container}>
      {isSavedRecord(item) && (
        <div className={styles.iframeContainer} data-testid="iframe">
          <iframe className={styles.iframe} title="on-cyferd" src={`${ENV.CLIENT_APP_URL}/${item.id}?hash=${item.updatedAt}`} />
        </div>
      )}
    </div>
  );
};
