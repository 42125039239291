import type { GeneralModel, ParsedList } from '@cyferd/client-engine';
import { ViewModel, formatDateRange, getParsedActionChildren } from '@cyferd/client-engine';
import { styles } from './styles';
import { Icon } from '../Icon';
import { COLOR, FONT_SIZE } from '@constants';
import { OptionMenu } from '../OptionMenu';
import { CTAType } from '../CTA';
import { useMemo } from 'react';
import { PreventClickPropagation } from '../PreventClickPropagation';
import dayjs from 'dayjs';

export interface CalendarPopoverContentProps {
  testid?: string;
  value: ParsedList['items'][0];
  from: GeneralModel.DateLike;
  to?: GeneralModel.DateLike;
  isFullDayEvent: boolean;
  actionListChildren?: ViewModel.CyCalendarProps['actionListChildren'];
  disabled: boolean;
}

export const CalendarPopoverContent = ({
  testid = 'CalendarPopoverContent',
  value,
  from,
  to,
  isFullDayEvent,
  disabled,
  actionListChildren
}: CalendarPopoverContentProps) => {
  const parsedActions = useMemo(() => getParsedActionChildren(actionListChildren, { item: value.fullItem }), [actionListChildren, value.fullItem]);
  return (
    <div css={styles.container} data-testid={testid}>
      <header css={styles.titleContainer}>
        <div css={styles.subtitleContainer}>
          <Icon name="schedule" fill={COLOR.NEUTRAL_2} size={FONT_SIZE.M} />
          <span css={styles.subtitle}>{isFullDayEvent ? dayjs(from).format('D MMMM YYYY') : formatDateRange(from, to)}</span>
        </div>
      </header>
      {!!value.description && (
        <div css={styles.descriptionContainer}>
          <p css={styles.description}>{value.description}</p>
        </div>
      )}
      {!!parsedActions?.length && (
        <div css={styles.actions}>
          <PreventClickPropagation>
            <OptionMenu
              defaultBtnType={CTAType.LINK}
              optionList={parsedActions.map(actionItem => ({
                label: actionItem.label,
                image: actionItem.icon,
                disabled: disabled || !!actionItem.disabled,
                onClick: actionItem.onClick && (event => actionItem.onClick(value.fullItem, { metaKey: event?.metaKey })),
                testid: 'action',
                color: actionItem.color,
                type: CTAType.TERTIARY,
                tooltip: actionItem.helperText,
                size: ViewModel.CTASize.SMALL,
                important: true
              }))}
            />
          </PreventClickPropagation>
        </div>
      )}
    </div>
  );
};

CalendarPopoverContent.displayName = 'CalendarPopoverContent';
