import * as React from 'react';

export const LoadingCity = props => (
  <svg width={1317} height={596} fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      d="M1070.89 39.066c-1.11 0-2-.896-2-2V4.18c0-1.173 1-2.098 2.16-1.938 18.23 2.512 33.21 16.415 37.14 34.513.26 1.204-.68 2.31-1.91 2.31h-35.39Z"
      fill="var(--BASE_BACKGROUND)"
      stroke="var(--NEUTRAL_3)"
      strokeWidth={3}
    />
    <path
      d="M1096.48 52.244h-41.01c-.55 0-1-.448-1-1V11.687c0-1.601-1.42-3.024-3.03-3.024h-1.6c-27.59 0-49.84 22.235-49.84 49.807 0 27.394 22.43 49.807 49.84 49.807 27.59 0 49.84-22.236 49.84-49.807 0-1.029 0-2.057-.15-2.927-.02-.065-.02-.13-.02-.197-.05-1.899-1.28-3.102-3.03-3.102Z"
      fill="var(--BASE_BACKGROUND)"
      stroke="var(--NEUTRAL_3)"
      strokeWidth={3}
    />
    <g opacity={0.8}>
      <path
        d="M588.961 490.33V279.447l4.838-7.251h17.536v132.331l51.939-71.215.063-.087-.063.087c-.621.88-1.751 3.279-1.751 6.56v38.672c0 3.384 2.419 5.438 3.628 6.042-1.209.403-3.628 2.055-3.628 5.439v41.693c0 2.9 2.419 4.431 3.628 4.834-1.209.403-3.628 2.054-3.628 5.438v43.506c0 4.23 6.047 2.417 3.628 3.626-1.935.967-2.418 4.028-2.418 5.438v42.298c0 2.417 5.442 3.625 2.418 4.834-2.418.967-2.62 2.82-2.418 3.625-.202 13.495-.484 40.969 0 42.902.483 1.934 2.62 3.223 3.628 3.626h-14.513V579.76l-9.674-25.983v-51.362c.201-1.409.12-4.954-1.815-7.855-1.935-2.9-5.24-4.028-6.651-4.23h-44.747Z"
        fill="var(--NEUTRAL_4)"
      />
      <path
        d="M603.474 553.777h32.653v-31.421l-32.653 31.421ZM1010.43 388.816h18.14v86.408h-25.4v-76.136c0-7.734 4.84-10.071 7.26-10.272ZM842.928 567.675v-90.034c.807.806 3.387 3.021 7.257 5.438 3.87 2.417 11.69 5.036 15.117 6.043v97.284c-.605-.201-4.004-.527-10.885-4.229-7.86-4.23-10.682-11.683-11.489-14.502ZM1277.7 492.143v18.732h-18.75l18.75-18.732ZM893.117 357.395v-34.442l34.467 34.442h-34.467ZM1147.69 574.321c-8.22-9.184-21.97-7.452-27.82-5.438l30.84-30.817.61 9.668 4.83 2.417 4.84 4.23 2.42.604c4.03-2.014 12.22-6.163 12.7-6.646l4.23-1.209 3.03 2.417 12.69 21.753 1.21 3.021-2.42 3.626-13.9 9.064v8.459h-24.8c.61-3.223-.24-11.964-8.46-21.149ZM244.897 403.922h26.606V591.24h-26.606V403.922Z"
        fill="var(--NEUTRAL_4)"
      />
      <path
        d="m261.223 577.947 73.167-73.115v79.762c0 5.317 4.031 7.855 6.047 8.459h-79.214v-15.106ZM370.066 521.147h-8.931a8 8 0 0 0-8 8v11.336l16.931-19.336ZM371.276 571.904h14.978a8 8 0 0 0 8-8v-18.587l-22.978 26.587ZM131.216 319.327v181.88h16.932c7.256 0 8.264 4.834 7.86 7.251v68.28c0 8.218-5.24 9.064-7.86 8.46h-16.932v7.251h33.863V310.263h-26.002c-6.772 0-8.062 6.043-7.861 9.064ZM113.864 540.98c0 9.113-5.044 13.669-13.528 18.621l-1.835 1.188-1.834-.99c-8.484-4.952-10.802-7.835-10.802-10.252l27.999-22.631v14.064ZM548.448 494.56l-.451.451c-.614.802-1.363 2.548-1.363 5.592v53.174l-9.675 25.983v12.085h-39.305c2.016-1.41 6.773-4.834 9.675-7.251 2.903-2.417 4.031-6.244 4.233-7.856v-30.212c.484-4.351-3.83-7.856-6.047-9.064l42.482-42.451c.166-.217.322-.365.451-.451Z"
        fill="var(--NEUTRAL_4)"
      />
      <path
        d="M497.654 397.88c0-4.834 4.838-6.848 7.256-7.251h8.466v161.335c-.605-3.021-2.298-10.151-4.233-14.502-1.935-4.351-8.465-6.244-11.489-6.647V397.88ZM180.309 300.595c-4.354 4.834-21.567 8.46-29.629 9.668h70.143c2.822-1.812 9.917-5.075 13.303-8.459 3.386-3.384 5.442-10.272 4.837-12.689H188.17c-.806 1.812-3.507 6.646-7.861 11.48Z"
        fill="var(--NEUTRAL_4)"
      />
      <g opacity={0.2}>
        <path
          d="M475.611 555.106v16.342M438.443 547.83v27.922c0 8.837 7.164 16 16 16h41.345c8.837 0 16-7.163 16-16V547.83c0-8.836-7.163-16-16-16h-41.345c-8.836 0-16 7.164-16 16ZM456.284 530.345v-8.762c0-8.836 7.163-16 16-16h5.664c8.836 0 16 7.164 16 16v8.762M874.425 378.423h37.663m-37.663 12.876h37.663m-37.663 12.876h37.663"
          stroke="var(--BRAND_1)"
          strokeWidth={3}
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M892.761 322.463h-31.672a5 5 0 0 0-5 5v89.045a5 5 0 0 0 4.999 5h61.859a5 5 0 0 0 5-5v-58.884m-35.186-35.161 35.186 35.161m-35.186-35.161v30.161a5 5 0 0 0 5 5h30.186"
          stroke="var(--BRAND_1)"
          strokeWidth={3}
          strokeLinejoin="round"
        />
        <path d="M0 592.449h1317" stroke="var(--BRAND_1)" strokeWidth={3} />
        <path
          d="M651.555 579.119c.187.375.187.937.187 1.311l.375 9.55a3.358 3.358 0 0 1-3.373 3.371H540.058a3.358 3.358 0 0 1-3.373-3.371v-9.737c0-.375 0-.936.188-1.311l9.182-24.53c0-.188 0-.375.187-.562v-52.806c0-5.431 4.31-9.925 9.744-9.925h76.455c5.435 0 9.745 4.494 9.745 9.925v52.806c0 .187.187.374.187.562l9.182 24.717ZM551.302 582.854h86.012M587.276 507.565h13.876"
          stroke="var(--BRAND_1)"
          strokeWidth={3}
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M946.987 476.152c0 1.785-1.066 3.716-3.526 5.685-2.445 1.957-6.076 3.785-10.695 5.349-9.224 3.122-22.063 5.08-36.314 5.08-14.251 0-27.09-1.958-36.314-5.08-4.619-1.564-8.25-3.392-10.695-5.349-2.46-1.969-3.526-3.9-3.526-5.685 0-1.786 1.066-3.717 3.526-5.685 2.445-1.957 6.076-3.786 10.695-5.35 9.224-3.122 22.063-5.079 36.314-5.079 14.251 0 27.09 1.957 36.314 5.079 4.619 1.564 8.25 3.393 10.695 5.35 2.46 1.968 3.526 3.899 3.526 5.685ZM948.487 507.693c0 9.049-23.297 16.385-52.035 16.385-28.738 0-52.035-7.336-52.035-16.385M948.487 539.644c0 9.954-23.297 18.023-52.035 18.023-28.738 0-52.035-8.069-52.035-18.023"
          stroke="var(--BRAND_1)"
          strokeWidth={3}
        />
        <path
          d="M844.417 486.705v80.893c0 13.067 23.297 23.659 52.035 23.659 28.738 0 52.035-10.592 52.035-23.659v-80.893"
          stroke="var(--BRAND_1)"
          strokeWidth={3}
          strokeLinecap="round"
        />
        <path d="M1103.64 591.752c0-14.628 11.84-26.459 26.48-26.459 14.42 0 26.26 12.046 26.48 26.459" stroke="var(--BRAND_1)" strokeWidth={3} />
        <path
          d="M1181.13 591.712c0-2.2-.12-4.171-.35-6.079-.15-1.26.44-2.507 1.55-3.123l11.71-6.502c2.37-1.29 3.23-4.087 2.15-6.668l-12.91-21.726c-1.25-2.078-3.9-2.952-6.41-2.233a2.3 2.3 0 0 0-.51.216l-12.05 6.67c-1.03.572-2.29.469-3.18-.307-.37-.318-.73-.631-1.1-.905-2.37-2.151-4.74-4.087-7.96-5.592-.65-.216-1.08-1.076-1.08-1.721v-8.39c0-5.162-4.31-9.465-9.47-9.465h-22.39c-5.17 0-9.47 4.303-9.47 9.465v8.605c0 .582-.35 1.163-.89 1.587-.12.094-.26.166-.39.231-3.13 1.491-5.45 3.395-7.98 5.711-.24.244-.52.488-.81.732-.92.762-2.23.822-3.27.232l-10.77-6.127c-4.09-2.151-6.89-.645-8.18 1.506l-12.27 21.081c-.65 1.29-.86 2.796-.43 4.087.43 1.29 1.29 2.581 2.58 3.227l11.49 6.479c1.11.628 1.69 1.891 1.54 3.16-.22 1.784-.33 3.707-.33 5.849"
          stroke="var(--BRAND_1)"
          strokeWidth={3}
          strokeLinejoin="round"
        />
        <path
          d="m100.336 559.601-.757-1.296-.03.018-.029.019.816 1.259Zm-1.835 1.188-.712 1.32.782.423.746-.484-.816-1.259Zm-1.834-.99-.756 1.295.021.013.022.012.713-1.32Zm-13.529-32.883-.602-1.373-.898.393v.98h1.5Zm15.363-6.735.602-1.373-.602-.265-.602.265.602 1.373Zm15.363 6.735h1.5v-.98l-.898-.393-.602 1.373Zm-1.5 14.065c0 4.238-1.159 7.319-3.253 9.932-2.141 2.671-5.321 4.935-9.532 7.392l1.513 2.591c4.274-2.494 7.858-4.985 10.36-8.107 2.549-3.181 3.912-6.935 3.912-11.808h-3ZM99.52 558.342l-1.834 1.189 1.63 2.517 1.835-1.188-1.631-2.518Zm-.306 1.128-1.835-.991-1.425 2.64 1.835.99 1.425-2.639Zm-1.79-.966c-4.211-2.458-7.391-4.722-9.532-7.393-2.094-2.613-3.254-5.694-3.254-9.932h-3c0 4.873 1.363 8.627 3.913 11.808 2.502 3.122 6.086 5.613 10.36 8.107l1.512-2.59Zm-12.786-17.325v-14.263h-3v14.263h3Zm-.897-12.889 15.362-6.735-1.204-2.747-15.363 6.735 1.205 2.747Zm14.158-6.735 15.363 6.735 1.204-2.747-15.362-6.735-1.205 2.747Zm14.465 5.361v14.065h3v-14.065h-3ZM100.076 500.724l-1.4.537.37.963h1.03v-1.5Zm-5.374-14.003-1.416.497.007.021.008.02 1.4-.538Zm52.079 12.503h-46.705v3h46.705v-3Zm-45.304.962-5.375-14.002-2.8 1.075 5.374 14.002 2.801-1.075Zm-5.36-13.961c-1.369-3.899-5.377-6.171-9.57-6.171v3c3.22 0 5.884 1.729 6.74 4.164l2.83-.993Zm-9.57-6.171H50.035v3h36.512v-3Zm-36.512 0c-5.493 0-10.025 3.86-10.025 9.168h3c0-3.36 2.881-6.168 7.025-6.168v-3Zm-10.025 9.168v86.68h3v-86.68h-3Zm0 86.68c0 5.141 4.717 9.168 10.025 9.168v-3c-3.958 0-7.025-2.975-7.025-6.168h-3Zm10.025 9.168h96.932v-3H50.035v3Zm96.932 0c5.308 0 10.025-4.027 10.025-9.168h-3c0 3.193-3.067 6.168-7.025 6.168v3Zm10.025-9.168v-67.511h-3v67.511h3Zm0-67.511c0-5.161-4.739-9.167-10.211-9.167v3c4.166 0 7.211 2.994 7.211 6.167h3Z"
          fill="var(--BRAND_1)"
        />
        <path
          d="M334.65 507.08v79.167a6 6 0 0 0 6 6h65.805a6 6 0 0 0 6-6V483.851a6 6 0 0 0-6-6h-42.56a6 6 0 0 0-4.241 1.756l-23.245 23.228a6 6 0 0 0-1.759 4.245Z"
          stroke="var(--BRAND_1)"
          strokeWidth={3}
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M353.482 527.431v38.017a6 6 0 0 0 6 6h30.123a6 6 0 0 0 6-6v-38.017a6 6 0 0 0-5.999-6h-30.124a6 6 0 0 0-6 6ZM354.473 546.192h40.637M374.791 522.916v47.542M137.017 310.667c8.858.171 28.823-1.123 37.818-7.66 7.946-5.775 10.627-11.443 11.145-14.329.097-.544.546-.992 1.098-.992h50.855c.461 0 .867.314.937.769 1.212 7.87-6.536 22.212-23.15 22.212h-78.703Z"
          stroke="var(--BRAND_1)"
          strokeWidth={3}
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M130.883 488.746v-173.28a5 5 0 0 1 5-5h99.522a5 5 0 0 1 5 5v45.513M165.573 317.399v185.708M166.564 324.332h19.823m-19.823 0h19.823M144.759 351.074h19.823m-19.823 0h19.823M149.219 364.445h15.859m-15.859 0h15.859M132.666 395.789h19.823m-19.823 0h19.823M131.821 409.965h20.559m-20.559 0h20.559M132.288 423.662h15.858m-15.858 0h15.858M167.737 440.504h19.823m-19.823 0h19.823M152.38 453.471h36.281m-36.281 0h36.281M154.799 466.765h29.025m-29.025 0h29.025M151.202 337.703h45.593m-45.593 0h45.593M497.935 490.232v-92.549a6 6 0 0 1 6-6h65.309a6 6 0 0 1 6 6v70.19M532.215 409.532h27.752M532.215 424.467h27.752M532.215 438.969h27.752M532.215 453.471h27.752M532.215 482.475h27.752M532.215 467.973h27.752"
          stroke="var(--BRAND_1)"
          strokeWidth={3}
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path d="M513.98 402.714V527.19M610.73 286.698v124.475" stroke="var(--BRAND_1)" strokeWidth={3} strokeLinecap="round" />
        <rect x={622.824} y={287.906} width={54.421} height={10.877} rx={2.75} stroke="var(--BRAND_1)" strokeWidth={2.75} />
        <rect x={622.824} y={310.868} width={54.421} height={10.877} rx={2.75} stroke="var(--BRAND_1)" strokeWidth={2.75} />
        <path
          d="M663.942 333.829h-38.368a2.75 2.75 0 0 0-2.75 2.75v5.377a2.75 2.75 0 0 0 2.75 2.75h35.345M661.523 356.791h-35.949a2.75 2.75 0 0 0-2.75 2.75v5.376a2.75 2.75 0 0 0 2.75 2.75h35.949"
          stroke="var(--BRAND_1)"
          strokeWidth={2.75}
        />
        <path
          opacity={0.7}
          d="M661.523 379.752h-35.949a2.75 2.75 0 0 0-2.75 2.75v5.377a2.75 2.75 0 0 0 2.75 2.75h35.949"
          stroke="var(--BRAND_1)"
          strokeWidth={2.75}
        />
        <path
          opacity={0.5}
          d="M661.523 402.714h-35.949a2.75 2.75 0 0 0-2.75 2.75v5.376a2.75 2.75 0 0 0 2.75 2.75h35.949M661.523 425.676h-35.949a2.75 2.75 0 0 0-2.75 2.75v5.376a2.75 2.75 0 0 0 2.75 2.75h35.949"
          stroke="var(--BRAND_1)"
          strokeWidth={2.75}
        />
        <path
          opacity={0.4}
          d="M661.523 448.637h-35.949a2.75 2.75 0 0 0-2.75 2.75v5.376a2.75 2.75 0 0 0 2.75 2.75h35.949"
          stroke="var(--BRAND_1)"
          strokeWidth={2.75}
        />
        <path
          opacity={0.3}
          d="M661.523 471.599h-35.949a2.75 2.75 0 0 0-2.75 2.75v5.376a2.75 2.75 0 0 0 2.75 2.75h35.949"
          stroke="var(--BRAND_1)"
          strokeWidth={2.75}
        />
        <path
          d="M588.602 468.959v-190.13a6 6 0 0 1 6-6h88.107a6 6 0 0 1 6 6v46.035M1208.01 480.68v93.977c0 3.314 2.69 6 6 6h75.22c3.32 0 6-2.686 6-6V480.68c0-3.314-2.68-6-6-6h-75.22c-3.31 0-6 2.686-6 6Z"
          stroke="var(--BRAND_1)"
          strokeWidth={3}
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M1225.36 508.317v-14.78c0-2.21 1.79-4 4-4h44.53c2.21 0 4 1.79 4 4v14.78a4 4 0 0 1-4 4h-44.53a4 4 0 0 1-4-4ZM1224.36 529.145v-3.943c0-.552.45-1 1-1h3.95c.55 0 1 .448 1 1v3.943c0 .552-.45 1-1 1h-3.95c-.55 0-1-.448-1-1ZM1256.08 525.202v3.943c0 .552.45 1 1 1h3.95c.55 0 1-.448 1-1v-3.943c0-.552-.45-1-1-1h-3.95c-.55 0-1 .448-1 1ZM1271.94 525.202v3.943c0 .552.45 1 1 1h3.95c.55 0 1-.448 1-1v-3.943c0-.552-.45-1-1-1h-3.95c-.55 0-1 .448-1 1ZM1240.22 529.145v-3.943c0-.552.45-1 1-1h3.95c.55 0 1 .448 1 1v3.943c0 .552-.45 1-1 1h-3.95c-.55 0-1-.448-1-1ZM1224.36 547.963v-3.943c0-.552.45-1 1-1h3.95c.55 0 1 .448 1 1v3.943c0 .552-.45 1-1 1h-3.95c-.55 0-1-.448-1-1ZM1256.08 544.02v3.943c0 .552.45 1 1 1h3.95c.55 0 1-.448 1-1v-3.943c0-.552-.45-1-1-1h-3.95c-.55 0-1 .448-1 1ZM1271.94 544.02v3.943c0 .552.45 1 1 1h3.95c.55 0 1-.448 1-1v-3.943c0-.552-.45-1-1-1h-3.95c-.55 0-1 .448-1 1ZM1240.22 547.963v-3.943c0-.552.45-1 1-1h3.95c.55 0 1 .448 1 1v3.943c0 .552-.45 1-1 1h-3.95c-.55 0-1-.448-1-1ZM1224.36 566.781v-3.942c0-.552.45-1 1-1h3.95c.55 0 1 .448 1 1v3.942c0 .553-.45 1-1 1h-3.95c-.55 0-1-.447-1-1ZM1256.08 562.839v3.942c0 .553.45 1 1 1h3.95c.55 0 1-.447 1-1v-3.942c0-.553-.45-1-1-1h-3.95c-.55 0-1 .447-1 1ZM1271.94 562.839v3.942c0 .553.45 1 1 1h3.95c.55 0 1-.447 1-1v-3.942c0-.553-.45-1-1-1h-3.95c-.55 0-1 .447-1 1ZM1240.22 566.781v-3.942c0-.553.45-1 1-1h3.95c.55 0 1 .447 1 1v3.942c0 .553-.45 1-1 1h-3.95c-.55 0-1-.447-1-1ZM662.207 377.885v-40.206a6 6 0 0 1 6-6h148.07a6 6 0 0 1 6 6v40.206a6 6 0 0 1-6 6h-148.07a6 6 0 0 1-6-6ZM769.251 357.566h27.752"
          stroke="var(--BRAND_1)"
          strokeWidth={3}
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <ellipse cx={698.384} cy={358.076} rx={3.965} ry={3.962} stroke="var(--BRAND_1)" strokeWidth={3} />
        <path
          d="M662.207 430.378v-40.206a6 6 0 0 1 6-6h148.07a6 6 0 0 1 6 6v40.206a6 6 0 0 1-6 6h-148.07a6 6 0 0 1-6-6ZM769.251 410.06h27.752"
          stroke="var(--BRAND_1)"
          strokeWidth={3}
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <ellipse cx={698.384} cy={410.57} rx={3.965} ry={3.962} stroke="var(--BRAND_1)" strokeWidth={3} />
        <path
          d="M662.207 482.344v-40.206a6 6 0 0 1 6-6h148.07a6 6 0 0 1 6 6v40.206a6 6 0 0 1-6 6h-148.07a6 6 0 0 1-6-6ZM769.251 462.025h27.752"
          stroke="var(--BRAND_1)"
          strokeWidth={3}
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <ellipse cx={698.384} cy={462.535} rx={3.965} ry={3.962} stroke="var(--BRAND_1)" strokeWidth={3} />
        <path
          d="M662.207 534.309v-40.206a6 6 0 0 1 6-6h148.07a6 6 0 0 1 6 6v40.206a6 6 0 0 1-6 6h-148.07a6 6 0 0 1-6-6ZM769.251 513.991h27.752"
          stroke="var(--BRAND_1)"
          strokeWidth={3}
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <ellipse cx={698.384} cy={514.501} rx={3.965} ry={3.962} stroke="var(--BRAND_1)" strokeWidth={3} />
        <path
          d="M662.207 586.275v-40.206a6 6 0 0 1 6-6h148.07a6 6 0 0 1 6 6v40.206a6 6 0 0 1-6 6h-148.07a6 6 0 0 1-6-6ZM769.251 565.956h27.752"
          stroke="var(--BRAND_1)"
          strokeWidth={3}
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <ellipse cx={698.384} cy={566.466} rx={3.965} ry={3.962} stroke="var(--BRAND_1)" strokeWidth={3} />
        <path
          d="M964.879 585.752V481.87a6 6 0 0 1 6-6h59.361c3.32 0 6 2.686 6 6v103.882c0 3.314-2.68 6-6 6h-59.361a6 6 0 0 1-6-6ZM992.631 492.708h16.849"
          stroke="var(--BRAND_1)"
          strokeWidth={3}
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <ellipse cx={1000.56} cy={576.4} rx={3.965} ry={3.962} stroke="var(--BRAND_1)" strokeWidth={3} />
        <path d="M1017.41 364.94h70.37v-23.275h-70.37v23.275Z" stroke="var(--BRAND_1)" strokeWidth={3} strokeLinecap="round" strokeLinejoin="round" />
        <path
          d="m1056.37 351.256 5.56 19.433 5.24-4.236 8.52 8.432 4.02-3.536-8.51-8.432 4.76-4.365-19.59-7.296Z"
          fill="var(--BASE_FOREGROUND)"
          stroke="var(--BRAND_1)"
          strokeWidth={3}
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M243.937 591.752V405.568a2 2 0 0 1 2-2h88.673a2 2 0 0 1 2 2v64.855"
          stroke="var(--BRAND_1)"
          strokeWidth={3}
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <g opacity={0.4} stroke="var(--BRAND_1)" strokeWidth={3}>
          <rect x={282.073} y={418.716} width={11.512} height={11.502} rx={1.5} />
          <rect x={302.632} y={418.716} width={23.606} height={11.502} rx={1.5} />
          <rect x={282.073} y={440.469} width={11.512} height={11.502} rx={1.5} />
          <rect x={302.632} y={440.469} width={23.606} height={11.502} rx={1.5} />
          <rect x={282.073} y={462.222} width={11.512} height={11.502} rx={1.5} />
          <rect x={302.632} y={462.222} width={23.606} height={11.502} rx={1.5} />
          <rect x={282.073} y={483.975} width={11.512} height={11.502} rx={1.5} />
          <rect x={302.632} y={483.975} width={23.606} height={11.502} rx={1.5} />
          <rect x={282.073} y={505.728} width={11.512} height={11.502} rx={1.5} />
          <rect x={302.632} y={505.728} width={23.606} height={11.502} rx={1.5} />
          <rect x={282.073} y={527.481} width={11.512} height={11.502} rx={1.5} />
          <rect x={302.632} y={527.481} width={23.606} height={11.502} rx={1.5} />
          <rect x={282.073} y={549.234} width={11.512} height={11.502} rx={1.5} />
          <rect x={302.632} y={549.234} width={23.606} height={11.502} rx={1.5} />
        </g>
        <path
          d="M269.212 582.838V414.462M1005.02 475.375v-81.159c0-3.314 2.69-6 6-6h82.66c3.31 0 6 2.686 6 6v131.176M1028.81 398.616v76.264M1041.69 407.53h19.33M1070.44 407.53h19.32M1041.69 423.377h19.33M1070.44 423.377h19.32M1041.69 439.224h19.33M1070.44 439.224h19.32M1041.69 455.071h19.33M1070.44 455.071h19.32M1041.69 470.918h19.33M1070.44 470.918h19.32M1052.1 486.765h8.92M1070.44 486.765h19.32M1052.1 502.612h8.92M1070.44 502.612h19.32M1052.1 518.459h8.92M1070.44 518.459h19.32M1052.1 534.306h8.92M1070.44 534.306h19.32"
          stroke="var(--BRAND_1)"
          strokeWidth={3}
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
    </g>
  </svg>
);
