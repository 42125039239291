import { COLOR, GAP } from '@constants';
import { css } from '@emotion/css';

export const styles = {
  container: css`
    display: flex;
    flex-direction: column;
    gap: ${GAP.XS};
    padding-left: ${GAP.S};
  `,
  step: css`
    background-color: ${COLOR.BASE_BACKGROUND};
  `,
  activeStep: css`
    background-color: ${COLOR.NEUTRAL_4}!important;
    :hover {
      li {
        background-color: ${COLOR.NEUTRAL_4}!important;
      }
    }
  `
};
