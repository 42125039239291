import React from 'react';

import type { SvgProps } from '@components/elements/Icon/SVG/types';

const AppsSvg = ({ ...props }: SvgProps) => (
  <svg width={211} height={230} fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      d="M174.162 64.356c-4.427-27.886-28.035-56.363-79.53-50.609C42.697 19.06 30.008 59.93 27.5 76.603c-5.039 33.494 39.543 39.543 44.854 8.853 3.655-21.122 15.345-30.985 28.329-32.018 12.985-1.033 30.1-.443 34.379 26.559 3.788 23.903-26.263 35.411-39.837 45.592-12.394 9.296-7.674 30.395-5.903 42.199 1.77 11.804 6.64 12.099 12.542 11.361l21.1-2.951c9.148-1.328 8.705-9.148 7.819-14.754l-.038-.242c-1.164-7.349-2.273-14.349 15.678-26.317 25.122-16.749 32.166-42.642 27.74-70.529Z"
      fill="var(--NEUTRAL_4)"
    />
    <circle cx={180.381} cy={28.9} r={3.042} stroke="var(--BRAND_1)" strokeLinecap="round" strokeLinejoin="round" />
    <path d="M169.734 10.647h-6.084M166.692 7.605v6.084" stroke="var(--BRAND_1)" strokeLinecap="round" strokeLinejoin="round" />
    <circle cx={7.605} cy={57.165} stroke="var(--BRAND_1)" strokeLinecap="round" strokeLinejoin="round" r={6.084} />
    <circle cx={16.109} cy={73.771} stroke="var(--BRAND_1)" strokeLinecap="round" strokeLinejoin="round" r={2.282} />
    <path d="M24.095 6.205h9.368M28.778 1.521v9.368" stroke="var(--BRAND_1)" strokeLinecap="round" strokeLinejoin="round" />
    <circle cx={9.887} cy={18.379} stroke="var(--BRAND_1)" strokeLinecap="round" strokeLinejoin="round" r={3.803} />
    <path d="M153.384 206.107h9.368M158.067 210.791v-9.368" stroke="var(--BRAND_1)" strokeLinecap="round" strokeLinejoin="round" />
    <circle r={3.042} transform="matrix(1 0 0 -1 147.541 222.073)" stroke="var(--BRAND_1)" strokeLinecap="round" strokeLinejoin="round" />
    <path
      d="M134.031 209.765c-1.374-9.789-4.164-17.287-20.14-17.287-7.23 0-17.337 4.746-17.337 17.583 0 12.836 7.525 18.222 17.337 18.222 11.951 0 21.662-7.673 20.14-18.518Z"
      fill="var(--NEUTRAL_4)"
    />
    <path
      opacity={0.2}
      d="M116.338 80.822 56.3 94.017a5.63 5.63 0 0 1-6.67-4.135l60.419-12.61a4 4 0 0 0 3.1-4.729l-10.71-51.616a2.118 2.118 0 0 1 2.074-2.548 4.236 4.236 0 0 1 4.156 3.415l10.735 54.346a4.001 4.001 0 0 1-3.066 4.681Z"
      fill="var(--BRAND_1)"
    />
    <path
      d="m96.315 12.363-64.12 13.533a4 4 0 0 0-3.077 4.784L41.69 87.068a4 4 0 0 0 4.717 3.046l63.342-13.141a4 4 0 0 0 3.104-4.73l-11.797-56.78a4 4 0 0 0-4.742-3.1Z"
      fill="var(--BASE_BACKGROUND)"
      stroke="var(--BRAND_1)"
      strokeWidth={1.5}
    />
    <path
      opacity={0.2}
      d="m177.174 140.025-52.959-17.438a5.053 5.053 0 0 1-3.262-6.249l52.978 17.429a5 5 0 0 0 6.329-3.239l13.919-43.915a1.968 1.968 0 0 1 2.651-1.214 3.936 3.936 0 0 1 2.184 4.862l-15.533 46.596a5 5 0 0 1-6.307 3.168Z"
      fill="var(--BRAND_1)"
    />
    <path
      d="M55.976 72.029c-1.889-.59-2.656-12.935-2.803-19.034-.072-2.213-10.92-9-10.92-12.1 0-3.098 51.79-13.869 53.266-12.54 1.475 1.327-7.082 38.067-10.919 39.985-3.836 1.918-14.754-7.673-18.148-7.082-3.394.59-8.115 11.508-10.476 10.77Z"
      fill="var(--BRAND_1)"
      fillOpacity={0.15}
      stroke="var(--BRAND_1)"
    />
    <path
      d="M52.566 52.967c.368 4.858 1.104 12.986 1.703 16.779.518 3.285 2.297 3.49 4.237.625 2.118-3.129 5.378-7.197 8.474-10.952 3.097-3.756 25.206-25.943 22.762-27.351-1.904-1.097-23.265 11.229-36.224 19.053a1.977 1.977 0 0 0-.952 1.846Z"
      fill="var(--BASE_BACKGROUND)"
      stroke="var(--BRAND_1)"
    />
    <path
      d="m193.966 75.712-57.089-18.64a4 4 0 0 0-5.058 2.605l-15.839 50.451a4 4 0 0 0 2.562 4.996l56.293 18.595a4 4 0 0 0 5.054-2.545l16.634-50.406a4 4 0 0 0-2.557-5.056Z"
      fill="var(--BASE_BACKGROUND)"
      stroke="var(--BRAND_1)"
      strokeWidth={1.5}
    />
    <path
      opacity={0.2}
      d="m108.812 162.965-33.243 7.253a5.353 5.353 0 0 1-6.22-3.537l29.621-8.194a7 7 0 0 0 5.03-7.951l-3.842-21.988a1.895 1.895 0 0 1 1.866-2.221 3.789 3.789 0 0 1 3.707 3.002l6.141 28.897a4 4 0 0 1-3.06 4.739Z"
      fill="var(--BRAND_1)"
    />
    <path
      d="m96.61 120.667-36.388 7.709a4 4 0 0 0-3.075 4.78l7.067 31.812a4 4 0 0 0 4.72 3.048l35.952-7.486a4 4 0 0 0 3.102-4.727l-6.631-32.033a4 4 0 0 0-4.746-3.103Z"
      fill="var(--BASE_BACKGROUND)"
      stroke="var(--BRAND_1)"
    />
    <path
      opacity={0.1}
      d="M76.51 153.626c.761.76 1.826 3.498 9.127 2.281 4.563-1.521 6.845-3.802 6.845-6.084-1.217-4.259-5.577-5.831-7.605-6.084.76-.761 2.13-2.738 1.52-4.563-.76-2.282-2.281-4.563-5.323-3.803-2.282.57-4.237 2.282-3.803 5.324.609 4.259 2.282 3.802 3.803 3.802 0 0-5.324 5.324-4.563 9.127Z"
      fill="var(--BRAND_1)"
    />
    <circle cx={82.536} cy={144.327} r={12.029} stroke="var(--BRAND_1)" />
    <circle cx={81.769} cy={140.236} stroke="var(--BRAND_1)" r={4.603} />
    <path d="M92.659 150.262a8.31 8.31 0 0 0-16.021 4.339" stroke="var(--BRAND_1)" />
    <path
      d="m139.249 83.54 10.517 3.33a1 1 0 0 0 1.244-.62l3.655-10.348a1 1 0 0 1 1.255-.617l13.167 4.331a1 1 0 0 1 .633 1.276l-3.51 10.17a.999.999 0 0 0 .621 1.272l10.1 3.457a1 1 0 0 1 .631 1.242l-3.442 11.105a1 1 0 0 1-1.277.651l-10.716-3.632a.999.999 0 0 0-1.269.629l-3.396 10.141a.999.999 0 0 1-1.273.628l-12.52-4.31a1 1 0 0 1-.63-1.239l2.92-9.505a1 1 0 0 0-.657-1.248l-10.454-3.281a1 1 0 0 1-.65-1.267l3.799-11.524a1 1 0 0 1 1.252-.64Z"
      fill="var(--BRAND_1)"
      fillOpacity={0.1}
      stroke="var(--BRAND_1)"
    />
  </svg>
);

export default AppsSvg;
