import { GAP } from '@constants';
import { css } from '@emotion/css';

export const styles = {
  content: {
    loadingContainer: css`
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;
    `,
    loadingText: css`
      padding-top: ${GAP.XS};
    `
  }
};
