import { ApiModel } from '@cyferd/client-engine';
import type { GeneralModel } from '@models';
import type { UserState } from '@models/general';

export const getInitialState = (): UserState => null;

export const reducer = (state: GeneralModel.UserState = getInitialState(), action: GeneralModel.Action) => {
  const { type, payload } = action;
  switch (type) {
    case ApiModel.TriggerActionType.DISPATCH_SET_DATA:
      if (payload?.section !== ApiModel.ApiSetDataSections.USER) return state;
      return { ...payload, organisation: payload?.organisation ?? 'Cyferd' };
    case ApiModel.TriggerActionType.DISPATCH_SET_USER:
      return { ...payload, organisation: payload?.organisation ?? 'Cyferd' };
    default:
      return state;
  }
};
