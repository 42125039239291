import { css } from '@emotion/react';

import { COLOR, FONT_SIZE, FONT_WEIGHT, GAP, RADIUS, TRANSITION_SPEED } from '@constants';

const textOverflowStyle = `
  word-break: break-word;
  display: -webkit-box;
  overflow: hidden;
  text-overflow: ellipsis;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
`;

export const styles = {
  content: css`
    display: flex;
    align-items: center;
    gap: ${GAP.S};
    flex: 1;
  `,
  bodyContainer: css`
    display: flex;
    align-items: flex-start;
    justify-content: space-bewteen;
    gap: ${GAP.S};
    flex: 1;
  `,
  textContainer: css`
    flex: 1;
    display: flex;
    flex-direction: column;
    gap: ${GAP.XXS};
  `,
  card: css`
    cursor: pointer;
    display: flex;
    overflow: hidden;
    background-color: ${COLOR.TOP_BACKGROUND};
    border-bottom: 1px ${COLOR.NEUTRAL_4} solid;
    border-radius: ${RADIUS.M};
    width: 100%;
    padding: ${GAP.M} ${GAP.L};
  `,
  unreadCard: css`
    background-color: ${COLOR.NEUTRAL_5};
  `,
  dot: css`
    width: 12px;
    height: 12px;
    border-radius: 50%;
    transition: background-color ${TRANSITION_SPEED} ease-in-out;
    border: 1px ${COLOR.NEUTRAL_3} solid;
  `,
  unreadDot: css`
    cursor: pointer;
    background-color: ${COLOR.RD_1};
    border-color: ${COLOR.RD_1};
  `,
  imageUrl: {
    base: css`
      margin-right: ${GAP.L};
      margin-top: ${GAP.XXS};
    `,
    icon: css`
      display: flex;
      span {
        font-size: 20px;
        font-weight: 800;
      }
      svg {
        width: 20px;
        height: 20px;
      }
    `,
    image: css`
      width: ${FONT_SIZE.XL};
      max-height: ${FONT_SIZE.XL};
    `
  },
  title: css`
    ${textOverflowStyle}
    font-weight: ${FONT_WEIGHT.SEMI_BOLD};
    font-size: ${FONT_SIZE.S};
    color: ${COLOR.NEUTRAL_1};
  `,
  subtitle: css`
    ${textOverflowStyle}
    font-weight: ${FONT_WEIGHT.NORMAL};
    font-size: ${FONT_SIZE.XS};
    color: ${COLOR.NEUTRAL_2};
  `,
  actions: css`
    margin-top: ${GAP.XXS};
  `
};
