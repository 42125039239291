import { GAP } from '@constants';
import { css } from '@emotion/react';

export const styles = {
  container: css`
    height: 100%;
    overflow: hidden;
    display: flex;
    flex-flow: column;
  `,
  modalContainer: css`
    height: calc(100vh - 160px);
    padding: ${GAP.XS} 0 ${GAP.M} 0;
    gap: ${GAP.M};
  `
};
