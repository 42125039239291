import { arrayToTsv } from '@utils';

const notUndefined = val => typeof val !== 'undefined';

export const onCopy = (e: ClipboardEvent) => {
  e.preventDefault();
  const items = document.querySelectorAll('[data-inselection="true"]');
  if (items) {
    const rows = Array.from(items).reduce((acc, item) => {
      const rowIndex = item.getAttribute('data-rowindex');
      const colIndex = item.getAttribute('data-colindex');
      const newAcc = [...acc];
      if (rowIndex && colIndex) {
        // @todo: no mutations
        newAcc[rowIndex] = newAcc[rowIndex] || [];
        newAcc[rowIndex][colIndex] = item.getAttribute('data-value');
      }
      return newAcc;
    }, []);

    // Filter undefined indexes
    const cleanRows = rows.map(row => row?.filter(notUndefined)).filter(notUndefined);

    // If there's only one value, copy it raw without doing the tsv conversion
    if (cleanRows.length === 1 && cleanRows[0].length === 1) {
      return navigator.clipboard.writeText(cleanRows[0][0]);
    }

    const tsv = arrayToTsv(cleanRows);
    navigator.clipboard.writeText(tsv);
  }
};
