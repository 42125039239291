import type { ComponentProps } from 'react';
import { memo } from 'react';

import type { ViewModel } from '@cyferd/client-engine';
import { ApiModel, encodeQueryString } from '@cyferd/client-engine';

import { styles } from '../../styles';
import type { SearchValueItem } from '../../useSearch';
import { CyList } from '../../../../smart/CyList';
import { IconImage } from '../../../Icon/renderIcon';
import { COLOR, FONT_SIZE } from '@constants';
import { CTA, CTAType } from '../../../CTA';

export interface ResultListProps {
  type: ComponentProps<typeof CyList>['type'];
  density: ComponentProps<typeof CyList>['density'];
  results: ApiModel.ApiValue;
  title?: string;
  searchString?: string;
  onResultClick: ViewModel.TriggerCallback<SearchValueItem>;
}

export const ResultList = memo(({ results, title, type, density, searchString, onResultClick }: ResultListProps) => {
  const onInternalClick = (event: ApiModel.ApiRecord, meta: any) => {
    /* istanbul ignore next line @todo */
    const href = (() => {
      if (event.collectionId === ApiModel.ApiEntity.FAVORITE) return `/${event.view?.id}${encodeQueryString(event.view?.input)}`;
      if (event.collectionId === ApiModel.ApiEntity.VIEW) return event.id;
      if (event.collectionId === ApiModel.ApiEntity.FLOW) return `/FLOW?id=${event.id}`;
      if (event.collectionId === ApiModel.ApiEntity.TAG) return `/APP_HOME?id=${event.id}`;
      if (event.collectionId === ApiModel.ApiEntity.ENTITY) return `/TABLE?collectionId=${event.id}&`;
      if (!event.collectionId) return `/TABLE?collectionId=${event.id}&`;
      return `/DETAIL?collectionId=${event.collectionId}&id=${event.id}`;
    })();
    return onResultClick({ record: event, href }, meta);
  };

  return (
    <div css={styles.resultsContainer}>
      <div>
        <CyList
          type={type}
          density={density}
          value={results}
          paginationHidden={true}
          searchStringHidden={true}
          advancedFiltersHidden={true}
          typeSelectorHidden={true}
          onClickItem={onInternalClick}
        />
      </div>
      <div css={styles.resultHeader}>
        <div css={styles.resultTitleContainer}>
          <IconImage
            title=""
            icon={results?.query?.recordImage}
            iconProps={{ fill: COLOR.NEUTRAL_2, size: FONT_SIZE.XM }}
            imageProps={{ css: { width: 20, height: 20, objectFit: 'cover' } }}
          />
          <h3 css={styles.resultTitle}>{title || results?.query?.name}</h3>
        </div>
        {!!results?.query?.description && <h3 css={styles.resultDescription}>{results?.query?.description}</h3>}
        {!!results?.query?.cursor?.collectionId && !Object.values(ApiModel.ApiEntity).includes(results.query.cursor.collectionId as any) && (
          <CTA
            testid="show-all"
            type={CTAType.LINK}
            label="Show all matches"
            onClick={meta =>
              onResultClick(
                {
                  href: `/TABLE${encodeQueryString({ cursor: { searchString }, collectionId: results.query.cursor.collectionId })}`,
                  record: results?.record
                },
                meta
              )
            }
          />
        )}
      </div>
    </div>
  );
});

ResultList.displayName = 'ResultList';
