import { useCallback, useContext, useMemo, useState } from 'react';

import type { TagModel } from '@cyferd/client-engine';
import { ApiModel, createUUID, getParsedActionChildren, noop } from '@cyferd/client-engine';

import { GlobalContext } from '../../../../state-mgmt/GlobalState';
import { BondEditor } from '../../../shared/BondEditor';
import type { EditorContextValue } from '../../../shared/EditorHome';
import { EditorContext } from '../../../shared/EditorHome';
import { EmptyLinkingPage } from '../../../../../@components/elements/EmptyLinkingPage';
import { LinkingPageDiagram, PredefinedDiagrams } from '../../../../../@components/elements/LinkingPageDiagram';
import { CTAType } from '@components/elements/CTA';
import { SecurityOverrideModal } from '../../../shared/SecurityOverrideModal';

export const EntityBondEditor = () => {
  const id = useMemo(createUUID, []);
  const [showModal, setShowModal] = useState(false);
  const [entity, setEntity] = useState<ApiModel.ApiRecord>(null);
  const { item } = useContext<EditorContextValue<TagModel.Tag>>(EditorContext);
  const { deps } = useContext(GlobalContext);

  const cursor = useMemo(() => ({ associationKey: 'appCollections', relatedTo: { collectionId: ApiModel.ApiEntity.TAG, id: item?.id } }), [item]);

  const onSuccess = () => deps.refresh$.next(id);

  const onEditSecurity = useCallback(collection => {
    setShowModal(true);
    setEntity(collection);
  }, []);

  const onClose = useCallback(() => {
    setShowModal(false);
    setEntity(null);
  }, []);

  return (
    <>
      {showModal && (
        <SecurityOverrideModal
          collectionName={entity?.name}
          appName={item?.name}
          associationKey="collectionApps"
          item={entity}
          detailGroupList={entity?.detailGroupList}
          entitySchema={entity?.schema}
          onClose={onClose}
          onSuccess={onSuccess}
        />
      )}
      <BondEditor
        componentId={id}
        item={item}
        cursor={cursor}
        collectionId={ApiModel.ApiEntity.ENTITY}
        additionalActions={getParsedActionChildren([
          { icon: 'add_moderator', label: 'Edit security', onClick: onEditSecurity, important: true, type: CTAType.ACTION_TERTIARY }
        ])}
        associationKey="appCollections"
        modalTitle="Unlinked collections"
        customEmptyState={
          <EmptyLinkingPage
            title="Nothing linked"
            subtitle="A collection is data structure that the admin creates by adding fields and associations"
            ctaLabel="Link collection"
            ctaOnClick={noop}
            diagram={<LinkingPageDiagram value={PredefinedDiagrams.appLinkedCollections} />}
          />
        }
      />
    </>
  );
};
