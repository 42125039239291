import type { GeneralModel } from '@cyferd/client-engine';
import { ClientEngineContext } from '@cyferd/client-engine';
import { useCallback, useContext, useMemo } from 'react';
import { IconImage } from '@components/elements/Icon/renderIcon';
import { DropdownMenu } from '@components/elements/DropdownMenu';
import { CyWrapperContext } from '@components/smart/CyWrapper';
import { COLOR, FOREGROUND_COLOR } from '@constants';
import type { OptionMenuDropdownProps } from '@components/elements/OptionMenuDropdown';
import { OptionMenuDropdown, OptionMenuDropdownType } from '@components/elements/OptionMenuDropdown';
import { styles } from './styles';

export const ViewUsageInApps = () => {
  const testid = 'view-usage-in-apps';
  const { useViewSelector } = useContext(ClientEngineContext);
  const { useAction } = useContext(CyWrapperContext);
  const globalView = useViewSelector();
  const onNavigateTo = useAction('dispatchNavigateTo');
  const onClickItem = useCallback((id: string) => onNavigateTo({ path: 'APP_HOME', qs: { id } }), [onNavigateTo]);

  const apps = useMemo(() => globalView?.apps || [], [globalView]);

  const parsedApps: OptionMenuDropdownProps['optionList'] = useMemo(
    () =>
      apps.map(app => ({
        id: app.id,
        // TODO: Remove the "|| (app as any).name" once the backend complies the globalView.apps iface
        label: app.recordTitle || (app as any).name,
        tooltip: app.recordDescription,
        image: (app.recordImage as GeneralModel.IconName) || 'apps',
        color: (app.recordColor as GeneralModel.Color.ThemeColor) || 'BRAND_1',
        onClick: () => onClickItem(app.id)
      })),
    [apps, onClickItem]
  );

  const mainApp = parsedApps[0];
  return !apps?.length ? null : (
    <div css={styles.container} data-testid={testid}>
      <div css={styles.mainApp} onClick={mainApp.onClick}>
        <div css={styles.iconContainer} style={{ backgroundColor: COLOR[mainApp.color] }}>
          <IconImage
            icon={mainApp.image}
            title={mainApp.label}
            iconProps={{ size: '20px', fill: FOREGROUND_COLOR[mainApp.color] }}
            imageProps={{ css: styles.image }}
          />
        </div>
        <div css={styles.title} data-testid={`${testid}-main-app-title`}>
          {mainApp.label}
        </div>
      </div>
      {apps?.length > 1 && (
        <DropdownMenu
          vertical={12}
          renderTrigger={({ onClick, ref, testid }) => (
            <div css={[styles.triggerContainer]} onClick={onClick} ref={ref} data-testid={testid}>
              + {apps.length - 1}
            </div>
          )}
        >
          <OptionMenuDropdown optionList={parsedApps.slice(1)} type={OptionMenuDropdownType.APPS} />
        </DropdownMenu>
      )}
    </div>
  );
};
