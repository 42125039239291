import { useCallback } from 'react';

import type { ApiModel, ViewModel } from '@cyferd/client-engine';
import { getTimeFromNow, tapOnSuccess } from '@cyferd/client-engine';

import { COLOR } from '@constants';
import { CTA, CTAType } from '../CTA';
import { IconImage } from '../Icon/renderIcon';
import { PreventClickPropagation } from '../PreventClickPropagation';
import { styles } from './styles';
import { ToolTip } from '../Tooltip';
import { useNotificationUtils } from '@utils';

export interface NotificationCardProps {
  notification: ApiModel.ApiRecord;
  onMarkAsReadSuccess?: ViewModel.TriggerCallback<void>;
  onClick: () => void;
}

export const NotificationCard = ({ notification, onClick, onMarkAsReadSuccess }: NotificationCardProps) => {
  const { onClickNotificationLink, onMarkItemAsRead } = useNotificationUtils();

  const onInternalMarkItemAsRead = useCallback(() => {
    onMarkItemAsRead(notification)
      .pipe(tapOnSuccess(() => onMarkAsReadSuccess?.()))
      .subscribe();
  }, [notification, onMarkAsReadSuccess, onMarkItemAsRead]);

  const onInternalClick = () => {
    if (!notification.read) onInternalMarkItemAsRead();
    onClick();
  };

  return (
    <div css={[styles.card, !notification.read && styles.unreadCard]} key={notification.id} data-testid="notification-card" onClick={onInternalClick}>
      <div css={styles.content}>
        <div>
          <IconImage
            icon={notification.recordImage}
            title={notification.type}
            iconProps={{ css: styles.imageUrl, size: '25px', fill: COLOR.NEUTRAL_1 }}
            imageProps={{ css: { width: '30px', height: '30px', borderRadius: '100%', objectFit: 'cover', flexShrink: 0 } }}
          />
        </div>
        <div css={styles.bodyContainer}>
          <div css={styles.textContainer}>
            <div css={styles.title}>{notification.title}</div>
            <div css={styles.subtitle}>
              {getTimeFromNow(notification.createdAt)} | {notification.type}
            </div>
            {!!notification.link?.path && (
              <PreventClickPropagation>
                <CTA
                  type={CTAType.LINK}
                  testid="link"
                  onClick={e => onClickNotificationLink(notification.link, { metaKey: e.metaKey })}
                  label={notification.link.title}
                />
              </PreventClickPropagation>
            )}
          </div>
          <PreventClickPropagation>
            <ToolTip text={notification.read && 'Mark as read'}>
              <div data-testid="mark-as-read" css={[styles.dot, !notification.read && styles.unreadDot]} onClick={onInternalMarkItemAsRead} />
            </ToolTip>
          </PreventClickPropagation>
        </div>
      </div>
    </div>
  );
};
