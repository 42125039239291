import { COLOR } from '@constants';
import { styles } from './styles';

type ChartLegendProps = {
  testid?: string;
  hidden?: boolean;
  series?: any;
  referenceLines?: any;
  direction?: 'row' | 'column';
};

export const ChartLegend = ({ hidden, series, referenceLines, direction }: ChartLegendProps) =>
  !hidden && (
    <div css={styles.container} style={{ flexDirection: direction === 'column' ? 'column' : 'row' }}>
      {series?.map((item: any, i) => (
        <div key={`serie-legend-${i}`} css={styles.item}>
          <span css={styles.mark} style={{ backgroundColor: item.color }} />
          <span>{item.label}</span>
        </div>
      ))}
      {referenceLines?.map((item: any, i) => (
        <div key={`line-legend-${i}`} css={styles.item}>
          <span css={styles.line} style={{ backgroundColor: COLOR[item.color] }} />
          <span>{item.label}</span>
        </div>
      ))}
    </div>
  );
