import { createUUID, GeneralModel } from '@cyferd/client-engine';
import { TRANS } from '@constants';
import type { JSONSchema6TypeName } from 'json-schema';

export const detailGroupMap = {
  defaultFilter: {
    id: createUUID(),
    name: 'Table view: default filters',
    image: 'table_rows_narrow',
    order: 1,
    description: 'This filter will be applied by default to the table view only'
  },
  quickFilters: {
    id: createUUID(),
    name: 'Everywhere: quick filters',
    description: 'These will be visible everywhere the collection is',
    image: 'filter_list',
    order: 2
  }
};

export const detailGroupList = Object.values(detailGroupMap);

export const isCustomFilterFormula = { $cyf_eq: ['{{event.parent.value.type}}', 'custom'] };

export const isDynamicFilterFormula = { $cyf_not: [isCustomFilterFormula] };

const getDefaultSelectedInvalidFormula = fieldName => ({
  $cyf_if: [
    {
      $cyf_and: [
        {
          $cyf_exists: [`{{event.parent.value.${fieldName}}}`]
        },
        {
          $cyf_not: [
            {
              $cyf_contains: [
                {
                  $cyf_map: [
                    {
                      $cyf_filter: [
                        '{{event.definition.metadata.optionList}}',
                        {
                          $cyf_eq: [{ $cyf_evaluate: ['{{each.hidden}}'] }, false]
                        }
                      ]
                    },
                    '{{each.value}}'
                  ]
                },
                `{{event.parent.value.${fieldName}}}`
              ]
            }
          ]
        }
      ]
    },
    'The selected value does not exist in the available options',
    null
  ]
});

const getMultipleSelectedInvalidFormula = fieldName => ({
  $cyf_if: [
    {
      $cyf_and: [
        {
          $cyf_exists: [`{{event.parent.value.${fieldName}}}`]
        },
        {
          $cyf_tostring: [
            {
              $cyf_gt: [{ $cyf_length: [`{{event.parent.value.${fieldName}}}`] }, 0]
            }
          ]
        },
        {
          $cyf_not: [
            {
              $cyf_every: [
                {
                  $cyf_map: [
                    `{{event.parent.value.${fieldName}}}`,
                    {
                      $cyf_contains: [
                        {
                          $cyf_map: [
                            {
                              $cyf_filter: [
                                '{{event.definition.items.metadata.optionList}}',
                                {
                                  $cyf_eq: [{ $cyf_evaluate: ['{{each.hidden}}'] }, false]
                                }
                              ]
                            },
                            '{{each.value}}'
                          ]
                        },
                        '{{each}}'
                      ]
                    }
                  ]
                },
                {
                  $cyf_eq: ['{{each}}', true]
                }
              ]
            }
          ]
        }
      ]
    },
    'Some of the selected values does not exist in the available options',
    null
  ]
});

const stringByDefault = {
  type: 'string' as JSONSchema6TypeName,
  format: GeneralModel.JSONSchemaFormat.STRING_OPTION_LIST,
  title: 'Chose filter to be active by default',
  info: '<p>Any changes to the field options will be reflected in the quick filter options.</p><br/><p>If a field option is removed from a field, then it will no longer be available in the quick filter. </p>',
  metadata: {
    detailOrder: 15,
    invalid: getDefaultSelectedInvalidFormula('stringByDefault')
  }
};

const multipleStringByDefault = {
  type: 'array' as JSONSchema6TypeName,
  format: GeneralModel.JSONSchemaFormat.MULTI_OPTION_LIST_ALT,
  title: 'Chose filters to be active by default',
  metadata: {
    detailOrder: 16,
    invalid: getMultipleSelectedInvalidFormula('multipleStringByDefault')
  },
  required: [],
  uniqueItems: true,
  items: {
    type: 'string' as JSONSchema6TypeName,
    format: GeneralModel.JSONSchemaFormat.STRING_OPTION_LIST,
    title: ''
  }
};

export const baseSchema: GeneralModel.JSONSchema = {
  type: 'object',
  properties: {
    defaultFilter: {
      label: TRANS.client.schemaEditor.fields.titles.defaultFilter,
      type: 'object',
      format: GeneralModel.JSONSchemaFormat.COLLECTION_FILTER,
      metadata: { allowFormula: true, fieldSize: GeneralModel.FieldSize.FULL, detailGroupId: detailGroupMap.defaultFilter.id }
    },
    quickFilters: {
      type: 'array',
      format: GeneralModel.JSONSchemaFormat.ARRAY,
      title: 'List',
      description: 'The filters will be displayed in this order',
      metadata: {
        ctaConfig: {
          label: 'Add quick filter',
          image: 'add'
        },
        detailOrder: 1,
        unlimitedHeight: true,
        fieldSize: GeneralModel.FieldSize.FULL,
        detailGroupId: detailGroupMap.quickFilters.id
      },
      items: {
        type: 'object',
        format: GeneralModel.JSONSchemaFormat.OBJECT,
        title: ' ',
        required: ['name', 'fieldId'],
        additionalProperties: true,
        properties: {
          id: {
            type: 'string',
            metadata: { hidden: true, calculation: { $cyf_coalesce: ['{{event.value}}', { $cyf_guid: [] }] } }
          },
          type: {
            type: 'string',
            title: 'Type',
            format: GeneralModel.JSONSchemaFormat.INLINE_STRING_OPTION_LIST,
            default: 'field',
            metadata: {
              fieldSize: GeneralModel.FieldSize.FULL,
              optionList: [
                {
                  label: 'Field',
                  value: 'field',
                  hidden: false
                },
                {
                  label: 'Custom',
                  value: 'custom',
                  hidden: false
                }
              ],
              detailOrder: 1
            }
          },
          fieldId: {
            type: 'string',
            title: 'Field',
            format: GeneralModel.JSONSchemaFormat.COLLECTION_FIELD,
            metadata: {
              hidden: isCustomFilterFormula,
              detailOrder: 3
            }
          },
          filter: {
            type: 'object',
            title: 'Filter',
            format: GeneralModel.JSONSchemaFormat.COLLECTION_FILTER,
            metadata: {
              allowFormula: true,
              hidden: isDynamicFilterFormula,
              fieldSize: GeneralModel.FieldSize.FULL,
              detailOrder: 2
            },
            required: [],
            properties: {},
            additionalProperties: true
          },
          'info-general': {
            type: 'string',
            label: 'General',
            title: 'General',
            format: 'info-block' as GeneralModel.JSONSchemaFormat,
            metadata: {
              hidden: isDynamicFilterFormula,
              fieldSize: GeneralModel.FieldSize.FULL,
              fixedValue: '<h4>General</h4>',
              detailOrder: 4,
              unlimitedHeight: true
            }
          },
          name: {
            type: 'string',
            title: TRANS.client.fields.titles.name,
            format: GeneralModel.JSONSchemaFormat.TEXT,
            metadata: {
              hidden: isDynamicFilterFormula,
              detailOrder: 5
            }
          },
          description: {
            type: 'string',
            title: TRANS.client.fields.titles.description,
            format: GeneralModel.JSONSchemaFormat.MULTILINE,
            metadata: {
              hidden: isDynamicFilterFormula,
              detailOrder: 6
            }
          },
          notes: {
            type: 'string',
            title: 'Admin notes',
            format: GeneralModel.JSONSchemaFormat.MULTILINE,
            metadata: {
              hidden: isDynamicFilterFormula,
              fieldSize: GeneralModel.FieldSize.FULL,
              detailOrder: 7
            }
          },
          'info-appearance': {
            type: 'string',
            title: 'Appearance',
            format: 'info-block' as GeneralModel.JSONSchemaFormat,
            metadata: {
              fieldSize: GeneralModel.FieldSize.FULL,
              fixedValue: '<h4>Appearance</h4>',
              detailOrder: 8,
              unlimitedHeight: true
            }
          },
          color: {
            type: 'string',
            title: TRANS.client.fields.titles.color,
            format: GeneralModel.JSONSchemaFormat.COLOR,
            metadata: {
              detailOrder: 9
            }
          },
          icon: {
            type: 'string',
            title: TRANS.client.fields.titles.image,
            format: GeneralModel.JSONSchemaFormat.ICON_IMAGE,
            metadata: {
              expanded: true,
              detailOrder: 10
            }
          },
          'info-settings': {
            type: 'string',
            title: 'Settings',
            format: 'info-block' as GeneralModel.JSONSchemaFormat,
            metadata: {
              fieldSize: GeneralModel.FieldSize.FULL,
              fixedValue: '<h4>Settings</h4>',
              detailOrder: 11,
              unlimitedHeight: true
            }
          },
          hidden: {
            type: 'boolean',
            label: TRANS.client.fields.titles.hidden,
            title: TRANS.client.fields.titles.hidden,
            format: GeneralModel.JSONSchemaFormat.CHECKBOX,
            default: false,
            metadata: {
              allowFormula: true,
              detailOrder: 13
            }
          },
          optionListType: {
            type: 'string',
            title: 'How many filters can be selected',
            format: GeneralModel.JSONSchemaFormat.INLINE_STRING_OPTION_LIST,
            info: 'Choose if the user can only apply one filter from this list or multiple at the same time.',
            default: 'one',
            metadata: {
              hidden: isCustomFilterFormula,
              fieldSize: GeneralModel.FieldSize.FULL,
              optionList: [
                {
                  label: 'One',
                  value: 'one'
                },
                {
                  label: 'Multiple',
                  value: 'multiple'
                }
              ],
              detailOrder: 12
            }
          },
          selectedByDefault: {
            type: 'boolean',
            title: 'Active by default',
            format: GeneralModel.JSONSchemaFormat.CHECKBOX,
            default: false,
            metadata: {
              hidden: isDynamicFilterFormula,
              allowFormula: true,
              detailOrder: 14
            }
          },
          stringByDefault,
          multipleStringByDefault,
          numberByDefault: {
            ...stringByDefault,
            type: 'number' as JSONSchema6TypeName,
            format: GeneralModel.JSONSchemaFormat.NUMBER_OPTION_LIST,
            metadata: { ...stringByDefault.metadata, invalid: getDefaultSelectedInvalidFormula('numberByDefault') }
          },
          multipleNumberByDefault: {
            ...multipleStringByDefault,
            metadata: { ...multipleStringByDefault.metadata, invalid: getMultipleSelectedInvalidFormula('multipleNumberByDefault') },
            items: {
              ...multipleStringByDefault.items,
              type: 'number' as JSONSchema6TypeName,
              format: GeneralModel.JSONSchemaFormat.NUMBER_OPTION_LIST
            }
          }
        }
      }
    }
  }
};
