import type { ComponentProps, ComponentType, FC } from 'react';
import { memo } from 'react';

import type { WrappedComponent } from '@cyferd/client-engine';
import { CyViewContent } from '@cyferd/client-engine';

import { CyAction } from '@components/smart/CyAction';
import { CyActionEffect, CyDataEffect, CyIntervalEffect, CyLoadEffect, CyReusableActionEffect, CyUnloadEffect } from '@components/smart/CyEffect';
import { CyActivityLog } from '@components/smart/CyActivityLog';
import { CyBlank } from '@components/smart/CyBlank';
import { CyCalendar } from '@components/smart/CyCalendar';
import { CyChart } from '@components/smart/CyChart';
import { CyForm } from '@components/smart/CyForm';
import { CyImage } from '@components/smart/CyImage';
import { CyInfo } from '@components/smart/CyInfo';
import { CyKanban } from '@components/smart/CyKanban';
import { CyLayout } from '@components/smart/CyLayout';
import { CyList } from '@components/smart/CyList';
import { CyModal } from '@components/smart/CyModal';
import { CySearch } from '@components/smart/CySearch';
import { CyTable } from '@components/smart/CyTable';
import { CyText } from '@components/smart/CyText';
import { CyView } from '@components/smart/CyView';

export const getAdaptedComponent = (componentList: ComponentType<any>[]): Record<string, FC<any>> => {
  return componentList.reduce(
    (total, CurrentCompo) => ({
      ...total,
      [CurrentCompo.displayName]: memo((props: ComponentProps<WrappedComponent>) => <CurrentCompo {...props} />)
    }),
    {}
  );
};
export const WPLComponents = getAdaptedComponent([
  CyList,
  CyTable,
  CyForm,
  CyChart,
  CyModal,
  CyInfo,
  CyBlank,
  CyLayout,
  CyKanban,
  CySearch,
  CyAction,
  CyText,
  CyImage,
  CyCalendar,
  CyDataEffect,
  CyActionEffect,
  CyLoadEffect,
  CyUnloadEffect,
  CyIntervalEffect,
  CyReusableActionEffect,
  CyView,
  CyViewContent,
  CyActivityLog
]);
