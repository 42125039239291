import * as React from 'react';

const Empty = props => (
  <svg width="126" height="133" viewBox="0 0 126 133" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path opacity="0.6" d="M58 43.0264L16.5 55.0264L58 66.0264V43.0264Z" fill="var(--BRAND_1)" />
    <path opacity="0.4" d="M57.5 43.0264L110 54.5264L63.5 67.5264L57.5 66.0264V43.0264Z" fill="var(--BRAND_1)" />
    <ellipse cx="63" cy="111.026" rx="62.5" ry="21" fill="var(--NEUTRAL_4)" />
    <path d="M16.5 106.422V55.0264L63.5 67.6233V120.026L16.5 106.422Z" fill="var(--NEUTRAL_4)" />
    <path d="M62.5 70.5264V115.526" stroke="var(--BRAND_1)" strokeLinecap="round" strokeLinejoin="round" />
    <path d="M110.5 106.422V55.0264L63.5 67.6233V120.026L110.5 106.422Z" fill="var(--BASE_BACKGROUND)" />
    <path
      d="M16.5 55.5264V106.526L63 119.526L110 106.526V55.0264L63 67.5264L21.5 56.0264"
      stroke="var(--BRAND_1)"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M78 86.5264C78 87.4007 77.7461 88.1713 77.3609 88.7106C76.975 89.2509 76.487 89.5264 76 89.5264C75.513 89.5264 75.025 89.2509 74.6391 88.7106C74.2539 88.1713 74 87.4007 74 86.5264C74 85.6521 74.2539 84.8814 74.6391 84.3421C75.025 83.8019 75.513 83.5264 76 83.5264C76.487 83.5264 76.975 83.8019 77.3609 84.3421C77.7461 84.8814 78 85.6521 78 86.5264Z"
      fill="var(--BRAND_1)"
      stroke="var(--BRAND_1)"
    />
    <path
      d="M103 79.5264C103 80.4007 102.746 81.1713 102.361 81.7106C101.975 82.2509 101.487 82.5264 101 82.5264C100.513 82.5264 100.025 82.2509 99.6391 81.7106C99.2539 81.1713 99 80.4007 99 79.5264C99 78.6521 99.2539 77.8814 99.6391 77.3421C100.025 76.8019 100.513 76.5264 101 76.5264C101.487 76.5264 101.975 76.8019 102.361 77.3421C102.746 77.8814 103 78.6521 103 79.5264Z"
      fill="var(--BRAND_1)"
      stroke="var(--BRAND_1)"
    />
    <path
      d="M95.5 93.0264C94.6724 97.9787 91.3621 101.026 88.4655 101.026C85.569 101.026 83.5 97.9787 83.5 96.074"
      stroke="var(--BRAND_1)"
      strokeWidth="2"
      strokeLinecap="round"
    />
    <path d="M106 53.0264L58 43.0264M58 43.0264L16.5 54.5264M58 43.0264V63.0264" stroke="var(--BRAND_1)" strokeLinecap="round" strokeLinejoin="round" />
    <path
      d="M47.0003 57.0265C58.5003 40.0265 48.5004 21.0265 37.5003 21.0265C26.5002 21.0265 30.3414 32.0265 37.5003 32.0265C44.0004 32.0265 54.5004 29.0264 58.0004 14.0264"
      stroke="var(--BRAND_1)"
      strokeLinecap="round"
      strokeDasharray="2 2"
    />
    <circle cx="61.5" cy="7.02637" r="2" fill="var(--BRAND_1)" />
    <path d="M60.5002 5.52486C57.5002 -1.97484 50.5 8.52539 59.5002 7.52486" stroke="var(--BRAND_1)" />
    <path d="M62.116 5.40983C63.4888 -0.0900352 58.5002 0.0258789 58.0003 2.52565" stroke="var(--BRAND_1)" />
  </svg>
);

export default Empty;
