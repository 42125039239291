import { useContext, useMemo } from 'react';

import type { TagModel } from '@cyferd/client-engine';
import { ApiModel, noop } from '@cyferd/client-engine';

import { BondEditor } from '../../../shared/BondEditor';
import type { EditorContextValue } from '../../../shared/EditorHome';
import { EditorContext } from '../../../shared/EditorHome';
import { EmptyLinkingPage } from '../../../../../@components/elements/EmptyLinkingPage';
import { LinkingPageDiagram, PredefinedDiagrams } from '../../../../../@components/elements/LinkingPageDiagram';

export const ViewBondEditor = () => {
  const { item } = useContext<EditorContextValue<TagModel.Tag>>(EditorContext);

  const cursor = useMemo(() => ({ associationKey: 'appViews', relatedTo: { collectionId: ApiModel.ApiEntity.TAG, id: item?.id } }), [item]);

  return (
    <BondEditor
      item={item}
      cursor={cursor}
      collectionId={ApiModel.ApiEntity.VIEW}
      associationKey="appViews"
      modalTitle="Unlinked views"
      customEmptyState={
        <EmptyLinkingPage
          title="Nothing linked"
          subtitle="A view is a page that displays your data. It can be made up of components such as lists, actions, charts, forms, etc. Default views are used if there are no custom linked views"
          ctaLabel="Link view"
          ctaOnClick={noop}
          diagram={<LinkingPageDiagram value={PredefinedDiagrams.appLinkedViews} />}
        />
      }
    />
  );
};
