import { css } from '@emotion/react';

export const styles = {
  loadingContainer: css`
    display: flex;
    width: 100%;
    height: 100%;
    flex: 1;
    align-items: center;
    justify-content: center;
    min-height: 100px;
  `
};
