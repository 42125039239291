import { useMemo } from 'react';
import type { JSONSchema6TypeName } from 'json-schema';

import type { CollectionModel } from '@cyferd/client-engine';
import { GeneralModel, mergeTruthy } from '@cyferd/client-engine';

import { baseSchema, isCustomFilterFormula } from './schemas';

export const useFilterEditorSchema = (item: CollectionModel.Collection) => {
  const {
    optionFieldsList,
    stringOptionFieldsOptions,
    numberOptionFieldsOptions,
    stringByDefaultHiddenFormula,
    stringMultipleByDefaultHiddenFormula,
    numberByDefaultHiddenFormula,
    multipleNumberByDefaultHiddenFormula
  } = useMemo(() => {
    const properties = item?.schema?.properties || {};

    const optionFields: [string, GeneralModel.JSONSchema][] = Object.entries(properties).filter(([, field]) =>
      [
        GeneralModel.JSONSchemaFormat.MULTI_OPTION_LIST,
        GeneralModel.JSONSchemaFormat.MULTI_OPTION_LIST_ALT,
        GeneralModel.JSONSchemaFormat.NUMBER_OPTION_LIST,
        GeneralModel.JSONSchemaFormat.STRING_OPTION_LIST,
        GeneralModel.JSONSchemaFormat.INLINE_STRING_OPTION_LIST
      ].includes(field.format)
    );

    const optionFieldsList = optionFields.map(([key, field]) => ({
      label: field.title,
      value: field.$id || key
    }));

    const { stringOptions, numberOptions } = optionFields.reduce(
      ({ stringOptions, numberOptions }, [, field]) => {
        const options = (field?.metadata?.optionList || []).map(option => ({
          ...option,
          hidden: { $cyf_ne: ['{{event.parent.value.fieldId}}', field?.$id] }
        }));

        return field.type === 'string'
          ? { stringOptions: [...stringOptions, ...options], numberOptions }
          : { stringOptions, numberOptions: [...numberOptions, ...options] };
      },
      { stringOptions: [], numberOptions: [] }
    );

    const getFieldsIdsByType = (type: JSONSchema6TypeName): string[] =>
      Object.values(properties)
        .filter(field => field.type === type)
        .map(field => field.$id);

    const hideNumericDefaultFields = {
      $cyf_not: [{ $cyf_contains: [getFieldsIdsByType('number'), '{{event.parent.value.fieldId}}'] }]
    };

    const hideStringDefaultFields = {
      $cyf_not: [{ $cyf_contains: [getFieldsIdsByType('string'), '{{event.parent.value.fieldId}}'] }]
    };

    const defaultSelectedIsMultiple = {
      $cyf_eq: ['{{event.parent.value.optionListType}}', 'multiple']
    };

    const stringByDefaultHiddenFormula = {
      $cyf_or: [isCustomFilterFormula, defaultSelectedIsMultiple, hideStringDefaultFields]
    };

    const stringMultipleByDefaultHiddenFormula = {
      $cyf_or: [isCustomFilterFormula, { $cyf_not: [defaultSelectedIsMultiple] }, hideStringDefaultFields]
    };

    const numberByDefaultHiddenFormula = {
      $cyf_or: [isCustomFilterFormula, defaultSelectedIsMultiple, hideNumericDefaultFields]
    };

    const multipleNumberByDefaultHiddenFormula = {
      $cyf_or: [isCustomFilterFormula, { $cyf_not: [defaultSelectedIsMultiple] }, hideNumericDefaultFields]
    };

    return {
      optionFieldsList,
      stringOptionFieldsOptions: stringOptions,
      numberOptionFieldsOptions: numberOptions,
      stringByDefaultHiddenFormula,
      stringMultipleByDefaultHiddenFormula,
      numberByDefaultHiddenFormula,
      multipleNumberByDefaultHiddenFormula
    };
  }, [item?.schema?.properties]);

  const schema: GeneralModel.JSONSchema = useMemo(
    () =>
      mergeTruthy(baseSchema, {
        properties: {
          defaultFilter: { metadata: { entity: item } },
          quickFilters: {
            items: {
              properties: {
                fieldId: { metadata: { collectionId: item?.id, optionList: optionFieldsList } },
                filter: { metadata: { entity: item } },
                stringByDefault: { metadata: { optionList: stringOptionFieldsOptions, hidden: stringByDefaultHiddenFormula } },
                multipleStringByDefault: {
                  metadata: { hidden: stringMultipleByDefaultHiddenFormula },
                  items: { metadata: { optionList: stringOptionFieldsOptions } }
                },
                numberByDefault: { metadata: { optionList: numberOptionFieldsOptions, hidden: numberByDefaultHiddenFormula } },
                multipleNumberByDefault: {
                  metadata: { hidden: multipleNumberByDefaultHiddenFormula },
                  items: { metadata: { optionList: numberOptionFieldsOptions } }
                }
              }
            }
          }
        }
      }),
    [
      item,
      optionFieldsList,
      stringOptionFieldsOptions,
      stringByDefaultHiddenFormula,
      stringMultipleByDefaultHiddenFormula,
      numberOptionFieldsOptions,
      numberByDefaultHiddenFormula,
      multipleNumberByDefaultHiddenFormula
    ]
  );
  return schema;
};
