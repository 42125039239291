import type { ViewScopeRegistryItem } from '@cyferd/client-engine';
import { ClientEngineContext, GeneralModel, noop, normalize, useParsers, useRegistry } from '@cyferd/client-engine';
import { useContext, useMemo } from 'react';
import { SchemaForm } from '../../../builder/views/shared/SchemaForm';

const getLongDescriptionForView = (reg: ViewScopeRegistryItem) => `
<br/>
<p><strong>Origin:</strong> ${reg.componentName}</p>
<p><strong>Instance:</strong> ${reg.instanceId}</p>
<p><strong>View ID:</strong> ${reg.view?.id}</p>
<p><strong>View name:</strong> ${reg.view?.name}</p>
`;

const getLabelForView = (reg: ViewScopeRegistryItem) => `[${reg.componentName}] ${reg.view?.name}`;

export const DevMenuData = () => {
  const { viewScopeRegistry, progRegistry } = useRegistry();
  const { useDataSelector, useUrl, useUserSelector } = useContext(ClientEngineContext);
  const { parseData } = useParsers();
  const user = useUserSelector();
  const global = useDataSelector();
  const url = useUrl();
  const platform = useMemo(() => parseData('{{platform}}'), [parseData]);
  const tenant = useMemo(() => parseData('{{tenant}}'), [parseData]);
  const variables = useMemo(() => parseData('{{vars}}'), [parseData]);

  const value = useMemo(
    () => ({
      general: {
        user,
        tenant,
        url,
        platform,
        global,
        variables
      },
      local: viewScopeRegistry,
      inputs: viewScopeRegistry,
      views: viewScopeRegistry,
      progRegistry
    }),
    [global, platform, progRegistry, tenant, url, user, variables, viewScopeRegistry]
  );

  const collection = useMemo(() => {
    return normalize.collection(
      {
        detailGroupList: [{ id: '1' }, { id: '2' }, { id: '3' }, { id: '4' }, { id: '5' }],
        schema: {
          type: 'object',
          properties: {
            general: {
              title: 'Global',
              type: 'object',
              metadata: { subtype: GeneralModel.JSONSchemaSubtype.FRAMED, size: GeneralModel.FieldSize.FULL, detailGroupId: '1' },
              properties: {
                user: { title: 'User', type: 'any', format: GeneralModel.JSONSchemaFormat.JSON },
                tenant: { title: 'Tenant', type: 'any', format: GeneralModel.JSONSchemaFormat.JSON },
                platform: { title: 'Platform', type: 'any', format: GeneralModel.JSONSchemaFormat.JSON },
                url: { title: 'URL', type: 'any', format: GeneralModel.JSONSchemaFormat.JSON },
                variables: { title: 'Variables', type: 'any', format: GeneralModel.JSONSchemaFormat.JSON },
                global: {
                  title: 'Global scope',
                  info: '<p>This scope is the one that gets mapped to <strong>global</strong> when using a formula.</p><p><br></p><p>This is shared among all views so there is only a single one.</p>',
                  type: 'object',
                  format: GeneralModel.JSONSchemaFormat.JSON
                }
              }
            },
            local: {
              title: 'Local scopes',
              info: '<p>Scope used by default. This is what gets mapped to <strong>store</strong> when using a formula.</p><p><br></p><p>They are independent from each other meaning even if two or more views use them same namespace e.g. <strong>store.information</strong>, still each view gets a different version of it.</p>',
              type: 'object',
              metadata: {
                subtype: GeneralModel.JSONSchemaSubtype.FRAMED,
                size: GeneralModel.FieldSize.FULL,
                hidden: { $not: true, $cyf_hasproperties: ['{{event.value}}'] },
                detailGroupId: '2'
              },
              properties: Object.fromEntries(
                Object.entries(viewScopeRegistry).map(([key, reg]) => [
                  key,
                  {
                    type: 'object',
                    label: ' ',
                    properties: {
                      state: { label: getLabelForView(reg), info: getLongDescriptionForView(reg), type: 'any', format: GeneralModel.JSONSchemaFormat.JSON }
                    }
                  }
                ])
              )
            },
            progRegistry: {
              title: 'Component scopes',
              info: '<p>These are scopes internal to each component generated by <strong>programmatic fallbacks (prog)</strong>.</p><p><br></p><p>They include what these component get on <strong>runtime</strong> in the <strong>value</strong> attribute.</p>',
              type: 'object',
              metadata: {
                subtype: GeneralModel.JSONSchemaSubtype.FRAMED,
                size: GeneralModel.FieldSize.FULL,
                hidden: { $not: true, $cyf_hasproperties: ['{{event.value}}'] },
                detailGroupId: '3'
              },
              properties: Object.fromEntries(
                Object.entries(progRegistry).map(([key, reg]) => [
                  key,
                  {
                    type: 'object',
                    label: ' ',
                    properties: {
                      state: { label: reg.node?.name, type: 'any', format: GeneralModel.JSONSchemaFormat.JSON }
                    }
                  }
                ])
              )
            },
            inputs: {
              title: 'Inputs',
              info: "<p>Already parsed inputs being used in <strong>runtime</strong> by each view.</p><p>The <strong>root view</strong>'s input are URL query params by default.</p>",
              type: 'object',
              metadata: {
                subtype: GeneralModel.JSONSchemaSubtype.FRAMED,
                size: GeneralModel.FieldSize.FULL,
                hidden: { $not: true, $cyf_hasproperties: ['{{event.value}}'] },
                detailGroupId: '4'
              },
              properties: Object.fromEntries(
                Object.entries(viewScopeRegistry).map(([key, reg]) => [
                  key,
                  {
                    type: 'object',
                    label: ' ',
                    properties: {
                      input: { label: getLabelForView(reg), info: getLongDescriptionForView(reg), type: 'any', format: GeneralModel.JSONSchemaFormat.JSON }
                    }
                  }
                ])
              )
            },
            views: {
              title: 'Views',
              info: '<p>Views being used starting by the <strong>root view</strong> and then <strong>View components</strong> inside of it.</p><p><br></p><p>It is important to notice that <strong>JSON</strong> objects here are from the <strong>runtime</strong> version of each view and not the base one saved from the builder. These include things like component <strong>fallbacks</strong>, temporal runtime ids and other changes and extras.</p>',
              type: 'object',
              metadata: {
                subtype: GeneralModel.JSONSchemaSubtype.FRAMED,
                size: GeneralModel.FieldSize.FULL,
                hidden: { $not: true, $cyf_hasproperties: ['{{event.value}}'] },
                detailGroupId: '5'
              },
              properties: Object.fromEntries(
                Object.entries(viewScopeRegistry).map(([key, reg]) => [
                  key,
                  {
                    type: 'object',
                    label: ' ',
                    properties: {
                      view: { label: getLabelForView(reg), info: getLongDescriptionForView(reg), type: 'any', format: GeneralModel.JSONSchemaFormat.JSON }
                    }
                  }
                ])
              )
            }
          }
        }
      },
      { avoidAlphabeticalSort: true }
    );
  }, [progRegistry, viewScopeRegistry]);

  return (
    <div data-testid="dev-menu-data">
      <SchemaForm framed={false} disabled={true} value={value} schema={collection.schema} detailGroupList={collection.detailGroupList} onChange={noop} />
    </div>
  );
};

DevMenuData.displayName = 'DevMenuData';
