import type { PropsWithChildren } from 'react';
import { memo } from 'react';

import { ErrorBoundary } from '@cyferd/client-engine';
import { Text } from '@components/elements/Text';

/**
 * generic wrapper for all mapped compos
 */
export const MappedComponent = memo(({ children }: PropsWithChildren<any>) => {
  return <ErrorBoundary fallback={() => <Text>Something went wrong</Text>}>{children}</ErrorBoundary>;
});
