import { EMPTY_CONTAINER_HEIGHT, GAP } from '@constants';
import { css } from '@emotion/react';

export const styles = {
  headerContainer: css`
    margin-bottom: ${GAP.M};
  `,
  headerActionList: css`
    [data-selector='option-menu-container'] {
      flex-wrap: wrap;
      justify-content: flex-end;
    }
  `,
  headerSearchContainer: css`
    display: flex;
    justify-content: flex-end;
    align-items: flex-end;
    gap: ${GAP.XS};
  `,
  searchInputContainer: css`
    > div {
      align-items: flex-end;
    }
  `,
  controls: css`
    display: flex;
    flex-direction: column;
    gap: ${GAP.S};
  `,
  spinnerContainer: css`
    display: flex;
    width: 100%;
    height: 100%;
    flex: 1;
    align-items: center;
    justify-content: center;
    min-height: ${EMPTY_CONTAINER_HEIGHT.XS}px;
  `,
  editControls: css`
    display: flex;
    align-items: center;
    justify-content: flex-end;
    gap: ${GAP.S};
  `,
  titleContainer: css`
    display: flex;
    align-items: center;
    justify-content: flex-start;
    gap: ${GAP.M};
  `,
  editModeBtns: css`
    display: flex;
    align-items: center;
    justify-content: flex-end;
    gap: ${GAP.S};
  `
};
