import { COLOR, FONT_SIZE, FONT_WEIGHT, GAP } from '@constants';
import { css } from '@emotion/react';

export const styles = {
  container: css`
    display: flex;
    align-items: center;
    justify-content: space-between;
  `,
  editionHeader: css`
    display: flex;
    align-items: center;
    gap: ${GAP.S};
  `,
  cancelBtn: css`
    transform: rotate(180deg);
  `,
  editionTitle: css`
    color: ${COLOR.NEUTRAL_1};
    font-size: ${FONT_SIZE.M};
    font-weight: ${FONT_WEIGHT.SEMI_BOLD};
  `
};
