import type { ChartDataOutput } from '@cyferd/client-engine';
import { ErrorBoundary, ViewModel, useTranslate } from '@cyferd/client-engine';
import { Spinner } from '../Spinner';
import { styles } from './styles';
import type { Subscription } from 'rxjs';
import { useCallback, useEffect, useMemo, useState } from 'react';
import {
  ChartsAxis,
  ChartsAxisHighlight,
  ChartsClipPath,
  ChartsGrid,
  ChartsTooltip,
  ChartsVoronoiHandler,
  ResponsiveChartContainer,
  ScatterPlot
} from '@mui/x-charts';
import { useCurrentTheme } from '@components/providers/UIprovider';
import { COLOR, FONT_SIZE, TRANS } from '@constants';
import { ChartLegend } from '../ChartLegend';
import { ClassNames } from '@emotion/react';
import { Header } from '../Header';
import { ChartReferenceLines } from '../ChartReferenceLines';
import { CyText } from '@components/smart/CyText';
import { useGetElementSize } from '@utils';
import { ScatterChartTooltip } from './ScatterChartTooltip';

const SERIES_CONFIG = { type: 'scatter', markerSize: 4 };
const CHART_MARGIN = { bottom: 30, top: 35, right: 35 };

export type ScatterChartProps = {
  id?: string;
  value?: ChartDataOutput;
  isLoading?: boolean;
  testid?: string;
  onClickItem?: (event: any, meta: any) => Subscription;
} & Pick<ViewModel.CyChartProps, 'title' | 'subtitle' | 'config'>;

const baseTextStyle = { fontFamily: 'inherit', fontSize: FONT_SIZE.XS, fill: COLOR.NEUTRAL_2 };

export const ScatterChart = ({ id, title, subtitle, value, isLoading, testid, onClickItem }: ScatterChartProps) => {
  const [chartAxisKey, setChartAxisKey] = useState(0);
  const currentTheme = useCurrentTheme();
  const { translate } = useTranslate();
  const clipPathId = useMemo(() => `clipPath-${id}`, [id]);
  const { ref: yAxisRef, width: chartYAxisWidth } = useGetElementSize();
  const chartAxisRef = useCallback(node => node && yAxisRef(node?.querySelector('.MuiChartsAxis-left')), [yAxisRef]);

  const {
    referenceLines,
    dimensions,
    measures,
    metadata: { range, applyMask, mask, legendHidden },
    data
  } = value;

  const safeApplyMask = useMemo(() => (mask && applyMask ? applyMask : undefined), [mask, applyMask]);

  const series = useMemo(
    () =>
      dimensions?.map(({ fieldId, label, baseColor }, i) => {
        const color = baseColor && currentTheme?.[baseColor]?.value ? currentTheme?.[baseColor]?.value : undefined;
        const chartSerie = {
          ...SERIES_CONFIG,
          id: fieldId || `series-${i}`,
          label: label || fieldId,
          data: data
            .map(d => ({
              label: d?.[fieldId]?.calculatedValue,
              color: d?.color,
              x: d?.fullItem?.[measures[0]?.fieldId],
              y: d?.fullItem?.[measures[1]?.fieldId],
              fullItem: d?.fullItem
            }))
            .filter(d => d?.label !== undefined && d?.x !== undefined && d?.y !== undefined),
          valueFormatter: safeApplyMask
        };
        return { ...chartSerie, ...(color ? { color } : {}) };
      }),
    [dimensions, measures, data, currentTheme, safeApplyMask]
  );

  const handleClickItem = useCallback(
    (event, d) => onClickItem(series.find(s => s?.id === d?.seriesId)?.data[d?.dataIndex]?.fullItem, { metaKey: event?.metakey }),
    [series, onClickItem]
  );
  const shouldRenderChart = useMemo(() => !!data.length && series?.length, [data, series]);
  const itemColorGetter = useCallback((dataIndex: number) => COLOR[(series[0]?.data[dataIndex] as any)?.color] || series[0]?.color, [series]);

  useEffect(() => {
    setChartAxisKey(prev => prev + 1);
  }, [isLoading, shouldRenderChart, value]);

  return (
    <div id={id} data-testid={testid || 'scatter-chart'}>
      <Header title={title} subtitle={subtitle} />
      {isLoading ? (
        <div data-testid="loading" css={styles.spinnerContainer}>
          <Spinner />
        </div>
      ) : shouldRenderChart ? (
        <ErrorBoundary>
          <div css={styles.chartWrapper} data-testid="chart-wrapper">
            <div css={styles.chartContainer}>
              <ResponsiveChartContainer
                series={series as any}
                margin={{ ...CHART_MARGIN, left: chartYAxisWidth }}
                xAxis={[{ min: range?.[0], max: range?.[1] }]}
                yAxis={[{ min: range?.[0], max: range?.[1] }]}
              >
                <ChartsGrid horizontal={true} vertical={true} />
                <g clipPath={`url(#${clipPathId})`}>
                  <ScatterPlot
                    skipAnimation={true}
                    slotProps={{ scatter: { colorGetter: itemColorGetter } }}
                    onItemClick={onClickItem ? handleClickItem : undefined}
                  />
                </g>
                <g ref={chartAxisRef} key={chartAxisKey}>
                  <ChartsAxis
                    slotProps={{
                      axisLine: { style: { stroke: COLOR.NEUTRAL_3 } },
                      axisTickLabel: { style: baseTextStyle },
                      axisTick: { style: { stroke: COLOR.NEUTRAL_3, opacity: 0.5 } }
                    }}
                    margin={CHART_MARGIN}
                  />
                </g>
                <ChartsAxisHighlight />
                <ClassNames>
                  {({ css, cx }) => (
                    <ChartsTooltip
                      trigger="item"
                      classes={{ root: cx('chartTooltip', css(styles.chartTooltip)) }}
                      slots={{
                        itemContent: ({ series, itemData }) => <ScatterChartTooltip series={series} itemData={itemData} measures={measures} />
                      }}
                    />
                  )}
                </ClassNames>
                <ChartReferenceLines referenceLines={referenceLines} />
                <ChartsVoronoiHandler voronoiMaxRadius={25} />
                <ChartsClipPath id={clipPathId} />
              </ResponsiveChartContainer>
            </div>
            <ChartLegend hidden={legendHidden} series={series} testid={testid} />
          </div>
        </ErrorBoundary>
      ) : (
        <div css={styles.empty} data-testid="empty">
          <CyText content={translate(TRANS.client.emptyStates.cyList)} titleAlignment={ViewModel.Alignment.CENTER} />
        </div>
      )}
    </div>
  );
};
