import { ExampleEditor } from '../ExampleEditor';
import type { CollectionModel } from '@cyferd/client-engine';
import { ViewModel } from '@cyferd/client-engine';
import type { ReactNode } from 'react';
import React, { useCallback, useRef, useState } from 'react';
import { styles } from './styles';

interface PreviewLayoutProps {
  children: ReactNode;
  collection: CollectionModel.Collection;
  onCollectionChange: (item: CollectionModel.Collection) => void;
}

export const PreviewLayout = ({ children, collection, onCollectionChange }: PreviewLayoutProps) => {
  const [size, setSize] = useState<number>(1);
  const exampleRef = useRef<HTMLDivElement>();

  const previewType = `${ViewModel.DisplayName.CY_LIST}:::${ViewModel.CyListType.LIST}`;

  const onToggleSize = useCallback(() => setSize(p => (p >= 3 ? 1 : p + 1)), []);

  const getSizeIcon = useCallback((size: number) => (size === 3 ? 'close_fullscreen' : 'open_in_full'), []);

  return (
    <div css={[styles.grid, styles.size(size)]} id="collection-default-settings" data-testid="collection-default-settings">
      <div css={[styles.gridCol, styles.sidebarContainer]}>{children}</div>

      <div css={[styles.gridCol, styles.preview]} ref={exampleRef}>
        <ExampleEditor
          collection={collection}
          onCollectionChange={onCollectionChange}
          onToggleSize={onToggleSize}
          toggleIcon={getSizeIcon(size)}
          previewType={previewType}
        />
      </div>
    </div>
  );
};
