import { CLOSED_MENU_WIDTH, COLOR, FONT_SIZE, FONT_WEIGHT, GAP, MENU_WIDTH, RADIUS, SHADOW } from '@constants';
import { css } from '@emotion/react';

export const styles = {
  container: css`
    [data-selector='cta-label'] {
      text-decoration: none;
      font-weight: ${FONT_WEIGHT.NORMAL};
      font-size: ${FONT_SIZE.S};
      margin-left: ${GAP.M};
    }
  `,
  loadinSkeleton: css`
    gap: ${GAP.M};
    display: flex;
    flex-direction: column;
    max-width: 48px;
    margin: 0 auto;
    height: 250px;
  `,
  menu: ({
    verticalOffset,
    verticalOffsetDirection,
    biggerGap
  }: {
    verticalOffset: number;
    verticalOffsetDirection: 'top' | 'bottom';
    biggerGap?: boolean;
  }) => css`
    position: fixed;
    left: 0;
    z-index: 10;
    width: ${MENU_WIDTH};
    max-width: 100%;
    background-color: ${COLOR.NEUTRAL_5};
    padding-top: ${GAP.XS};
    padding-bottom: ${GAP.XS};
    border-top-right-radius: ${RADIUS.S};
    border-bottom-right-radius: ${RADIUS.S};
    border-width: 1px;
    border-color: ${COLOR.NEUTRAL_6};
    box-shadow: ${SHADOW.S};
    display: flex;
    gap: ${biggerGap ? GAP.L : GAP.XS};
    flex-direction: column;
    height: 100%;
    max-height: calc(100% - ${verticalOffset || 0}px);
    container-type: inline-size;

    top: ${verticalOffsetDirection === 'top' ? `calc(${verticalOffset || 0}px)` : 'auto'};
    bottom: ${verticalOffsetDirection === 'bottom' ? `calc(${verticalOffset || 0}px)` : 'auto'};

    #APPS,
    #BUILDER {
      flex: 1;
      & > span {
        display: grid;
      }
    }
  `,
  menuAlwaysDark: css`
    background-color: #35383b;
    #APPS {
      [data-selector='tab-display-name'] {
        color: white;
      }
    }
    #BUILDER {
      [data-selector='tab-display-name'] {
        color: #99c7ff;
      }
      & > span > button > div > div {
        background-color: #99c7ff;
      }
    }
  `,
  collapsedMenu: css`
    width: ${CLOSED_MENU_WIDTH};
    border-radius: 0;
    border-width: 0px;
  `,
  avatarWrapper: css`
    display: flex;
    justify-content: center;
    align-items: center;
    p {
      display: none;
    }
    @container (min-width: 60px) {
      justify-content: flex-start;
      margin-left: ${GAP.M};
      p {
        display: block;
        font-size: ${FONT_SIZE.S};
        font-weight: ${FONT_WEIGHT.EXTRA_BOLD};
        margin-left: ${GAP.S};
      }
    }
  `,
  imageContent: css`
    width: 32px;
    height: 32px;
  `,
  content: css`
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    gap: ${GAP.XS};
  `,
  containerTab: css`
    display: flex;
    flex-direction: column;
    flex: 1;
    overflow: auto;
  `,
  headerExpanded: css`
    display: flex;
  `,
  headerCollapsed: css`
    display: flex;
    flex-direction: column;
  `,
  toggleBtnExpanded: css`
    margin-left: auto;
    margin-right: ${GAP.S};
  `,
  toggleBtnCollapsed: css`
    display: flex;
    flex-direction: column;
    margin: 6px 0;
  `,
  divider: css`
    width: 100%;
    border-top: 1px ${COLOR.NEUTRAL_4} solid;
    margin-bottom: ${GAP.M};
  `
};
