import { GAP } from '@constants';
import { css } from '@emotion/react';

export const styles = {
  container: css``,
  title: css`
    margin-bottom: ${GAP.L};
    display: flex;
    align-items: center;
    gap: ${GAP.XS};
  `,
  subTitle: css`
    margin-bottom: ${GAP.L};
  `,
  extraInfo: css`
    margin-bottom: ${GAP.L};
  `,
  wrapperTable: css`
    overflow: auto;
    margin-bottom: ${GAP.L};
  `
};
