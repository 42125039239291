import { COLOR, FONT_SIZE, FONT_WEIGHT, GAP, RADIUS, SECONDARY_COLOR, TRANSITION_SPEED } from '@constants';
import { css } from '@emotion/css';

import HiddenDark from './hidden_dark.png';
import HiddenLight from './hidden_light.png';

const headerHeight = '40px';

export const styles = {
  container: css`
    background-color: ${COLOR.BASE_BACKGROUND};
    border-radius: ${RADIUS.XS};
    border: 1px solid ${COLOR.BRAND_1};
    transition: all ${TRANSITION_SPEED};
    display: flex;
    flex-direction: column;
    justify-content: center;
    cursor: pointer;
    overflow: hidden;
    [data-testid='layout'] {
      gap: ${GAP.S};
      overflow: initial;
    }
  `,
  nodeContent: css`
    min-height: 75px;
    display: flex;
    align-items: center;
    justify-content: center;
    padding-bottom: ${headerHeight};
  `,
  childContainer: css`
    padding: 0 ${GAP.S} ${GAP.S} ${GAP.S};
    transition: all ${TRANSITION_SPEED};
  `,
  header: css`
    min-height: ${headerHeight};
    padding: ${GAP.XS} ${GAP.XS} ${GAP.XXS} ${GAP.S};
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    cursor: pointer;
    transition: all ${TRANSITION_SPEED};
    :hover [data-selector='node-options'] {
      display: flex;
    }
  `,
  titleContainer: css`
    flex: auto;
    overflow: hidden;
  `,
  title: css`
    width: 100%;
    font-size: ${FONT_SIZE.S};
    font-weight: ${FONT_WEIGHT.BOLD};
    overflow: hidden;
    text-overflow: ellipsis;
  `,
  subtitle: css`
    font-size: ${FONT_SIZE.XS};
    font-weight: ${FONT_WEIGHT.SEMI_BOLD};
  `,
  name: css`
    font-size: ${FONT_SIZE.XXS};
    font-weight: ${FONT_WEIGHT.SEMI_BOLD};
  `,
  hiddenLight: css`
    background-image: url(${HiddenLight});
  `,
  hiddenDark: css`
    background-image: url(${HiddenDark});
  `,
  highlighted: css`
    background-color: ${SECONDARY_COLOR.NEUTRAL_1}!important;
  `,
  titleInnerContainer: css`
    display: flex;
    align-items: flex-start;
  `,
  optionContainer: css`
    display: flex;
    align-items: center;
    justify-content: flex-end;
    padding: ${GAP.XXS};
    border-radius: 30px;
    display: none;
  `,
  optionListContainer: css`
    margin-left: calc(${GAP.XS} / 2);
  `,
  nodeTooltipContainer: css`
    display: flex;
    flex-direction: column;
    gap: ${GAP.S};
  `,
  nodeTooltipTable: css`
    display: grid;
    grid-gap: ${GAP.XS};
    grid-template-columns: auto 1fr;
    font-size: ${FONT_SIZE.XS};
  `,
  nodeTitle: css`
    font-weight: ${FONT_WEIGHT.BOLD};
  `,
  nodeValue: css`
    width: 100%;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
  `,
  detailContent: css`
    border-top: 1px ${COLOR.NEUTRAL_1} solid;
    padding-top: ${GAP.XS};
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    height: 100%;
  `,
  nodeDetail: css`
    overflow: auto;
    flex: 1;
  `
};
