import { useCallback } from 'react';

import type { GeneralModel } from '@cyferd/client-engine';
import { ViewModel, useTranslate } from '@cyferd/client-engine';

import { CTAType } from '../CTA';
import type { IconKeys } from '../Icon';
import type { ModalProps } from '../Modal';
import { Modal } from '../Modal';
import { OptionMenu } from '../OptionMenu';
import { styles } from './styles';
import { RichText } from '../RichTextEditor';
import { TRANS } from '@constants';

export type ConfirmationModalProps = {
  title: string;
  description: string;
  icon?: IconKeys;
  cancelLabel?: string;
  confirmLabel?: string;
  status?: ViewModel.Status;
  singleAction?: boolean;
  size?: ViewModel.ModalType;
  color?: GeneralModel.Color.ThemeColor;
  onChange: (hasConfirmed: boolean) => void;
} & Pick<ModalProps, 'open'>;

export const ConfirmationModal = ({
  open,
  title,
  description,
  icon,
  cancelLabel = TRANS.client.buttons.cancel,
  confirmLabel = TRANS.client.buttons.confirm,
  status,
  singleAction,
  size = ViewModel.ModalType.COMPACT,
  color,
  onChange
}: ConfirmationModalProps) => {
  const { translate } = useTranslate();
  const onCancel = useCallback(() => onChange(false), [onChange]);
  const onConfirm = useCallback(() => onChange(true), [onChange]);

  return (
    <Modal
      open={open}
      onClose={onCancel}
      testid="confirmation-modal"
      type={size}
      title={title}
      icon={icon}
      status={status}
      color={color}
      footer={
        <OptionMenu
          optionList={[
            !singleAction && {
              important: true,
              testid: 'modal-cancel-btn',
              type: CTAType.SECONDARY,
              onClick: onCancel,
              label: cancelLabel
            },
            { important: true, testid: 'modal-confirm-btn', type: CTAType.PRIMARY, onClick: onConfirm, label: confirmLabel }
          ]}
        />
      }
    >
      <div css={styles.description}>
        <RichText value={translate(description)} />
      </div>
    </Modal>
  );
};
