/* istanbul ignore file */
import { useCallback } from 'react';
import * as en from './en';
import * as es from './es';
import { TranslationModel, useTranslate } from '@cyferd/client-engine';
import { Storage } from '@utils/storage';
import { LANG_KEY } from '@constants';

export const prefix = '[%';
export const suffix = '%]';

const storage = new Storage();

export const configTranslations = () => {
  TranslationModel.configTranslations({
    lng: storage.get(LANG_KEY) || 'en',
    debug: false,
    resources: { en, es }
  });
};

export const useChangeLang = () => {
  const { changeLanguage } = useTranslate();

  return useCallback(
    (lang: string) => {
      changeLanguage(lang);
      storage.set(LANG_KEY, lang);
    },
    [changeLanguage]
  );
};
