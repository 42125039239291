import { COLOR } from '@constants';
import { css } from '@emotion/react';

export const styles = {
  globalBg: css`
    html,
    body {
      background-color: ${COLOR.BASE_BACKGROUND_ALT_1};
    }
  `
};
