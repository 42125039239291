import { COLOR, FONT_SIZE, FONT_WEIGHT, GAP } from '@constants';
import { css } from '@emotion/css';

export const styles = {
  container: css`
    display: flex;
    flex-direction: column;
    gap: ${GAP.XXL};
  `,
  title: css`
    font-size: ${FONT_SIZE.XM};
    color: ${COLOR.NEUTRAL_1};
    margin-bottom: ${GAP.M};
  `,
  emptyStateMessage: css`
    font-weight: ${FONT_WEIGHT.NORMAL};
    font-size: ${FONT_SIZE.M};
    color: ${COLOR.NEUTRAL_1};
  `,
  infoLabelValueContainer: css`
    margin-top: ${GAP.S};
  `,
  infoLabelValueDiagram: css`
    margin-top: ${GAP.L};
  `,
  bondEditorContainer: css`
    div > div:nth-child(2) {
      height: 100%;
    }
  `
};
