import { useCallback, useEffect, useMemo, useState } from 'react';
import { filter, takeUntil, tap } from 'rxjs';

import { useRequest } from '@utils/useRequest';
import { SchemaForm } from '../SchemaForm';
import type { CollectionModel, SchemaFormBaseProps, TagModel, ViewModel } from '@cyferd/client-engine';
import { ApiModel, GeneralModel, actions, normalize, useUnmountObservable } from '@cyferd/client-engine';

export interface CustomViewSelectorProps {
  item: CollectionModel.Collection | TagModel.Tag;
  tag: ViewModel.View['tag'];
  collectionId: ApiModel.ApiEntity;
  associationKey: string;
  title: string;
  testid?: string;
  onChange: SchemaFormBaseProps['onChange'];
}

export const CustomViewSelector = ({ item, onChange, tag, collectionId, associationKey, title, testid = 'custom-view-selector' }: CustomViewSelectorProps) => {
  const request = useRequest();
  const onDestroy$ = useUnmountObservable();

  const [value, setValue] = useState(undefined);
  const [initValue, setInitValue] = useState(undefined);

  const initialFetchCriteria: GeneralModel.FetchCriteria = useMemo(
    () => ({
      collectionId: ApiModel.ApiEntity.VIEW,
      associationKey,
      relatedTo: { collectionId, id: item?.id },
      excluded: false,
      filter: { $and: [{ tag }] },
      options: { limit: 10 }
    }),
    [associationKey, collectionId, item?.id, tag]
  );

  useEffect(() => {
    request(
      actions.coreList({
        pointer: GeneralModel.IGNORED_POINTER_ID,
        query: { cursor: initialFetchCriteria }
      })
    )
      .pipe(
        takeUntil(onDestroy$),
        filter(action => action.type === ApiModel.TriggerActionType.DISPATCH_SET_DATA),
        tap(action => {
          const response = { ...action.payload.value, query: normalize.collection(action.payload.value?.query) } as ApiModel.ApiValue;
          setValue({ view: [response.list?.[0]] });
          setInitValue(response.list?.[0]?.id);
        })
      )
      .subscribe();
  }, [initialFetchCriteria, onDestroy$, request]);

  const internalOnChange = useCallback(
    newValue => {
      const newItem = {
        ...item,
        [`$$${associationKey}`]: {
          ...item[`$$${associationKey}`],
          add: newValue?.$$view.add[0]
            ? [...item[`$$${associationKey}`].add.filter(val => val.id !== value.view[0]?.id), newValue?.$$view.add[0]]
            : item[`$$${associationKey}`].add,
          remove: newValue?.$$view.remove[0]
            ? [...item[`$$${associationKey}`].remove.filter(val => val.id !== initValue), newValue?.$$view.remove[0]]
            : item[`$$${associationKey}`].remove
        }
      };
      onChange(newItem);
      setValue({ view: newValue?.$$view.add[0] ? [newValue?.$$view.add[0]] : [] });
    },
    [associationKey, initValue, item, onChange, value?.view]
  );

  const schema = useMemo(
    () => ({
      required: [],
      format: GeneralModel.JSONSchemaFormat.OBJECT,
      type: GeneralModel.JSONSchemaFormat.OBJECT,
      $id: 'view',
      title: '',
      label: '',
      metadata: {},
      properties: {
        view: {
          format: 'association',
          type: 'array',
          $id: 'view',
          title,
          label: title,
          metadata: {
            subtype: 'dropdown',
            association: {
              min: 0,
              max: 1,
              fixedFilter: { $and: [{ tag }] },
              cursor: {
                collectionId: 'View',
                filter: { $and: [{ tag }] },
                options: {
                  skip: 0,
                  limit: 10
                },
                quickFilters: [],
                excluded: false
              }
            }
          }
        }
      }
    }),
    [tag, title]
  );

  return (
    <div data-testid={testid}>
      <SchemaForm schema={schema as GeneralModel.JSONSchema} value={value} onChange={internalOnChange} />
    </div>
  );
};
