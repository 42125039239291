import { GAP } from '@constants';
import { css } from '@emotion/css';

export const styles = {
  infoLabelValueContainer: css`
    margin-top: ${GAP.S};
  `,
  infoLabelValueDiagram: css`
    margin-top: ${GAP.L};
  `
};
