import { toastWidth } from '@components/elements/Toast';
import { GAP } from '@constants';
import { css } from '@emotion/css';

export const styles = {
  container: css`
    position: fixed;
    top: ${GAP.XL};
    right: ${GAP.XL};
    z-index: 1000;
    pointer-events: auto;
    max-width: ${toastWidth};
    width: 100%;
  `,
  item: css`
    margin-bottom: calc(${GAP.XS} * 2);
  `
};
