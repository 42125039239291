import * as React from 'react';

export const LoadingSky = props => (
  <svg width={2789} height={198} fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      d="m2762.29 139.473.36.564.65.115c6.23 1.088 12.28 4.201 16.43 8.661l.01.006c4.96 5.267 7.73 12.066 7.73 19.157 0 15.422-12.82 28.127-28.8 28.127h-46.9c-11.92-.158-21.48-9.619-21.48-21.128 0-10.919 8.5-20.055 19.64-20.969l1.31-.108.06-1.323c.62-14.48 12.81-26.075 27.66-26.075 3.24 0 6.33.617 8.95 1.542l.01.003.01.002c5.86 2.005 10.96 6.017 14.36 11.426Z"
      fill="var(--BASE_BACKGROUND)"
      stroke="var(--NEUTRAL_3)"
      strokeWidth={3}
    />
    <path
      d="m2746.54 161.521-10.86 10.024-3.43-3.23a2.042 2.042 0 0 0-2.86 0c-.8.78-.8 2.005 0 2.785l4.34 4.12c.46.446 1.15.78 1.83.78.58 0 1.26-.223 1.72-.557l.11-.111 11.78-10.804c.8-.779.8-2.004.12-2.784-.69-.891-1.95-1.002-2.75-.223Z"
      fill="var(--NEUTRAL_3)"
    />
    <path
      d="m2659 56.173.36.564.65.115c5.82 1.018 11.48 3.928 15.36 8.094v.006c4.64 4.923 7.23 11.276 7.23 17.9 0 14.41-11.98 26.284-26.91 26.284h-43.99c-11.13-.147-20.05-8.981-20.05-19.72 0-10.19 7.94-18.717 18.33-19.57l1.32-.109.06-1.323c.58-13.527 11.96-24.362 25.85-24.362 3.02 0 5.9.576 8.36 1.44v.003l.01.003c5.48 1.872 10.24 5.62 13.42 10.675Z"
      fill="var(--BASE_BACKGROUND)"
      stroke="var(--NEUTRAL_3)"
      strokeWidth={3}
    />
    <path d="m2628 84.193 8.69 8.684m0 0 8.69-8.684m-8.69 8.684V69.72" stroke="var(--NEUTRAL_3)" strokeWidth={3} strokeLinecap="round" strokeLinejoin="round" />
    <rect x={2369.78} y={64.953} width={94.133} height={14.828} rx={7.414} fill="var(--BASE_BACKGROUND)" stroke="var(--NEUTRAL_3)" strokeWidth={3} />
    <mask id="a" fill="var(--BASE_BACKGROUND)">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M2443.25 22.09c-5 0-9.06 4.058-9.06 9.064 0 .203.01.404.02.604h-9.37c-4.92 0-8.91 3.991-8.91 8.914s3.99 8.914 8.91 8.914h58.49c4.93 0 8.92-3.99 8.92-8.914 0-4.923-3.99-8.914-8.92-8.914h-10.46c.01-.2.02-.401.02-.604 0-5.006-4.06-9.064-9.06-9.064h-20.58Z"
      />
    </mask>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M2443.25 22.09c-5 0-9.06 4.058-9.06 9.064 0 .203.01.404.02.604h-9.37c-4.92 0-8.91 3.991-8.91 8.914s3.99 8.914 8.91 8.914h58.49c4.93 0 8.92-3.99 8.92-8.914 0-4.923-3.99-8.914-8.92-8.914h-10.46c.01-.2.02-.401.02-.604 0-5.006-4.06-9.064-9.06-9.064h-20.58Z"
      fill="var(--BASE_BACKGROUND)"
    />
    <path
      d="M2434.21 31.758v3h3.2l-.21-3.197-2.99.197Zm38.66 0-2.99-.197-.21 3.197h3.2v-3Zm-35.68-.604a6.066 6.066 0 0 1 6.06-6.064v-6c-6.66 0-12.06 5.401-12.06 12.064h6Zm.01.407c-.01-.134-.01-.27-.01-.407h-6c0 .269.01.536.03.801l5.98-.394Zm-12.36 3.197h9.37v-6h-9.37v6Zm-5.91 5.914a5.915 5.915 0 0 1 5.91-5.914v-6c-6.58 0-11.91 5.334-11.91 11.914h6Zm5.91 5.914a5.915 5.915 0 0 1-5.91-5.914h-6c0 6.58 5.33 11.914 11.91 11.914v-6Zm58.49 0h-58.49v6h58.49v-6Zm5.92-5.914a5.917 5.917 0 0 1-5.92 5.914v6c6.58 0 11.92-5.334 11.92-11.914h-6Zm-5.92-5.914c3.27 0 5.92 2.648 5.92 5.914h6c0-6.58-5.34-11.914-11.92-11.914v6Zm-10.46 0h10.46v-6h-10.46v6Zm-2.98-3.604c0 .137 0 .273-.01.407l5.98.394c.02-.265.03-.532.03-.801h-6Zm-6.06-6.064a6.06 6.06 0 0 1 6.06 6.064h6c0-6.663-5.4-12.064-12.06-12.064v6Zm-20.58 0h20.58v-6h-20.58v6Z"
      fill="var(--NEUTRAL_3)"
      mask="url(#a)"
    />
    <mask id="b" fill="var(--BASE_BACKGROUND)">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M2206.7 133.828c-4.92 0-8.91-3.991-8.91-8.914s3.99-8.914 8.91-8.914h16.02a8.943 8.943 0 0 1-.93-4 9 9 0 0 1 9-9h36a9 9 0 0 1 8.06 13h11.16c4.92 0 8.91 3.991 8.91 8.914s-3.99 8.914-8.91 8.914h-79.31Z"
      />
    </mask>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M2206.7 133.828c-4.92 0-8.91-3.991-8.91-8.914s3.99-8.914 8.91-8.914h16.02a8.943 8.943 0 0 1-.93-4 9 9 0 0 1 9-9h36a9 9 0 0 1 8.06 13h11.16c4.92 0 8.91 3.991 8.91 8.914s-3.99 8.914-8.91 8.914h-79.31Z"
      fill="var(--BASE_BACKGROUND)"
    />
    <path
      d="m2222.72 116 2.69-1.335 2.15 4.335h-4.84v-3Zm52.13 0v3h-4.84l2.16-4.335 2.68 1.335Zm-74.06 8.914a5.915 5.915 0 0 0 5.91 5.914v6c-6.58 0-11.91-5.334-11.91-11.914h6Zm5.91-5.914a5.915 5.915 0 0 0-5.91 5.914h-6c0-6.58 5.33-11.914 11.91-11.914v6Zm16.02 0h-16.02v-6h16.02v6Zm2.07-7c0 .963.22 1.866.62 2.665l-5.37 2.67c-.8-1.61-1.25-3.425-1.25-5.335h6Zm6-6c-3.32 0-6 2.686-6 6h-6c0-6.627 5.37-12 12-12v6Zm36 0h-36v-6h36v6Zm6 6c0-3.314-2.69-6-6-6v-6c6.63 0 12 5.373 12 12h-6Zm-.62 2.665a6.06 6.06 0 0 0 .62-2.665h6c0 1.91-.45 3.725-1.25 5.335l-5.37-2.67Zm13.84 4.335h-11.16v-6h11.16v6Zm5.91 5.914a5.915 5.915 0 0 0-5.91-5.914v-6c6.58 0 11.91 5.334 11.91 11.914h-6Zm-5.91 5.914c3.26 0 5.91-2.648 5.91-5.914h6c0 6.58-5.33 11.914-11.91 11.914v-6Zm-79.31 0h79.31v6h-79.31v-6Z"
      fill="var(--NEUTRAL_3)"
      mask="url(#b)"
    />
    <rect
      x={1.5}
      y={-1.5}
      width={48}
      height={15}
      rx={7.5}
      transform="matrix(1 0 0 -1 2175.79 158)"
      fill="var(--BASE_BACKGROUND)"
      stroke="var(--NEUTRAL_3)"
      strokeWidth={3}
    />
    <mask id="c" fill="var(--BASE_BACKGROUND)">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M2272.76 147.694c-5 0-9.06 4.058-9.06 9.064 0 .203 0 .405.02.604h-9.37c-4.92 0-8.92 3.991-8.92 8.914s4 8.914 8.92 8.914h58.49c4.92 0 8.91-3.991 8.91-8.914s-3.99-8.914-8.91-8.914h-10.46c.01-.199.02-.401.02-.604 0-5.006-4.06-9.064-9.07-9.064h-20.57Z"
      />
    </mask>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M2272.76 147.694c-5 0-9.06 4.058-9.06 9.064 0 .203 0 .405.02.604h-9.37c-4.92 0-8.92 3.991-8.92 8.914s4 8.914 8.92 8.914h58.49c4.92 0 8.91-3.991 8.91-8.914s-3.99-8.914-8.91-8.914h-10.46c.01-.199.02-.401.02-.604 0-5.006-4.06-9.064-9.07-9.064h-20.57Z"
      fill="var(--BASE_BACKGROUND)"
    />
    <path
      d="M2263.72 157.362v3h3.2l-.21-3.197-2.99.197Zm38.66 0-3-.197-.21 3.197h3.21v-3Zm-35.68-.604a6.06 6.06 0 0 1 6.06-6.064v-6c-6.66 0-12.06 5.401-12.06 12.064h6Zm.01.407c-.01-.134-.01-.27-.01-.407h-6c0 .269.01.536.02.801l5.99-.394Zm-12.36 3.197h9.37v-6h-9.37v6Zm-5.92 5.914a5.917 5.917 0 0 1 5.92-5.914v-6c-6.58 0-11.92 5.334-11.92 11.914h6Zm5.92 5.914a5.916 5.916 0 0 1-5.92-5.914h-6c0 6.58 5.34 11.914 11.92 11.914v-6Zm58.49 0h-58.49v6h58.49v-6Zm5.91-5.914a5.908 5.908 0 0 1-5.91 5.914v6c6.58 0 11.91-5.334 11.91-11.914h-6Zm-5.91-5.914c3.27 0 5.91 2.648 5.91 5.914h6c0-6.58-5.33-11.914-11.91-11.914v6Zm-10.46 0h10.46v-6h-10.46v6Zm-2.98-3.604c0 .137-.01.273-.02.407l5.99.394c.02-.265.03-.532.03-.801h-6Zm-6.07-6.064c3.35 0 6.07 2.715 6.07 6.064h6c0-6.663-5.4-12.064-12.07-12.064v6Zm-20.57 0h20.57v-6h-20.57v6Z"
      fill="var(--NEUTRAL_3)"
      mask="url(#c)"
    />
    <path
      d="m1848.06 60.055.35.565.66.114c6.22 1.089 12.27 4.201 16.43 8.661v.006c4.96 5.268 7.74 12.067 7.74 19.157 0 15.423-12.82 28.127-28.8 28.127h-46.91c-11.92-.157-21.47-9.619-21.47-21.127 0-10.92 8.5-20.055 19.63-20.97l1.32-.108.06-1.323c.61-14.48 12.8-26.074 27.66-26.074 3.23 0 6.32.616 8.95 1.542l.01.002v.003c5.87 2.004 10.97 6.016 14.37 11.425Z"
      fill="var(--BASE_BACKGROUND)"
      stroke="var(--NEUTRAL_3)"
      strokeWidth={3}
    />
    <path
      d="m1815.54 85.296 9.35-9.549m0 0 9.34 9.549m-9.34-9.549v25.463"
      stroke="var(--NEUTRAL_3)"
      strokeWidth={3}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M1651 64h47c8.17 1.167 24.6-1.1 25-19.5.4-18.4-17.5-21.333-26.5-20.5-1-8.5-5.5-21.5-24-21.5-12.8 0-19.67 8.667-21.5 13-8 .167-24.2 5.1-25 23.5-.8 18.4 16.33 24.333 25 25Z"
      fill="var(--BASE_BACKGROUND)"
    />
    <path
      d="M1650.68 64.284c-13.46 0-24.43-11.111-24.43-24.57 0-13.614 10.97-24.569 24.43-24.569C1654.91 7.008 1663.21 2 1672.3 2c12.84 0 23.33 10.015 24.43 22.691 1.88-.626 3.91-.939 5.95-.939 10.96 0 20.04 9.077 20.04 20.188 0 11.11-8.92 20.187-20.04 20.187h-3.29"
      stroke="var(--NEUTRAL_3)"
      strokeWidth={3}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <ellipse cx={1674.49} cy={57.249} rx={16.138} ry={16.127} fill="var(--BASE_BACKGROUND)" stroke="var(--NEUTRAL_3)" strokeWidth={3} />
    <path d="m1695.3 79.317-9.77-9.76" stroke="var(--NEUTRAL_3)" strokeWidth={3} strokeLinecap="round" strokeLinejoin="round" />
    <mask id="d" fill="var(--BASE_BACKGROUND)">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M1507.39 113.017c-5 0-9.06 4.058-9.06 9.064 0 .89.13 1.749.36 2.561h-9.49c-5.2 0-9.41 4.213-9.41 9.409 0 5.197 4.21 9.41 9.41 9.41h78.31c5.2 0 9.41-4.213 9.41-9.41a9.408 9.408 0 0 0-9.41-9.409h-23.59c.24-.812.36-1.671.36-2.561a9.058 9.058 0 0 0-9.06-9.064h-27.83Z"
      />
    </mask>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M1507.39 113.017c-5 0-9.06 4.058-9.06 9.064 0 .89.13 1.749.36 2.561h-9.49c-5.2 0-9.41 4.213-9.41 9.409 0 5.197 4.21 9.41 9.41 9.41h78.31c5.2 0 9.41-4.213 9.41-9.41a9.408 9.408 0 0 0-9.41-9.409h-23.59c.24-.812.36-1.671.36-2.561a9.058 9.058 0 0 0-9.06-9.064h-27.83Z"
      fill="var(--BASE_BACKGROUND)"
    />
    <path
      d="M1498.69 124.642v3h4.01l-1.13-3.846-2.88.846Zm45.23 0-2.88-.846-1.13 3.846h4.01v-3Zm-42.59-2.561a6.06 6.06 0 0 1 6.06-6.064v-6c-6.66 0-12.06 5.402-12.06 12.064h6Zm.24 1.715a6.024 6.024 0 0 1-.24-1.715h-6c0 1.179.17 2.323.49 3.407l5.75-1.692Zm-12.37 3.846h9.49v-6h-9.49v6Zm-6.41 6.409a6.41 6.41 0 0 1 6.41-6.409v-6c-6.86 0-12.41 5.556-12.41 12.409h6Zm6.41 6.41a6.41 6.41 0 0 1-6.41-6.41h-6c0 6.854 5.55 12.41 12.41 12.41v-6Zm78.31 0h-78.31v6h78.31v-6Zm6.41-6.41a6.41 6.41 0 0 1-6.41 6.41v6c6.85 0 12.41-5.556 12.41-12.41h-6Zm-6.41-6.409a6.41 6.41 0 0 1 6.41 6.409h6c0-6.853-5.56-12.409-12.41-12.409v6Zm-23.59 0h23.59v-6h-23.59v6Zm-2.64-5.561c0 .6-.08 1.175-.24 1.715l5.76 1.692c.31-1.084.48-2.228.48-3.407h-6Zm-6.06-6.064a6.06 6.06 0 0 1 6.06 6.064h6c0-6.662-5.4-12.064-12.06-12.064v6Zm-27.83 0h27.83v-6h-27.83v6Z"
      fill="var(--NEUTRAL_3)"
      mask="url(#d)"
    />
    <rect x={1441.5} y={157.836} width={94.133} height={15.818} rx={7.909} fill="var(--BASE_BACKGROUND)" stroke="var(--NEUTRAL_3)" strokeWidth={3} />
    <path
      d="m1322.29 139.473.36.564.65.115c6.23 1.088 12.28 4.201 16.43 8.661l.01.006c4.96 5.267 7.73 12.066 7.73 19.157 0 15.422-12.82 28.127-28.8 28.127h-46.9c-11.92-.158-21.48-9.619-21.48-21.128 0-10.919 8.5-20.055 19.64-20.969l1.31-.108.06-1.323c.62-14.48 12.81-26.075 27.66-26.075 3.24 0 6.33.617 8.95 1.542l.01.003.01.002c5.86 2.005 10.96 6.017 14.36 11.426Z"
      fill="var(--BASE_BACKGROUND)"
      stroke="var(--NEUTRAL_3)"
      strokeWidth={3}
    />
    <path
      d="m1306.54 161.521-10.86 10.024-3.43-3.23a2.042 2.042 0 0 0-2.86 0c-.8.78-.8 2.005 0 2.785l4.34 4.12c.46.446 1.15.78 1.83.78.58 0 1.26-.223 1.72-.557l.11-.111 11.78-10.804c.8-.779.8-2.004.12-2.784-.69-.891-1.95-1.002-2.75-.223Z"
      fill="var(--NEUTRAL_3)"
    />
    <path
      d="m1219 56.173.36.564.65.115c5.82 1.018 11.48 3.928 15.36 8.094v.006c4.64 4.923 7.23 11.276 7.23 17.9 0 14.41-11.98 26.284-26.91 26.284h-43.99c-11.13-.147-20.05-8.981-20.05-19.72 0-10.19 7.94-18.717 18.33-19.57l1.32-.109.06-1.323c.58-13.527 11.96-24.362 25.85-24.362 3.02 0 5.9.576 8.36 1.44v.003l.01.003c5.48 1.872 10.24 5.62 13.42 10.675Z"
      fill="var(--BASE_BACKGROUND)"
      stroke="var(--NEUTRAL_3)"
      strokeWidth={3}
    />
    <path d="m1188 84.193 8.69 8.684m0 0 8.69-8.684m-8.69 8.684V69.72" stroke="var(--NEUTRAL_3)" strokeWidth={3} strokeLinecap="round" strokeLinejoin="round" />
    <rect x={929.781} y={64.953} width={94.133} height={14.828} rx={7.414} fill="var(--BASE_BACKGROUND)" stroke="var(--NEUTRAL_3)" strokeWidth={3} />
    <mask id="e" fill="var(--BASE_BACKGROUND)">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M1003.25 22.09c-5.002 0-9.06 4.058-9.06 9.064 0 .203.007.404.02.604h-9.369a8.914 8.914 0 0 0 0 17.828h58.489c4.93 0 8.92-3.99 8.92-8.914 0-4.923-3.99-8.914-8.92-8.914h-10.46c.01-.2.02-.401.02-.604 0-5.006-4.06-9.064-9.06-9.064h-20.58Z"
      />
    </mask>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M1003.25 22.09c-5.002 0-9.06 4.058-9.06 9.064 0 .203.007.404.02.604h-9.369a8.914 8.914 0 0 0 0 17.828h58.489c4.93 0 8.92-3.99 8.92-8.914 0-4.923-3.99-8.914-8.92-8.914h-10.46c.01-.2.02-.401.02-.604 0-5.006-4.06-9.064-9.06-9.064h-20.58Z"
      fill="var(--BASE_BACKGROUND)"
    />
    <path
      d="M994.21 31.758v3h3.204l-.21-3.197-2.994.197Zm38.66 0-2.99-.197-.21 3.197h3.2v-3Zm-35.68-.604a6.063 6.063 0 0 1 6.06-6.064v-6c-6.658 0-12.06 5.401-12.06 12.064h6Zm.014.407a6.082 6.082 0 0 1-.014-.407h-6c0 .269.009.536.027.801l5.987-.394Zm-12.363 3.197h9.369v-6h-9.369v6Zm-5.914 5.914a5.914 5.914 0 0 1 5.914-5.914v-6c-6.579 0-11.914 5.334-11.914 11.914h6Zm5.914 5.914a5.914 5.914 0 0 1-5.914-5.914h-6c0 6.58 5.335 11.914 11.914 11.914v-6Zm58.489 0h-58.489v6h58.489v-6Zm5.92-5.914a5.917 5.917 0 0 1-5.92 5.914v6c6.58 0 11.92-5.334 11.92-11.914h-6Zm-5.92-5.914c3.27 0 5.92 2.648 5.92 5.914h6c0-6.58-5.34-11.914-11.92-11.914v6Zm-10.46 0h10.46v-6h-10.46v6Zm-2.98-3.604c0 .137 0 .273-.01.407l5.98.394c.02-.265.03-.532.03-.801h-6Zm-6.06-6.064a6.06 6.06 0 0 1 6.06 6.064h6c0-6.663-5.4-12.064-12.06-12.064v6Zm-20.58 0h20.58v-6h-20.58v6Z"
      fill="var(--NEUTRAL_3)"
      mask="url(#e)"
    />
    <mask id="f" fill="var(--BASE_BACKGROUND)">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M766.702 133.828a8.914 8.914 0 0 1 0-17.828h16.022a9 9 0 0 1 8.064-13h36a9 9 0 0 1 9 9 8.973 8.973 0 0 1-.935 4h11.154a8.914 8.914 0 0 1 0 17.828h-79.305Z"
      />
    </mask>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M766.702 133.828a8.914 8.914 0 0 1 0-17.828h16.022a9 9 0 0 1 8.064-13h36a9 9 0 0 1 9 9 8.973 8.973 0 0 1-.935 4h11.154a8.914 8.914 0 0 1 0 17.828h-79.305Z"
      fill="var(--BASE_BACKGROUND)"
    />
    <path
      d="m782.724 116 2.686-1.335 2.155 4.335h-4.841v-3Zm52.129 0v3h-4.841l2.154-4.335 2.687 1.335Zm-74.065 8.914a5.914 5.914 0 0 0 5.914 5.914v6c-6.58 0-11.914-5.334-11.914-11.914h6Zm5.914-5.914a5.914 5.914 0 0 0-5.914 5.914h-6c0-6.58 5.334-11.914 11.914-11.914v6Zm16.022 0h-16.022v-6h16.022v6Zm2.064-7c0 .963.225 1.866.622 2.665l-5.373 2.67a11.967 11.967 0 0 1-1.249-5.335h6Zm6-6a6 6 0 0 0-6 6h-6c0-6.627 5.373-12 12-12v6Zm36 0h-36v-6h36v6Zm6 6a6 6 0 0 0-6-6v-6c6.628 0 12 5.373 12 12h-6Zm-.622 2.665a5.967 5.967 0 0 0 .622-2.665h6c0 1.91-.449 3.725-1.249 5.335l-5.373-2.67ZM846.007 119h-11.154v-6h11.154v6Zm5.914 5.914a5.914 5.914 0 0 0-5.914-5.914v-6c6.58 0 11.914 5.334 11.914 11.914h-6Zm-5.914 5.914a5.914 5.914 0 0 0 5.914-5.914h6c0 6.58-5.334 11.914-11.914 11.914v-6Zm-79.305 0h79.305v6h-79.305v-6Z"
      fill="var(--NEUTRAL_3)"
      mask="url(#f)"
    />
    <rect
      x={1.5}
      y={-1.5}
      width={48}
      height={15}
      rx={7.5}
      transform="matrix(1 0 0 -1 735.788 158)"
      fill="var(--BASE_BACKGROUND)"
      stroke="var(--NEUTRAL_3)"
      strokeWidth={3}
    />
    <mask id="g" fill="var(--BASE_BACKGROUND)">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M832.761 147.694a9.064 9.064 0 0 0-9.044 9.668h-9.369a8.914 8.914 0 0 0 0 17.828h58.491a8.914 8.914 0 0 0 0-17.828h-10.462a9.064 9.064 0 0 0-9.044-9.668h-20.572Z"
      />
    </mask>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M832.761 147.694a9.064 9.064 0 0 0-9.044 9.668h-9.369a8.914 8.914 0 0 0 0 17.828h58.491a8.914 8.914 0 0 0 0-17.828h-10.462a9.064 9.064 0 0 0-9.044-9.668h-20.572Z"
      fill="var(--BASE_BACKGROUND)"
    />
    <path
      d="M823.717 157.362v3h3.204l-.21-3.197-2.994.197Zm38.66 0-2.993-.197-.211 3.197h3.204v-3Zm-35.68-.604a6.064 6.064 0 0 1 6.064-6.064v-6c-6.663 0-12.064 5.401-12.064 12.064h6Zm.014.407a6.078 6.078 0 0 1-.014-.407h-6c0 .269.009.536.027.801l5.987-.394Zm-12.363 3.197h9.369v-6h-9.369v6Zm-5.914 5.914a5.914 5.914 0 0 1 5.914-5.914v-6c-6.58 0-11.914 5.334-11.914 11.914h6Zm5.914 5.914a5.914 5.914 0 0 1-5.914-5.914h-6c0 6.58 5.334 11.914 11.914 11.914v-6Zm58.491 0h-58.491v6h58.491v-6Zm5.914-5.914a5.914 5.914 0 0 1-5.914 5.914v6c6.58 0 11.914-5.334 11.914-11.914h-6Zm-5.914-5.914a5.914 5.914 0 0 1 5.914 5.914h6c0-6.58-5.334-11.914-11.914-11.914v6Zm-10.462 0h10.462v-6h-10.462v6Zm-2.98-3.604c0 .137-.005.273-.013.407l5.987.394c.017-.265.026-.532.026-.801h-6Zm-6.064-6.064a6.064 6.064 0 0 1 6.064 6.064h6c0-6.663-5.401-12.064-12.064-12.064v6Zm-20.572 0h20.572v-6h-20.572v6Z"
      fill="var(--NEUTRAL_3)"
      mask="url(#g)"
    />
    <path
      d="m408.058 60.055.355.565.656.114c6.225 1.089 12.273 4.201 16.429 8.661l.005.006c4.962 5.268 7.737 12.067 7.737 19.157 0 15.423-12.821 28.127-28.8 28.127h-46.906c-11.921-.157-21.479-9.619-21.479-21.127 0-10.92 8.504-20.055 19.637-20.97l1.32-.108.056-1.323c.617-14.48 12.807-26.074 27.661-26.074 3.236 0 6.325.616 8.95 1.542l.007.002.006.003c5.864 2.004 10.963 6.016 14.366 11.425Z"
      fill="var(--BASE_BACKGROUND)"
      stroke="var(--NEUTRAL_3)"
      strokeWidth={3}
    />
    <path
      d="m375.544 85.296 9.344-9.549m0 0 9.343 9.549m-9.343-9.549v25.463"
      stroke="var(--NEUTRAL_3)"
      strokeWidth={3}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <mask id="h" fill="var(--BASE_BACKGROUND)">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M67.392 113.017a9.064 9.064 0 0 0-8.697 11.625h-9.498a9.41 9.41 0 0 0 0 18.819h78.314a9.41 9.41 0 0 0 9.409-9.41 9.409 9.409 0 0 0-9.409-9.409h-23.594a9.064 9.064 0 0 0-8.697-11.625H67.392Z"
      />
    </mask>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M67.392 113.017a9.064 9.064 0 0 0-8.697 11.625h-9.498a9.41 9.41 0 0 0 0 18.819h78.314a9.41 9.41 0 0 0 9.409-9.41 9.409 9.409 0 0 0-9.409-9.409h-23.594a9.064 9.064 0 0 0-8.697-11.625H67.392Z"
      fill="var(--BASE_BACKGROUND)"
    />
    <path
      d="M58.695 124.642v3h4.009l-1.131-3.846-2.878.846Zm45.222 0-2.878-.846-1.13 3.846h4.008v-3Zm-42.59-2.561a6.064 6.064 0 0 1 6.065-6.064v-6c-6.663 0-12.064 5.402-12.064 12.064h6Zm.246 1.715a6.067 6.067 0 0 1-.245-1.715h-6c0 1.179.17 2.323.489 3.407l5.756-1.692Zm-12.376 3.846h9.498v-6h-9.498v6Zm-6.41 6.409a6.41 6.41 0 0 1 6.41-6.409v-6c-6.854 0-12.41 5.556-12.41 12.409h6Zm6.41 6.41a6.41 6.41 0 0 1-6.41-6.41h-6c0 6.854 5.556 12.41 12.41 12.41v-6Zm78.314 0H49.197v6h78.314v-6Zm6.409-6.41a6.41 6.41 0 0 1-6.409 6.41v6c6.854 0 12.409-5.556 12.409-12.41h-6Zm-6.409-6.409a6.41 6.41 0 0 1 6.409 6.409h6c0-6.853-5.555-12.409-12.409-12.409v6Zm-23.594 0h23.594v-6h-23.594v6Zm-2.633-5.561c0 .6-.086 1.175-.245 1.715l5.756 1.692c.319-1.084.489-2.228.489-3.407h-6Zm-6.064-6.064a6.064 6.064 0 0 1 6.064 6.064h6c0-6.662-5.401-12.064-12.064-12.064v6Zm-27.828 0H95.22v-6H67.392v6Z"
      fill="var(--NEUTRAL_3)"
      mask="url(#h)"
    />
    <rect x={1.5} y={157.836} width={94.133} height={15.818} rx={7.909} fill="var(--BASE_BACKGROUND)" stroke="var(--NEUTRAL_3)" strokeWidth={3} />
    <path
      d="M211.027 64h47c8.167 1.167 24.6-1.1 25-19.5.4-18.4-17.5-21.333-26.5-20.5-1-8.5-5.5-21.5-24-21.5-12.8 0-19.667 8.667-21.5 13-8 .167-24.2 5.1-25 23.5-.8 18.4 16.333 24.333 25 25Z"
      fill="var(--BASE_BACKGROUND)"
    />
    <path
      d="M210.712 64.284c-13.468 0-24.43-11.111-24.43-24.57 0-13.614 10.962-24.569 24.43-24.569C214.94 7.008 223.24 2 232.323 2c12.842 0 23.334 10.015 24.43 22.691 1.88-.626 3.916-.939 5.951-.939 10.963 0 20.046 9.077 20.046 20.188 0 11.11-8.927 20.187-20.046 20.187h-3.288"
      stroke="var(--NEUTRAL_3)"
      strokeWidth={3}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <ellipse cx={234.516} cy={57.249} rx={16.138} ry={16.127} fill="var(--BASE_BACKGROUND)" stroke="var(--NEUTRAL_3)" strokeWidth={3} />
    <path d="m255.326 79.317-9.768-9.76" stroke="var(--NEUTRAL_3)" strokeWidth={3} strokeLinecap="round" strokeLinejoin="round" />
  </svg>
);
