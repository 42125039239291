import { COLOR, FONT_SIZE, FONT_WEIGHT, GAP, HC_COLOR, HC_SECONDARY_COLOR, RADIUS, TRANSITION_SPEED } from '@constants';
import { css } from '@emotion/react';

export const styles = {
  sideContent: css`
    display: flex;
    align-items: center;
    padding: 12px;
    padding-left: 20px;
    gap: ${GAP.S};
    cursor: pointer;
    :hover {
      background-color: ${COLOR.NEUTRAL_4};
    }
  `,
  active: css`
    background-color: ${HC_SECONDARY_COLOR.BRAND_1};
    font-weight: ${FONT_WEIGHT.BOLD};
    color: ${HC_COLOR.BRAND_1};
  `,
  extraCount: css`
    display: flex;
    align-items: center;
    justify-content: center;
    height: 32px;
    letter-spacing: 0px;
    font-size: ${FONT_SIZE.M};
  `,
  content: css`
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    gap: ${GAP.XS};
  `,
  footer: css`
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    gap: ${GAP.XS};
    margin-top: 100px;
  `,
  mainContent: css`
    flex: 1;
    overflow: auto;
  `,
  option: css`
    width: 100%;
    padding: 0 ${GAP.XS};
  `,
  optionPrevent: css`
    display: block;
    width: 100%;
  `,
  optionContent: css`
    padding: 0 ${GAP.M};
    cursor: pointer;
    border-radius: ${RADIUS.S};
    height: 32px;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    transition: opacity ${TRANSITION_SPEED};
    :hover {
      opacity: 0.6;
    }
  `,
  divider: css`
    width: 100%;
    border-top: 1px ${COLOR.NEUTRAL_6} solid;
  `,
  show: css`
    transition: all 0.5s;
    display: block;
    height: max-content;
    opacity: 1;
    @starting-style {
      height: 0px;
      opacity: 0;
    }
  `,
  hide: css`
    display: none;
    height: 0px;
    opacity: 0;
  `
};
