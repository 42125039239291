import type { GeneralModel } from '@cyferd/client-engine';
import { ViewModel } from '@cyferd/client-engine';
import { styles } from './styles';
import { CTA } from '../CTA';
import { CTAType } from '@components/elements/CTA';
import dayjs from 'dayjs';
import { useMemo } from 'react';
import { InlineDropdown } from '../InlineDropdown';

export interface CalendarTitleProps {
  currentDate: GeneralModel.DateLike;
  referenceDate: GeneralModel.DateLike;
  testid?: string;
  disabled?: boolean;
  calendarType: ViewModel.CyCalendarType;
  onChangeCalendarType: (event: ViewModel.CyCalendarType) => void;
  onChange: (event: GeneralModel.DateLike) => void;
  isMobile?: boolean;
}

export const CalendarTitle = ({
  testid = 'CalendarTitle',
  currentDate,
  referenceDate,
  disabled,
  calendarType,
  onChange,
  onChangeCalendarType,
  isMobile = false
}: CalendarTitleProps) => {
  const isDayControl = calendarType === ViewModel.CyCalendarType.DAY;
  const isMonthControl = calendarType === ViewModel.CyCalendarType.MONTH;
  const formattedReferenceDate = useMemo(() => {
    const date = dayjs(referenceDate);
    if (date.startOf('day').isSame(dayjs(Date.now()).startOf('day'))) return 'Today';
    if (isMobile) return date.format('DD MMM');
    return date.format('D MMMM YYYY');
  }, [referenceDate, isMobile]);

  const formattedCurrentDate = useMemo(() => {
    if (isDayControl) return dayjs(currentDate).format('D MMM YYYY');
    if (isMonthControl) return dayjs(currentDate).format('MMMM YYYY');
    return `${dayjs(currentDate).startOf('week').format('DD MMM')} - ${dayjs(currentDate).endOf('week').format('DD MMM YYYY')}`;
  }, [currentDate, isDayControl, isMonthControl]);

  return (
    <div css={styles.container} data-testid={testid}>
      <div css={styles.typeSelector}>
        <InlineDropdown
          label={null}
          minWidth={10}
          required={false}
          disabled={disabled}
          onChange={onChangeCalendarType}
          value={calendarType}
          options={[
            { label: 'Month', value: ViewModel.CyCalendarType.MONTH, color: 'BRAND_1' },
            { label: 'Week', value: ViewModel.CyCalendarType.WEEK, color: 'BRAND_1' },
            { label: 'Day', value: ViewModel.CyCalendarType.DAY, color: 'BRAND_1' }
          ]}
        />
      </div>
      {!isMobile && (
        <>
          <CTA
            testid="calendar-title-prev"
            type={CTAType.ACTION_TERTIARY}
            disabled={disabled}
            size={ViewModel.CTASize.SMALL}
            icon="chevron_left"
            onClick={() => onChange(dayjs(currentDate).subtract(1, calendarType).toISOString())}
          />
          <p css={[styles.title, isDayControl && styles.dayTitle, isMonthControl && styles.monthTitle]}>{formattedCurrentDate}</p>
          <CTA
            testid="calendar-title-next"
            type={CTAType.ACTION_TERTIARY}
            disabled={disabled}
            size={ViewModel.CTASize.SMALL}
            icon="chevron_right"
            onClick={() => onChange(dayjs(currentDate).add(1, calendarType).toISOString())}
          />
        </>
      )}
      <div style={isMobile ? { marginTop: 4 } : {}}>
        <CTA
          testid="calendar-title-reference"
          type={CTAType.SECONDARY}
          disabled={disabled}
          size={ViewModel.CTASize.SMALL}
          label={formattedReferenceDate}
          onClick={() => onChange(referenceDate)}
        />
      </div>
    </div>
  );
};

CalendarTitle.displayName = 'CalendarTitle';
