import { COLOR, TRANS } from '@constants';
import { styles } from './styles';
import { Translate } from '@cyferd/client-engine';

export const Right = () => {
  return (
    <div css={styles.container}>
      <h3 css={styles.title}>
        <Translate>{TRANS.client.fields.info.right}</Translate>
      </h3>
      <svg width="216" height="192" viewBox="0 0 216 192" fill="none" xmlns="http://www.w3.org/2000/svg">
        <g filter="url(#filter0_d_1832_7096)">
          <rect x="156" y="128" width="152" height="29" rx="2" transform="rotate(-180 156 128)" fill={COLOR.NEUTRAL_9} />
          <rect x="156" y="95" width="152" height="29" rx="2" transform="rotate(-180 156 95)" fill={COLOR.NEUTRAL_9} />
          <rect x="156" y="62" width="152" height="29" rx="2" transform="rotate(-180 156 62)" fill={COLOR.NEUTRAL_9} />
          <rect x="156" y="194" width="152" height="29" rx="2" transform="rotate(-180 156 194)" fill={COLOR.NEUTRAL_9} />
          <rect x="156" y="29" width="152" height="29" rx="2" transform="rotate(-180 156 29)" fill={COLOR.NEUTRAL_9} />
          <rect x="156" y="161" width="152" height="29" rx="2" transform="rotate(-180 156 161)" fill={COLOR.NEUTRAL_9} />
          <rect x="146" y="119" width="61" height="10" rx="2" transform="rotate(-180 146 119)" fill={COLOR.TOP_BACKGROUND} />
          <rect x="146" y="86" width="61" height="10" rx="2" transform="rotate(-180 146 86)" fill={COLOR.TOP_BACKGROUND} />
          <rect x="146" y="53" width="61" height="10" rx="2" transform="rotate(-180 146 53)" fill={COLOR.TOP_BACKGROUND} />
          <rect x="146" y="185" width="61" height="10" rx="2" transform="rotate(-180 146 185)" fill={COLOR.TOP_BACKGROUND} />
          <rect x="146" y="20" width="61" height="10" rx="2" transform="rotate(-180 146 20)" fill={COLOR.TOP_BACKGROUND} />
          <rect x="146" y="152" width="61" height="10" rx="2" transform="rotate(-180 146 152)" fill={COLOR.TOP_BACKGROUND} />
          <rect x="74" y="119" width="61" height="10" rx="2" transform="rotate(-180 74 119)" fill={COLOR.TOP_BACKGROUND} />
          <rect x="74" y="86" width="61" height="10" rx="2" transform="rotate(-180 74 86)" fill={COLOR.TOP_BACKGROUND} />
          <rect x="74" y="53" width="61" height="10" rx="2" transform="rotate(-180 74 53)" fill={COLOR.TOP_BACKGROUND} />
          <rect x="74" y="185" width="61" height="10" rx="2" transform="rotate(-180 74 185)" fill={COLOR.TOP_BACKGROUND} />
          <rect x="74" y="20" width="61" height="10" rx="2" transform="rotate(-180 74 20)" fill={COLOR.TOP_BACKGROUND} />
          <rect x="74" y="152" width="61" height="10" rx="2" transform="rotate(-180 74 152)" fill={COLOR.TOP_BACKGROUND} />
          <rect x="164" y="193" width="193" height="56" rx="2" transform="rotate(-90 164 193)" fill={COLOR.BRAND_3} />
        </g>
      </svg>
    </div>
  );
};
