import { GAP } from '@constants';
import { css } from '@emotion/css';

export const styles = {
  container: css`
    padding: 0 ${GAP.M};
  `,
  infoLabelValueContainer: css`
    margin-top: ${GAP.S};
  `,
  infoLabelValueDiagram: css`
    margin-top: ${GAP.L};
  `
};
