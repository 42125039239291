import * as React from 'react';

const Disconnected = props => (
  <svg width={197} height={200} fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      d="M100.06 171.88c6.606 6.864 4.955 16.017 1.651 19.83 3.304.763 4.13.763 8.259 0 5.781-9.152 1.651-16.017-2.478-22.118-4.129.762-7.432 2.288-7.432 2.288Z"
      fill="var(--NEUTRAL_4)"
      stroke="var(--NEUTRAL_3)"
    />
    <path
      d="m110.696 173.137-8.509 2.836s-.776-1.362-1.418-2.127c-.504-.6-1.418-1.418-1.418-1.418l9.218-2.836 2.127 3.545Z"
      fill="var(--BRAND_1)"
      fillOpacity={0.2}
    />
    <path
      d="M152.119 196.57c-22.758.975-70.345 2.87-85.226 2.929a93.908 93.908 0 0 0 .302-1.024c.139-.478.269-.924.403-1.344.284-.887.576-1.614.976-2.198a3.57 3.57 0 0 1 1.62-1.321c.736-.311 1.726-.491 3.104-.491 5.686 0 62.37-2.953 70.879-3.692 3.227-.28 5.183.936 6.354 2.529 1.065 1.449 1.505 3.246 1.588 4.612Z"
      fill="var(--NEUTRAL_4)"
      stroke="var(--NEUTRAL_4)"
    />
    <path d="M156.936 4.474 124.94 65.25a2.559 2.559 0 1 1-4.528-2.383L152.407 2.09a2.559 2.559 0 0 1 4.529 2.384Z" fill="var(--NEUTRAL_4)" />
    <ellipse cx={139.348} cy={69.36} rx={4.72} ry={4.714} fill="var(--NEUTRAL_4)" />
    <path
      d="M86.053 42.372C107.05.898 153.942 6.027 170.891 20.813c.859.75.846 2.058.04 2.863-.717.716-1.852.801-2.695.238-29.188-19.509-64.071-6.729-77.007 20.72-.65 1.378-2.237 2.08-3.664 1.545-1.531-.574-2.25-2.348-1.512-3.807Z"
      fill="var(--NEUTRAL_4)"
    />
    <path
      d="M154.267 38.45c-14.259-8.556-35.485-3.167-43.545 13.073-.624 1.26-2.11 1.892-3.401 1.34-1.292-.554-1.875-2.073-1.221-3.317 12.39-23.55 36.364-23.179 51.204-15.542 1.549.797 1.776 2.855.543 4.086-.946.945-2.433 1.048-3.58.36ZM147.482 55.424c-8.444-4.28-18.094-1.824-22.828 9.692-.489 1.19-1.822 1.84-3.023 1.381-1.052-.401-1.653-1.525-1.325-2.602 5.1-16.744 21.734-17.432 30.046-12.292 1.078.667 1.225 2.109.47 3.127-.765 1.032-2.195 1.275-3.34.694Z"
      fill="var(--NEUTRAL_4)"
    />
    <path d="M46.36 14.437v6.399M49.559 17.636h-6.4" stroke="var(--BRAND_1)" strokeLinecap="round" />
    <circle cx={63.956} cy={35.233} r={3.233} stroke="var(--BRAND_1)" />
    <path d="M193.333 37.783v4.514M191.076 40.04h4.514" stroke="var(--BRAND_1)" strokeLinecap="round" />
    <circle r={2.133} transform="scale(-1 1) rotate(15 -302.084 -673.296)" stroke="var(--BRAND_1)" />
    <path d="M11.165 101.889v6.399M14.364 105.089H7.966" stroke="var(--BE_2)" strokeLinecap="round" />
    <circle cx={4.233} cy={139.75} r={3.233} stroke="var(--NEUTRAL_4)" />
    <circle cx={17.031} cy={156.814} r={2.166} stroke="var(--BRAND_1)" />
    <path d="M171.763 152.93 57.138 183.169 34.75 113.207l116.023-32.346 20.99 72.069Z" fill="var(--NEUTRAL_4)" stroke="var(--NEUTRAL_4)" />
    <path d="m165.274 148.6-102.06 27.476-19.246-60.248 103.26-29.292 18.046 62.064Z" fill="var(--BASE_BACKGROUND)" stroke="var(--NEUTRAL_4)" />
    <ellipse cx={98.79} cy={116.05} rx={3.545} ry={6.382} transform="rotate(-15 98.79 116.05)" fill="var(--BRAND_1)" />
    <ellipse cx={127.846} cy={102.572} rx={3.545} ry={6.382} transform="rotate(-15 127.846 102.572)" fill="var(--BRAND_1)" />
    <path
      d="M103.121 145.483c-1.319-6.862 3.518-9.15 5.716-9.607 6.157-1.281 7.036 6.404 13.192 4.117 4.925-1.83 5.277-6.557 4.837-8.692"
      stroke="var(--BRAND_1)"
      strokeLinecap="round"
    />
  </svg>
);

export default Disconnected;
