import { COLOR, FONT_SIZE, FONT_WEIGHT, GAP, RADIUS, SHADOW } from '@constants';
import { css } from '@emotion/react';

export const styles = {
  container: css`
    box-shadow: ${SHADOW.S};
    background-color: ${COLOR.NEUTRAL_5};
    border-radius: ${RADIUS.S};
    display: flex;
    justify-content: space-between;
    gap: ${GAP.M};
    padding: ${GAP.S} ${GAP.M};
    :hover {
      [data-selector='calendar-card-actions'] {
        display: block;
      }
    }
  `,
  popover: css`
    display: inline-block;
    width: 100%;
  `,
  bar: css`
    width: 4px;
    border-radius: 80px;
    margin: -${GAP.S} 0 -${GAP.S} -${GAP.M};
  `,
  content: css`
    flex: 1;
    display: flex;
    flex-direction: column;
    gap: ${GAP.XS};
  `,
  contentRow: css`
    display: flex;
    gap: ${GAP.XS};
    align-items: center;
  `,
  ball: css`
    min-width: ${FONT_SIZE.XS};
    min-height: ${FONT_SIZE.XS};
    border-radius: 100%;
  `,
  title: css`
    font-size: ${FONT_SIZE.S};
    font-weight: ${FONT_WEIGHT.EXTRA_BOLD};
    color: ${COLOR.NEUTRAL_1};
    overflow: hidden;
    word-break: break-word;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 1;
  `,
  time: css`
    color: ${COLOR.NEUTRAL_2};
    font-size: ${FONT_SIZE.XS};
    font-weight: ${FONT_WEIGHT.NORMAL};
  `,
  actions: css`
    display: none;
  `
};
