import { GAP } from '@constants';
import { css } from '@emotion/react';

export const styles = {
  headerContainer: css`
    display: flex;
    gap: ${GAP.XS};
    margin-bottom: ${GAP.XS};
  `
};
