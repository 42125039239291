import { COLOR, TRANS } from '@constants';
import { styles } from './styles';
import { Translate } from '@cyferd/client-engine';

export const Top = () => {
  return (
    <div css={styles.container}>
      <h3 css={styles.title}>
        <Translate>{TRANS.client.fields.info.top}</Translate>
      </h3>
      <svg width="216" height="192" viewBox="0 0 216 192" fill="none" xmlns="http://www.w3.org/2000/svg">
        <rect y="64" width="216" height="29" rx="2" fill={COLOR.NEUTRAL_9} />
        <rect y="97" width="216" height="29" rx="2" fill={COLOR.NEUTRAL_9} />
        <rect y="130" width="216" height="29" rx="2" fill={COLOR.NEUTRAL_9} />
        <rect y="163" width="216" height="29" rx="2" fill={COLOR.NEUTRAL_9} />
        <rect x="10" y="73" width="60" height="10" rx="2" fill={COLOR.TOP_BACKGROUND} />
        <rect x="10" y="106" width="60" height="10" rx="2" fill={COLOR.TOP_BACKGROUND} />
        <rect x="10" y="139" width="60" height="10" rx="2" fill={COLOR.TOP_BACKGROUND} />
        <rect x="10" y="172" width="60" height="10" rx="2" fill={COLOR.TOP_BACKGROUND} />
        <rect x="147" y="73" width="60" height="10" rx="2" fill={COLOR.TOP_BACKGROUND} />
        <rect x="79" y="73" width="60" height="10" rx="2" fill={COLOR.TOP_BACKGROUND} />
        <rect x="147" y="106" width="60" height="10" rx="2" fill={COLOR.TOP_BACKGROUND} />
        <rect x="79" y="106" width="60" height="10" rx="2" fill={COLOR.TOP_BACKGROUND} />
        <rect x="147" y="139" width="60" height="10" rx="2" fill={COLOR.TOP_BACKGROUND} />
        <rect x="79" y="139" width="60" height="10" rx="2" fill={COLOR.TOP_BACKGROUND} />
        <rect x="147" y="172" width="60" height="10" rx="2" fill={COLOR.TOP_BACKGROUND} />
        <rect x="79" y="172" width="60" height="10" rx="2" fill={COLOR.TOP_BACKGROUND} />
        <rect width="216" height="56" rx="2" fill={COLOR.BRAND_3} />
      </svg>
    </div>
  );
};
