import { styles } from './styles';
import { Icon } from '@components/elements/Icon';
import { COLOR, FONT_SIZE, TRANS } from '@constants';
import { Translate } from '@cyferd/client-engine';
import { useCallback, useMemo, useState } from 'react';

const DISMISSED_BANNER_KEY = 'mobile-app-banner-dismissed';
const MOBILE_PATH_PREFIX = '/m';

export const MobileAppBanner = () => {
  const [isDismissed, setIsDismissed] = useState(false);

  const handleClose = useCallback(() => {
    sessionStorage.setItem(DISMISSED_BANNER_KEY, 'true');
    setIsDismissed(true);
  }, []);

  const mobileUrl = useMemo(() => `${MOBILE_PATH_PREFIX}${window.location.pathname + window.location.search}`, []);

  const isDismissedInSession = useMemo(() => sessionStorage.getItem(DISMISSED_BANNER_KEY) === 'true', []);
  if (isDismissed || isDismissedInSession) return null;

  return (
    <div className={styles.container}>
      <div className={styles.content}>
        <div className={styles.iconContainer}>
          <Icon name="CyferdIcon" size={FONT_SIZE.XL} fill={COLOR.BASE_BACKGROUND} />
        </div>
        <a className={styles.link} href={mobileUrl} data-testid="open-in-app" target="_blank">
          <Translate>{TRANS.client.buttons.openInApp}</Translate>
        </a>
        <div className={styles.closeContainer} onClick={handleClose} data-testid="dismiss-button">
          <Icon name="close" fill={COLOR.NEUTRAL_2} size={FONT_SIZE.XL} />
        </div>
      </div>
      <iframe id="l" width="1" height="1" style={{ visibility: 'hidden' }}></iframe>
    </div>
  );
};
