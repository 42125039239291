import { EMPTY_CONTAINER_HEIGHT, GAP } from '@constants';
import { css } from '@emotion/react';

export const styles = {
  container: css`
    display: contents;
    overflox-x: auto !important;
  `,
  content: css`
    display: flow;
    flex: 1;
    overflow: auto;
  `,
  spinnerContainer: css`
    display: flex;
    width: 100%;
    height: 100%;
    flex: 1;
    align-items: center;
    justify-content: center;
    min-height: ${EMPTY_CONTAINER_HEIGHT.XS}px;
  `,
  progressBarContainer: css`
    margin-top: -${GAP.S};
    margin-bottom: ${GAP.XS};
  `
};
