import { useContext, useMemo } from 'react';

import type { CollectionModel } from '@cyferd/client-engine';
import { ApiModel, GeneralModel, ViewModel, useTranslate } from '@cyferd/client-engine';

import { CustomViewSelector } from '../../../shared/CustomViewSelector';
import type { EditorContextValue } from '../../../shared/EditorHome';
import { EditorContext } from '../../../shared/EditorHome';
import { styles } from './styles';
import { LinkingPageDiagram, PredefinedDiagrams } from '../../../../../@components/elements/LinkingPageDiagram';
import { SchemaForm } from '../../../../views/shared/SchemaForm';
import { CyLayout } from '@components/smart/CyLayout';
import { TRANS } from '@constants';
import { Fieldset } from '@components/elements/Fieldset';
import { getPartialViewSchema, viewInputList } from '@models/view';
import { Left, Right, Top } from '../../../../../assets/partials';
import { userInputList } from '@models/user';

const coreViewsConfigSchema: GeneralModel.JSONSchema = {
  type: 'object',
  label: ' ',
  metadata: { fieldSize: GeneralModel.FieldSize.FULL },
  properties: {
    TABLE: {
      label: ' ',
      type: 'object',
      metadata: { detailGroupId: 'TABLE' },
      properties: {
        top: getPartialViewSchema(TRANS.client.fields.titles.above, <Top />),
        left: getPartialViewSchema(TRANS.client.fields.titles.left, <Left />)
      }
    },
    DETAIL: {
      label: ' ',
      type: 'object',
      metadata: { detailGroupId: 'DETAIL' },
      properties: {
        top: getPartialViewSchema(TRANS.client.fields.titles.above, <Top />),
        left: getPartialViewSchema(TRANS.client.fields.titles.left, <Left />),
        right: getPartialViewSchema(TRANS.client.fields.titles.right, <Right />)
      }
    }
  }
};

const inputList = [...userInputList, ...viewInputList];

export const CustomViewEditor = () => {
  const { translate } = useTranslate();
  const { item, setItem } = useContext<EditorContextValue<CollectionModel.Collection>>(EditorContext);

  const detailGroupList = useMemo(
    () =>
      [
        { id: 'TABLE', image: 'table_rows_narrow', name: translate(TRANS.client.groups.titles.coreViewsConfig, { view: 'Table' }) },
        { id: 'DETAIL', image: 'description', name: translate(TRANS.client.groups.titles.coreViewsConfig, { view: 'Detail' }) }
      ] as CollectionModel.Collection['detailGroupList'],
    [translate]
  );

  const infoLabelValue = useMemo(
    () => (
      <div className={styles.infoLabelValueContainer}>
        <div>
          A view is a page that displays your data. It can be made up of components such as lists, actions, charts, forms, etc. Default views are used if there
          are no custom linked views.
        </div>
        <div className={styles.infoLabelValueDiagram}>
          <LinkingPageDiagram value={PredefinedDiagrams.collectionLinkedViews} />
        </div>
      </div>
    ),
    []
  );

  return (
    <div className={styles.container}>
      <CyLayout type={ViewModel.LayoutType.FULL}>
        <CyLayout
          type={ViewModel.LayoutType.FULL}
          title={TRANS.client.groups.titles.customizeCoreViews}
          subtitle={TRANS.client.groups.descriptions.customizeCoreViews}
        >
          <SchemaForm
            wrapDetailGroups={true}
            schema={coreViewsConfigSchema}
            detailGroupList={detailGroupList}
            value={item?.coreViewsConfig}
            inputList={inputList}
            onChange={/* istanbul ignore next */ coreViewsConfig => setItem({ ...item, coreViewsConfig })}
          />
        </CyLayout>
        <CyLayout
          type={ViewModel.LayoutType.FULL}
          title={TRANS.client.groups.titles.replaceCoreViews}
          subtitle={TRANS.client.groups.descriptions.replaceCoreViews}
        >
          <CyLayout type={ViewModel.LayoutType.FULL}>
            <Fieldset isDetailGroup={true} title="Select a view to replace" info={infoLabelValue as any}>
              <CustomViewSelector
                title="Detail view"
                item={item}
                onChange={setItem}
                tag="DETAIL"
                collectionId={ApiModel.ApiEntity.ENTITY}
                associationKey="isView"
              />
              <CustomViewSelector
                title="Table view"
                item={item}
                onChange={setItem}
                tag="TABLE"
                collectionId={ApiModel.ApiEntity.ENTITY}
                associationKey="isView"
              />
            </Fieldset>
          </CyLayout>
        </CyLayout>
      </CyLayout>
    </div>
  );
};
