import { COLOR, FONT_SIZE, FONT_WEIGHT, GAP, RADIUS, SHADOW } from '@constants';
import { css } from '@emotion/css';

export const styles = {
  container: css`
    position: fixed;
    display: flex;
    bottom: ${GAP.XXL};
    width: 100%;
    z-index: 1000;
  `,
  content: css`
    display: flex;
    align-items: center;
    margin: 0 auto;
    background-color: ${COLOR.TOP_BACKGROUND};
    height: 60px;
    padding: 0 ${GAP.L};
    border-radius: 50px;
    box-shadow: ${SHADOW.S};
  `,
  link: css`
    color: ${COLOR.BRAND_1};
    font-size: ${FONT_SIZE.XXM};
    font-weight: ${FONT_WEIGHT.SEMI_BOLD};
    text-decoration: underline;
    background: transparent;
    text-align: center;
    margin: 0 ${GAP.L};
    line-height: 1.8em;
    cursor: pointer;
  `,
  iconContainer: css`
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: ${RADIUS.S};
    width: ${GAP.XXL};
    height: ${GAP.XXL};
    background-color: ${COLOR.BRAND_1};
  `,
  closeContainer: css`
    display: flex;
    cursor: pointer;
  `
};
