import { COLOR, TRANS } from '@constants';
import { styles } from './styles';
import { Translate } from '@cyferd/client-engine';

export const Left = () => {
  return (
    <div css={styles.container}>
      <h3 css={styles.title}>
        <Translate>{TRANS.client.fields.info.left}</Translate>
      </h3>
      <svg width="216" height="192" viewBox="0 0 216 192" fill="none" xmlns="http://www.w3.org/2000/svg">
        <rect x="64" y="66" width="152" height="29" rx="2" fill={COLOR.NEUTRAL_9} />
        <rect x="64" y="99" width="152" height="29" rx="2" fill={COLOR.NEUTRAL_9} />
        <rect x="64" y="132" width="152" height="29" rx="2" fill={COLOR.NEUTRAL_9} />
        <rect x="64" width="152" height="29" rx="2" fill={COLOR.NEUTRAL_9} />
        <rect x="64" y="165" width="152" height="29" rx="2" fill={COLOR.NEUTRAL_9} />
        <rect x="64" y="33" width="152" height="29" rx="2" fill={COLOR.NEUTRAL_9} />
        <rect x="74" y="75" width="61" height="10" rx="2" fill={COLOR.TOP_BACKGROUND} />
        <rect x="74" y="108" width="61" height="10" rx="2" fill={COLOR.TOP_BACKGROUND} />
        <rect x="74" y="141" width="61" height="10" rx="2" fill={COLOR.TOP_BACKGROUND} />
        <rect x="74" y="9" width="61" height="10" rx="2" fill={COLOR.TOP_BACKGROUND} />
        <rect x="74" y="174" width="61" height="10" rx="2" fill={COLOR.TOP_BACKGROUND} />
        <rect x="74" y="42" width="61" height="10" rx="2" fill={COLOR.TOP_BACKGROUND} />
        <rect x="146" y="75" width="61" height="10" rx="2" fill={COLOR.TOP_BACKGROUND} />
        <rect x="146" y="108" width="61" height="10" rx="2" fill={COLOR.TOP_BACKGROUND} />
        <rect x="146" y="141" width="61" height="10" rx="2" fill={COLOR.TOP_BACKGROUND} />
        <rect x="146" y="9" width="61" height="10" rx="2" fill={COLOR.TOP_BACKGROUND} />
        <rect x="146" y="174" width="61" height="10" rx="2" fill={COLOR.TOP_BACKGROUND} />
        <rect x="146" y="42" width="61" height="10" rx="2" fill={COLOR.TOP_BACKGROUND} />
        <rect x="56" y="1" width="193" height="56" rx="2" transform="rotate(90 56 1)" fill={COLOR.BRAND_3} />
      </svg>
    </div>
  );
};
