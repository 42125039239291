import { ApiModel, GeneralModel } from '@cyferd/client-engine';
import type { GeneralModel as ClientGeneralModel } from '@models';

export const getInitialState = (): ClientGeneralModel.FavoriteState => ({
  current: null,
  list: null
});

export const reducer = (state: ClientGeneralModel.FavoriteState = getInitialState(), action: ClientGeneralModel.Action): ClientGeneralModel.FavoriteState => {
  const { type, payload } = action;
  switch (type) {
    case ApiModel.TriggerActionType.DISPATCH_SET_DATA:
      if (payload?.section !== ApiModel.ApiSetDataSections.FAVORITE || payload?.pointer === GeneralModel.IGNORED_POINTER_ID) {
        return state;
      }
      return {
        ...state,
        list: payload.value
      };
    case ApiModel.TriggerActionType.DISPATCH_SET_CURRENT_FAVORITE:
      return { ...state, current: payload };
    default:
      return state;
  }
};
