import { COLOR, FONT_SIZE, FONT_WEIGHT, FOREGROUND_COLOR, SECONDARY_COLOR, TRANSITION_SPEED } from '@constants';
import { css } from '@emotion/react';

const cellSize = '28px';

export const styles = {
  container: css`
    width: 100%;
  `,
  controls: css`
    display: flex;
    align-items: center;
    justify-content: center;
  `,
  controlsTitle: css`
    font-weight: ${FONT_WEIGHT.EXTRA_BOLD};
    font-size: ${FONT_SIZE.M};
    color: ${COLOR.NEUTRAL_1};
    margin: 0 15px;
  `,
  header: css`
    display: flex;
    justify-content: space-between;
  `,
  headerItem: css`
    font-weight: ${FONT_WEIGHT.SEMI_BOLD};
    color: ${COLOR.NEUTRAL_2};
  `,
  week: css`
    display: flex;
  `,
  isActiveWeek: css`
    background-color: ${SECONDARY_COLOR.BRAND_1};
  `,
  day: css`
    font-size: ${FONT_SIZE.XXS};
    font-weight: ${FONT_WEIGHT.NORMAL};
    color: ${COLOR.NEUTRAL_2};
    width: ${cellSize};
    height: ${cellSize};
    margin: 0 auto;
    display: flex;
    align-items: center;
    justify-content: center;
    transition: all calc(${TRANSITION_SPEED} / 2);
    :hover {
      border-radius: 100%;
      background-color: ${COLOR.NEUTRAL_4};
      color: ${COLOR.NEUTRAL_1};
    }
  `,
  selectableDay: css`
    cursor: pointer;
  `,
  isFromCurrentMonth: css`
    color: ${COLOR.NEUTRAL_1};
  `,
  activeDay: css`
    border-radius: 100%;
    background-color: ${COLOR.BRAND_1};
    color: ${FOREGROUND_COLOR.BRAND_1};
  `,
  referenceDay: css`
    border-radius: 100%;
    border: 1px solid ${COLOR.BRAND_1};
    background-color: ${SECONDARY_COLOR.BRAND_1};
  `,
  inactiveWeekDay: css`
    border: 0;
  `,
  highlightedDay: css`
    background-color: ${SECONDARY_COLOR.BRAND_1};
  `
};
