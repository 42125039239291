import { useCallback, useContext, useMemo, useState } from 'react';
import type { Observable } from 'rxjs';
import { EMPTY, takeUntil } from 'rxjs';

import type { CollectionModel, FlowModel } from '@cyferd/client-engine';
import {
  ApiModel,
  ErrorBoundary,
  GeneralModel,
  ViewModel,
  actions,
  createUUID,
  mergeTruthy,
  normalize,
  tapOnSuccess,
  useTranslate,
  useUnmountObservable
} from '@cyferd/client-engine';

import { ENV, TRANS } from '@constants';
import { listActionsInputList, recordActionsInputList, triggerInputList } from '@models/flow';
import { GlobalContext } from '../../../../../state-mgmt/GlobalState';
import { getLink } from '@utils/getLink';
import { useOnOpenExternalUrl } from '@utils/useOnOpenExternalUrl';
import { BuilderCyList } from '../../../../shared/BuilderCyList';
import { ItemList } from '@components/elements/ItemList';
import { SchemaForm } from '../../../../shared/SchemaForm';
import { styles } from './styles';
import { LinkedFlowType } from '../types';
import { flowFormDetailGroupList, flowFormSchema, recordActionFormSchema } from './schemas';
import { Modal } from '@components/elements/Modal';
import { CTA, CTAType } from '@components/elements/CTA';
import { Stepper } from '@components/elements/Stepper';
import type { IconKeys } from '@components/elements/Icon';
import { useRequest } from '@utils/useRequest';
import { CyText } from '@components/smart/CyText';
import { Layout } from '@components/elements/Layout';

const hasInputs = (flow: FlowModel.Flow) => !!Object.keys(flow?.model?.schema?.properties || {}).length;

export interface TriggerSelectorProps {
  title: string;
  subtitle?: string;
  ctaLabel: string;
  ctaIcon: IconKeys;
  entityId: string;
  associationKey: string;
  linkedFlowType?: LinkedFlowType;
  cursorFilter?: GeneralModel.FetchCriteria;
}

export const TriggerSelector = ({ title, subtitle, ctaLabel, ctaIcon, entityId, linkedFlowType, cursorFilter, associationKey }: TriggerSelectorProps) => {
  const { translate } = useTranslate();
  const id = useMemo(createUUID, []);
  const { deps } = useContext(GlobalContext);
  const [modalOpen, setModalOpen] = useState<boolean>(false);
  const [selectedFlow, setSelectedFlow] = useState(null);
  const [step, setStep] = useState<number>(0);
  const [triggerValue, setTriggerValue] = useState({});
  const onDestroy$ = useUnmountObservable();
  const request = useRequest();

  const cursor = useMemo(
    () => ({
      associationKey,
      relatedTo: { collectionId: ApiModel.ApiEntity.ENTITY, id: entityId },
      filter: cursorFilter,
      options: { limit: 25 }
    }),
    [associationKey, cursorFilter, entityId]
  );

  const onSetEdit = useCallback((flow: ApiModel.ApiRecord) => {
    setSelectedFlow(flow);
    setStep(1);
    setTriggerValue(flow?.$r);
    setModalOpen(true);
    return EMPTY;
  }, []);

  const onSetCreate = useCallback(() => setModalOpen(true), []);

  const onClose = useCallback(() => {
    setModalOpen(false);
    setSelectedFlow(null);
    setStep(0);
    setTriggerValue({});
  }, []);

  const onSave = useCallback(() => {
    onClose();
    deps.refresh$.next(id);
  }, [deps.refresh$, id, onClose]);

  const generateUpsert = useCallback(
    (type: string) => (event?: ApiModel.ApiRecord) => {
      const assChange = (() => {
        switch (type) {
          case 'create':
            return { add: [{ id: selectedFlow?.id, $r: { ...triggerValue } }] };
          case 'edit':
            return {
              modify: [{ $r: { ...triggerValue, id: selectedFlow?.$r?.id } }]
            };
          case 'remove':
            return { remove: [{ id: event.id, $r: { id: event.$r?.id } }] };
        }
      })();
      return request(
        actions.coreUpsert({
          $cyf_escape: [
            {
              query: { cursor: { collectionId: ApiModel.ApiEntity.ENTITY, id: entityId } },
              record: { [`$$${associationKey}`]: assChange },
              options: { reset: false }
            }
          ]
        } as any)
      ).pipe(takeUntil(onDestroy$), tapOnSuccess(type === 'remove' ? () => deps.refresh$.next(id) : onSave));
    },
    [request, entityId, associationKey, onDestroy$, onSave, selectedFlow?.id, selectedFlow?.$r?.id, triggerValue, deps.refresh$, id]
  );

  const onCreate = useCallback((): Observable<ApiModel.APIAction> => generateUpsert('create')(), [generateUpsert]);
  const onEdit = useCallback((): Observable<ApiModel.APIAction> => generateUpsert('edit')(), [generateUpsert]);
  const onRemove = useCallback((event: ApiModel.ApiRecord) => generateUpsert('remove')(event), [generateUpsert]);

  const onSelect = useCallback((item: ApiModel.ApiRecord) => {
    setSelectedFlow(item);
    if (item?.title) setTriggerValue({ modalTitle: item.title });
    setStep(1);
    return EMPTY;
  }, []);

  const openExternalUrl = useOnOpenExternalUrl();
  /* istanbul ignore next line */
  const schema = linkedFlowType === LinkedFlowType.TRIGGER ? flowFormSchema : recordActionFormSchema;

  const detailGroupList: CollectionModel.DetailGroup[] = useMemo(
    () => [...flowFormDetailGroupList, ...(selectedFlow?.model?.detailGroupList || [])],
    [selectedFlow?.model?.detailGroupList]
  );

  const completeFlowFormSchema = useMemo(
    () =>
      !hasInputs(selectedFlow)
        ? schema
        : mergeTruthy(schema, {
            properties: {
              input: {
                type: 'object',
                format: GeneralModel.JSONSchemaFormat.COLLECTION_RECORD,
                properties: {},
                title: 'Input',
                metadata: {
                  hidden: !hasInputs(selectedFlow),
                  subtype: GeneralModel.JSONSchemaSubtype.FRAMED,
                  allowFormula: true,
                  collection: {
                    detailGroupList,
                    schema: {
                      title: ' ',
                      type: 'object',
                      format: GeneralModel.JSONSchemaFormat.OBJECT,
                      metadata: { detailGroupId: 'input' },
                      properties: normalize.schema(selectedFlow?.model?.schema, {
                        validDetailGroupIdList: selectedFlow?.model?.detailGroupList?.map(/* istanbul ignore next */ d => d?.id)
                      })?.properties
                    }
                  }
                }
              }
            }
          }),
    [detailGroupList, schema, selectedFlow]
  );

  /* istanbul ignore next line */
  const modalTitle = useMemo(() => {
    if (linkedFlowType === LinkedFlowType.TRIGGER) return `Flow to trigger`;
    if (linkedFlowType === LinkedFlowType.LIST) return 'Collection action: linked flow';
    if (linkedFlowType === LinkedFlowType.RECORD) return 'Record action: linked flow';
  }, [linkedFlowType]);

  /* istanbul ignore next line */
  const modalDescription = useMemo(() => {
    if (linkedFlowType === LinkedFlowType.TRIGGER) return 'Fill up the details to complete this process';
    if ([LinkedFlowType.LIST, LinkedFlowType.RECORD].includes(linkedFlowType)) return `This flow will run when the ${linkedFlowType} action button is pressed`;
  }, [linkedFlowType]);

  /* istanbul ignore next line */
  const inputList = useMemo(() => {
    if (linkedFlowType === LinkedFlowType.TRIGGER) return triggerInputList;
    if (linkedFlowType === LinkedFlowType.LIST) return listActionsInputList;
    if (linkedFlowType === LinkedFlowType.RECORD) return recordActionsInputList;
  }, [linkedFlowType]);

  if (!entityId) return null;

  return (
    <div data-testid="trigger-selector">
      <Layout title={title} subtitle={subtitle} type={ViewModel.LayoutType.FULL} framed={true}>
        <BuilderCyList
          framed={false}
          componentName={id}
          searchStringHidden={true}
          advancedFiltersHidden={true}
          type={ViewModel.CyListType.TABLE}
          request={request}
          initialFetchCriteria={cursor}
          orderHidden={true}
          onClickItem={/* istanbul ignore next */ event => openExternalUrl(`${ENV.PUBLIC_URL}${getLink(event.id, ApiModel.ApiEntity.FLOW)}`)}
          actionListChildren={[
            { icon: 'edit', label: 'Edit', important: true, onClick: onSetEdit as any },
            { icon: 'delete', label: 'Remove', color: 'HC_5', onClick: onRemove as any }
          ]}
          headerListChildren={[
            {
              onClick: onSetCreate as any,
              label: ctaLabel,
              icon: ctaIcon as any,
              type: CTAType.PRIMARY,
              important: true
            }
          ]}
          hideListOnNoFilteredResults={true}
        >
          <CyText content={translate(TRANS.client.emptyStates.cyList)} titleAlignment={ViewModel.Alignment.CENTER} />
        </BuilderCyList>
      </Layout>
      <Modal
        icon="touch_app"
        title={modalTitle}
        description={modalDescription}
        testid={`${linkedFlowType}-modal`}
        open={modalOpen}
        onClose={onClose}
        type={ViewModel.ModalType.FULL_SCREEN}
        footer={
          step === 1 && (
            <ItemList>
              <CTA onClick={onClose} label={TRANS.client.buttons.cancel} type={CTAType.SECONDARY} testid="cancel-modal" />
              <CTA onClick={selectedFlow?.$r?.id ? onEdit : onCreate} label={TRANS.client.buttons.save} type={CTAType.PRIMARY} testid="finish-modal" />
            </ItemList>
          )
        }
      >
        <Stepper stepCount={2} currentStep={step + 1} />
        {step === 0 && modalOpen && (
          <BuilderCyList
            collectionId={ApiModel.ApiEntity.FLOW}
            request={request}
            actionListChildren={[{ label: 'Select', type: CTAType.LINK, important: true, onClick: onSelect as any }]}
          />
        )}
        {step === 1 && (
          <div>
            <ErrorBoundary>
              <SchemaForm
                schema={completeFlowFormSchema}
                detailGroupList={detailGroupList}
                onChange={setTriggerValue}
                value={triggerValue}
                wrapDetailGroups={true}
                inputList={inputList}
              />
            </ErrorBoundary>
            {!hasInputs(selectedFlow) && (
              <div className={styles.noInputsContainer}>
                <h6 className={styles.noInputsSubtitle}>Type: No input required</h6>
              </div>
            )}
          </div>
        )}
      </Modal>
    </div>
  );
};
