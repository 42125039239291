import { useEffect, useMemo } from 'react';
import { checkMobileUserAgent } from '@utils';
import { styles } from './styles';
import { ANDROID_PACKAGE_NAME, IOS_APP_ID, MOBILE_AGENTS, TRANS } from '@constants';
import { Translate } from '@cyferd/client-engine';

export const MobilePage = () => {
  const mobileAgent = useMemo(() => checkMobileUserAgent(window.navigator.userAgent), []);
  const storeLink = useMemo(() => {
    switch (mobileAgent) {
      case MOBILE_AGENTS.ANDROID:
        return `https://play.google.com/store/apps/details?id=${ANDROID_PACKAGE_NAME}`;
      case MOBILE_AGENTS.IOS:
        return `https://apps.apple.com/ae/app/${IOS_APP_ID}`;
      default:
        return null;
    }
  }, [mobileAgent]);

  useEffect(() => {
    if (storeLink) window.location.replace(encodeURI(storeLink));
  }, [storeLink]);

  return (
    <div className={styles.container}>
      <Translate>
        {storeLink ? <h3 data-testid="redirects">{TRANS.client.emptyStates.redirectingToMobileApp}</h3> : <h3>{TRANS.client.emptyStates.noMobileApp}</h3>}
      </Translate>
    </div>
  );
};
