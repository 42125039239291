import { FONT_SIZE, FONT_WEIGHT, GAP, RADIUS } from '@constants';
import { css } from '@emotion/react';

export const styles = {
  // Edge
  edgeContainer: css`
    display: flex;
    flex-direction: row;
    align-items: center;
  `,
  edgeRect: css`
    height: 2px;
    width: 100%;
    min-width: 20px;
  `,
  edgeLabel: css`
    font-size: ${FONT_SIZE.M};
    font-weight: ${FONT_WEIGHT.NORMAL};
    margin: 0 ${GAP.XS};
    white-space: nowrap;
  `,
  // Node
  nodeContainer: css`
    font-size: ${FONT_SIZE.XS};
    font-weight: ${FONT_WEIGHT.NORMAL};
    border-radius: ${RADIUS.L};
    padding: ${GAP.XS} ${GAP.M};
    display: flex;
    justify-content: center;
    border-width: 1px;
    border-style: solid;
  `,
  // Diagram
  container: css`
    display: flex;
    flex-direction: row;
    align-items: center;
  `,
  subNodesContainer: css`
    display: flex;
    flex-direction: column;
    align-items: stretch;
    gap: ${GAP.S};
  `
};
