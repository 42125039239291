import * as RadixAvatar from '@radix-ui/react-avatar';

import { Icon } from '@components/elements/Icon';
import { useGetElementSize } from '@utils/useGetElementSize';
import { styles } from './styles';
import { COLOR } from '@constants';

export type AvatarProps = {
  size: 'xs' | 'sm' | 'md' | 'lg' | 'xl' | 'fill';
  testid?: string;
};

export type AvatarIconProps = { title?: string } & AvatarProps;

export type AvatarImageProps = {
  src: string;
  alt: string;
};

export const Avatar = (props: (AvatarImageProps & AvatarProps) | AvatarIconProps) => {
  const size = styles.iconSizes[props.size];
  const { ref, height } = useGetElementSize();
  return (
    <div ref={ref}>
      <RadixAvatar.Root data-testid={props.testid || 'avatar'}>
        <RadixAvatar.Fallback css={[styles.avatarIcon, size]}>
          <Icon name="person" fill={COLOR.NEUTRAL_2} title={(props as AvatarIconProps).title || (props as AvatarImageProps).alt} size={`${height * 0.8}px`} />
        </RadixAvatar.Fallback>
        <RadixAvatar.Image src={(props as AvatarImageProps).src} alt={(props as AvatarImageProps).alt} css={[styles.avatarImage, size]} />
      </RadixAvatar.Root>
    </div>
  );
};

Avatar.displayName = 'Avatar';
