import { ViewModel } from '@cyferd/client-engine';
import React, { memo, useMemo } from 'react';
import { styles } from './styles';
import { CyAction } from '../CyAction';
import { CyText } from '../CyText';
import { IconImage } from '@components/elements/Icon/renderIcon';
import { COLOR, RADIUS } from '@constants';
import { useGetElementSize } from '@utils';
import { BBContainer } from '@components/elements/BBContainer';

export const CyImage = memo(
  ({
    id,
    image,
    imageBorder,
    imagePosition,
    size,
    textPosition,
    /** cytext */
    title,
    titleSize,
    titleAlignment,
    iconColor,
    content,
    actionsAlignment,
    actionsSize,
    actionsDirection,
    actionListChildren,
    effectChildren,
    framed,
    fitToPage,
    moreOptionsType
  }: ViewModel.CyImageProps) => {
    const { ref, width } = useGetElementSize();

    const shouldBreak = width < 350;

    const textPositionStyle = useMemo(() => {
      switch (textPosition) {
        case ViewModel.CyImageTextPosition.RIGHT:
          return styles.actionsPositionRight;
        case ViewModel.CyImageTextPosition.ABOVE:
          return styles.actionsPositionAbove;
        case ViewModel.CyImageTextPosition.BELOW:
          return null;
        case ViewModel.CyImageTextPosition.LEFT:
        default:
          return styles.actionsPositionLeft;
      }
    }, [textPosition]);

    const imagePositionStyle = useMemo(() => {
      switch (imagePosition) {
        case ViewModel.CyImagePosition.START:
          return styles.imagePositionStart;
        case ViewModel.CyImagePosition.END:
          return styles.imagePositionEnd;
        case ViewModel.CyImagePosition.CENTER:
        default:
          return styles.imagePositionCenter;
      }
    }, [imagePosition]);

    const iconSizeStyle = useMemo(() => {
      switch (size) {
        case ViewModel.CyImageSize.S_1:
          return '30px';
        case ViewModel.CyImageSize.S_2:
          return '70px';
        case ViewModel.CyImageSize.S_3:
          return '125px';
        case ViewModel.CyImageSize.S_4:
          return '250px';
        case ViewModel.CyImageSize.S_5:
          return '350px';
        case ViewModel.CyImageSize.S_0:
        default:
          return '30px';
      }
    }, [size]);

    const imageSizeStyle = useMemo(() => {
      switch (size) {
        case ViewModel.CyImageSize.S_1:
          return { maxWidth: 'min(100%, 30px)' };
        case ViewModel.CyImageSize.S_2:
          return { maxWidth: 'min(100%, 70px)' };
        case ViewModel.CyImageSize.S_3:
          return { maxWidth: 'min(100%, 125px)' };
        case ViewModel.CyImageSize.S_4:
          return { maxWidth: 'min(100%, 250px)' };
        case ViewModel.CyImageSize.S_5:
          return { maxWidth: 'min(100%, 400px)' };
        case ViewModel.CyImageSize.SPECIAL_1:
          return { maxWidth: '600px', maxHeight: '200px' };
        case ViewModel.CyImageSize.S_0:
        default:
          return {};
      }
    }, [size]);

    const actionsDirectionStyle = useMemo(() => {
      switch (actionsDirection) {
        case ViewModel.Direction.HORIZONTAL:
          return styles.actionsDirectionHorozntal;
        case ViewModel.Direction.VERTICAL:
        default:
          return styles.actionsDirectionVertical;
      }
    }, [actionsDirection]);

    const imageBorderStyle = useMemo(() => {
      switch (imageBorder) {
        case ViewModel.CyImageBorder.CURVE:
          return RADIUS.L;
        case ViewModel.CyImageBorder.CIRCLE:
          return '100%';
        case ViewModel.CyImageBorder.NONE:
        default:
          return undefined;
      }
    }, [imageBorder]);

    return (
      <BBContainer framed={framed} fitToPage={fitToPage}>
        <div id={id} data-testid={id} css={styles.container} ref={ref}>
          <div data-testid="effects">{effectChildren}</div>
          <div css={[styles.content, actionsDirectionStyle]}>
            <div css={[styles.mainContent, textPositionStyle, imagePositionStyle, shouldBreak && styles.stackedContent]}>
              <div css={styles.imageContainer}>
                <IconImage
                  icon={image}
                  iconProps={{ fill: COLOR[iconColor], size: iconSizeStyle }}
                  imageProps={{ css: { maxWidth: '100%', maxHeight: '100%', objectFit: 'cover', borderRadius: imageBorderStyle, ...imageSizeStyle } }}
                  title=""
                />
              </div>
              <div css={styles.textContainer}>
                <CyText title={title} titleSize={titleSize} titleAlignment={titleAlignment} content={content} />
              </div>
            </div>
            <div>
              <CyAction actionListChildren={actionListChildren} size={actionsSize} alignment={actionsAlignment} moreOptionsType={moreOptionsType} />
            </div>
          </div>
        </div>
      </BBContainer>
    );
  }
);

CyImage.displayName = ViewModel.DisplayName.CY_IMAGE;
