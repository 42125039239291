import { replaceDeep } from '@cyferd/client-engine';

import { Table } from '@components/elements/Table';
import { Input } from '@components/elements/Input';
import { CTA, CTAType } from '@components/elements/CTA';
import { TRANS } from '@constants';

export type KeyValueData = { key: string; value: string }[];

export type Props = {
  value: KeyValueData;
  disabled?: boolean;
  onChange: (value: KeyValueData) => void;
};

export const KeyValueEditor = ({ value, disabled, onChange }: Props) => {
  const addRow = () => onChange([...value, { key: undefined, value: undefined }]);
  const onKeyChange = (index: number, event: string) => onChange(replaceDeep(value, event, `${index}.key`));
  const onValueChange = (index: number, event: string) => onChange(replaceDeep(value, event, `${index}.value`));
  const onDeleteRow = (index: number) => onChange([...value.slice(0, index), ...value.slice(index + 1, value.length)]);

  return (
    <div data-testid="user-app-security-modal">
      <div>
        <Table
          head={[
            { id: 'key', item: 'Key' },
            { id: 'value', item: 'Value' },
            { id: 'actions', item: 'Actions' }
          ]}
          body={value.map((row, index) => [
            {
              id: 'key',
              item: (
                <div>
                  <Input
                    testid={`key-${index}`}
                    name={`key-${index}`}
                    label="key"
                    value={row?.key}
                    onChange={e => onKeyChange(index, e)}
                    data-testid={`input-key-${index}`}
                    disabled={disabled}
                  />
                </div>
              )
            },
            {
              id: 'value',
              item: (
                <div>
                  <Input
                    testid={`value-${index}`}
                    name={`value-${index}`}
                    label="value"
                    value={row?.value}
                    onChange={e => onValueChange(index, e)}
                    data-testid={`input-value-${index}`}
                    disabled={disabled}
                  />
                </div>
              )
            },
            {
              id: 'actions',
              item: (
                <CTA
                  type={CTAType.ACTION_SECONDARY}
                  icon="delete"
                  tooltip={TRANS.client.buttons.delete}
                  onClick={() => onDeleteRow(index)}
                  testid={`delete-row-${index}`}
                  disabled={disabled}
                />
              )
            }
          ])}
        />
        <CTA type={CTAType.SECONDARY} label="Add row" testid="add-row-button" onClick={addRow} disabled={disabled} />
      </div>
    </div>
  );
};
