import { COLOR, FONT_SIZE, FONT_WEIGHT, GAP } from '@constants';
import { css } from '@emotion/react';

export const styles = {
  container: css`
    width: 360px;
  `,
  titleContainer: css`
    display: flex;
    flex-direction: column;
    gap: ${GAP.S};
    margin-top: ${GAP.XS};
  `,
  subtitleContainer: css`
    display: flex;
    align-items: center;
    gap: ${GAP.XS};
  `,
  subtitle: css`
    color: ${COLOR.NEUTRAL_2};
    font-size: ${FONT_SIZE.XXS};
    font-weight: ${FONT_WEIGHT.NORMAL};
  `,
  descriptionContainer: css`
    padding-top: ${GAP.S};
    margin-top: ${GAP.S};
    border-top: 1px solid ${COLOR.NEUTRAL_3};
    display: flex;
    flex-direction: column;
    gap: ${GAP.S};
  `,
  description: css`
    color: ${COLOR.NEUTRAL_2};
    font-size: ${FONT_SIZE.XXS};
    font-weight: ${FONT_WEIGHT.NORMAL};
  `,
  actions: css`
    margin-top: ${GAP.S};
    display: flex;
    align-items: flex-start;
  `
};
