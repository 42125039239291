// istanbul ignore file
import { useCallback, useMemo } from 'react';
import { ViewModel } from '@cyferd/client-engine';
import { CTAType } from '@components/elements/CTA';
import { useEvaluate, useOnActionClick } from '../../hooks';

export const useRecordActions = ({ isLoading, value, rowActions: { additionalRecordActions = [], hideRecordActions } }: any): any => {
  const evaluate = useEvaluate();
  const { onActionClick } = useOnActionClick();

  const { fullList, hasRecordActions }: { fullList?: any[]; hasRecordActions: boolean } = useMemo(() => {
    if (hideRecordActions) {
      return { hasRecordActions: false };
    }

    const fullList = [
      ...(value?.query?.recordActions || []).map(({ $r: { input: flowInput, ...each }, id }) => ({
        ...each,
        flowInput,
        id
      })),
      ...additionalRecordActions
    ];

    return {
      fullList,
      hasRecordActions: true
    };
  }, [value?.query?.recordActions, hideRecordActions, additionalRecordActions]);

  const parseRecordActions = useCallback(
    (record, rowIndex) => {
      return fullList?.map((action, actionItemIndex) => {
        const { helperText, icon, disabled, ...each } = evaluate(action, { record });

        return {
          type: CTAType.ACTION,
          ...each,
          onClick: (_, event) => onActionClick(action, { record, event: { item: record, meta: { index: rowIndex, metaKey: event?.metaKey } } }),
          image: icon,
          tooltip: helperText,
          size: ViewModel.CTASize.SMALL,
          disabled: isLoading || !!disabled,
          testid: `${rowIndex}-action-list-cta-${actionItemIndex}`
        };
      });
    },
    [isLoading, fullList, onActionClick, evaluate]
  );

  return {
    parseRecordActions,
    hasRecordActions
  };
};
