import { COLOR, FONT_SIZE, FONT_WEIGHT, GAP } from '@constants';
import { css } from '@emotion/react';

export const styles = {
  container: css`
    display: flex;
    gap: ${GAP.S};
    align-items: center;
  `,
  title: css`
    color: ${COLOR.NEUTRAL_1};
    font-size: ${FONT_SIZE.XXM};
    font-weight: ${FONT_WEIGHT.EXTRA_BOLD};
    display: inline.block;
    min-width: 200px;
    text-align: center;
  `,
  dayTitle: css`
    min-width: 120px;
  `,
  monthTitle: css`
    min-width: 160px;
  `,
  typeSelector: css`
    margin-top: -12px;
  `
};
