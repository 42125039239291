import { COLOR, FONT_SIZE, GAP } from '@constants';
import { css } from '@emotion/css';

export const styles = {
  noInputsContainer: css`
    padding: ${GAP.L};
  `,
  noInputsSubtitle: css`
    text-align: center;
    color: ${COLOR.NEUTRAL_1};
    font-size: ${FONT_SIZE.M};
    line-height: ${FONT_SIZE.M};
    margin-bottom: ${GAP.XXL};
  `
};
