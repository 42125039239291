import { useCurrentTheme } from '@components/providers/UIprovider';
import { COLOR } from '@constants';
import type { ChartDataOutput } from '@cyferd/client-engine';
import { ChartsReferenceLine } from '@mui/x-charts';
import React from 'react';

type ChartReferenceLinesProps = {
  referenceLines?: ChartDataOutput['referenceLines'];
  forceHorizontal?: boolean;
};
export const ChartReferenceLines = ({ referenceLines = [], forceHorizontal }: ChartReferenceLinesProps) => {
  const currentTheme = useCurrentTheme();

  return referenceLines.map(({ calculatedValue, color, orientation }, i) => {
    const baseLineProps = {
      key: `ref-line-${i}-base`,
      lineStyle: { strokeWidth: 1, stroke: currentTheme?.[color]?.value }
    };
    const outerLineProps = {
      key: `ref-line-${i}-outer`,
      lineStyle: { strokeWidth: 2, stroke: COLOR.ABSOLUTE_WHITE, opacity: 0.5 }
    };
    return (
      <React.Fragment key={`ref-line-${i}`}>
        {forceHorizontal || orientation === 'horizontal' ? (
          <>
            <ChartsReferenceLine x={calculatedValue} {...outerLineProps} />
            <ChartsReferenceLine x={calculatedValue} {...baseLineProps} />
          </>
        ) : (
          <>
            <ChartsReferenceLine y={calculatedValue} {...outerLineProps} />
            <ChartsReferenceLine y={calculatedValue} {...baseLineProps} />
          </>
        )}
      </React.Fragment>
    );
  });
};
