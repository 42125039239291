import { COLOR, EMPTY_CONTAINER_HEIGHT, FONT_SIZE, FONT_WEIGHT, GAP } from '@constants';
import { css } from '@emotion/react';

export const styles = {
  title: css`
    color: ${COLOR.NEUTRAL_1};
    font-size: ${FONT_SIZE.XM};
    font-weight: ${FONT_WEIGHT.SEMI_BOLD};
  `,
  spinnerContainer: css`
    display: flex;
    width: 100%;
    height: 100%;
    flex: 1;
    align-items: center;
    justify-content: center;
    min-height: ${EMPTY_CONTAINER_HEIGHT.XS}px;
  `,
  chartWrapper: css`
    display: flex;
    gap: ${GAP.L};
    padding: ${GAP.M} 0;
    padding-left: ${GAP.M};
    align-items: center;
  `,
  chartContainer: (width: number) => css`
    height: ${width}px; // square proportions
    max-height: 350px;
    max-width: 40%;
    flex: 1;

    .pieArcRoot {
      stroke: transparent;
    }
  `,
  legendContainer: css`
    display: flex;
    flex: 1;
  `,
  empty: css`
    display: flex;
    justify-content: center;
    align-items: center;
    min-height: ${EMPTY_CONTAINER_HEIGHT.XS}px;
  `
};
