import type { useTranslate } from '@cyferd/client-engine';
import { GeneralModel, isObject, mergeTruthy, normalize } from '@cyferd/client-engine';
import { baseConfigSchema, groupSchema, templateMap } from '../SchemaEditor/schemas';

export const getSchemaWhenInMetadata = (field: GeneralModel.JSONSchema) => {
  const isMetadata = field.key === 'metadata' && isObject(field.properties);
  const internalKey = !isMetadata ? null : Object.keys(field.properties)[0];
  const schema = isMetadata ? field.properties[internalKey] : field;
  return { isMetadata, internalKey, schema };
};

export const getFieldList = (format: GeneralModel.JSONSchema['format'], translate: ReturnType<typeof useTranslate>['translate']): GeneralModel.JSONSchema[] => {
  const isItemSchema = typeof format === 'string';
  const formSchema = normalize.schema(isItemSchema ? mergeTruthy(baseConfigSchema, templateMap[format] || /* istanbul ignore next */ {}) : groupSchema);
  return Object.entries(formSchema.properties)
    .flatMap(([key, prop]) => {
      if (key !== 'metadata') return [prop];
      return Object.entries(prop.properties).map(([k, p]) => ({ type: 'object' as GeneralModel.JSONSchema['type'], key, properties: { [k]: p } }));
    })
    .filter(prop => prop.metadata?.hidden !== true)
    .sort(
      /* istanbul ignore next */ (a, b) => {
        const { schema: schemaA } = getSchemaWhenInMetadata(a);
        const { schema: schemaB } = getSchemaWhenInMetadata(b);
        /** default first */
        if (a.key === 'default') return -1;
        if (b.key === 'default') return 1;
        /** lists last */
        if ([GeneralModel.JSONSchemaFormat.ARRAY].includes(schemaA.format)) return 1;
        if ([GeneralModel.JSONSchemaFormat.ARRAY].includes(schemaB.format)) return -1;
        /** by format */
        if (schemaA.format !== schemaB.format) return schemaA.format.localeCompare(schemaB.format);
        /** label alphabetical */
        return translate(schemaA.label).localeCompare(translate(schemaB.label));
      }
    );
};

/** keeps items inside arrays */
export const removeEmptyKeys = (value: any) => {
  const isEmpty = v => [null, undefined].includes(v);
  if (isObject(value)) {
    const obj = Object.fromEntries(
      Object.entries(value)
        .map(([k, v]) => [k, isEmpty(v) ? undefined : removeEmptyKeys(v)])
        .filter(([_, v]) => v !== undefined)
    );
    return !Object.keys(obj).length ? undefined : obj;
  }
  if (Array.isArray(value)) {
    const arr = value.filter(v => !isEmpty(v));
    return !arr.length ? undefined : arr;
  }

  return isEmpty(value) ? undefined : value;
};

export const getOverridePathList = (value: GeneralModel.JSONSchema, initialPath: string[] = []): string[][] => {
  if (!isObject(value)) return [];

  const getHasOverrides = (keys: string[]) => !!keys.filter(k => !['properties', 'items'].includes(k)).length;

  return [
    getHasOverrides(Object.keys(value)) && initialPath,
    ...(!isObject(value.items) ? [] : getOverridePathList(value.items, [...initialPath, 'items'])),
    ...(!isObject(value.properties)
      ? []
      : Object.entries(value.properties)
          .map(([key, prop]) => getOverridePathList(prop, [...initialPath, 'properties', key]))
          .flat())
  ].filter(i => i?.length);
};
