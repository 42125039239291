import { FONT_SIZE, FONT_WEIGHT, GAP } from '@constants';
import { css } from '@emotion/react';

export const styles = {
  container: css`
    display: flex;
    gap: ${GAP.M};
    align-items: center;
  `,
  title: css`
    font-size: ${FONT_SIZE.M};
    font-weight: ${FONT_WEIGHT.BOLD};
  `
};
