import { CONTAINER_WIDTH, GAP } from '@constants';
import { css } from '@emotion/css';

export const styles = {
  container: css`
    max-width: ${CONTAINER_WIDTH.MAX}px;
    margin: auto;
    padding: ${GAP.S} ${GAP.M} ${GAP.M} ${GAP.M};
  `
};
