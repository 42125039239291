import { TRANS } from '@constants';
import type { CollectionModel } from '@cyferd/client-engine';
import { GeneralModel, normalize } from '@cyferd/client-engine';

const descFormula = {
  $cyf_coalesce: [
    '{{event.item.request.payload.query.cursor.collectionId}}',
    '{{event.item.request.payload.value.query.cursor.collectionId}}',
    '{{event.item.request.payload.searchString}}',
    '{{event.item.request.payload.path}}',
    '{{event.item.request.payload.pointer}}',
    '{{event.item.request.payload.modalId}}',
    '{{event.item.request.payload.target}}',
    ''
  ]
};

const truncDescFormula = {
  $cyf_if: [
    { $cyf_gt: [{ $cyf_length: [descFormula] }, 12] },
    { $cyf_concatenate: [{ $cyf_join: [{ $cyf_match: [descFormula, '^.{0,12}'] }, ''] }, '...'] },
    descFormula
  ]
};

export const collection: CollectionModel.Collection = normalize.collection(
  {
    schema: {
      type: 'object',
      properties: {
        label: {
          type: 'string',
          label: TRANS.client.fields.titles.name,
          metadata: {
            hidden: { $cyf_toboolean: [true] },
            calculation: {
              $cyf_concatenate: [
                '{{event.item.action.trigger.label}} {{event.item.action.listener.label}} {{event.item.telemetry.context.node.name}}',
                {
                  $cyf_if: [{ $cyf_exists: [truncDescFormula] }, { $cyf_concatenate: [': ', truncDescFormula] }, '']
                }
              ]
            },
            detailGroupId: 'list'
          }
        },
        recordTitle: {
          type: 'string',
          label: TRANS.client.fields.titles.title,
          metadata: {
            hidden: true,
            calculation: {
              $cyf_concatenate: [
                '{{event.item.action.trigger.label}} {{event.item.telemetry.context.node.name}} [{{event.item.request.type}}',
                { $cyf_if: [{ $cyf_exists: ['{{event.item.response.type}}'] }, ' → {{event.item.response.type}}]', ']'] }
              ]
            }
          }
        },
        timestamp: {
          type: 'string',
          format: GeneralModel.JSONSchemaFormat.DATE_TIME_U,
          label: 'Timestamp',
          metadata: {
            hidden: { $cyf_toboolean: [true] },
            detailGroupId: 'list',
            detailPriority: GeneralModel.JSONSchemaPropertyPriority.IMPORTANT,
            calculation: { $cyf_coalesce: ['{{event.item.telemetry.time.responseAt}}', '{{event.item.telemetry.time.startedAt}}'] },
            mask: 'HH:mm:ss.SSS'
          }
        },
        recordDescription: {
          type: 'string',
          label: TRANS.client.fields.titles.description,
          metadata: {
            detailGroupId: 'list',
            hidden: true,
            calculation: {
              $cyf_concatenate: [descFormula]
            }
          }
        },
        recordColor: {
          type: 'string',
          label: 'Color',
          metadata: {
            hidden: true,
            calculation: {
              $cyf_switch: [
                { $cyf_and: [{ $cyf_sw: ['{{event.item.response.type}}', 'API'] }] },
                'RD_2',
                { $cyf_and: [{ $cyf_sw: ['{{event.item.request.type}}', 'CORE'] }, { $cyf_exists: ['{{event.item.response.type}}'] }] },
                'GN_2',
                { $cyf_and: [{ $cyf_sw: ['{{event.item.request.type}}', 'CORE'] }, { $not: true, $cyf_exists: ['{{event.item.response.type}}'] }] },
                'YW_3',
                'NEUTRAL_4'
              ]
            }
          }
        },
        request: {
          type: 'object',
          label: 'Request',
          metadata: {
            subtype: GeneralModel.JSONSchemaSubtype.FRAMED,
            detailGroupId: 'req',
            color: { $cyf_switch: [{ $cyf_exists: ['{{event.item.response.type}}'] }, 'YW_3', 'NEUTRAL_4'] }
          },
          properties: {
            type: { type: 'string', label: 'Type', format: GeneralModel.JSONSchemaFormat.ACTION_TYPE_OPTION_LIST },
            payload: { type: 'object', label: 'Payload', format: GeneralModel.JSONSchemaFormat.JSON, metadata: { expanded: true } }
          }
        },
        response: {
          type: 'object',
          label: 'Response',
          metadata: {
            subtype: GeneralModel.JSONSchemaSubtype.FRAMED,
            detailGroupId: 'req',
            color: {
              $cyf_switch: [
                { $cyf_sw: ['{{event.item.response.type}}', 'API'] },
                'RD_2',
                { $cyf_exists: ['{{event.item.response.type}}'] },
                'GN_2',
                'NEUTRAL_4'
              ]
            }
          },
          properties: {
            type: { type: 'string', label: 'Type', format: GeneralModel.JSONSchemaFormat.ACTION_TYPE_OPTION_LIST },
            payload: { type: 'object', label: 'Payload', format: GeneralModel.JSONSchemaFormat.JSON, metadata: { expanded: true } }
          }
        },
        action: {
          type: 'object',
          label: ' ',
          metadata: { subtype: GeneralModel.JSONSchemaSubtype.FRAMED, detailGroupId: 'config' },
          properties: {
            event: { type: 'object', label: 'Event', format: GeneralModel.JSONSchemaFormat.JSON },
            trigger: { type: 'object', label: 'Trigger', format: GeneralModel.JSONSchemaFormat.JSON },
            listener: {
              type: 'object',
              label: 'Listener',
              format: GeneralModel.JSONSchemaFormat.JSON,
              metadata: { detailPriority: GeneralModel.JSONSchemaPropertyPriority.OTHER }
            }
          }
        },
        telemetry: {
          type: 'object',
          label: ' ',
          metadata: { detailGroupId: 'telemetry' },
          properties: {
            time: {
              type: 'object',
              label: 'Time',
              metadata: { subtype: GeneralModel.JSONSchemaSubtype.FRAMED },
              properties: {
                ellapsed: {
                  type: 'string',
                  label: 'Total',
                  metadata: { calculation: { $cyf_coalesce: ['{{event.value}}', 'N/A'] } }
                },
                startedAt: {
                  type: 'string',
                  format: GeneralModel.JSONSchemaFormat.DATE_TIME_U,
                  label: 'Start At',
                  metadata: { detailPriority: GeneralModel.JSONSchemaPropertyPriority.OTHER }
                },
                responseAt: {
                  type: 'string',
                  format: GeneralModel.JSONSchemaFormat.DATE_TIME_U,
                  label: 'Response At',
                  metadata: { detailPriority: GeneralModel.JSONSchemaPropertyPriority.OTHER }
                },
                responseNumber: { type: 'number', label: 'Response number', metadata: { detailPriority: GeneralModel.JSONSchemaPropertyPriority.OTHER } }
              }
            },
            view: {
              type: 'object',
              label: 'View',
              metadata: { subtype: GeneralModel.JSONSchemaSubtype.FRAMED },
              properties: {
                viewName: { type: 'string', label: TRANS.client.fields.titles.name },
                viewId: { type: 'string', label: TRANS.client.fields.titles.id, metadata: { detailPriority: GeneralModel.JSONSchemaPropertyPriority.OTHER } },
                viewDepth: { type: 'number', label: 'Depth', metadata: { detailPriority: GeneralModel.JSONSchemaPropertyPriority.OTHER } },
                viewInstanceId: { type: 'string', label: 'Instance ID', metadata: { detailPriority: GeneralModel.JSONSchemaPropertyPriority.OTHER } }
              }
            },
            message: {
              type: 'object',
              label: 'Message',
              metadata: { subtype: GeneralModel.JSONSchemaSubtype.FRAMED },
              properties: {
                correlationId: { type: 'string', label: 'Correlation ID' },
                uniqueId: { type: 'string', label: 'Unique ID', metadata: { detailPriority: GeneralModel.JSONSchemaPropertyPriority.OTHER } },
                flowId: { type: 'string', label: 'Flow ID', metadata: { detailPriority: GeneralModel.JSONSchemaPropertyPriority.OTHER } },
                depth: { type: 'number', label: 'Depth', metadata: { detailPriority: GeneralModel.JSONSchemaPropertyPriority.OTHER } }
              }
            },
            context: {
              type: 'object',
              label: ' ',
              properties: { node: { label: 'Node', type: 'object', format: GeneralModel.JSONSchemaFormat.JSON, metadata: { expanded: false } } }
            }
          }
        }
      }
    },
    detailGroupList: [
      { id: 'list', name: '' },
      { id: 'req', name: '' },
      { id: 'config', name: '' },
      { id: 'telemetry', name: '' }
    ]
  },
  { avoidAlphabeticalSort: true }
);
