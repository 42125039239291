import React from 'react';

import { LoadingCity as LoadingCityLight } from './assets/LoadingCity';
import { LoadingSky as LoadingSkyLight } from './assets/LoadingSky';
import { Logo } from './assets/Logo';
import { styles } from './styles';

export const LoadingPage = () => {
  return (
    <div css={styles.container} data-testid="loading-container">
      <div css={styles.content}>
        <Logo />
        <p css={styles.text}>
          All your data in one platform.
          <br />
          Easier than ever.
        </p>
      </div>
      <div css={styles.imagesWrapper}>
        <div css={styles.sliderContainer}>
          <LoadingSkyLight />
        </div>
        <div css={styles.imageContainer}>
          <LoadingCityLight />
        </div>
      </div>
    </div>
  );
};
