import { COLOR, GAP, RADIUS } from '@constants';
import { css } from '@emotion/css';

export const styles = {
  sidebarContainer: css`
    display: flex;
    flex-direction: column;
    gap: ${GAP.XS};
    background-color: ${COLOR.BASE_BACKGROUND_ALT_1};
    border-radius: ${RADIUS.M};
    padding: ${GAP.M} ${GAP.S};
    overflow: auto;
  `,
  optionContainer: css`
    display: flex;
    flex-direction: column;
    gap: ${GAP.XS};
  `,
  option: css`
    background-color: ${COLOR.BASE_BACKGROUND};
    border-radius: ${RADIUS.S};
  `,
  activeOption: css`
    background-color: ${COLOR.NEUTRAL_4}!important;
    :hover {
      li {
        background-color: ${COLOR.NEUTRAL_4}!important;
      }
    }
  `
};
