import { css } from '@emotion/react';

import { COLOR, FONT_SIZE, FONT_WEIGHT, GAP, HC_SECONDARY_COLOR, RADIUS } from '@constants';

export const styles = {
  container: css`
    display: grid;
    grid-template-columns: 1fr auto;
    gap: ${GAP.XS};
    max-height: ${GAP.XL};
  `,
  mainApp: css`
    display: grid;
    grid-template-columns: auto 1fr;
    gap: ${GAP.XS};
    cursor: pointer;
  `,
  iconContainer: css`
    width: ${FONT_SIZE.L};
    height: ${FONT_SIZE.L};
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: ${GAP.XS};
  `,
  image: css`
    width: 20px;
    height: 20px;
    border-radius: ${RADIUS.XS};
    object-fit: cover;
  `,
  title: css`
    font-size: ${FONT_SIZE.S};
    font-weight: ${FONT_WEIGHT.BOLD};
    color: ${COLOR.NEUTRAL_1};
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    line-height: 1.5;
  `,
  triggerContainer: css`
    cursor: pointer;
    font-size: ${FONT_SIZE.S};
    font-weight: ${FONT_WEIGHT.BOLD};
    color: ${COLOR.NEUTRAL_1};
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: ${GAP.XS};
    background-color: ${HC_SECONDARY_COLOR.BRAND_1};
    color: ${COLOR.BRAND_1};
    padding: 0 ${GAP.XS};
    height: ${GAP.XL};
    text-wrap: nowrap;
  `
};
