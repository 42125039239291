import { COLOR, FONT_SIZE, FONT_WEIGHT, GAP, RADIUS } from '@constants';
import { css } from '@emotion/react';

export const styles = {
  container: css`
    display: flex;
    flex-direction: column;
    gap: ${GAP.L};
  `,
  header: css`
    display: flex;
    gap: ${GAP.M};
    align-items: center;
    justify-content: flex-start;
  `,
  avatar: css`
    width: 50px;
    height: 50px;
  `,
  user: css`
    display: flex;
    flex-direction: column;
    gap: ${GAP.XS};
  `,
  title: css`
    color: ${COLOR.NEUTRAL_1};
    font-size: ${FONT_SIZE.M};
    font-weight: ${FONT_WEIGHT.SEMI_BOLD};
  `,
  subtitle: css`
    color: ${COLOR.NEUTRAL_2};
    font-size: ${FONT_SIZE.XXS};
    font-weight: ${FONT_WEIGHT.NORMAL};
  `,
  content: css`
    [data-selector='cta-label'] {
      text-decoration: none;
    }
    button {
      gap: ${GAP.M};
    }
  `,
  row: css`
    display: flex;
    align-items: center;
    justify-content: flex-start;
    height: 36px;
    margin-left: -${GAP.M};
    padding-left: ${GAP.M};
    width: calc(100% + (${GAP.M} * 2));
    border-radius: ${RADIUS.S};
    cursor: pointer;
    :hover {
      background-color: ${COLOR.NEUTRAL_5};
    }
  `,
  activeRow: css`
    background-color: ${COLOR.BRAND_1}!important;
  `,
  division: css`
    width: calc(100% + (${GAP.L} * 2));
    margin-left: -${GAP.L};
    border-top: 1px ${COLOR.NEUTRAL_6} solid;
    padding-top: ${GAP.S};
    margin-top: ${GAP.S};
  `
};
