/* istanbul ignore file */
import { useSelector } from 'react-redux';

import type { ViewFormationProps, ViewModel } from '@cyferd/client-engine';
import { ErrorBoundary, ViewFormation, capitalize, noop, useTranslate } from '@cyferd/client-engine';

import type { GeneralModel } from '@models';
import { useNavigation } from '@utils/useNavigation';
import { styles } from './styles';
import { ViewHeader } from '@components/platform/ViewHeader';
import { useCallback, useEffect } from 'react';
import { ENV } from '@constants';
import { MobileAppBanner } from './components/MobileAppBanner';
import { checkMobileUserAgent } from '@utils';

const getString = (el: string | any) => (typeof el === 'string' ? el : null);
const isMobile = checkMobileUserAgent(window.navigator.userAgent);

const ViewBody = ({
  children,
  headerProps,
  shouldShowHeader,
  timestamp,
  view
}: Parameters<ViewFormationProps['renderBody']>[0] & { timestamp: number; view: ViewModel.View }) => {
  const { translate } = useTranslate();
  useEffect(() => {
    document.title = translate(
      getString(headerProps?.title) ||
        getString(view?.title) ||
        getString(view?.name) ||
        `${window.location.hostname
          .replace(ENV.DOMAIN, '')
          .split('.')
          .map(str => capitalize(str))
          .join(' ')}`
    );
  }, [view?.name, view?.title, headerProps, translate]);

  return (
    <>
      {isMobile && <MobileAppBanner />}
      {shouldShowHeader && (
        <ErrorBoundary>
          <ViewHeader {...headerProps} />
        </ErrorBoundary>
      )}
      <main data-testid="view-page-main" className={styles.container} key={timestamp}>
        {children}
      </main>
    </>
  );
};

export const ViewPage = () => {
  const { view, timestamp } = useSelector((state: GeneralModel.State) => state.view);
  useNavigation();

  const renderBody: ViewFormationProps['renderBody'] = useCallback(props => <ViewBody {...props} timestamp={timestamp} view={view} />, [timestamp, view]);

  return <ViewFormation componentName="root" key={timestamp} view={view} input={null} renderLoading={noop} renderBody={renderBody} />;
};
