import { COLOR, CONTAINER_WIDTH, FONT_SIZE, FONT_WEIGHT, GAP } from '@constants';
import { css } from '@emotion/react';

export const styles = {
  container: css`
    width: 100%;
    display: flex;
    flex-flow: column;
    align-items: center;
    gap: ${GAP.L};
    padding: ${GAP.XL};
    background-color: ${COLOR.BASE_BACKGROUND_ALT_1};
    border-radius: ${GAP.XS};
  `,
  titleContainer: css`
    display: flex;
    flex-direction: column;
    align-items: center;
  `,
  title: css`
    font-size: ${FONT_SIZE.XXM};
    font-weight: ${FONT_WEIGHT.BOLD};
    color: ${COLOR.NEUTRAL_1};
    margin-bottom: ${GAP.XS};
    text-align: center;
  `,
  subtitle: css`
    font-size: ${FONT_SIZE.S};
    font-weight: ${FONT_WEIGHT.NORMAL};
    color: ${COLOR.NEUTRAL_2};
    text-align: center;
    max-width: ${CONTAINER_WIDTH.S}px;
  `
};
