import type { CollectionModel } from '@cyferd/client-engine';
import { GeneralModel, Translate, ViewModel, isDeepEqual, mergeTruthy, noop, replaceDeep, useTranslate } from '@cyferd/client-engine';

import { SchemaForm } from '../SchemaForm';
import { Fragment, useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { CTA, CTAType } from '@components/elements/CTA';
import { styles } from './styles';
import { getFieldList, getSchemaWhenInMetadata } from './resources';
import { GlobalContext } from '../../../state-mgmt/GlobalState';
import { EMPTY } from 'rxjs';
import { OptionMenu } from '@components/elements/OptionMenu';
import { Layout } from '@components/elements/Layout';
import { CyText } from '@components/smart/CyText';
import { TRANS } from '@constants';

export interface OverrideSchemaProps {
  original: CollectionModel.DetailGroup | GeneralModel.JSONSchema;
  override: Partial<CollectionModel.DetailGroup | GeneralModel.JSONSchema['properties']>;
  onChange: (override: Partial<CollectionModel.DetailGroup | GeneralModel.JSONSchema>) => void;
}

export const OverrideSchema = ({ original, override, onChange }: OverrideSchemaProps) => {
  const { translate } = useTranslate();
  const { deps } = useContext(GlobalContext);
  const [activeKey, setActiveKey] = useState<string>(null);

  const fieldList: GeneralModel.JSONSchema[] = useMemo(
    () => getFieldList((original as GeneralModel.JSONSchema)?.format as GeneralModel.JSONSchemaFormat, translate),
    [original, translate]
  );

  const onClear = useCallback(() => {
    deps.modalInteraction.onConfirm(
      {
        status: ViewModel.Status.ERROR,
        icon: 'delete',
        title: 'Clear all overrides for this field',
        description: "You're trying to clear all the overrides from this field. Are you sure you want to continue?",
        confirmLabel: 'Clear'
      },
      () => {
        setActiveKey(null);
        onChange(undefined);
      }
    );
    return EMPTY;
  }, [deps.modalInteraction, onChange]);

  const onInternalChange = (event: any, key: string) => {
    onChange(replaceDeep(override || {}, event, key));
  };

  useEffect(() => {
    setActiveKey(null);
  }, [original]);

  if (!original)
    return (
      <div css={styles.emptyState}>
        <CyText content={translate(TRANS.client.emptyStates.collectionOverride)} titleAlignment={ViewModel.Alignment.CENTER} />
      </div>
    );

  return (
    <div data-testid="OverrideSchema">
      <div css={[styles.propRow, styles.tableHeader]}>
        <div css={styles.propTitle}>Property</div>
        <div css={styles.propTitle}>Original</div>
        <div css={[styles.propTitle, styles.controls]}>
          <span>Overriden</span>
          <div>
            <OptionMenu
              optionList={[
                { type: CTAType.LINK, disabled: !override, label: 'Clear all', testid: 'cta-clear-all', color: 'HC_5', onClick: onClear, important: true }
              ]}
            />
          </div>
        </div>
      </div>
      <Layout type={ViewModel.LayoutType.FULL} fitToPage={true}>
        <div>
          {fieldList.map((field, index) => {
            const { schema: plainField, isMetadata, internalKey } = getSchemaWhenInMetadata(field);
            const emptyTitles = { label: ' ', title: ' ', description: '', info: '', default: undefined };
            const dataSetFramed = { metadata: { subtype: GeneralModel.JSONSchemaSubtype.FRAMED } };
            const cleanInternalField = mergeTruthy(
              { ...plainField, ...emptyTitles },
              plainField.format === GeneralModel.JSONSchemaFormat.OBJECT ? /* istanbul ignore next */ dataSetFramed : {}
            );
            const cleanPropConfig = {
              ...(isMetadata ? mergeTruthy(field, { properties: { [internalKey]: cleanInternalField } }) : cleanInternalField),
              ...emptyTitles
            };
            const rowOriginalExists = ![null, undefined, [], {}].some(i =>
              isDeepEqual(i, isMetadata ? (original as any)?.metadata?.[internalKey] : original?.[field.key])
            );
            const rowOverrideExists = ![null, undefined].includes(isMetadata ? (override as any)?.metadata?.[internalKey] : override?.[field.key]);
            return (
              <Fragment key={plainField.$id}>
                {!!index && <div css={styles.division} />}
                <div css={styles.propRow}>
                  <div css={[styles.propCellBase, styles.propCellCenter, styles.propCellLabel]}>
                    <p css={styles.propTitle}>
                      <Translate>{plainField.label}</Translate>
                    </p>
                  </div>
                  <div css={styles.propCellBase}>
                    {rowOriginalExists && (
                      <SchemaForm
                        schema={cleanPropConfig}
                        value={original?.[field.key]}
                        disabledType={GeneralModel.DisabledType.VIEW_ONLY}
                        disabled={true}
                        shouldValidate={false}
                        onChange={noop}
                      />
                    )}
                  </div>
                  <div css={styles.propCellBase}>
                    {activeKey === plainField.key || rowOverrideExists ? (
                      <SchemaForm
                        schema={cleanPropConfig}
                        value={override?.[field.key]}
                        shouldValidate={false}
                        getOptionMenu={props => [
                          props.path.length === 1 /** removes only from the first level (lists/data-sets) */ && {
                            testid: `delete-${plainField.key}`,
                            image: 'delete',
                            color: 'ERROR_1',
                            tooltip: 'Delete override',
                            important: true,
                            onClick: () => {
                              onInternalChange(undefined, field.key);
                              setActiveKey(null);
                            }
                          }
                        ]}
                        onChange={event => onInternalChange(event, field.key)}
                      />
                    ) : (
                      <div css={[styles.propCellCenter, styles.addBtn]} onClick={() => setActiveKey(plainField.key)}>
                        <CTA type={CTAType.LINK} testid={`cta-${plainField.key}`} icon="add" tooltip="Override value" size={ViewModel.CTASize.MEDIUM} />
                      </div>
                    )}
                  </div>
                </div>
              </Fragment>
            );
          })}
        </div>
      </Layout>
    </div>
  );
};
