import { useContext } from 'react';

import { ApiModel, FlowModel, GeneralModel, ViewModel, createUUID } from '@cyferd/client-engine';
import { useTestingHelper } from '@utils';

import { TRANS, dateSchemaProps, defaultFormDetailGroupList, defaultFormDetailGroupMap } from '@constants';
import type { EditorContextValue } from '../../../shared/EditorHome';
import { EditorContext } from '../../../shared/EditorHome';
import { EmptyState } from '@components/elements/EmptyState';
import { SchemaForm } from '../../../shared/SchemaForm';
import { styles } from './styles';
import { timezones } from './timezones';
import { Layout } from '@components/elements/Layout';

const dateTimePattern =
  '^(19\\d{2}|2\\d{3})-((0[13578]|1[02])-([0-2]\\d|3[01])|02-[0-2]\\d|(0[469]|11)-([0-2]\\d|30))T([01]\\d|2[0-4])(:[0-5]\\d){2}(\\.\\d{3})?(Z|([+-]([01]\\d|2[0-3]):[0-5]\\d))$';

const schema: GeneralModel.JSONSchema = {
  $id: createUUID(),
  type: 'object',
  required: ['name'],
  properties: {
    name: {
      type: 'string',
      label: TRANS.client.fields.titles.name,
      minLength: 1,
      metadata: { detailGroupId: defaultFormDetailGroupMap.basic.id }
    },
    title: {
      type: 'string',
      label: TRANS.client.fields.titles.publicName,
      info: 'A public name is the name visible to your users. If a public name is created, "Name" will only be visible to Admins in the builder.',
      metadata: { detailGroupId: defaultFormDetailGroupMap.basic.id }
    },
    description: {
      type: 'string',
      format: GeneralModel.JSONSchemaFormat.MULTILINE,
      label: TRANS.client.fields.titles.description,
      info: 'This description will be how it appears to your users.',
      metadata: { detailGroupId: defaultFormDetailGroupMap.basic.id }
    },
    enabled: {
      type: 'boolean',
      label: TRANS.client.fields.titles.enabled,
      format: GeneralModel.JSONSchemaFormat.CHECKBOX,
      info: 'A master setting to enable or disable the flow. A disabled flow will not run regardless of the trigger used.',
      metadata: { detailGroupId: defaultFormDetailGroupMap.configuration.id, detailOrder: 1 }
    },
    verboseLog: {
      type: 'boolean',
      label: TRANS.client.fields.titles.verboseLog,
      format: GeneralModel.JSONSchemaFormat.CHECKBOX,
      description: 'WARNING: Input/output will be stored in the log',
      info: 'Controls whether the input and output of a flow will be captured in the flow log. This should be used with caution as some data (such as PII or sensitive values) may be included in the input or outputs and this may be exposed to users who would not normally see that data as they can see the logs of the flow.',
      default: false,
      metadata: { color: 'RD_3', detailGroupId: defaultFormDetailGroupMap.configuration.id, detailOrder: 2 }
    },
    status: {
      type: 'string',
      label: TRANS.client.fields.titles.status,
      format: GeneralModel.JSONSchemaFormat.STRING_OPTION_LIST,
      info: `<p>The current status of the flow, this will indicate the status as of the last execution and will indicate if the last execution was successful (ready), failed or if the flow has failed more than 5 times consecutively (blocked)&nbsp;</p><p>&nbsp;&nbsp;</p><p>Note: A blocked flow will need to be reset before it can run again. This is done by resetting this status and the consecutive error count to less than 5.&nbsp;</p>`,
      metadata: {
        detailGroupId: defaultFormDetailGroupMap.configuration.id,
        detailOrder: 3,
        optionList: [
          { label: 'Ready', value: FlowModel.FlowStatus.READY },
          { label: 'Failed', value: FlowModel.FlowStatus.FAILED },
          { label: 'Blocked', value: FlowModel.FlowStatus.BLOCKED }
        ]
      }
    },
    globalSearchable: {
      type: 'boolean',
      label: TRANS.client.fields.titles.globalSearch,
      format: GeneralModel.JSONSchemaFormat.CHECKBOX,
      default: false,
      info: 'Controls whether the flow will show in the search results in the global search. Being visible in the search results allows certain processes to be found easily and triggered by users. Such flows need to be stand-alone (not require inputs) or require a user to enter details into a form to trigger the flow.',
      metadata: {
        detailGroupId: defaultFormDetailGroupMap.configuration.id,
        detailOrder: 4
      }
    },
    maxStepRuns: {
      type: 'number',
      label: TRANS.client.fields.titles.maxStepRuns,
      description: 'Max number of times each step can run in each execution (to prevent infinite loops)',
      info: `Controls the number of times a step can run during a flow execution. This is a failsafe for any unexpected loops that may occur, and the flow will stop the execution when this limit is reached by any one step. This value will need to be managed if a loop is created in the flow with conditions or if you call another flow with a loop behavior (such as a loop list action step where you call a flow for each record or value in a list).`,
      format: GeneralModel.JSONSchemaFormat.NUMBER,
      default: 5,
      minimum: 1,
      maximum: 1_000,
      metadata: {
        detailGroupId: defaultFormDetailGroupMap.configuration.id,
        detailOrder: 5,
        suggestionList: [{ value: 1 }, { value: 5 }, { value: 10 }, { value: 25 }, { value: 50 }, { value: 100 }]
      }
    },
    hideInApps: {
      type: 'boolean',
      default: false,
      label: TRANS.client.fields.titles.hideInApps,
      info: 'Controls whether the flow is visible as a selection in the app home of any app that this flow is linked to. Some flows may make sense as stand along flows and may make sense to show in the app home. Other flows will not make sense as stand-alone items as they may rely on specific inputs from a record or other trigger input so can be hidden using this setting.',
      format: GeneralModel.JSONSchemaFormat.CHECKBOX,
      metadata: { detailGroupId: defaultFormDetailGroupMap.configuration.id }
    },
    recordColor: {
      type: 'string',
      label: TRANS.client.fields.titles.color,
      format: GeneralModel.JSONSchemaFormat.COLOR,
      metadata: { detailGroupId: defaultFormDetailGroupMap.basic.id }
    },
    recordImage: {
      type: 'string',
      label: TRANS.client.fields.titles.icon,
      format: GeneralModel.JSONSchemaFormat.ICON_IMAGE,
      metadata: { detailGroupId: defaultFormDetailGroupMap.basic.id }
    },
    notes: {
      type: 'string',
      label: TRANS.client.fields.titles.adminNotes,
      info: 'This description is only visible to admin users',
      format: GeneralModel.JSONSchemaFormat.MULTILINE,
      metadata: { detailGroupId: defaultFormDetailGroupMap.basic.id }
    },
    schedules: {
      type: 'array',
      label: TRANS.client.fields.titles.schedules,
      format: GeneralModel.JSONSchemaFormat.ARRAY,
      metadata: { detailGroupId: defaultFormDetailGroupMap.configuration.id, fieldSize: GeneralModel.FieldSize.FULL, startsCollapsed: true },
      items: {
        label: TRANS.client.fields.titles.schedule,
        type: 'object',
        properties: {
          enabled: {
            type: 'boolean',
            label: TRANS.client.fields.titles.enabled,
            format: GeneralModel.JSONSchemaFormat.CHECKBOX,
            default: true
          },
          dueAt: {
            type: 'string',
            label: TRANS.client.fields.titles.nextRun,
            format: GeneralModel.JSONSchemaFormat.DATE_TIME_U,
            metadata: {
              disabled: true
            }
          },
          payload: {
            type: 'object',
            label: TRANS.client.fields.titles.payload,
            format: GeneralModel.JSONSchemaFormat.COLLECTION_RECORD,
            properties: {},
            metadata: {
              collectionId: ApiModel.ApiEntity.FLOW,
              recordId: '{{event.item.id}}',
              subtype: GeneralModel.JSONSchemaSubtype.FRAMED,
              fieldSize: GeneralModel.FieldSize.FULL
            }
          },
          cron: {
            type: 'object',
            label: TRANS.client.fields.titles.whenToRun,
            metadata: { subtype: GeneralModel.JSONSchemaSubtype.FRAMED, fieldSize: GeneralModel.FieldSize.FULL },
            properties: {
              type: {
                type: 'string',
                format: GeneralModel.JSONSchemaFormat.STRING_OPTION_LIST,
                metadata: {
                  optionList: [
                    { label: 'Once a day', value: 'daily' },
                    { label: 'Monday to Friday', value: 'weekdays' },
                    { label: 'Once a week', value: 'weekly' },
                    { label: 'Once a month', value: 'monthly' },
                    { label: 'Custom cron pattern', value: 'custom' }
                  ],
                  disabled: { $cyf_exists: ['{{event.parent.value.pattern}}'] },
                  detailOrder: 1
                },
                label: TRANS.client.fields.titles.type
              },
              pattern: {
                type: 'string',
                label: TRANS.client.fields.titles.customPattern,
                format: GeneralModel.JSONSchemaFormat.TEXT,
                description: 'Pattern format: "m h d M D"',
                pattern:
                  '(@(annually|yearly|monthly|weekly|daily|hourly))|(@every (\\d+(ns|us|µs|ms|s|m|h))+)|((((\\d+,)+\\d+|(\\d+(\\/|-)\\d+)|\\d+|\\*) ?){5,7})',
                metadata: {
                  hidden: { $cyf_and: [{ $cyf_not: [{ $cyf_exists: ['{{event.value}}'] }] }, { $cyf_ne: ['{{event.parent.value.type}}', 'custom'] }] }
                }
              },
              dayOfMonth: {
                type: 'number',
                format: GeneralModel.JSONSchemaFormat.NUMBER_OPTION_LIST,
                metadata: {
                  optionList: Array.from(Array(31).keys()).map(index => ({ value: index + 1 })),
                  hidden: { $cyf_ne: ['{{event.parent.value.type}}', 'monthly'] }
                },
                label: TRANS.client.fields.titles.dayOfMonth
              },
              dayOfWeek: {
                type: 'number',
                label: TRANS.client.fields.titles.dayOWeek,
                format: GeneralModel.JSONSchemaFormat.NUMBER_OPTION_LIST,
                metadata: {
                  optionList: ['Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday', 'Sunday'].map((label, index) => ({
                    label,
                    value: index + 1
                  })),
                  hidden: { $cyf_ne: ['{{event.parent.value.type}}', 'weekly'] }
                }
              },
              time: {
                label: TRANS.client.fields.titles.time,
                type: 'string',
                format: GeneralModel.JSONSchemaFormat.TEXT,
                pattern: '[0-9]{1,2}(:[0-9]{2})?',
                description: 'Format: 00:00',
                default: '09:00',
                metadata: { hidden: { $cyf_not: [{ $cyf_in: ['{{event.parent.value.type}}', 'daily', 'weekdays', 'weekly', 'monthly'] }] } }
              },
              startDate: {
                type: 'string',
                label: TRANS.client.fields.titles.startDate,
                format: GeneralModel.JSONSchemaFormat.TEXT,
                description: 'Date format: "YYYY-MM-DDThh:mm:ss+/-hh:mm"',
                pattern: dateTimePattern
              },
              endDate: {
                type: 'string',
                label: TRANS.client.fields.titles.endDate,
                format: GeneralModel.JSONSchemaFormat.TEXT,
                description: 'Date format: "YYYY-MM-DDThh:mm:ss+/-hh:mm"',
                pattern: dateTimePattern
              },
              timezone: {
                type: 'string',
                label: TRANS.client.fields.titles.timezone,
                format: GeneralModel.JSONSchemaFormat.STRING_OPTION_LIST,
                metadata: { optionList: timezones }
              }
            }
          }
        }
      }
    }
  }
};

const dateSchema: GeneralModel.JSONSchema = {
  $id: createUUID(),
  type: 'object',
  properties: {
    ...dateSchemaProps,
    lastExecutedAt: {
      label: TRANS.client.fields.titles.lastExecutedAt,
      type: 'string',
      format: GeneralModel.JSONSchemaFormat.DATE_TIME_U,
      metadata: { disabled: true, disabledType: GeneralModel.DisabledType.VIEW_ONLY, detailGroupId: 'meta' }
    },
    lastFinishedAt: {
      label: TRANS.client.fields.titles.lastFinishedAt,
      type: 'string',
      format: GeneralModel.JSONSchemaFormat.DATE_TIME_U,
      metadata: { disabled: true, disabledType: GeneralModel.DisabledType.VIEW_ONLY, detailGroupId: 'meta' }
    },
    nextScheduledAt: {
      label: TRANS.client.fields.titles.nextScheduledAt,
      type: 'string',
      format: GeneralModel.JSONSchemaFormat.DATE_TIME_U,
      metadata: {
        detailGroupId: 'meta',
        disabledType: GeneralModel.DisabledType.VIEW_ONLY,
        calculation: {
          $cyf_pluck: [
            {
              $cyf_filter: [
                '{{event.item.schedules}}',
                { $cyf_and: ['{{~filter.enabled}}', { $cyf_gt: [{ $cyf_todate: ['{{~filter.dueAt}}'] }, { $cyf_now: [] }] }] },
                'filter'
              ]
            },
            '0.dueAt'
          ]
        }
      }
    },
    consecutiveErrorCount: {
      type: 'number',
      minimum: 0,
      maximum: 5,
      title: 'Consecutive errors',
      default: 0,
      metadata: {
        disabled: true,
        disabledType: GeneralModel.DisabledType.VIEW_ONLY,
        mask: '0"/5"',
        detailGroupId: 'meta',
        detailOrder: 4,
        color: {
          $cyf_switch: [{ $cyf_eq: [{ $cyf_coalesce: ['{{event.value}}', 0] }, 0] }, 'GN_2', { $cyf_eq: ['{{event.value}}', 5] }, 'RD_3', 'OE_3']
        }
      }
    }
  }
};

export const GeneralInfo = () => {
  const { getTestIdProps } = useTestingHelper('general-info');
  const { item, setItem } = useContext<EditorContextValue<FlowModel.Flow>>(EditorContext);

  if (!item) return <EmptyState />;

  return (
    <div {...getTestIdProps('container')} className={styles.container}>
      <Layout type={ViewModel.LayoutType.TWO_ALT_1}>
        <SchemaForm schema={schema} value={item} onChange={setItem} detailGroupList={defaultFormDetailGroupList} wrapDetailGroups={true} />
        <SchemaForm schema={dateSchema} value={item} onChange={setItem} detailGroupList={defaultFormDetailGroupList} wrapDetailGroups={true} />
      </Layout>
    </div>
  );
};
