import { ApiModel, ofType, swallowError } from '@cyferd/client-engine';
import { useCyActions } from '@utils';
import { useEffect, useReducer } from 'react';
import { type Observable, combineLatest } from 'rxjs';
import { finalize, take, takeUntil, tap } from 'rxjs/operators';
import type { INavigationList, IRecentViews } from '../types';

type State = {
  apps: INavigationList;
  recentViews: IRecentViews;
  isLoading: boolean;
};

type Action = { type: 'SET_APPS'; payload: INavigationList } | { type: 'SET_RECENT_VIEWS'; payload: IRecentViews } | { type: 'SET_LOADING'; payload: boolean };

const initialState: State = {
  apps: JSON.parse(localStorage.getItem('apps') || '[]'),
  recentViews: JSON.parse(localStorage.getItem('recentViews') || '[]'),
  isLoading: !localStorage.getItem('apps')
};

function reducer(state: State, action: Action): State {
  switch (action.type) {
    case 'SET_APPS':
      return { ...state, apps: action.payload };
    case 'SET_RECENT_VIEWS':
      return { ...state, recentViews: action.payload };
    case 'SET_LOADING':
      return { ...state, isLoading: action.payload };
    default:
      return state;
  }
}

function areArraysEqual(arr1: any[], arr2: any[]): boolean {
  return JSON.stringify(arr1) === JSON.stringify(arr2);
}

export const useGlobalHeaderFetcher = (onDestroy$: Observable<void>) => {
  const { onFlowRun } = useCyActions();
  const [{ isLoading, apps, recentViews }, dispatch] = useReducer(reducer, initialState);

  useEffect(() => {
    const apps$ = onFlowRun({ id: 'NAVIGATION.APPS' }).pipe(
      ofType(ApiModel.TriggerActionType.DISPATCH_RESULT),
      take(1),
      tap(({ list }) => {
        const storedApps = JSON.parse(localStorage.getItem('apps') || '[]');
        if (!areArraysEqual(storedApps, list)) {
          dispatch({ type: 'SET_APPS', payload: list });
          localStorage.setItem('apps', JSON.stringify(list));
        }
      }),
      swallowError(),
      takeUntil(onDestroy$)
    );

    const recentViews$ = onFlowRun({ id: 'NAVIGATION.RECENT_VIEWS' }).pipe(
      ofType(ApiModel.TriggerActionType.DISPATCH_RESULT),
      take(1),
      tap(({ list }) => {
        const storedRecentViews = JSON.parse(localStorage.getItem('recentViews') || '[]');
        if (!areArraysEqual(storedRecentViews, list)) {
          dispatch({ type: 'SET_RECENT_VIEWS', payload: list });
          localStorage.setItem('recentViews', JSON.stringify(list));
        }
      }),
      swallowError(),
      takeUntil(onDestroy$)
    );

    const combined$ = combineLatest([apps$, recentViews$]).pipe(
      finalize(() => {
        dispatch({ type: 'SET_LOADING', payload: false });
      })
    );

    const subscription = combined$.subscribe();

    return () => {
      subscription.unsubscribe();
    };
  }, [onDestroy$, onFlowRun]);

  return {
    apps,
    recentViews,
    isLoading
  };
};
