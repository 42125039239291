import { COLOR, GAP, RADIUS, SHADOW } from '@constants';
import { css } from '@emotion/react';

export const styles = {
  container: css`
    padding: ${GAP.M} 0;
    border-radius: ${RADIUS.M};
    width: 300px;
    height: max-content;
    max-height: 600px;
    gap: ${GAP.M};
    box-shadow: ${SHADOW.XS};
    background-color: ${COLOR.NEUTRAL_5};
    overflow: auto;
    position: relative;
  `
};
